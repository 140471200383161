import { DestroyRef, Directive, forwardRef, inject, Input, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { MarinasService } from '@dm-workspace/core';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[selectedMarinaRouterLink]',
  providers: [
    {
      provide: RouterLink,
      useExisting: forwardRef(() => SelectedMarinaRouterLinkDirective),
    },
  ],
})
export class SelectedMarinaRouterLinkDirective extends RouterLink implements OnInit {
  #marinasService = inject(MarinasService);
  #destroyRef = inject(DestroyRef);
  #routerLink = new BehaviorSubject<typeof RouterLink.prototype.routerLink>(null);
  @Input() set selectedMarinaRouterLink(value: typeof RouterLink.prototype.routerLink) {
    this.#routerLink.next(value);
  }

  // Workaround IDE error requiring routerLink attribute
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  @Input() override routerLink: never;

  ngOnInit() {
    combineLatest([this.#marinasService.selectedMarina$, this.#routerLink])
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        tap(([marina, routerLink]) => {
          const routerLinkString = this.#getRouterLinkString(routerLink);
          super.routerLink = `/${marina.code}${routerLinkString}`;
          this.ngOnChanges(null);
        })
      )
      .subscribe();
  }

  #getRouterLinkString(routerLink: typeof RouterLink.prototype.routerLink): string {
    if (typeof routerLink === 'object') {
      return routerLink.join('/');
    }
    return routerLink;
  }
}
