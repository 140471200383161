<table class="table selection-table">
  <thead>
    <th>{{ "BERTH" | translate }}</th>
    <th>{{ "LENGTH_M" | translate }}</th>
    <th>{{ "WIDTH_M" | translate }}</th>
    <th class="text-right">{{ "TOTAL_PRICE" | translate }}</th>
  </thead>
  <tbody>
    <tr>
      <td>
        <dm-form-group>
          <dm-form-select-typeahead
            class="selectable-berth"
            typeahead="resources"
            [notFoundText]="'NO_BERTHS_FOUND' | translate"
            bindLabel="name"
            [placeholder]="'BERTH' | translate"
            [marinaCode]="marinaCode"
            [addonRequestParams]="resourceAddonParams"
            (itemChanged)="fetchPricing($event)"
          />
        </dm-form-group>
      </td>
      <td>{{ (resourceData?.pricingDetails ? boat?.loa : "") | empty }}</td>
      <td>{{ (resourceData?.pricingDetails ? boat?.maxBeam : "") | empty }}</td>
      <td class="text-right">
        <ng-container *ngIf="resourceData?.pricingDetails">
          {{ getTotalPrice() | currency : resourceData.pricingDetails.currency }}
          <i class="ms-1 icon i-info"
             [ngbTooltip]="totalPriceTooltip"
             container="body"
          ></i>
          <ng-template #totalPriceTooltip>
            <p class="mb-1"><strong>{{ 'TOTAL_PRICE_INCLUDE' | translate }}:</strong></p>
            <p class="d-flex justify-content-between">
              <span class="pe-3">{{ "MARINA_PRODUCT_MANDATORY_.MOORING" | translate }}</span>
              <span class="text-right">{{ resourceData.pricingDetails.sumPriceGross | currency : resourceData.pricingDetails.currency }}</span>
            </p>
            <p *ngFor="let product of mandatoryProductsWithoutMooring" class="d-flex justify-content-between mt-1">
              <span class="pe-3">{{ "MARINA_PRODUCT_MANDATORY_." + product.product | translate }}</span>
              <span class="text-right">{{ product.totalPriceGross | currency : (product.currency) }}</span>
            </p>
          </ng-template>
        </ng-container>
      </td>
    </tr>
  </tbody>
</table>

<dm-ui-overflow-spinner [showSpinner]="pending" size="sm" type="absolute" />
