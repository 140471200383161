import { Directive, EventEmitter, inject, Input, Output } from '@angular/core';
import { Quote } from '@dm-workspace/types';
import { MmsOfferActionSendTarget } from '../../modals/mms-offer-action-send-modal/mms-offer-action-send-modal.component';
import { filter } from 'rxjs';
import { tap } from 'rxjs/operators';
import { OrderPaymentStatus, QuoteStatus, QuoteType } from '@dm-workspace/types';
import { MmsOfferActionsService } from '../../services/mms-offer-actions';

@Directive()
export abstract class MmsOffersTableBase {
  @Input({ required: true }) public offers: Quote[];
  @Output() protected offersChange = new EventEmitter<void>();
  protected readonly QuoteStatus = QuoteStatus;
  protected readonly OrderPaymentStatus = OrderPaymentStatus;
  protected readonly QuoteType = QuoteType;
  readonly #offerActionsService = inject(MmsOfferActionsService);
  protected clickButtonSendOffer(offer: Quote, target: MmsOfferActionSendTarget) {
    this.#offerActionsService
      .clickButtonSendOffer(offer, target)
      .pipe(
        filter(Boolean),
        tap(() => this.offersChange.emit())
      )
      .subscribe();
  }

  protected clickButtonSendReminderOffer(offer: Quote, target: MmsOfferActionSendTarget) {
    this.#offerActionsService
      .clickButtonSendReminderOffer(offer, target)
      .pipe(
        filter(Boolean),
        tap(() => this.offersChange.emit())
      )
      .subscribe();
  }

  protected clickButtonAcceptOffer(offer: Quote) {
    this.#offerActionsService
      .clickButtonAcceptOffer(offer)
      .pipe(
        filter(Boolean),
        tap(() => this.offersChange.emit())
      )
      .subscribe();
  }

  protected clickButtonSendPaymentReminder(offer: Quote) {
    this.#offerActionsService
      .clickButtonSendPaymentReminder(offer)
      .pipe(
        filter(Boolean),
        tap(() => this.offersChange.emit())
      )
      .subscribe();
  }

  protected clickButtonMarkOrderAsPaid(offer: Quote) {
    this.#offerActionsService
      .clickButtonMarkOrderAsPaid(offer)
      .pipe(
        filter(Boolean),
        tap(() => this.offersChange.emit())
      )
      .subscribe();
  }

  protected clickButtonCancelOffer(offer: Quote) {
    this.#offerActionsService
      .clickButtonCancelOffer(offer)
      .pipe(
        filter(Boolean),
        tap(() => this.offersChange.emit())
      )
      .subscribe();
  }

  protected clickButtonDeclineOffer(offer: Quote) {
    this.#offerActionsService
      .clickButtonDeclineOffer(offer)
      .pipe(
        filter(Boolean),
        tap(() => this.offersChange.emit())
      )
      .subscribe();
  }
}
