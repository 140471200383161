import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { Quote, QuoteStatus, QuoteType } from '@dm-workspace/types';

@Component({
  selector: 'dm-mms-table-cell-total-price',
  templateUrl: './mms-table-cell-total-price.component.html',
  styleUrls: ['./mms-table-cell-total-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsTableCellTotalPriceComponent {
  quote = input.required<Quote>();
  showingTotalPrice = computed(() => {
    const isAnnual = this.quote().type === QuoteType.ANNUAL_SALES;
    if (!isAnnual) {
      return true;
    }

    const statusesForHide = [
      QuoteStatus.NEW,
      QuoteStatus.DRAFT,
      QuoteStatus.WAITING_LIST,
      QuoteStatus.PENDING_FOR_APPROVAL,
    ];
    if (statusesForHide.includes(this.quote().quoteStatus)) {
      return false;
    }

    return true;
  });
  protected readonly QuoteType = QuoteType;
}
