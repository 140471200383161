import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MmsUiFullPageHeaderComponent } from './components/mms-ui-full-page-header/mms-ui-full-page-header.component';
import { MmsUiFullPageComponent } from './components/mms-ui-full-page/mms-ui-full-page.component';
import { MmsUiFullPageFooterComponent } from './components/mms-ui-full-page-footer/mms-ui-full-page-footer.component';
import { RouterModule } from '@angular/router';
import { MmsUiFullPageSidebarComponent } from './components/mms-ui-full-page-sidebar/mms-ui-full-page-sidebar.component';

const declarations = [
  MmsUiFullPageSidebarComponent,
  MmsUiFullPageHeaderComponent,
  MmsUiFullPageComponent,
  MmsUiFullPageFooterComponent,
];

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [...declarations],
  exports: [...declarations],
})
export class MmsUiModule {}
