import { inject } from '@angular/core';
import { Routes } from '@angular/router';
import { authGuard, Features, FeaturesGuard, PermissionGuard, PermissionService } from '@dm-workspace/core';
import {
  MmsLayoutMainViewComponent,
  MmsLayoutMarinaCodeWrapperViewComponent,
  MmsLayoutNotFoundViewComponent,
  mmsLayoutRedirectMap,
} from '@dm-workspace/mms-panel/mms-layout';
import { Ability } from '@dm-workspace/types';
import { MmsMarinaCodeGuard, SelectedMarinaRouter } from '@dm-workspace/mms-panel/shared';

export const mmsAppRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('@dm-workspace/mms-panel/mms-auth').then((m) => m.MmsAuthModule),
  },
  {
    path: 'login',
    redirectTo: 'auth/login',
  },
  {
    path: '',
    canMatch: [authGuard],
    component: MmsLayoutMainViewComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        children: [],
        canActivate: [
          () => {
            const permission = inject(PermissionService);
            const selectedMarinaRouter = inject(SelectedMarinaRouter);
            if (permission.checkUserPermission(Ability.READ_BOAT)) {
              return selectedMarinaRouter.parseUrl(`/bookings/map/reservations`);
            }
            if (permission.checkUserPermission(Ability.MANAGE_TASK_MANAGEMENT)) {
              return selectedMarinaRouter.parseUrl(`/planner`);
            }
          },
        ],
      },
      {
        path: 'boats',
        loadChildren: () => import('@dm-workspace/mms-panel/boats').then((m) => m.MmsPanelBoatsModule),
        canLoad: [PermissionGuard],
        data: {
          permissions: mmsLayoutRedirectMap.boats,
        },
      },
      {
        path: 'users',
        loadChildren: () => import('@dm-workspace/mms-panel/mms-users').then((m) => m.MmsUsersModule),
        canLoad: [PermissionGuard],
        data: {
          permissions: [mmsLayoutRedirectMap.users, mmsLayoutRedirectMap.customers],
        },
      },
      {
        path: 'customers',
        loadChildren: () => import('@dm-workspace/mms-panel/mms-customers').then((m) => m.MmsCustomersModule),
        canMatch: [
          () => {
            const permission = inject(PermissionService);
            return permission.checkUserPermission(Ability.READ_CUSTOMER);
          },
        ],
      },
      // {
      //   path: 'translations',
      //   loadChildren: () => import('@dm-workspace/mms-panel/mms-translations').then((m) => m.MmsTranslationsModule),
      //   canLoad: [PermissionGuard],
      //   data: {
      //     permissions: mmsLayoutRedirectMap.translations,
      //   },
      // },
      {
        path: '404',
        component: MmsLayoutNotFoundViewComponent,
        data: {
          errorCode: '404',
        },
      },
      {
        path: '403',
        component: MmsLayoutNotFoundViewComponent,
        data: {
          errorCode: '403',
        },
      },
      {
        path: ':marinaCode',
        component: MmsLayoutMarinaCodeWrapperViewComponent,
        providers: [],
        canActivate: [MmsMarinaCodeGuard],
        children: [
          {
            path: 'dashboard',
            loadChildren: () => import('@dm-workspace/mms-panel/mms-dashboard').then((m) => m.MmsDashboardModule),
            canMatch: [
              () => {
                const permission = inject(PermissionService);
                return permission.checkUserPermission(Ability.READ_DASHBOARD);
              },
            ],
          },
          {
            path: 'marina',
            pathMatch: 'prefix',
            redirectTo: 'bookings',
          },
          {
            path: 'bookings',
            loadChildren: () => import('@dm-workspace/mms-panel/marina').then((m) => m.MmsPanelMarinaModule),
            canLoad: [PermissionGuard],
            data: {
              permissions: mmsLayoutRedirectMap.bookings,
            },
          },
          {
            path: 'products',
            loadChildren: () => import('@dm-workspace/mms-panel/mms-products').then((m) => m.MmsProductsModule),
            canLoad: [PermissionGuard],
            data: {
              permissions: mmsLayoutRedirectMap['products'],
            },
          },
          {
            path: 'contracts',
            loadChildren: () => import('@dm-workspace/mms-panel/contracts').then((m) => m.MmsPanelContractsModule),
            canLoad: [PermissionGuard],
            data: {
              permissions: mmsLayoutRedirectMap.contracts,
            },
          },
          {
            path: 'orders',
            loadChildren: () => import('@dm-workspace/mms-panel/mms-orders').then((m) => m.MmsOrdersModule),
            canLoad: [PermissionGuard],
            data: {
              permissions: mmsLayoutRedirectMap.orders,
            },
          },
          {
            path: 'planner',
            loadChildren: () => import('@dm-workspace/mms-panel/mms-planner').then((m) => m.MmsPlannerModule),
            canMatch: [
              () => {
                const permission = inject(PermissionService);
                return permission.checkUserPermission(Ability.MANAGE_TASK_MANAGEMENT);
              },
            ],
          },
          {
            path: 'pylons',
            loadChildren: () => import('@dm-workspace/mms-panel/mms-pylons').then((m) => m.MmsPylonsModule),
            canActivate: [FeaturesGuard],
            data: {
              feature: Features.pylons,
            },
          },
          {
            path: 'renewals',
            loadChildren: () => import('@dm-workspace/mms-panel/mms-offers').then((m) => m.MmsOffersModule),
            canLoad: [PermissionGuard],
            data: {
              permissions: mmsLayoutRedirectMap.orders,
            },
          },
          {
            path: 'enquiry',
            loadChildren: () => import('@dm-workspace/mms-panel/mms-enquiry').then((m) => m.MmsEnquiryModule),
          },
          {
            path: 'admin',
            canLoad: [PermissionGuard],
            data: {
              permissions: Ability.UPDATE_MARINA,
            },
            children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: 'map',
              },
              {
                path: 'map',
                loadChildren: () => import('@dm-workspace/map-admin-tools').then((m) => m.MmsMapAdminToolsModule),
              },
              {
                path: 'marina',
                loadChildren: () => import('@dm-workspace/mms-panel/mms-admin').then((m) => m.MmsAdminModule),
              },
            ],
          },
          {
            path: 'journal-tasks',
            loadChildren: () =>
              import('@dm-workspace/mms-panel/mms-journal-tasks').then((m) => m.MmsJournalTasksModule),
          },
        ],
      },
    ],
  },
  {
    path: '**',
    redirectTo: '404',
  },
];
