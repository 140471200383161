import { Component, Input } from '@angular/core';
import { ApiValidatorService } from '@dm-workspace/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MmsCustomerApiService } from '@dm-workspace/data-access';
import { finalize } from 'rxjs/operators';
import { ProfileDetailsFormService, ProfileDocumentsFormService } from '@dm-workspace/shared';
import { MmsCustomerForm } from '../../forms/mms-customer-form/mms-customer-form.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from '@dm-workspace/notification';

@Component({
  selector: 'dm-mms-customer-contact-form-modal',
  templateUrl: './mms-customer-contact-form-modal.component.html',
  styleUrls: ['./mms-customer-contact-form-modal.component.scss'],
})
export class MmsCustomerContactFormModalComponent {
  @Input() public form: MmsCustomerForm;
  public pending = false;

  constructor(
    private profileDetailsFormService: ProfileDetailsFormService,
    private profileDocumentsFormService: ProfileDocumentsFormService,
    private apiValidator: ApiValidatorService,
    private activeModal: NgbActiveModal,
    private customerService: MmsCustomerApiService,
    private notification: NotificationService
  ) {}

  public submit(): void {
    if (this.pending || !this.apiValidator.formIsValid(this.form)) {
      return;
    }
    this.pending = true;

    const formValues = this.form.getRawValue();
    const profileDocumentsValue = this.profileDocumentsFormService.transformFormValueToPayload(formValues);
    const profileDetailsValue = this.profileDetailsFormService.transformFormValueToPayload(formValues);

    this.customerService
      .createIndividualCustomer({
        ...profileDocumentsValue,
        ...profileDetailsValue,
      })
      .pipe(finalize(() => (this.pending = false)))
      .subscribe({
        next: (customer) => {
          this.activeModal.close(customer);
        },
        error: (res: HttpErrorResponse) => {
          this.apiValidator.setApiValidationErrors(this.form, res);
          const content = this.apiValidator.getApiNotificationError(res);
          this.notification.openError(content);
        },
      });
  }

  public dismissModal(): void {
    this.activeModal.dismiss();
  }
}
