import { ChangeDetectionStrategy, Component, inject, Input, signal } from '@angular/core';
import { BoatMovementOperation, IBaseId, PostBoatMovementBody, ResourceType } from '@dm-workspace/types';
import { MmsBoatsApiService } from '@dm-workspace/data-access';
import { NotificationService } from '@dm-workspace/notification';
import { ApiValidator } from '@dm-workspace/shared';
import { ConfirmModal } from '@dm-workspace/ui';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormUtils } from '@dm-workspace/forms';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { BoatMovementService } from '../../services/boat-movement.service';
import { of } from 'rxjs';

@Component({
  selector: 'dm-mms-boat-departure-modal',
  templateUrl: './mms-boat-departure-modal.component.html',
  styleUrls: ['./mms-boat-departure-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsBoatDepartureModalComponent extends ConfirmModal {
  readonly #boatsApiService = inject(MmsBoatsApiService);
  readonly #boatMovementService = inject(BoatMovementService);
  readonly #notificationService = inject(NotificationService);

  @Input({ required: true }) boatId: string;
  @Input() set resource(value: IBaseId) {
    this.form.controls.source.setValue(value);
  }
  protected readonly apiCalling = signal<boolean>(false);
  protected readonly ResourceType = ResourceType;

  protected readonly form = new FormGroup({
    source: new FormControl<IBaseId>(null, { validators: [Validators.required] }),
  });

  submitForm() {
    if (this.apiCalling() || !FormUtils.isValid(this.form)) {
      return;
    }

    this.apiCalling.set(true);

    const formValue = this.form.getRawValue();
    const dto: PostBoatMovementBody = {
      sourceId: formValue.source.id,
      startsAt: new Date().toISOString(),
      operation: BoatMovementOperation.DEPARTURE,
    };

    this.#boatMovementService
      .validAndAcceptMovement(this.boatId, dto)
      .pipe(
        switchMap((accepted) => {
          if (!accepted) {
            return of(false);
          }

          return this.#boatsApiService.movement(this.boatId, dto).pipe(
            tap(() => {
              this.#notificationService.openSuccess();
              super.onConfirm();
            })
          );
        }),
        finalize(() => this.apiCalling.set(false))
      )
      .subscribe({
        error: (res: HttpErrorResponse) => {
          this.#notificationService.openError(ApiValidator.getApiNotificationError(res));
        },
      });
  }
}
