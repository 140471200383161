import { inject, Injectable, OnDestroy, TemplateRef } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { UserMeSse, UserMeSseType } from '@dm-workspace/types';
import { filter, tap } from 'rxjs/operators';
import { BoatsService, MarinasService, UserService } from '@dm-workspace/core';
import { NotificationBrowserService, NotificationService } from '@dm-workspace/notification';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectedMarinaRouter } from '@dm-workspace/mms-panel/shared';

@Injectable({
  providedIn: 'root',
})
export class MmsDashboardBoatAlertNotificationService implements OnDestroy {
  #selectedMarinaRouter = inject(SelectedMarinaRouter);
  constructor(
    private userService: UserService,
    private boatsService: BoatsService,
    private notificationService: NotificationService,
    private notificationBrowserService: NotificationBrowserService,
    private ngbModal: NgbModal,
    private marinaService: MarinasService
  ) {}

  public ngOnDestroy() {
    this.closeUserSse();
  }

  public showBoatAlerts(alertTemplate: TemplateRef<HTMLElement>): Observable<UserMeSse> {
    return this.marinaService.selectedMarina$.pipe(
      switchMap((marina) =>
        this.userService.getUserSse(true).pipe(
          filter(
            (event) => event && event.data?.type === UserMeSseType.BOAT_ALERT && event.data?.marinaCode === marina?.code
          ),
          tap((event) => {
            const { data } = event;
            this.showNotification(alertTemplate, data?.context?.boatAlertId);
            this.boatsService.triggerViewRefresh('sensors');
          })
        )
      )
    );
  }

  public closeUserSse(): void {
    return this.userService.closeUserSse();
  }

  private showNotification(contentTpl: TemplateRef<HTMLElement>, alertId: string): void {
    this.notificationService.add({
      type: 'error',
      contentTpl,
      persistent: true,
      hideDefaultTitle: true,
      onCloseCallback: () => this.onClose(alertId),
    });

    this.notificationBrowserService.notify(
      'NEW_BOAT_ALERT',
      {
        body: 'VIEW_MAP_WITH_BOAT_ALERTS',
        requireInteraction: true,
        tag: 'boat-alerts',
      },
      () => this.onClose(alertId)
    );
  }

  private onClose(alertId: string): void {
    this.ngbModal.dismissAll(false);
    this.#selectedMarinaRouter.navigate(['bookings', 'map', 'boat-alerts'], { fragment: alertId });
    this.notificationService.close();
  }
}
