<a class="item" [class.done]="stats?.todoCount === 0" [selectedMarinaRouterLink]="'/dashboard/' + link" routerLinkActive="active">
  <div class="item__inner">
    <div class="body px-3 px-xxl-4 py-4">
      <div class="position-relative">
        <p class="todo">{{ stats?.todoCount | empty }}</p>
        <h2 class="title">
          {{ titleKey | translate
          }}<dm-ui-icon-tooltip
            *ngIf="tooltipText"
            [tooltipText]="tooltipText | translate"
            container="body"
            color="secondary"
           />
        </h2>
      </div>
    </div>
  </div>
</a>
