<div class="px-xxl py-xl" *ngLet="stats$ | async as stats">
  <div class="row gx-4 gy-4">
    <div class="col-12 col-xl-6">
      <div class="header px-4">
        <h2 class="title">
          <i class="icon i-calendar"></i>
          {{ "BOAT_DID_NOT_LEFT_THE_MARINA" | translate }}
          <dm-ui-icon-tooltip
            [tooltipText]="'MMS_DASHBOARD.TOOLTIPS.UNFINISHED_BOOKINGS' | translate"
            color="secondary"
           />
        </h2>
      </div>

      <dm-mms-dashboard-unfinished-bookings />
    </div>

    <div class="col-12 col-xl-6">
      <div class="header px-4">
        <h2 class="title">
          <i class="icon i-users"></i>
          {{ "CUSTOMER_DID_NOT_SHOW" | translate }}
          <dm-ui-icon-tooltip
            [tooltipText]="'MMS_DASHBOARD.TOOLTIPS.CUSTOMER_DID_NOT_SHOW' | translate"
            color="secondary"
           />
        </h2>
      </div>

      <dm-mms-dashboard-customer-did-not-show />
    </div>
  </div>
</div>
