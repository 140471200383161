@if (records) {
  <dm-ui-card color="blue" [shadow]="true" class="record-card">
    <dm-ui-card-body>
      @if (label) {
        <p class="card-label">{{ label | translate }}</p>
      }
      <div class="row">
        @for (column of getEntries; track column) {
          <div class="col-6">
            @for (entry of column; track entry) {
              <div class="d-flex justify-content-between record">
                <p class="key">{{ entry[0] }}</p>
                <p class="value">{{ entry[1] }}</p>
              </div>
            }
          </div>
        }
      </div>
    </dm-ui-card-body>
  </dm-ui-card>
}
