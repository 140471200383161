import { Pipe, PipeTransform } from '@angular/core';
import { QuoteCrmStatus, Quote } from '@dm-workspace/types';

@Pipe({
  name: 'offerCanResendPipe',
})
export class MmsOfferCanResendPipe implements PipeTransform {
  public transform(quote: Quote): boolean {
    const isSent = [QuoteCrmStatus.IN_PROGRESS, QuoteCrmStatus.PENDING_FOR_APPROVAL].includes(quote.crmStatus);
    return isSent;
  }
}
