import { IPier } from '@dm-workspace/types';

const markerTemplate = (width: number) => {
  const rectWidth = width - 1;
  // const rx = width / 2;
  return `<svg width="${width}" height="24" viewBox="0 0 ${width} 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.5" y="0.5" width="${rectWidth}" height="23" rx="11.5" fill="white"/>
  <rect x="0.5" y="0.5" width="${rectWidth}" height="23" rx="11.5" stroke="#1B253A"/>
</svg>
`;
};
const markerWidth = 24;
export function getPierMarkerOptions(pier: IPier, lat: number, lng: number, editable = false) {
  const width = Math.max(markerWidth, pier.name.length * 10);
  return {
    position: new google.maps.LatLng(lat, lng),
    draggable: editable,
    id: pier.id,
    label: {
      text: pier.name,
      fontWeight: '700',
      fontSize: '11px',
      fontFamily: 'Montserrat',
    },
    icon: {
      url: 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(markerTemplate(width)),
      size: new google.maps.Size(width, 24),
      labelOrigin: new google.maps.Point(width / 2, 12),
    },
  };
}
