import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { IOrder, ResourceBookingResponse, ServiceBusResendToCrmEntity } from '@dm-workspace/types';
import { finalize } from 'rxjs';
import { OrdersApiService, ServiceBusApiService } from '@dm-workspace/data-access';
import { NotificationService } from '@dm-workspace/notification';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiValidatorService } from '@dm-workspace/forms';

@Component({
  selector: 'dm-mms-shared-resend-to-crm',
  templateUrl: './resend-to-crm.component.html',
  styleUrls: ['./resend-to-crm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResendToCrmComponent implements OnInit {
  @Input() public booking: ResourceBookingResponse;
  @Input() public order: IOrder;
  private _orderFetched: IOrder;
  public pending: boolean;

  constructor(
    private serviceBusApiService: ServiceBusApiService,
    private notification: NotificationService,
    private orderService: OrdersApiService,
    private cd: ChangeDetectorRef,
    private apiValidator: ApiValidatorService
  ) {}

  public ngOnInit() {
    this.fetchBookingOrder();
  }

  private fetchBookingOrder() {
    if (!this.booking?.order || this.order) {
      return;
    }

    this.pending = true;

    this.orderService
      .fetchById(this.booking.order.id)
      .pipe(
        finalize(() => {
          this.pending = false;
          this.cd.detectChanges();
        })
      )
      .subscribe((order) => {
        this._orderFetched = order;
      });
  }

  public resendBookingToServiceBus(): void {
    if (this.pending || !this.booking) {
      return;
    }

    this.pending = true;

    this.serviceBusApiService
      .resendEntities({
        entities: [
          {
            entity: ServiceBusResendToCrmEntity.OnlineBookingReservation,
            id: this.booking.id,
          },
        ],
      })
      .pipe(
        finalize(() => {
          this.pending = false;
          this.cd.detectChanges();
        })
      )
      .subscribe({
        next: () => {
          this.notification.add({
            content: 'ONLINE_BOOKING_RESERVATION_RESEND_TO_CRM_SUCCESS',
          });
        },
        error: (res: HttpErrorResponse) => {
          const notificationContent = this.apiValidator.getApiNotificationError(res);
          this.notification.openError(notificationContent);
        },
      });
  }

  public get orderId(): string {
    return (this.order || this._orderFetched)?.id || this.booking?.order?.id;
  }

  public resendOrderToServiceBus(): void {
    if (this.pending || !this.orderId) {
      return;
    }

    this.pending = true;

    this.serviceBusApiService
      .resendEntities({
        entities: [
          {
            entity: ServiceBusResendToCrmEntity.Order,
            id: this.orderId,
          },
        ],
      })
      .pipe(
        finalize(() => {
          this.pending = false;
          this.cd.detectChanges();
        })
      )
      .subscribe({
        next: () => {
          this.notification.add({
            content: 'ORDER_RESEND_TO_CRM_SUCCESS',
          });
        },
        error: (res: HttpErrorResponse) => {
          const notificationContent = this.apiValidator.getApiNotificationError(res);
          this.notification.openError(notificationContent);
        },
      });
  }

  public get orderProductId(): string {
    return (this.order || this._orderFetched)?.associatedProducts[0]?.id;
  }

  public resendOrderProductToServiceBus(): void {
    if (this.pending || !this.orderProductId) {
      return;
    }

    this.pending = true;

    this.serviceBusApiService
      .resendEntities({
        entities: [
          {
            entity: ServiceBusResendToCrmEntity.OrderProduct,
            id: this.orderProductId,
          },
        ],
      })
      .pipe(
        finalize(() => {
          this.pending = false;
          this.cd.detectChanges();
        })
      )
      .subscribe({
        next: () => {
          this.notification.add({
            content: 'ORDER_PRODUCT_RESEND_TO_CRM_SUCCESS',
          });
        },
        error: (res: HttpErrorResponse) => {
          const notificationContent = this.apiValidator.getApiNotificationError(res);
          this.notification.openError(notificationContent);
        },
      });
  }
}
