import { ChangeDetectionStrategy, Component, effect, inject, signal } from '@angular/core';
import { MmsQuotesApiService } from '@dm-workspace/data-access';
import { FormUtils } from '@dm-workspace/forms';
import { catchError, throwError } from 'rxjs';
import { NotificationService } from '@dm-workspace/notification';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiValidator } from '@dm-workspace/shared';
import { finalize, tap } from 'rxjs/operators';
import { Quote } from '@dm-workspace/types';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'dm-mms-quote-change-valid-until-modal',
  templateUrl: './mms-quote-change-valid-until-modal.component.html',
  styleUrls: ['./mms-quote-change-valid-until-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsQuoteChangeValidUntilModalComponent {
  #aModal = inject(NgbActiveModal);
  #quotesApi = inject(MmsQuotesApiService);
  #notificationService = inject(NotificationService);

  quote = signal<Quote>(null);
  protected todayDate = new Date();
  protected readonly formSubmitting = signal<boolean>(false);
  protected readonly form = new FormGroup({
    validUntil: new FormControl<string>(null, [Validators.required]),
  });

  constructor() {
    effect(() => {
      this.form.patchValue(this.quote());
    });
  }

  protected submitForm() {
    if (this.formSubmitting() || !FormUtils.isValid(this.form)) {
      return;
    }
    this.formSubmitting.set(true);

    const formValue = this.form.getRawValue();

    this.#quotesApi
      .changeValidUntil(this.quote().quoteId, formValue)
      .pipe(
        catchError((res: HttpErrorResponse) => {
          this.#notificationService.openError(ApiValidator.getApiNotificationError(res));
          return throwError(() => res);
        }),
        finalize(() => this.formSubmitting.set(false)),
        tap(() => {
          this.#notificationService.openSuccess();
          this.#aModal.close(true);
        })
      )
      .subscribe();
  }

  protected closeModal() {
    this.#aModal.close(false);
  }
}
