@if (hasItemsToShow()) {
  <section class="mb-5">
    <h3 class="header-16 fw-700 mb-3">{{ "SENSOR_STATUS" | translate }}</h3>

    @for (sensor of boatSensorList(); track sensor.boat.id) {
      @if (sensor.booking?.customerDisplayName) {
        <div class="py-2">
          <label for="customer" class="me-2 typo-body-m fw-500">{{ "CUSTOMER" | translate }}:</label>
          <a
            id="customer"
            class="details-link text-nowrap me-2 typo-body-m fw-500"
            [routerLink]="['/', 'customers', sensor.booking.customerId, 'details']"
            [state]="{ backOnClose: true }"
            returnUrlCurrentPath
            >{{ sensor.booking.customerDisplayName }}</a
          >
        </div>
      }

      <div class="d-flex align-items-baseline py-2">
        <label for="booking" class="me-2 typo-body-m fw-500">{{ "BOOKING" | translate }}:</label>
        <div id="booking" class="d-flex align-items-baseline w-100 justify-content-between">
          @if (sensor.booking?.humanReadableId) {
            <div>
              <a
                class="details-link text-nowrap me-2 typo-body-m fw-500"
                [routerLink]="['/', marinaCode(), 'bookings', sensor.booking.id, 'details']"
                [state]="{ backOnClose: true }"
                returnUrlCurrentPath
                >{{ sensor.booking.humanReadableId }}</a
              >
              <span class="typo-body-m fw-600">{{ sensor.booking.contractType }}</span>
            </div>
          } @else {
            <span class="typo-body-m fw-600 color-danger">{{ "NO_BOOKING" | translate }}</span>
          }
        </div>
      </div>

      <div class="d-flex py-2 justify-content-between align-items-center">
        <div class="w-100 overflow-hidden long-text">
          <label for="boat" class="me-2 typo-body-m fw-500">{{ "BOAT" | translate }}:</label>
          <a
            id="boat"
            class="details-link text-nowrap me-2 typo-body-m fw-500"
            [routerLink]="['/', 'boats', sensor.boat.id]"
            [state]="{ backOnClose: true }"
            returnUrlCurrentPath
            >{{ sensor.boat.name }}</a
          >
        </div>
        <div class="w-100 text-end">
          <dm-ui-tag [color]="getBoatSensor(sensor) | tagColorByBerthStat" size="small">
            {{ "MAP_BERTH_STATUS." + getBoatSensor(sensor) | translate }}
          </dm-ui-tag>
          @if (sensor.eligibleContract) {
            <button
              type="button"
              class="btn btn-secondary btn-secondary-outline py-2 px-3 ms-2 list-action-button"
              (click)="openHandOverPopup(sensor.eligibleContract.customerEmail, sensor.eligibleContract.contractId)"
            >
              {{ "HAND_OVER" | translate | uppercase }}
            </button>
          }
        </div>
      </div>

      @if (sensor.alerts) {
        @for (alert of sensor.alerts; track alert.alertId) {
          <div class="d-flex flex-column">
            <div class="d-flex py-2 align-items-start justify-items-center">
              <dm-ui-tag [color]="alert | alertStatusColor" class="tag-min-width me-2">{{
                alert | alertStatusTranslateKey | translate
              }}</dm-ui-tag>
              <div class="d-flex flex-column">
                <span class="typo-body-m fw-600 color-danger alert-text">{{
                  "ALERT_.TYPES_." + alert.type | translate
                }}</span>
                <span class="typo-body-s fw-500">{{ alert.createdDate | dateFormat: "date_time" }}</span>
              </div>
              <a
                class="btn btn-secondary btn-secondary-outline p-2 list-action-button"
                [style.min-width]="'104px'"
                [routerLink]="['/boats', alert.boatId, 'sensors']"
                returnUrlCurrentPath
                >{{ "DETAILS" | translate }}</a
              >
            </div>
          </div>
        }
      }
      <dm-ui-horizontal-line />
    }
  </section>
}
