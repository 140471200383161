import { ChangeDetectionStrategy, Component, inject, Input, signal } from '@angular/core';
import { ConfirmModal } from '@dm-workspace/ui';
import { MmsOrdersApiService } from '@dm-workspace/data-access';
import { NotificationService } from '@dm-workspace/notification';
import { ApiValidatorService } from '@dm-workspace/forms';
import { defer, first, share, switchMap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'dm-mms-order-action-send-payment-reminder-modal',
  templateUrl: './mms-order-action-send-payment-reminder-modal.component.html',
  styleUrls: ['./mms-order-action-send-payment-reminder-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsOrderActionSendPaymentReminderModalComponent extends ConfirmModal {
  @Input({ required: true }) public orderId: string;
  protected readonly apiCalling = signal<boolean>(false);
  protected readonly ordersApiService = inject(MmsOrdersApiService);
  protected readonly notification = inject(NotificationService);
  protected readonly apiValidator = inject(ApiValidatorService);
  protected readonly order$ = defer(() => this.ordersApiService.getById(this.orderId)).pipe(share());

  protected buttonConfirmClick() {
    this.apiCalling.set(true);

    this.order$
      .pipe(
        first(),
        switchMap((order) => this.ordersApiService.sendPaymentReminder(order.id)),
        finalize(() => this.apiCalling.set(false))
      )
      .subscribe({
        next: () => {
          this.notification.openSuccess();
          this.onConfirm();
        },
        error: (res: HttpErrorResponse) => {
          const notificationContent = this.apiValidator.getApiNotificationError(res);
          this.notification.openError(notificationContent);
        },
      });
  }
}
