import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';
import { MmsDashboardStats } from '@dm-workspace/types';

@Injectable()
export class MmsDashboardViewService {
  public lastActiveRoute = 'new-inquiries';
  private refreshStatsTriggerSubject = new BehaviorSubject<void>(null);

  public get refreshStatsTrigger$() {
    return this.refreshStatsTriggerSubject.asObservable();
  }

  private statsSubject = new BehaviorSubject<MmsDashboardStats>(null);
  public stats$ = this.statsSubject.asObservable().pipe(distinctUntilChanged());

  public setStats(value: MmsDashboardStats) {
    return this.statsSubject.next(value);
  }

  public refreshStats() {
    this.refreshStatsTriggerSubject.next();
  }
}
