import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { IResourceBookingQuotation, MmsDashboardStatName } from '@dm-workspace/types';
import { BoatVerifyCruiseModalComponent } from '@dm-workspace/mms-panel/shared';
import { fadeAnimation } from '@dm-workspace/shared';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MmsDashboardViewService } from '../../services/mms-dashboard-view.service';
import { MmsDashboardApiService, ResourceBookingApiService } from '@dm-workspace/data-access';
import { MapDashboardChildView } from '../../class/mms-dashboard-child-view.class';
import { NotificationService } from '@dm-workspace/notification';
import { MarinasService } from '@dm-workspace/core';

@Component({
  selector: 'dm-mms-dashboard-cruises',
  templateUrl: './mms-dashboard-cruises-view.component.html',
  styleUrls: ['./mms-dashboard-cruises-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeAnimation],
})
export class MmsDashboardCruisesViewComponent extends MapDashboardChildView {
  protected override statName: MmsDashboardStatName = 'cruisesForVerification';

  constructor(
    modal: NgbModal,
    cd: ChangeDetectorRef,
    dashboardService: MmsDashboardViewService,
    dashboardApiService: MmsDashboardApiService,
    resourceBookingApiService: ResourceBookingApiService,
    notification: NotificationService,
    marinaService: MarinasService
  ) {
    super(cd, dashboardService, dashboardApiService, resourceBookingApiService, notification, marinaService, modal);
  }

  public openCruiseModal(booking: IResourceBookingQuotation) {
    const modalRef = this.modal.open(BoatVerifyCruiseModalComponent, {
      modalDialogClass: `booking-boat-confirmation-modal-container modal-type-cruise`,
    });

    modalRef.componentInstance.booking = booking;
  }
}
