<div class="card px-xxl py-l mt-2" [formGroup]="form" *ngIf="form">
  <div class="d-flex justify-content-between align-items-start">
    <h3 class="mb-l">{{ "BOAT" | translate | uppercase }}</h3>
    <div>
      <button class="ms-2 btn btn-primary-negative btn-tiny"
              *ngIf="selectedBoat && !existsInDatabase && !selectedBoat.id" type="button"
              (click)="openNewBoatModal(form.controls.existingBoat.value)">
        <i class="i-add"></i>
        {{ "CREATE_BOAT" | translate }}
      </button>
      <button type="button" *ngIf="!selectedBoat" class="ms-2 btn btn-primary-negative btn-tiny" (click)="openNewBoatModal()">
        <i class="i-add"></i>
        {{ "CREATE_NEW_BOAT" | translate }}
      </button>
      <button class="ms-2 btn btn-primary-negative btn-tiny" type="button" (click)="clearBoat()"
              *ngIf="!disabled && selectedBoat">
        <i class="icon i-bin"></i>
        {{ "CLEAR" | translate }}
      </button>
    </div>
  </div>

  <div *ngIf="!selectedBoat" class="d-flex row justify-content-start align-items-end">
    <dm-form-group [class]="columnClasses">
      <label>{{ "BOAT_NAME" | translate }}</label>
      <dm-form-select-typeahead
        dmForm
        typeahead="boats"
        [notFoundText]="'NO_BOATS_FOUND' | translate"
        bindLabel="name"
        formControlName="existingBoat"
        [placeholder]="'BOAT_NAME' | translate"
        [defaultSearchValue]="existingBoatSearch"
      />
      <dm-form-error-message controlName="existingBoat" />
    </dm-form-group>
  </div>

  <div *ngIf="selectedBoat as boat" class="row row-cols-6 my-2">
    <dm-ui-info-text [text]="boat.name" [label]="'BOAT_NAME' | translate" />
    <dm-ui-info-text
      [label]="'REGISTRATION_NUMBER' | translate"
      [text]="boat.registrationNumber"
    />
    <dm-ui-info-text [text]="boat.length" [label]="'LENGTH_M' | translate" />
    <dm-ui-info-text [text]="boat.maxBeam" [label]="'WIDTH_M' | translate" />
    <dm-ui-info-text [text]="boat.brand?.name" [label]="'BRAND' | translate" />
    <dm-ui-info-text [text]="boat.flag" [label]="'FLAG' | translate" />
  </div>
</div>
