import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  inject,
  InjectionToken,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  signal,
  SimpleChanges,
} from '@angular/core';
import {
  BerthBoatAlertStatus,
  BerthMapResponseDto,
  BerthStatusInMarina,
  GroupedPylons,
  IBerthDto,
  Marina,
  MultihullPolygon,
  PylonsStatusInMarina,
  BoatPylonInfo,
  BerthPylonsStatusInMarina,
  IMarinaPylonsResponse,
} from '@dm-workspace/types';
import {
  BehaviorSubject,
  debounceTime,
  defer,
  finalize,
  forkJoin,
  fromEvent,
  map,
  Observable,
  ReplaySubject,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { getPylonsMapStatus, getPylonStatus, getSensorTypeAndBoatName, MapViewTypes } from '@dm-workspace/utils';
import { BerthsApiService, MmsPylonsApiService, PiersApiService } from '@dm-workspace/data-access';
import { enterLeaveFromLeftAnimation } from '@dm-workspace/shared';
import { ActivatedRoute, Router } from '@angular/router';
import { MapPolygon } from '@angular/google-maps';
import { MAP_BOAT_ID_BOAT_TYPE } from '../../const/boat-temp';
import { AlertMapResponseDtoWithBerth, MapBerthClass } from '../../berths.class';
import {
  BerthsMapApiService,
  getBerthColorByStatus,
  MapComponentBase,
  MapService,
  MapTransformationsService,
} from '@dm-workspace/map-utils';
import { getPierMarkerOptions } from '../../../../../map-utils/src/lib/const/pier-styles';
import { environment } from '@dm-workspace/environments';

const SELECTED_BERTH_QUERY_PARAM_NAME = 'selectedBerth';
const SELECTED_PYLON_QUERY_PARAM_NAME = 'selectedPylon';

export const MapMmiComponentToken = new InjectionToken<MapMmiComponent>('MapMmiComponent');

@Component({
  selector: 'dm-map-mmi',
  templateUrl: './map-mmi.component.html',
  styleUrls: ['./map-mmi.component.scss'],
  providers: [
    {
      provide: MAP_BOAT_ID_BOAT_TYPE,
      useFactory: () => new Map(),
    },
    {
      provide: MapMmiComponentToken,
      useExisting: forwardRef(() => MapMmiComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [enterLeaveFromLeftAnimation],
})
export class MapMmiComponent
  extends MapComponentBase<MapBerthClass>
  implements OnInit, OnDestroy, AfterViewInit, OnChanges
{
  readonly ViewTypes = MapViewTypes;
  @Input() public viewType!: MapViewTypes;
  @Input() marina!: Marina;
  @Output() alertsCount: EventEmitter<number> = new EventEmitter<number>();
  protected showStatus: BerthStatusInMarina[] = [];
  private destroy$ = new Subject();
  private pierApi = inject(PiersApiService);
  private pierRefresher$ = new BehaviorSubject(null);
  protected alertFilterIsEnabled = signal(false);
  protected pylons = signal<GroupedPylons[]>([]);
  public socketStateChanged = signal<boolean>(false);
  public initSocket = signal<IMarinaPylonsResponse>(null);
  public piers$ = this.pierRefresher$.pipe(
    switchMap(() => this.pierApi.fetch()),
    map((value) =>
      value.filter((pier) => pier.marker).map((pier) => getPierMarkerOptions(pier, pier.marker[1], pier.marker[0]))
    ),
    takeUntil(this.destroy$)
  );
  private refreshMarina$ = new ReplaySubject<MapBerthClass[]>(1);
  protected selectedPylon = signal<GroupedPylons>(null);
  protected hoverPylon = signal<GroupedPylons>(null);
  protected pylons$: Observable<GroupedPylons[]> = defer(() =>
    this.refreshMarina$.pipe(
      switchMap(() => this.pylonsApi.getMarinaPylons()),
      tap((mapPylons) => this.updateBerthsPylonStatuses(mapPylons)),
      tap((mapPylons) => this.setPylonsBoats(mapPylons)),
      map((value) =>
        value.reduce((p: Record<string, GroupedPylons>, c, index) => {
          if (p[c.pylonName]) {
            p[c.pylonName].sockets.push(c);
          } else {
            const [lng, lat] = this.getLocation(c, index);
            p[c.pylonName] = {
              sockets: [c],
              name: c.pylonName,
              status: PylonsStatusInMarina.noUsage,
              location: {
                lat,
                lng,
              },
            };
          }
          return p;
        }, {})
      ),
      map((value) => Object.values(value)),
      tap((value) => {
        this.pylons.set(value);
        if (this.socketStateChanged() && this.initSocket()) {
          const changedSocket = this.pylons()
            .find((pylon) => pylon.name === this.initSocket().pylonName)
            .sockets.find((socket) => socket.outputName === this.initSocket().outputName);
          if (changedSocket.isUsed === this.initSocket().isUsed) {
            this.updatePending(true);
            setTimeout(() => {
              this.refreshMarina$.next(this.berths || null);
            }, 2000);
          } else {
            this.updatePending(false);
            this.socketStateChanged.set(false);
          }
        }

        this.addStatusToPylons(value);
        if (this.aRoute.snapshot.queryParamMap.get(SELECTED_PYLON_QUERY_PARAM_NAME)) {
          this.selectMapObjectFromQuery(SELECTED_PYLON_QUERY_PARAM_NAME);
        }
      }),
      takeUntil(this.destroy$)
    )
  );
  public multihullsPolygon: MultihullPolygon[] = [];
  public pending = true;
  public alerts: AlertMapResponseDtoWithBerth[];
  public alertsBoatInfosHover: BerthBoatAlertStatus[];
  public alertsBoatInfosSelected: BerthBoatAlertStatus[];
  public berthBoatsPylonConnectionInfo: Record<string, BoatPylonInfo[]> = {};
  constructor(
    mapService: MapService,
    el: ElementRef<HTMLElement>,
    private berthsApi: BerthsMapApiService,
    cd: ChangeDetectorRef,
    private pylonsApi: MmsPylonsApiService,
    private mapTransformation: MapTransformationsService,
    private berthsMmsApi: BerthsApiService,
    private aRoute: ActivatedRoute,
    protected router: Router
  ) {
    super(mapService, cd, el, router);
  }

  getLocation(c: IMarinaPylonsResponse, index: number): number[] {
    if (environment.production) {
      return c.location.split(' ').map((value) => +value);
    }
    const [lng, lat] = this.selectedMarina.layout.center;
    return [lng + index / 10000, lat];
  }
  public ngOnInit(): void {
    fromEvent(window, 'resize')
      .pipe(debounceTime(250), takeUntil(this.destroy$))
      .subscribe(() => {
        this.cd.markForCheck();
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const { marina, viewType } = changes;
    this.pylonClickInfoWindow?.close();
    if (marina && this.marina) {
      this.pierRefresher$.next(null);
      this.closeInfoWindow();
      this.selectedBerth = undefined;
      this.selectedPylon.set(undefined);
      this.hoveredBerth = undefined;
      this.selectedMarina = this.marina;
      this.updateMarina();
      this.refreshMapData();
      this.refreshMarina$.next(null);
    }
    if (viewType) {
      this.onOccupationStateChange();
      if (this.selectedBerth) {
        this.updateQueryParams(this.selectedBerth.berth.data.berthName);
      }
      if (this.selectedPylon()) {
        this.updateQueryParams(this.selectedPylon().name);
      }
    }
  }

  public ngAfterViewInit(): void {
    this.cd.markForCheck();
  }

  protected updateMarina() {
    if (!this.selectedMarina) {
      this.piers = [];
      return;
    }
    if (this.selectedMarina.layout?.center) {
      const [lng, lat] = this.selectedMarina.layout.center;
      this.center = { lng, lat };
    }
    this.piers = this.selectedMarina.layout.coordinates;
    this.cd.markForCheck();
    // this.piers = this.getLayotFromJson();
  }

  private updatePending(pending: boolean) {
    this.pending = pending;
    this.cd.markForCheck();
  }

  public refreshMapData(centerMarina = true): void {
    if (this.selectedMarina) {
      this.updatePending(true);
      forkJoin([this.fetchMmsBerths(), this.berthsMmsApi.fetchAll()])
        .pipe(
          map(([statuses, dimensions]) => this.mergeBerthsStatusesWithDimensions(statuses, dimensions)),
          tap((value) => this.updateAlerts(value)),
          tap((value) => this.checkMultihullsPolygons(value)),
          finalize(() => this.updatePending(this.socketStateChanged()))
        )
        .subscribe((value) => {
          this.berths = value;
          this.refreshMarina$.next(this.berths);
          this.alertsBoatInfosSelected = [
            ...new Set(
              getSensorTypeAndBoatName(
                this.berths.find(
                  (item) =>
                    item.data?.berthName === this.aRoute.snapshot.queryParamMap.get(SELECTED_BERTH_QUERY_PARAM_NAME)
                )?.data
              ).map((item) => item.type)
            ),
          ] as BerthBoatAlertStatus[];

          if (this.selectedBerth) {
            const newSelectedBerth = value.find((v) => v.data.berthId === this.selectedBerth.berth.data.berthId);
            if (newSelectedBerth) {
              this.selectedBerth.berth = newSelectedBerth;
            }
          } else if (this.aRoute.snapshot.queryParamMap.get(SELECTED_BERTH_QUERY_PARAM_NAME)) {
            this.selectMapObjectFromQuery(SELECTED_BERTH_QUERY_PARAM_NAME);
          }
          if (centerMarina || this.viewType === MapViewTypes.sensors) {
            this.setMarinaZoomCenter();
          }
        });
    } else {
      this.berths = [];
    }
  }

  private fetchMmsBerths(): Observable<BerthMapResponseDto[]> {
    return this.berthsApi.fetchMmsBerths();
  }

  private updateAlerts(berths: MapBerthClass[]) {
    const alerts: AlertMapResponseDtoWithBerth[] = berths.reduce((alerts, berth) => {
      return [...alerts, ...berth.data.alerts.map((value) => ({ ...value, berth }))];
    }, []);

    this.alerts = alerts;
    this.alerts.sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());
    this.alertsCount.emit(alerts.length);
  }

  private setMarinaZoomCenter() {
    if (!this.berths) {
      return;
    }
    const latLngList = this.berths
      .filter((value) => !!value.data.polygon)
      .reduce((p, c) => [...p, ...c.data.polygon], []);

    if (latLngList.length > 0) {
      this.setMapBoundsAndCenter(latLngList);
    } else if (this.map) {
      this.map.googleMap.setZoom(17);
    }
  }

  protected setMapBoundsAndCenter(latLngArray: [number, number][], padding: number | google.maps.Padding = 0) {
    const padding_ = this.viewType === MapViewTypes.sensors ? { left: this.DRAWER_SPACING } : padding;
    super.setMapBoundsAndCenter(latLngArray, padding_);
    this.cd.markForCheck();
  }

  protected onMapLoad() {
    setTimeout(() => {
      this.setMarinaZoomCenter();
    }, 10);
  }

  public override onBerthPolygonHover(polygon: MapPolygon, berth: MapBerthClass) {
    if (this.hoverInfoWindow) {
      this.closeHoverInfoWindow();
    }

    if (!this.selectedBerth || this.selectedBerth.berth.data?.berthId !== berth.data.berthId) {
      this.handleBerthMouseEvent(polygon, berth, true);
    }
  }
  public onMapClick() {
    this.selectedBerth = undefined;
    this.hoveredBerth = undefined;
    this.selectedPylon.set(undefined);
    this.hoverPylon.set(undefined);
    this.clearSelectedAlert();
    this.updateQueryParams();
  }

  protected handleBerthMouseEvent(berthM: MapPolygon, berth: MapBerthClass, hover?: boolean) {
    super.handleBerthMouseEvent(berthM, berth, hover);
    this.alertsBoatInfosHover = [
      ...new Set(getSensorTypeAndBoatName(berth.data).map((item) => item.type)),
    ] as BerthBoatAlertStatus[];
    if (this.alertsBoatInfosHover.length === 0) this.alertsBoatInfosHover = [BerthBoatAlertStatus.empty];
    if (!hover && this.selectedBerth) {
      this.alertsBoatInfosSelected = [
        ...new Set(getSensorTypeAndBoatName(berth.data).map((item) => item.type)),
      ] as BerthBoatAlertStatus[];
      if (this.alertsBoatInfosSelected.length === 0) {
        this.alertsBoatInfosSelected.push(BerthBoatAlertStatus.empty);
      }
      this.updateQueryParams(this.selectedBerth.berth.data.berthName);
    }
  }

  protected handlePylonMouseEvent(pylon: GroupedPylons, hover?: boolean) {
    if (!hover) {
      this.selectedBerth = undefined;
      this.selectedPylon.set(pylon);
      this.updateQueryParams(null, pylon.name);
      this.closeInfoWindow();
      setTimeout(() => this.pylonClickInfoWindow.open(), 10);
    } else {
      this.hoverPylon.set(pylon);
      this.openPylonHoverInfoWindow();
    }
  }

  public onPylonHover(pylon: GroupedPylons) {
    if (this.pylonHoverInfoWindow) {
      this.closePylonHoverInfoWindow();
    }
    this.handlePylonMouseEvent(pylon, true);
  }

  public onPylonClick(pylon: GroupedPylons) {
    this.handlePylonMouseEvent(pylon, false);
  }

  public onPylonMouseOut() {
    this.closePylonHoverInfoWindow();
  }

  public closePylonHoverInfoWindow() {
    setTimeout(() => this.pylonHoverInfoWindow?.close(), 300);
  }

  openPylonHoverInfoWindow(): void {
    setTimeout(() => this.pylonHoverInfoWindow.open(), 300);
  }

  updateQueryParams(selectedBerth: string | null = null, selectedPylon: string | null = null) {
    if (selectedBerth) {
      this.router.navigate([], {
        relativeTo: this.aRoute,
        queryParams: { [SELECTED_BERTH_QUERY_PARAM_NAME]: selectedBerth },
      });
    } else if (selectedPylon) {
      this.router.navigate([], {
        relativeTo: this.aRoute,
        queryParams: { [SELECTED_PYLON_QUERY_PARAM_NAME]: selectedPylon },
      });
    }
  }

  public onMouseOut() {
    this.closeHoverInfoWindow();
  }

  private onOccupationStateChange() {
    if (this.viewType === MapViewTypes.sensors) {
      //   this.setMarinaZoomCenter();
      this.updateBerthStatuses();
    } else {
      this.updateBerthStatuses();
      this.checkMultihullsPolygons(this.berths);
    }
  }

  private updateBerthStatuses() {
    // this.berths = addBerthMapStatusToBerths(this.berths as BerthMapResponseDto[], this.viewType);
    this.cd.markForCheck();
  }

  public get shouldShowAlerts(): boolean {
    return this.viewType === MapViewTypes.sensors;
  }

  public get boatBerthPylonType(): (BerthBoatAlertStatus | BerthPylonsStatusInMarina)[] {
    return [
      ...new Set(this.berthBoatsPylonConnectionInfo[this.selectedBerth.berth.data.berthId].map((item) => item.type)),
    ];
  }

  private mergeBerthsStatusesWithDimensions(statuses: BerthMapResponseDto[], dimensions: IBerthDto[]): MapBerthClass[] {
    return statuses.map((status) => {
      const {
        length,
        width,
        depth,
        maxAllowedBoatWidth,
        maxAllowedBoatLength,
        minAllowedBoatLength,
        minAllowedBoatWidth,
      } = dimensions.find((dimension) => dimension.id === status.berthId) || {};
      return new MapBerthClass(
        status,
        {
          length,
          width,
          depth,
          maxAllowedBoatWidth,
          maxAllowedBoatLength,
          minAllowedBoatLength,
          minAllowedBoatWidth,
        },
        this.selectedMarina.code
      );
    });
  }

  private checkMultihullsPolygons(berths: MapBerthClass[]) {
    const multihoolPolygons: MultihullPolygon[] = [];
    const reservations: Record<string, MapBerthClass[]> = {};
    const occupations: Record<string, MapBerthClass[]> = {};
    berths.forEach((value) => {
      if (value.data.bookings && value.visibleStatus(this.viewType, this.showStatus)) {
        value.data.bookings.forEach((reservation) => {
          reservations[reservation.humanReadableId] = reservations[reservation.humanReadableId] || [];
          reservations[reservation.humanReadableId].push(value);
        });
      }
    });

    [...Object.values(reservations), ...Object.values(occupations)]
      .filter((reservations) => reservations.length > 1)
      .forEach((berthAv) => {
        const poly = this.mapTransformation.getBerthsPolygon(berthAv.map((value) => value.data));
        const status = berthAv[0].visibleStatus(this.viewType, this.showStatus);
        multihoolPolygons.push({
          poly,
          color: getBerthColorByStatus(status),
        });
      });
    this.multihullsPolygon = multihoolPolygons;
  }

  public openBerthAlertInfoWindow(alert: AlertMapResponseDtoWithBerth): void {
    this.selectedBerth = undefined;
    this.closeHoverInfoWindow(0);

    this.selectBerth(alert.berth);
  }

  selectBerth(berth: MapBerthClass) {
    const latLngList = berth.data.polygon.map((value) => {
      const [lng, lat] = value;
      return new google.maps.LatLng(lat, lng);
    });
    const latLngBounds = new google.maps.LatLngBounds();
    latLngList.forEach((latLng) => {
      latLngBounds.extend(latLng);
    });

    this.selectedBerth = {
      lat: latLngBounds.getCenter(),
      berth,
    };

    this.map.googleMap.panToBounds(latLngBounds, { left: this.DRAWER_SPACING });
    this.openInfoWindow();
    this.cd.markForCheck();
  }

  selectPylon(pylon: GroupedPylons): void {
    const latLng = pylon.location;
    this.selectedPylon.set(pylon);

    const latLngBounds = new google.maps.LatLngBounds();
    latLngBounds.extend(latLng);

    this.map.googleMap.panToBounds(latLngBounds, { left: this.DRAWER_SPACING });
    this.openInfoWindow();
    this.cd.markForCheck();
  }

  public onBerthPolygonClick(polygon: MapPolygon, berth: MapBerthClass) {
    this.selectedBerth = undefined;
    this.selectedPylon.set(undefined);
    this.clearSelectedAlert();
    this.closeInfoWindowWithBerthName();
    this.closeHoverInfoWindow(0);
    this.handleBerthMouseEvent(polygon, berth, false);
  }

  onFiltersChange($event: BerthStatusInMarina[]) {
    this.showStatus = $event;
    setTimeout(() => this.setAlertFilterIsEnabled());
    this.checkMultihullsPolygons(this.berths);
    this.cd.detectChanges();
  }

  private selectMapObjectFromQuery(param: string) {
    if (param === 'selectedBerth') {
      const berthNameOrId = this.aRoute.snapshot.queryParamMap.get(param);
      const berth = this.berths.find(
        (value) => value.data.berthName === berthNameOrId || value.data.berthId === berthNameOrId
      );
      if (berth) {
        this.selectBerth(berth);
      }
    } else if (param === 'selectedPylon') {
      const pylonNameOrCode = this.aRoute.snapshot.queryParamMap.get(param);
      const pylon = this.pylons().find((value) => value.name === pylonNameOrCode);
      if (pylon) {
        this.selectPylon(pylon);
      }
    }
  }

  onDbClickPierName(label: string | google.maps.MarkerLabel) {
    const latLngList = this.berths
      .filter((value) => value.data.pierName === label)
      .reduce((p, c) => [...p, ...(c.data.polygon || [])], []);
    if (latLngList.length > 0) {
      this.setMapBoundsAndCenter(latLngList, 100);
    }
  }

  protected readonly MapViewTypes = MapViewTypes;

  setAlertFilterIsEnabled() {
    this.alertFilterIsEnabled.set(
      this.viewType === MapViewTypes.sensors && this.showStatus.includes(BerthBoatAlertStatus.activeAlert)
    );
  }

  private updateBerthsPylonStatuses(mapPylons: IMarinaPylonsResponse[]) {
    const pylonsWithBooking = mapPylons.filter((value) => !!value.resource);
    this.berths.forEach((berth) => {
      const pylonFromBerth = pylonsWithBooking.find((value) => value.resource.berthIds.includes(berth.data.berthId));
      berth.updatePylonStatus(pylonFromBerth);
    });
    this.onFiltersChange([...this.showStatus]);
    this.cd.detectChanges();
  }

  private setPylonsBoats(pylons: IMarinaPylonsResponse[]) {
    this.berthBoatsPylonConnectionInfo = pylons.reduce((p: Record<string, BoatPylonInfo[]>, c) => {
      if (c.resource) {
        const boat: BoatPylonInfo = {
          name: c.boat.name,
          id: c.boat.id,
          type: getPylonsMapStatus(c)[0],
          isUsed: c.isUsed,
        };
        return {
          ...p,
          ...c.resource.berthIds.reduce((pr, cr) => {
            const array = p[cr] || [];
            array.push(boat);
            return {
              ...pr,
              [cr]: array,
            };
          }, {}),
        };
      }
      return p;
    }, {});
  }

  private addStatusToPylons(pylons: GroupedPylons[]) {
    pylons.forEach((value) => (value.status = getPylonStatus(value.sockets)));
  }
}
