import { ChangeDetectionStrategy, Component, inject, Input, signal } from '@angular/core';
import { MmsBoatsApiService } from '@dm-workspace/data-access';
import { FormUtils } from '@dm-workspace/forms';
import { catchError, of, switchMap, throwError } from 'rxjs';
import { NotificationService } from '@dm-workspace/notification';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiValidator } from '@dm-workspace/shared';
import { finalize, tap } from 'rxjs/operators';
import { BoatMovementOperation } from '@dm-workspace/types';

import { BoatMovementService } from '../../services/boat-movement.service';
import {
  MmsBoatMovementFormBuilder,
  MmsBoatMovementFormValue,
} from '../../components/mms-boat-movement-form/mms-boat-movement-form.builder';

@Component({
  selector: 'dm-mms-boat-add-movement-modal',
  templateUrl: './mms-boat-add-movement-modal.component.html',
  styleUrls: ['./mms-boat-add-movement-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsBoatAddMovementModalComponent {
  #aModal = inject(NgbActiveModal);
  #boatsApiService = inject(MmsBoatsApiService);
  #boatMovementService = inject(BoatMovementService);
  #notificationService = inject(NotificationService);

  @Input({ required: true }) boatId: string;
  @Input({ required: true }) boatBookingId?: string;
  @Input() resourceId: string;
  @Input() operations: BoatMovementOperation[] = Object.values(BoatMovementOperation);
  @Input() boatQuickReturnType: BoatMovementOperation;
  protected readonly formSubmitting = signal<boolean>(false);
  protected readonly form = MmsBoatMovementFormBuilder.buildForm();

  protected submitForm() {
    if (this.formSubmitting() || !FormUtils.isValid(this.form)) {
      return;
    }
    this.formSubmitting.set(true);

    const formValue = this.form.getRawValue() as MmsBoatMovementFormValue;

    this.#boatMovementService
      .validAndAcceptMovement(this.boatId, formValue)
      .pipe(
        switchMap((accepted) => {
          if (!accepted) {
            return of(false);
          }

          return this.#boatsApiService.movement(this.boatId, formValue).pipe(
            catchError((res: HttpErrorResponse) => {
              const notificationError = ApiValidator.getApiNotificationError(res);
              this.#notificationService.openError(notificationError);
              return throwError(() => res);
            }),
            tap(() => {
              this.#notificationService.openSuccess('MOVEMENT_ADDED');
              this.#aModal.close(true);
            })
          );
        }),
        finalize(() => this.formSubmitting.set(false))
      )
      .subscribe();
  }

  protected closeModal() {
    this.#aModal.close(false);
  }
}
