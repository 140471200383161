import { Pipe, PipeTransform } from '@angular/core';
import { QuoteCrmStatus, Quote, QuoteCrmState } from '@dm-workspace/types';

@Pipe({
  name: 'offerCanCancel',
})
export class MmsOfferCanCancelPipe implements PipeTransform {
  public transform(quote: Quote): boolean {
    const isSent = [QuoteCrmStatus.IN_PROGRESS, QuoteCrmStatus.PENDING_FOR_APPROVAL].includes(quote.crmStatus);
    return quote.crmState === QuoteCrmState.DRAFT || isSent;
  }
}
