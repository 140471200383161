import { AfterViewInit, Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dm-mms-shared-resource-booking-cancellation-preview-modal',
  templateUrl: './resource-booking-cancellation-preview-modal.component.html',
  styleUrls: ['./resource-booking-cancellation-preview-modal.component.scss'],
})
export class ResourceBookingCancellationPreviewModalComponent implements AfterViewInit {
  @Input() public emailPreviewContent: string;
  @ViewChild('body') body: ElementRef<HTMLDivElement>;

  constructor(private renderer: Renderer2, private activeModal: NgbActiveModal, private modal: NgbModal) {}

  // Bootstrap modal animation bug - can't use static iframe element to append content
  public ngAfterViewInit(): void {
    const iframeEl = this.renderer.createElement('iframe') as HTMLIFrameElement;
    this.body.nativeElement.appendChild(iframeEl);

    iframeEl.setAttribute('sandbox', '');
    iframeEl.contentDocument.write(this.emailPreviewContent);
  }

  public onSubmit() {
    this.activeModal.close(true);
  }

  public onBack() {
    this.activeModal.close(false);
  }

  public onCancel() {
    this.modal.dismissAll();
  }
}
