import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {
  BoatFitmentWithReasons,
  BookingResource,
  BookingResourceProduct,
  IResourceBookingCalculatePricingBoatData,
} from '@dm-workspace/types';

@Component({
  selector: 'dm-mms-bookings-berths-table',
  templateUrl: './bookings-berths-table.component.html',
  styleUrls: ['./bookings-berths-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingsBerthsTableComponent {
  protected mandatoryProductsWithoutMooring: BookingResourceProduct[];
  @Input() berths: BookingResource[];
  @Input() set products(products: BookingResourceProduct[]) {
    this.mandatoryProductsWithoutMooring = products.filter(
      (product) => product.isMandatory && product.product !== 'MOORING'
    );
  }
  @Input() boat: IResourceBookingCalculatePricingBoatData;
  @Input() marinaCode: string;
  @Input() selectedResourceId: string;

  @Output() resourceSelected = new EventEmitter<[string, BoatFitmentWithReasons]>();

  selectBerth(resourceId: string): void {
    this.resourceSelected.emit([resourceId, null]);
  }

  isActive(berth: BookingResource): boolean {
    return this.selectedResourceId === berth.resource.resourceId;
  }

  rowClassNames(berth: BookingResource): { [key: string]: boolean } {
    return {
      active: this.isActive(berth),
    };
  }

  protected getTotalPrice(berth: BookingResource) {
    const totalMandatoryProductsPrice = this.mandatoryProductsWithoutMooring.reduce(
      (acc, product) => acc + product.totalPriceGross,
      0
    );
    return totalMandatoryProductsPrice + berth.pricingDetails.sumPriceGross;
  }
}
