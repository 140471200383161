import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MmsDashboardViewService } from '../../services/mms-dashboard-view.service';
import { combineLatest, finalize, Observable, Subscription, switchMap, tap } from 'rxjs';
import { MmsDashboardApiService, ResourceBookingApiService } from '@dm-workspace/data-access';
import { MmsDashboardStats } from '@dm-workspace/types';

@Component({
  selector: 'dm-mms-dashboard-navigation',
  templateUrl: './mms-dashboard-navigation.component.html',
  styleUrls: ['./mms-dashboard-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsDashboardNavigationComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  public pending = true;
  public stats$ = this.dashboardService.stats$;

  constructor(
    private dashboardService: MmsDashboardViewService,
    private cd: ChangeDetectorRef,
    private dashboardApiService: MmsDashboardApiService,
    private resourceBookingApiService: ResourceBookingApiService
  ) {}

  public ngOnInit(): void {
    this.subscription.add(
      combineLatest([this.dashboardService.refreshStatsTrigger$, this.resourceBookingApiService.refreshView$])
        .pipe(
          switchMap(() => this.fetchStats()),
          tap((v) => this.dashboardService.setStats(v))
        )
        .subscribe()
    );
  }

  private fetchStats(): Observable<MmsDashboardStats> {
    this.pending = true;
    this.cd.detectChanges();

    return this.dashboardApiService.fetchStats().pipe(
      finalize(() => {
        this.pending = false;
        this.cd.detectChanges();
      })
    );
  }

  public ngOnDestroy(): void {
    this.dashboardService.setStats(null);
    this.subscription.unsubscribe();
  }
}
