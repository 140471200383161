<form (ngSubmit)="submitForm()">
  <dm-ui-modal>
    <h2 header>{{ "ADD_BOAT_MOVEMENT" | translate }}</h2>
    <div body>
      <dm-mms-boat-movement-form
        [form]="form"
        [operations]="operations"
        [resourceId]="resourceId"
        [boatQuickReturnType] = "boatQuickReturnType"
        [boatBookingId]="boatBookingId"
      />
    </div>

    <div footer class="d-flex justify-content-between align-items-center w-100">
      <button type="button" class="btn btn-tertiary btn-narrow" (click)="closeModal()">
        {{ "CANCEL" | translate }}
      </button>
      <button class="btn btn-primary btn-default" type="submit" [disabled]="formSubmitting()">
        <dm-ui-spinner [showSpinner]="formSubmitting()" class="me-2" />
        {{ "ADD_MOVEMENT" | translate }}
      </button>
    </div>
  </dm-ui-modal>
</form>
