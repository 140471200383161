import { FormView, MarinasService } from '@dm-workspace/core';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm, UntypedFormGroup } from '@angular/forms';
import {
  ApiErrorObject,
  BoatTypeCustomer,
  DateRange,
  GenericObject,
  Marina,
  ResourceBookingFindBerthForm,
} from '@dm-workspace/types';
import { ApiValidatorService } from '@dm-workspace/forms';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { BookingsFormsCreatorService } from '../../services/bookings-forms-creator.service';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dm-mms-bookings-find-berth-form',
  templateUrl: './bookings-find-berth-form.component.html',
  styleUrls: ['./bookings-find-berth-form.component.scss'],
})
export class BookingsFindBerthFormComponent
  extends FormView<ResourceBookingFindBerthForm>
  implements OnInit, OnDestroy
{
  @Input() public set findBerthApiErrors(errors: ApiErrorObject<ResourceBookingFindBerthForm>) {
    this.apiErrors = errors;
  }

  @Output() public formChanged = new EventEmitter<null>();
  @Output() public isBoatSelected = new EventEmitter<boolean>();

  @ViewChild('findForm') public findForm: NgForm;

  public marinas$ = this.marinasService.marinas$;
  public form: UntypedFormGroup;
  public isBoatSelected_ = false;
  public boatTypes = BoatTypeCustomer;
  public readonly minDate = this.calendar.getPrev(this.calendar.getToday(), 'd', 2);

  private userMarina: Marina;
  private subscriptions = new Subscription();

  constructor(
    private marinasService: MarinasService,
    private formCreator: BookingsFormsCreatorService,
    private calendar: NgbCalendar,
    protected apiValidator: ApiValidatorService
  ) {
    super(apiValidator);
  }

  public ngOnInit(): void {
    this.form = this.formCreator.createQuickQuotationFindBerthForm();

    this.marinasService.selectedMarina$.pipe(take(1)).subscribe({
      next: (marina) => {
        this.userMarina = marina;
        this.form.get('marinaCode').patchValue(marina?.code);
      },
    });

    this.subscriptions.add(
      this.form.valueChanges.subscribe({
        next: (value) => {
          this.formChanged.emit();
          if (value.boat !== null) {
            this.isBoatSelected_ = true;
            this.isBoatSelected.emit(value.boat.id);

            this.form.patchValue(
              {
                boatLength: value.boat.length,
                boatDraft: value.boat.draft,
                boatWidth: value.boat.maxBeam,
                boatType: value.boat.type,
              },
              { emitEvent: false, onlySelf: true }
            );
          }
        },
      })
    );
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public triggerSubmit(): void {
    this.findForm.ngSubmit.emit();
  }

  public clear(): void {
    this.isBoatSelected_ = false;
    this.isBoatSelected.emit(false);
    this.form.reset({
      marina: this.userMarina,
    });
    this.form.markAsPristine();
    this.form.markAsUntouched();
  }

  public getDateRange(): DateRange {
    const { fromDate, toDate } = this.form.get('dateRange').value || {};
    return { fromDate, toDate };
  }

  protected parseForm(formValue: GenericObject): ResourceBookingFindBerthForm {
    const { dateRange, boat, boatType, ...rest } = formValue;
    return {
      ...rest,
      ...dateRange,
      existingBoatId: boat?.id,
      boatType: boat?.type || boatType,
    };
  }
}
