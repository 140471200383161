import { ActivatedRouteSnapshot, Routes } from '@angular/router';
import { MmsOffersViewComponent } from './views/mms-offers-view/mms-offers-view.component';
import { MmsOfferViewComponent } from './views/mms-offer-view/mms-offer-view.component';
import { inject } from '@angular/core';
import { MmsOfferViewService } from './services/mms-offer-view.service';
import { MmsOfferDetailsViewComponent } from './views/mms-offer-details-view/mms-offer-details-view.component';

export const mmsOffersRoutes: Routes = [
  {
    path: '',
    component: MmsOffersViewComponent,
  },
  {
    path: ':offerId',
    component: MmsOfferViewComponent,
    canActivate: [
      (aRoute: ActivatedRouteSnapshot) => {
        inject(MmsOfferViewService).setOfferId(aRoute.params.offerId);
      },
    ],
    canDeactivate: [
      () => {
        inject(MmsOfferViewService).onDeactivateRoute();
      },
    ],
    children: [{ path: '', component: MmsOfferDetailsViewComponent }],
  },
];
