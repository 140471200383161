import { Pipe, PipeTransform } from '@angular/core';
import { QuoteDeclineReason } from '@dm-workspace/types';
import { KeyValue } from '@angular/common';

@Pipe({
  name: 'quoteDeclineReasonTranslateKey',
})
export class MmsQuoteDeclineReasonTranslateKeyPipe implements PipeTransform {
  public transform(reason: KeyValue<string, QuoteDeclineReason>): string {
    if (!reason) {
      return;
    }
    return `OFFER_DECLINE_REASON_.${reason.key}`;
  }
}
