<div class="modal-header">
  <h2 class="title">{{ "MARK_CONTRACT_AS_ELIGIBLE_MODAL.HEADER" | translate }}</h2>
</div>

<div class="modal-body">
  <p>{{ "MARK_CONTRACT_AS_ELIGIBLE_MODAL.CAPTION" | translate }}</p>
  @if (apiErrorMsg()) {
    <p class="error-text mt-4">{{ apiErrorMsg() }}</p>
  }
</div>

<div class="modal-footer justify-content-between d-flex">
  <button type="button" class="btn btn-primary-negative" (click)="onCancel()">
    {{ "CLOSE" | translate }}
  </button>
  <button type="button" class="btn btn-primary" (click)="onConfirm()" [disabled]="pending()">
    <dm-ui-spinner color="white" [showSpinner]="pending()" />
    {{ "CONFIRM" | translate }}
  </button>
</div>
