import { Component } from '@angular/core';
import { mmsLayoutNavigationRoutes, MmsNavRoutes } from '@dm-workspace/mms-panel/mms-layout';
import { FeaturesEnabledService } from '@dm-workspace/core';

@Component({
  selector: 'dm-mms-layout-navigation',
  templateUrl: './mms-layout-navigation.component.html',
  styleUrls: ['./mms-layout-navigation.component.scss'],
})
export class MmsLayoutNavigationComponent {
  public routes: MmsNavRoutes[] = mmsLayoutNavigationRoutes.filter(
    (navRoute) => this.featureService.hasFeatureEnabled(navRoute.feature) && !navRoute.isHidden
  );

  constructor(private featureService: FeaturesEnabledService) {}
}
