import { Pipe, PipeTransform } from '@angular/core';
import { QuoteProduct } from '@dm-workspace/types';

@Pipe({
  name: 'offerProductsSumPrice',
})
export class MmsOfferProductsSumPricePipe implements PipeTransform {
  public transform(quoteProducts: QuoteProduct[] | undefined): number {
    if (!quoteProducts || quoteProducts.length === 0) {
      return;
    }
    return quoteProducts.reduce((acc, curr) => acc + curr.totalPriceGross, 0);
  }
}
