<h2 class="drawer__title">{{ "ALERTS_IN_MARINA" | translate }}</h2>
<table class="table table-sticky">
  <thead>
    <tr>
      <th>{{ "BERTH" | translate }}</th>
      <th>{{ "CREATED_AT" | translate }} /<br />{{ "WARNING" | translate }}</th>
      <th>{{ "BOAT" | translate }}</th>
      <th>{{ "STATUS" | translate }}</th>
    </tr>
  </thead>

  <tbody>
    @if (alerts?.length > 0) {
      @for (alert of alerts; track "s4bAlertId") {
        <tr [class]="selectedClassName(alert)" #alertRow>
          <td>
            <a class="link" (click)="onBerthClick(alert)"><i class="icon i-pin"></i>{{ alert.berth.data.berthName }}</a>
          </td>

          <td>
            <p class="color-secondary typo-body-s mb-1">
              {{ alert.createdDate | dateFormat: "date_time" }}
            </p>
            <a class="link boat-link" [routerLink]="['/boats', alert.boatId, 'sensors']" returnUrlCurrentPath>{{
              "ALERT_.TYPES_." + alert.type | translate
            }}</a>
            <p class="color-secondary typo-body-s mt-1">
              {{ "SENSOR_ID" | translate }}:
              <strong>
                {{ alert.devEui | empty }}
              </strong>
            </p>
          </td>

          <td>{{ alert.boat.name }}</td>

          <td>
            <dm-ui-tag class="tag-min-width" [color]="alert | alertStatusColor"
              >{{ alert | alertStatusTranslateKey | translate | uppercase }}
            </dm-ui-tag>
          </td>
        </tr>
      }
    } @else if (alerts) {
      <tr>
        <td colspan="4" class="text-center">{{ "NO_ALERTS" | translate }}</td>
      </tr>
    }
  </tbody>
</table>
