<table class="table table-sticky d-print-none">
  <thead dmUiStickyElement name="dashboard" [secondElementOffset]="70">
    <tr>
      <th>{{ "BERTH" | translate }}</th>
      <th>{{ "BOOKING_ID" | translate }}</th>
      <th>{{ "CREATED_AT" | translate }}</th>
      <th>{{ "BOAT" | translate }}</th>
      <th>{{ "START" | translate }}&nbsp;/<br />{{ "END_DATE" | translate }}</th>
      <th>{{ "CUSTOMER" | translate }}</th>
      <th>{{ "BOOKING_TYPE" | translate }}</th>
      <th>{{ "PAYMENT_STATUS" | translate }}</th>
      <th *ngIf="showTotalAmount">{{ "TOTAL_AMOUNT" | translate }}</th>
      <th class="action">{{ "ACTIONS" | translate }}</th>
    </tr>
  </thead>
  <tbody *ngIf="bookings?.length">
    <tr *ngFor="let booking of bookings">
      <td>{{ booking.resource?.name }}</td>
      <td class="max-width">{{ booking.humanReadableId }}</td>
      <td>{{ booking.createdAt | dateFormat: "date_time" }}</td>
      <td class="max-width max-width--boat">
        <dm-ui-boat-cell [boat]="booking.boat" [boatDimensions]="booking.boatDimensions" />
        <span class="d-none d-print-block">{{booking.boat?.flag}}</span>
      </td>
      <td>{{ booking.fromDate | dateFormat }}<br />{{ booking.toDate | dateFormat }}</td>
      <td>
        <ng-container *ngIf="booking.customer; else customerEmail">
          <a
            class="link"
            [routerLink]="['/customers', booking.customer.id]"
            returnUrlCurrentPath
            *dmHasPermission="abilities.READ_CUSTOMER; else customerName"
          >
            {{ booking.customer?.name }}
          </a>
        </ng-container>

        <ng-template #customerName>
          {{ booking.customer?.name }}
        </ng-template>

        <ng-template #customerEmail>
          {{ booking.quotationPrefilledData?.email }}
        </ng-template>
      </td>
      <td>{{ booking.contractType | contractTypeTranslate | empty }}</td>
      <td *ngIf="showTotalAmount">
        <dm-ui-tag [color]="booking.primaryOrder?.paymentStatus | paymentStatusTagColor">
          {{ booking.primaryOrder?.paymentStatus | orderPaymentStatusTranslateKey | translate }}
        </dm-ui-tag>
      </td>

      <td *ngLet="booking.primaryOrder?.totalPriceGross as totalPriceGross">
        {{ totalPriceGross?.value | currency: totalPriceGross?.currency | empty }}
      </td>

      <td class="action actions--hide-overflow">
        <div class="content">
          <ng-container *ngIf="booking.labels?.length">
            <ng-template #tipContent>{{ booking.labels | resourceLabelsTooltip }} </ng-template>
            <i class="i-exclamation-mark-circle color-danger mr-s" [style.font-size.px]="24" [ngbTooltip]="tipContent"></i>
          </ng-container>

          <ng-container *ngIf="!actionRef"><dm-mms-shared-booking-action-button [booking]="booking"/></ng-container>
          <ng-container *ngIf="actionRef" [ngTemplateOutlet]="actionRef" [ngTemplateOutletContext]="{ booking: booking }" />
        </div>
      </td>
    </tr>

    <tr *ngIf="bookings?.length < 1">
      <td colspan="12">
        {{ "NO_TYPEOF_ITEMS_FOUND" | translate: { items: "BOOKINGS" | translate | lowercase } }}
      </td>
    </tr>
  </tbody>
</table>

<div class="d-none d-print-block">
<table class="table">
  <thead>
    <tr>
      <th>{{ "BERTH" | translate }}</th>
      <th>{{ "BOAT_NAME" | translate }}</th>
      <th>{{"BOAT" | translate}}<br/>{{"LENGTH_M" | translate }} / {{"WIDTH_M" | translate }}</th>
      <th>{{"BOAT_FLAG" | translate}}</th>
      <th>{{ "ARRIVAL_DATE" | translate }}</th>
      <th>{{ "DEPARTURE_DATE" | translate }}</th>
    </tr>
  </thead>
  <tbody *ngIf="bookings?.length">
  <tr *ngFor="let booking of bookings">
    <td>{{ booking.resource?.name }}</td>
    <td>
      <dm-ui-boat-icon [boatType]="booking.boat?.type" class="boat-icon" />
      {{booking.boat?.name}}
    </td>
    <td>
      <ng-container *ngIf="booking.boat?.id; else noBoatId">
        {{booking.boat.length}} / {{booking.boat.maxBeam}}
      </ng-container>
      <ng-template #noBoatId>
        <ng-container *ngIf="booking.boatDimensions as dimensions; else empty">
          <span>{{ dimensions.length }} m / {{ dimensions.width }} m</span>
        </ng-container>
      </ng-template>
    </td>
    <td><span class="d-none d-print-block">{{booking.boat?.flag}}</span></td>
    <td>{{ booking.fromDate | dateFormat }}</td>
    <td>{{ booking.toDate | dateFormat }}</td>
  </tr>

  <tr *ngIf="bookings?.length < 1">
    <td colspan="12">
      {{ "NO_TYPEOF_ITEMS_FOUND" | translate: { items: "BOOKINGS" | translate | lowercase } }}
    </td>
  </tr>
  </tbody>
</table>
</div>

<ng-template #empty>
  <span>-</span>
</ng-template>
