import { inject, Injectable } from '@angular/core';
import { MmsCustomerApiService } from '@dm-workspace/data-access';
import { switchMap, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MmsCustomerService {
  protected readonly customerApiService = inject(MmsCustomerApiService);

  public getCustomerPrimaryContact(customerId: string) {
    return this.customerApiService.fetchById(customerId).pipe(
      switchMap((customer) => {
        const customerPrimaryContactId = customer.customerPortalAccounts?.find(
          (account) => account.isPrimaryContact
        )?.contactId;

        if (!customerPrimaryContactId) {
          return throwError(() => 'MISSING_CUSTOMER_PRIMARY_CONTACT');
        }

        return this.customerApiService.fetchCustomerContacts(customer.id).pipe(
          map((contacts) => {
            const customerPrimaryContact = contacts.find((contact) => contact.id === customerPrimaryContactId);
            return { customer, customerPrimaryContact };
          })
        );
      })
    );
  }
}
