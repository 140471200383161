<div class="card px-xxl py-xl">
  <div class="d-flex align-items-center mb-2 d-print-none">
    <h3 class="text-m dashboard-header">{{ "EXPECTED_ARRIVALS" | translate | uppercase }}</h3>
    <dm-ui-print-button class="ml-auto" />
  </div>

  <h3 class="text-m dashboard-header d-none d-print-block">
      <span *ngIf="(selectedMarina$ | async) as marina"
      >{{marina.name | uppercase}}</span> {{ "EXPECTED_ARRIVALS" | translate | uppercase }} <span
  >{{"OF" | translate | uppercase}} <ng-container *ngFor="let date of arrivalDates; let isFirst = first; let isLast = last"
  ><ng-container *ngIf="isLast && !isFirst"> &dash; </ng-container> {{date | dateFormat}}</ng-container></span>
  </h3>

  <div class="position-relative" [style.min-height.px]="300">
    <dm-mms-dashboard-list-done header="😃" *ngIf="hasBookingsAllDone">
      {{ "MMS_DASHBOARD.EXPECTED_ARRIVALS_DONE.0" | translate }}
      {{ "MMS_DASHBOARD.EXPECTED_ARRIVALS_DONE.1" | translate }}
    </dm-mms-dashboard-list-done>

    <ng-container *ngIf="hasBookingsTodo">
      <dm-mms-dashboard-bookings-list *ngIf="bookings" [bookings]="bookings" />

      <dm-ui-pagination
        [rows]="pagination.limit"
        (pageChange)="onPageChange($event)"
        (numberOfRowsChange)="onLimitChange($event)"
        [collectionSize]="pagination.totalCount"
      />
    </ng-container>

    <dm-ui-overflow-spinner [showSpinner]="pending" type="absolute" />
  </div>
</div>
