  <div class="modal-header">
    <h2 class="title">{{"MARK_AS_PAID" | translate}}</h2>
  </div>
  <div class="modal-body color-danger-dark" [innerHTML]="'MAKE_SURE_YOU_HAVE_RECORDED_PAYMENT_IN_THE_F&O_BEFORE_MARKING_THE_OFFER_AS_PAID' | translate">
  </div>

  <div class="modal-footer justify-content-between d-flex">
    <button type="button" class="btn btn-secondary-negative" (click)="dismissModal()">
      {{ "CANCEL" | translate }}
    </button>
    <button type="button" class="btn btn-primary" (click)="buttonConfirmClick()" [disabled]="apiCalling()">
      <dm-ui-spinner class="me-1" [showSpinner]="apiCalling()" />
      {{ "CONFIRM" | translate }}
    </button>
  </div>
