import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Customer, CustomerType } from '@dm-workspace/types';

@Component({
  selector: 'dm-mms-customer-preview',
  templateUrl: './mms-customer-preview.component.html',
  styleUrls: ['./mms-customer-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsCustomerPreviewComponent {
  @Input() public customer?: Customer;
  protected readonly CustomerType = CustomerType;
}
