<dm-mms-offers-filters
  (filtersChange)="changeFilters($event)"
  [storageKey]="'offers'"
  [filtersRowColumnCount]="filtersRowColumnCount()"
/>

<div class="pt-s" dmUiStickyElementOffset="offersList">
  @if ("offers" | selectedMarinaSupportView | async) {
    <div class="card px-xxl py-m" dmUiStickyElement name="offersList">
      <h4 class="header-14 mb-2">{{ "RENEWAL_OFFERS" | translate | uppercase }}</h4>

      <div class="d-flex justify-content-between">
        <dm-ui-state-switch class="mb-2 mt-4 d-inline-block position-relative" columnsSize="flex">
          <a routerLink="../readyToSend" dmUiStateSwitchItemLink [queryParamsHandling]="'merge'">
            {{ "READY_TO_SEND" | translate }}
            @if (stats()) {
              <dm-ui-state-switch-badge>
                {{ stats()?.readyToSend }}
              </dm-ui-state-switch-badge>
            }
          </a>
          <dm-ui-state-switch-separator />
          <a routerLink="../sent" dmUiStateSwitchItemLink [queryParamsHandling]="'merge'">
            {{ "SENT" | translate }}
            @if (stats()) {
              <dm-ui-state-switch-badge>
                {{ stats()?.sent }}
              </dm-ui-state-switch-badge>
            }
          </a>
          <dm-ui-state-switch-separator />
          <a routerLink="../accepted" dmUiStateSwitchItemLink [queryParamsHandling]="'merge'">
            {{ "ACCEPTED" | translate }}
            @if (stats()) {
              <dm-ui-state-switch-badge>
                {{ stats()?.accepted }}
              </dm-ui-state-switch-badge>
            }
          </a>
          <dm-ui-state-switch-separator />
          <a routerLink="../declined" dmUiStateSwitchItemLink [queryParamsHandling]="'merge'">
            {{ "DECLINED" | translate }}
            @if (stats()) {
              <dm-ui-state-switch-badge>
                {{ stats()?.declined }}
              </dm-ui-state-switch-badge>
            }
          </a>
          <dm-ui-state-switch-separator />
          <a routerLink="../all" dmUiStateSwitchItemLink [queryParamsHandling]="'merge'">
            {{ "ALL" | translate }}
            @if (stats()) {
              <dm-ui-state-switch-badge>
                {{ stats()?.all }}
              </dm-ui-state-switch-badge>
            }
          </a>
          <dm-ui-overflow-spinner [showSpinner]="fetchingStats()" type="absolute" size="sm" />
        </dm-ui-state-switch>
      </div>
    </div>
    <div class="card px-xxl py-m">
      <div class="position-relative" [style.min-height.px]="100">
        @if (listData()?.values && !this.fetchingOffers()) {
          <dm-mms-offers-renewals-table
            [offers]="listData().values"
            [columns]="offersTableColumns()"
            [selectedOfferTable]="quoteStatuses()"
            (offersChange)="getTabOffers()"
          />
          <dm-ui-pagination
            [pagination]="pagination"
            (numberOfRowsChange)="getTabOffers()"
            class="justify-content-center"
          />
        } @else {
          <dm-ui-overflow-spinner [showSpinner]="fetchingOffers()" type="absolute" size="lg" />
        }
      </div>
    </div>
  } @else {
    <p class="mt-4 px-xxl py-xl">{{ "THIS_FEATURE_IS_YET_NOT_AVAILABLE_IN_THIS_MARINA" | translate }}</p>
  }
</div>
