<h3 class="title">{{ "BERTHS_LIST" | translate | uppercase }}</h3>

<ul
  ngbNav
  #nav="ngbNav"
  class="nav-tabs nav-tabs--no-bg"
  [destroyOnHide]="false"
  [(activeId)]="activeContent"
  (activeIdChange)="onResourceSelect($event)"
>
  <li [ngbNavItem]="selectionTypes.matching">
    <a ngbNavLink>{{ "MATCHING" | translate }} ({{ matchingBerths.length }})</a>
    <ng-template ngbNavContent>
      <div *ngIf="matchingBerths.length > 0; else noResults">
        <dm-mms-bookings-berths-table
          [berths]="matchingBerths"
          [products]="products"
          [boat]="boat"
          [marinaCode]="marinaCode"
          [selectedResourceId]="selectedResource?.matching"
          (resourceSelected)="onResourceSelect(selectionTypes.matching, $event)"
        />
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="selectionTypes.manual">
    <a ngbNavLink>{{ "MANUAL_BERTH_SELECT" | translate }}</a>
    <ng-template ngbNavContent>
      <dm-mms-bookings-berths-table-with-select
        (resourceSelected)="onResourceSelect(selectionTypes.manual, $event)"
        [dateRange]="dateRange"
        [boat]="boat"
        [products]="products"
        [marinaCode]="marinaCode"
      />

      <dm-ui-availability-check
        class="mt-xl"
        [checkPayload]="availabilityPayload()"
        [boatFitsInExternalCheck]="willBoatFit"
        [searchInGaps]="searchInGaps"
      />

      <dm-ui-info-box class="pt-xxl mt-0" icon="info" text="CHOOSE_BERTH_AT_OWN_RISK" />
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="nav"></div>

<ng-template #noResults>
  <div class="no-results">{{ "NO_ITEMS_FOUND" | translate }}</div>
</ng-template>
