import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { MarinasService } from '@dm-workspace/core';
import { tap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { distinctUntilChanged } from 'rxjs/operators';
import { ComparatorUtils } from '@dm-workspace/utils';

@Component({
  selector: 'dm-mms-layout-marina-code-wrapper-view',
  templateUrl: './mms-layout-marina-code-wrapper-view.component.html',
  styleUrls: ['./mms-layout-marina-code-wrapper-view.component.scss'],
})
export class MmsLayoutMarinaCodeWrapperViewComponent implements OnInit {
  #marinaService = inject(MarinasService);
  #aRouter = inject(ActivatedRoute);
  #router = inject(Router);
  #destroyRef = inject(DestroyRef);

  ngOnInit() {
    this.#marinaService.selectedMarina$
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        distinctUntilChanged(ComparatorUtils.byId),
        tap((marina) => {
          const { marinaCode } = this.#aRouter.snapshot.params;
          if (marina.code === marinaCode) {
            return;
          }

          const { url } = this.#router.routerState.snapshot;
          const newSelectedMarinaUrl = url.replace(marinaCode, marina.code);
          this.#router.navigateByUrl(newSelectedMarinaUrl);
        })
      )
      .subscribe();
  }
}
