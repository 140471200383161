import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BoatUsage, IBoat, IBoatCreatePayload, IBoatDto } from '@dm-workspace/types';
import { finalize } from 'rxjs/operators';
import { ApiValidatorService } from '@dm-workspace/forms';
import { MmsBoatsApiService } from '@dm-workspace/data-access';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dm-mms-modals-new-boat-modal',
  templateUrl: './new-boat-modal.component.html',
  styleUrls: ['./new-boat-modal.component.scss'],
})
export class NewBoatModalComponent {
  public boat: IBoat;
  public form: UntypedFormGroup;
  public isPending = false;

  constructor(
    private apiValidator: ApiValidatorService,
    private boatService: MmsBoatsApiService,
    private activeModal: NgbActiveModal
  ) {}

  public onFormReady(form: UntypedFormGroup): void {
    this.form = form;
  }

  public onSubmit(): void {
    if (this.isPending || (this.form && !this.apiValidator.formIsValid(this.form))) {
      return;
    }

    this.isPending = true;
    const formValues = this.form.getRawValue() as IBoatCreatePayload;

    formValues.isCharter = formValues.usage === BoatUsage.CHARTER;

    this.boatService
      .create(formValues)
      .pipe(finalize(() => (this.isPending = false)))
      .subscribe({
        next: (boatResponse) => {
          this.close(boatResponse);
        },
        error: (errors) => {
          this.apiValidator.parseServerSideErrors(this.form, errors.error.details);
        },
      });
  }

  public close(result?: IBoatDto): void {
    this.activeModal.close(result);
  }
}
