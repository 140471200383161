import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MmsOfferViewService } from '../../services/mms-offer-view.service';

@Component({
  selector: 'dm-mms-offer-timeline-view.component',
  templateUrl: './mms-offer-timeline-view.component.html',
  styleUrl: './mms-offer-timeline-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsOfferTimelineViewComponent {
  readonly offerViewService = inject(MmsOfferViewService);
  protected readonly offer = this.offerViewService.offer;
  protected readonly fetching = this.offerViewService.fetching;
}
