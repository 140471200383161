<div class="modal-header">
  <h2 class="title">{{ "RETURN_FROM_CRUISE" | translate }}</h2>
</div>

<div class="modal-body">
  <p class="mb-4">
    {{ "RETURN_MODAL.MMI.0" | translate }}
    <strong>{{ "BERTH" | translate }} {{ booking.resource?.name }}</strong>
  </p>

  <p class="mb-3">{{ "RETURN_MODAL.MMI.1" | translate }}:</p>

  <table
    [style.max-width.px]="300"
    class="table table--no-border table--no-aside-space"
    *ngIf="booking.marina | getMarinaByCode | async as marina"
  >
    <thead>
      <tr>
        <th>{{ "PHONE" | translate }}:</th>
        <th>{{ "EMAIL" | translate }}</th>
      </tr>
    </thead>
    <tbody *ngLet="marina.additionalProperties.contactInfo as contactInfo">
      <tr>
        <td>
          <a href="tel:{{ contactInfo.phone }}">{{ contactInfo.phone }}</a>
        </td>
        <td>
          <a href="mailto:{{ contactInfo.email }}">{{ contactInfo.email }}</a>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="modal-footer justify-content-between d-flex">
  <button class="btn btn-tertiary btn-narrow" type="button" (click)="closeModal(false)">
    {{ "CANCEL" | translate }}
  </button>
  <button type="button" class="btn btn-primary" (click)="onAction()" [disabled]="pending">
    <dm-ui-spinner [showSpinner]="pending" />
    {{ "CONFIRM" | translate }}
  </button>
</div>
