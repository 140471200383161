import { Directive, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ApiErrorObject, GenericObject } from '@dm-workspace/types';

@Directive()
export class NewBookingFormComponentClass<T extends GenericObject> {
  @Input() columnClasses: string;
  @Input() wideColumnClasses: string;
  @Input() form: UntypedFormGroup;
  @Input() disabled = false;
  @Input() apiErrors: ApiErrorObject<T>;
}
