import { Pipe, PipeTransform } from '@angular/core';
import { ListMarinaPylonsResponseDto } from '@dm-workspace/types';
import { berthsColors } from '@dm-workspace/map-utils';

@Pipe({
  name: 'pylonCircle',
})
export class PylonCirclePipe implements PipeTransform {
  transform(location: google.maps.LatLngLiteral, pylons: ListMarinaPylonsResponseDto[]): google.maps.CircleOptions {
    const hasUsgaeWithoutBooking = pylons.some((value) => !value.bookingReferenceId && value.isUsed);
    return {
      center: location,
      fillColor: hasUsgaeWithoutBooking ? berthsColors.red.fillColor : '#D9E2EB',
      strokeColor: hasUsgaeWithoutBooking ? berthsColors.red.strokeColor : '#506A8D',
      fillOpacity: 1,
      strokeOpacity: 1,
      strokeWeight: 1,
      zIndex: 10,
      radius: 2,
    };
  }
}
