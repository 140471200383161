import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs';
import { delay } from 'rxjs/operators';
import { NgbCalendar, NgbDate, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { DATE_FORMAT_PLACEHOLDER } from '@dm-workspace/shared';
import {
  DateRange,
  IAutocompleteResourceParams,
  IAvailabilityCheck,
  IAvailabilityCheckPayload,
  Marina,
  ResourceBookingBody,
} from '@dm-workspace/types';

import { NewBookingFormComponentClass } from '../../../../../marina/src/lib/classes/new-booking-form-component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SimpleChanges2 } from '@dm-workspace/utils';

@Component({
  selector: 'dm-mms-bookings-resource-booking-form',
  templateUrl: './mms-resource-booking-form.component.html',
  styleUrls: ['./mms-resource-booking-form.component.scss'],
})
export class MmsResourceBookingFormComponent
  extends NewBookingFormComponentClass<ResourceBookingBody>
  implements OnInit, OnChanges
{
  #destroyRef = inject(DestroyRef);
  @Input() public resourceId?: string;
  @Input({ required: true }) public marina: Marina;
  @Output() public availabilityCheck = new EventEmitter<IAvailabilityCheck>();
  public datePlaceholderFormat = DATE_FORMAT_PLACEHOLDER;
  public berthSearch: string;
  public availabilityPayload: IAvailabilityCheckPayload;
  public resourceAddonParams: Partial<IAutocompleteResourceParams> = {
    excludeInaccessible: true,
  };
  public readonly today = this.calendar.getToday();
  protected bookingMaxDateString: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    public calendar: NgbCalendar,
    public adapter: NgbDateAdapter<string>,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges2<MmsResourceBookingFormComponent>) {
    const marina = changes.marina.currentValue;
    if (marina) {
      this.bookingMaxDateString = marina.additionalProperties?.bookingMaxDate;
    }
  }

  ngOnInit() {
    const { berth, fromDate } = this.activatedRoute.snapshot.queryParams;

    if (berth) {
      this.berthSearch = berth;
    }

    if (fromDate) {
      this.form.get('fromDate').patchValue(fromDate);
    }

    if (this.resourceId) {
      this.form
        .get('resourceId')
        .valueChanges.pipe(first(), delay(100))
        .subscribe(() => {
          this.checkBerthAvailability();
        });
    }
    this.form
      .get('existingBoat')
      .valueChanges.pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => {
        this.checkBerthAvailability();
      });
  }

  public get minStartDate(): NgbDate {
    if (this.resourceId) {
      return null;
    }
    return this.calendar.getPrev(this.today, 'd', 2);
  }

  public getDateRange(): DateRange {
    const { fromDate, toDate } = this.form.value || {};
    return { fromDate, toDate };
  }

  public sendAvailabilityEvent($event: IAvailabilityCheck) {
    this.availabilityCheck.emit($event);
  }

  public checkBerthAvailability(): void {
    if (!this.form.get('resourceId').value) {
      return;
    }
    this.availabilityPayload = this.#getAvailabilityPayload();
    this.cd.detectChanges();
  }

  #getAvailabilityPayload(): IAvailabilityCheckPayload {
    const { resourceId, fromDate, toDate } = this.form.getRawValue();
    if (!resourceId) {
      return null;
    }

    const existingBoat = this.form.get('existingBoat').value;

    return {
      resourceId: resourceId?.id || resourceId,
      fromDate,
      toDate,
      boatId: existingBoat?.id || (typeof existingBoat === 'string' ? existingBoat : null),
      forBooking: this.resourceId,
    };
  }
}
