import { ChangeDetectionStrategy, Component, computed, EventEmitter, input, Input, Output } from '@angular/core';
import {
  BerthAvailabilityReservationDto,
  BerthOccupancyDetails,
  BerthOccupancyStatusInMarina,
  BerthReservationsStatusInMarina,
  BoatMovementOperation,
} from '@dm-workspace/types';
import { ACTION_TYPES, BoatActions } from '../action.model';
import { BerthDetailsDraftClass } from '../berthDetailsDraft.class';
import { MapBerthClass } from '../../../berths.class';

@Component({
  selector: 'dm-map-map-berth-details-occupancy-list',
  templateUrl: './map-berth-details-occupancy-list.component.html',
  styleUrl: './map-berth-details-occupancy-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapBerthDetailsOccupancyListComponent extends BerthDetailsDraftClass {
  berth = input.required<MapBerthClass>();
  hasItemsToShow = computed(() => !this.berth().occupancyStatus.includes(BerthOccupancyStatusInMarina.empty));
  hasSomeReservation = computed(
    () => !this.berth().reservationStatus.includes(BerthReservationsStatusInMarina.available)
  );
  mappedOccupancy = computed(() => this.berth().getOccupancyListDetails());
  @Input() marinaCode: string;
  @Output() boatAction = new EventEmitter<BoatActions>();

  moveBoat(occupancy: BerthOccupancyDetails, boatQuickReturnType: BoatMovementOperation = null) {
    this.boatAction.emit({
      action: ACTION_TYPES.moveBoat,
      payload: {
        boat: occupancy.boat,
        resourceId: occupancy.resourceId,
        boatQuickReturnType,
      },
    });
  }
  departOrArrivalBoat(occupancy: BerthOccupancyDetails, isArrival = false) {
    this.boatAction.emit({
      action: isArrival ? ACTION_TYPES.reportArrival : ACTION_TYPES.reportDeparture,
      payload: {
        boatId: occupancy.boat.id,
        berthName: this.berth().data.berthName,
        resourceId: occupancy.resourceId,
      },
    });
  }

  transferBooking(reservation: BerthAvailabilityReservationDto) {
    this.boatAction.emit({
      action: ACTION_TYPES.transferBooking,
      payload: {
        boatId: reservation.boat.id,
        reservationId: reservation.reservationId,
        toDate: reservation.toDate,
        fromDate: reservation.fromDate,
        resource: {
          id: reservation.resourceId,
          name: this.berth().data.berthName,
        },
      },
    });
  }

  quickQuote(occupancy: BerthOccupancyDetails) {
    this.boatAction.emit({
      action: ACTION_TYPES.quickQuote,
      payload: {
        boatId: occupancy.boat.id,
      },
    });
  }

  protected readonly BoatMovementOperation = BoatMovementOperation;
}
