import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, signal } from '@angular/core';
import { ResourceBookingApiService } from '@dm-workspace/data-access';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '@dm-workspace/notification';
import { catchError, defer, finalize, shareReplay, startWith, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiValidator } from '@dm-workspace/shared';

@Component({
  selector: 'dm-mms-shared-booking-media-access-modal',
  templateUrl: './mms-booking-media-access-modal.component.html',
  styleUrls: ['./mms-booking-media-access-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsBookingMediaAccessModalComponent {
  protected apiCalling = signal<boolean>(false);
  @Input() bookingId: string;

  protected readonly mediaAccess$ = defer(() => {
    return this.resourceBookingApiService.getBookingAvailableMediaAccess(this.bookingId);
  }).pipe(
    catchError((res: HttpErrorResponse) => {
      this.notification.openError(ApiValidator.getApiNotificationError(res));
      this.activeModal.dismiss();
      return throwError(() => res);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  readonly #products = this.mediaAccess$.pipe(
    map(({ additionalMediaProducts, existingMediaProducts }) => {
      if (existingMediaProducts?.length) {
        return existingMediaProducts;
      }
      return additionalMediaProducts;
    })
  );

  protected readonly totalPaymentForProducts$ = this.#products.pipe(
    map((products) => products?.reduce((acc, curr) => acc + curr.pricing.sumPriceGross, 0))
  );
  protected readonly currency$ = this.#products.pipe(map((products) => products[0]?.pricing?.currency));
  protected readonly fetchingMediaMediaAccess$ = this.mediaAccess$.pipe(
    map(() => false),
    startWith(true)
  );

  constructor(
    private activeModal: NgbActiveModal,
    private notification: NotificationService,
    private resourceBookingApiService: ResourceBookingApiService,
    private cd: ChangeDetectorRef
  ) {}

  public onSubmit() {
    this.apiCalling.set(true);
    this.resourceBookingApiService
      .setBookingMediaAccess(this.bookingId)
      .pipe(
        finalize(() => {
          this.apiCalling.set(false);
          this.cd.detectChanges();
        })
      )
      .subscribe({
        next: () => {
          this.notification.openSuccess();
          this.activeModal.close(true);
        },
        error: (res: HttpErrorResponse) => {
          this.notification.openError(ApiValidator.getApiNotificationError(res));
        },
      });
  }

  public dismissModal() {
    this.activeModal.dismiss();
  }
}
