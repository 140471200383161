import { BoatAbsenceMapResponseDto } from '@dm-workspace/types';
import { MmsBoatMovementChangeGapModalComponent } from '@dm-workspace/mms-panel/shared';
import { filter } from 'rxjs';
import { inject, input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MapMmiComponentToken } from '../map-mmi/map-mmi.component';
import { MapBerthClass } from '../../berths.class';
export class BerthDetailsDraftClass {
  berth = input.required<MapBerthClass>();
  #modalService = inject(NgbModal);

  #mapMmiComponent = inject(MapMmiComponentToken);

  protected openEditGapModal(temporaryAbsence: BoatAbsenceMapResponseDto) {
    const { expectedReturnDate, movementOperationDate, movementId, gapEnd, gapStart } = temporaryAbsence;
    const reservation = this.berth().data.bookings.find((value) => value.boatId === temporaryAbsence.boatId);
    const modal = this.#modalService.open(MmsBoatMovementChangeGapModalComponent, {
      size: 'lg',
    });
    const modalInstance = modal.componentInstance as MmsBoatMovementChangeGapModalComponent;

    modalInstance.expectedReturnDate.set(expectedReturnDate);
    modalInstance.movementOperationDate.set(movementOperationDate);
    modalInstance.gapStart.set(gapStart);
    modalInstance.gapEnd.set(gapEnd);
    modalInstance.movementId.set(movementId);
    if (reservation) {
      modalInstance.reservation.set(reservation);
    }

    modal.closed.pipe(filter(Boolean)).subscribe(() => {
      this.#mapMmiComponent.fetchBerthStatuses();
    });
  }
  temporaryAbsence(boatId: string) {
    return this.berth().data.temporaryAbsence.find((value) => value.boatId === boatId);
  }
}
