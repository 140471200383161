import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MmsDashboardViewComponent } from './views/mms-dashboard-view/mms-dashboard-view.component';
import { MmsDashboardRoutingModule } from './mms-dashboard-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { MmsDashboardViewService } from './services/mms-dashboard-view.service';
import { MmsDashboardNewInquiriesViewComponent } from './views/mms-dashboard-new-inquiries-view/mms-dashboard-new-inquiries-view.component';
import { MmsDashboardListDoneComponent } from './components/mms-dashboard-list-done/mms-dashboard-list-done.component';
import { MmsDashboardNavigationComponent } from './components/mms-dashboard-navigation/mms-dashboard-navigation.component';
import { UiModule } from '@dm-workspace/ui';
import { LibsSharedModule } from '@dm-workspace/shared';
import { MmsDashboardExpectedArrivalsViewComponent } from './views/mms-dashboard-expected-arrivals-view/mms-dashboard-expected-arrivals-view.component';
import { MmsDashboardDeparturesViewComponent } from './views/mms-dashboard-departures-view/mms-dashboard-departures-view.component';
import { MmsDashboardCruisesViewComponent } from './views/mms-dashboard-cruises-view/mms-dashboard-cruises-view.component';
import { MmsDashboardOtherIssuesViewComponent } from './views/mms-dashboard-other-issues-view/mms-dashboard-other-issues-view.component';
import { MmsDashboardCheckInsViewComponent } from './views/mms-dashboard-check-ins-view/mms-dashboard-check-ins-view.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { MmsDashboardBookingsListComponent } from './components/mms-dashboard-bookings-list/mms-dashboard-bookings-list.component';
import { MmsDashboardUnfinishedBookingsComponent } from './components/mms-dashboard-unfinished-bookings/mms-dashboard-unfinished-bookings.component';
import { MmsDashboardCustomerDidNotShowComponent } from './components/mms-dashboard-customer-did-not-show/mms-dashboard-customer-did-not-show.component';
import { MmsDashboardNavigationItemComponent } from './components/mms-dashboard-navigation-item/mms-dashboard-navigation-item.component';
import { MmsPanelSharedModule } from '@dm-workspace/mms-panel/shared';
import { MmsDashboardEmptyTileGuard } from './guards/mms-dashboard-empty-tile-guard.service';
import { MmsDashboardBoatMovementsViewComponent } from './views/mms-dashboard-boat-movements-view/mms-dashboard-boat-movements-view.component';
import { MmsDashboardQuotesViewComponent } from './views/mms-dashboard-quotes-view/mms-dashboard-quotes-view.component';
import { MmsOffersModule } from '@dm-workspace/mms-panel/mms-offers';

@NgModule({
  imports: [
    CommonModule,
    MmsDashboardRoutingModule,
    TranslateModule,
    UiModule,
    LibsSharedModule,
    NgbTooltipModule,
    MmsPanelSharedModule,
    MmsOffersModule,
  ],
  declarations: [
    MmsDashboardViewComponent,
    MmsDashboardNewInquiriesViewComponent,
    MmsDashboardListDoneComponent,
    MmsDashboardNavigationComponent,
    MmsDashboardExpectedArrivalsViewComponent,
    MmsDashboardDeparturesViewComponent,
    MmsDashboardCruisesViewComponent,
    MmsDashboardOtherIssuesViewComponent,
    MmsDashboardCheckInsViewComponent,
    MmsDashboardBookingsListComponent,
    MmsDashboardUnfinishedBookingsComponent,
    MmsDashboardCustomerDidNotShowComponent,
    MmsDashboardNavigationItemComponent,
    MmsDashboardBoatMovementsViewComponent,
    MmsDashboardQuotesViewComponent,
  ],
  providers: [MmsDashboardViewService, MmsDashboardEmptyTileGuard],
})
export class MmsDashboardModule {}
