<p class="mb-1">
  {{ quote().activitySummary?.contactAttempts?.last | dateFormat: DATE_SHORT_YEAR_FORMAT | empty }}
</p>
<p class="color-secondary">
  <strong class="color-black" [class.color-danger]="quote().activitySummary?.contactAttempts?.count >= 3">
    {{ quote().activitySummary?.contactAttempts?.count | ordinal }}
  </strong>
  {{ "ATTEMPT" | translate | lowercase }}
  <ng-container *ngLet="quote().activitySummary?.contactAttempts?.last | dateDiff: nowDate as sentDaysAgo">
    @if (sentDaysAgo === 0) {
      • {{ "TODAY" | translate | lowercase }}
    } @else if (sentDaysAgo > 0) {
      •
      <strong class="color-black" [class.color-danger]="sentDaysAgo >= 7">
        {{ sentDaysAgo }}
      </strong>
      {{ sentDaysAgo | singularPlural: "DAY_AGO" : "DAYS_AGO" | translate | lowercase }}
    }
  </ng-container>
</p>
