import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import {
  BerthAvailabilityReservationDto,
  BerthOccupancyStatusInMarina,
  BoatMovementOperation,
} from '@dm-workspace/types';
import { BerthDrawerDetailsClass } from '../berth-drawer-details.class';
import { MapBerthClass } from '../../../berths.class';

@Component({
  selector: 'dm-map-map-berth-details-occupancy-list',
  templateUrl: './map-berth-details-occupancy-list.component.html',
  styleUrl: '../map-berth-details-list-styles.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapBerthDetailsOccupancyListComponent extends BerthDrawerDetailsClass {
  berth = input.required<MapBerthClass>();
  marinaCode = input.required<string>();
  extendedBookingsData = input.required<BerthAvailabilityReservationDto[]>();
  hasItemsToShow = computed(() =>
    this.berth() ? !this.berth().occupancyStatus.includes(BerthOccupancyStatusInMarina.empty) : false
  );
  ongoingBooking = computed(() =>
    this.hasItemsToShow() ? this.getOccupancyListDetails().filter((occ) => occ.booking)[0] : undefined
  );
  occupancy = computed(() =>
    this.getOccupancyListDetails().filter((occ) => occ.boatId !== this.ongoingBooking()?.boatId)
  );

  protected readonly BoatMovementOperation = BoatMovementOperation;
}
