import { ChangeDetectorRef, Directive, inject, Input } from '@angular/core';
import { finalize, Observable, of, switchMap } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { MmsBoatsApiService, ResourceBookingApiService } from '@dm-workspace/data-access';
import { NotificationService } from '@dm-workspace/notification';
import {
  BoatMovementOperation,
  BoatMovementOperationForStatus,
  PostBoatMovementBody,
  IBoatMovement,
  ResourceBookingBoatEventRequest,
} from '@dm-workspace/types';
import { BoatMovementService } from './../services/boat-movement.service';

@Directive()
export abstract class BoatOperationModalClass<T extends { id: string }> {
  readonly #boatMovementService = inject(BoatMovementService);

  @Input() public booking: T;
  @Input() public operationWithoutBooking: {
    resourceId: string;
    boatId: string;
    berthName: string;
  };
  public pending = false;

  protected constructor(
    protected cd: ChangeDetectorRef,
    protected activeModal: NgbActiveModal,
    protected resourceBookingApi: ResourceBookingApiService,
    protected notification: NotificationService,
    protected resourceBookingService: ResourceBookingApiService,
    protected boatApi: MmsBoatsApiService
  ) {
    setTimeout(() => {
      console.log(this.operationWithoutBooking);
    }, 1500);
  }

  protected abstract operationType: BoatMovementOperationForStatus;
  protected abstract successMessage: string;
  protected abstract errorMessage: string;
  public operationTimestamp: string;
  public declaredReturnDate: string;
  public allowsBerthReuse: boolean;

  protected valid(): boolean {
    return true;
  }

  public closeModal(success = true): void {
    this.activeModal.close(success);
  }

  public dismissModal(success = true): void {
    this.activeModal.dismiss();
  }

  public onAction(): void {
    if (this.pending || !this.valid()) return;
    this.pending = true;

    const now = new Date();
    const operationTimestamp = this.operationTimestamp || now.toISOString();

    let payload: ResourceBookingBoatEventRequest = {
      operation: this.operationType,
      operationTimestamp,
    };

    if (this.declaredReturnDate) {
      payload = {
        ...payload,
        declaredReturnDate: this.declaredReturnDate,
      };
    }
    if (this.allowsBerthReuse) {
      payload = {
        ...payload,
        allowsBerthReuse: this.allowsBerthReuse,
      };
    }

    let api: Observable<void | IBoatMovement | boolean>;

    if (this.operationWithoutBooking) {
      const { boatId, resourceId: sourceId } = this.operationWithoutBooking;

      let payloadMovement: PostBoatMovementBody = {
        sourceId,
        operation: this.operationType as unknown as BoatMovementOperation,
        startsAt: operationTimestamp,
        marinaCode: null,
      };
      if (this.allowsBerthReuse) {
        payloadMovement = {
          ...payloadMovement,
          allowsBerthReuse: this.allowsBerthReuse,
        };
      }

      api = this.#boatMovementService.validAndAcceptMovement(boatId, payloadMovement).pipe(
        switchMap((accepted) => {
          if (!accepted) {
            return of(false);
          }
          return this.boatApi.movement(boatId, payloadMovement);
        })
      );
    } else {
      alert('do usuniecia');
      throw Error('boatEvent');
      // api = this.resourceBookingApi.boatEvent(this.booking.id, payload).pipe(map(() => true));
    }

    api
      .pipe(
        finalize(() => {
          this.pending = false;
          this.cd.detectChanges();
        })
      )
      .subscribe({
        next: (success) => {
          if (!success) {
            return;
          }
          this.onSuccess(this.successMessage);
        },
        error: () => {
          this.onError(this.errorMessage);
        },
      });
  }

  onSuccess(text: string): void {
    this.closeModal();
    this.notification.add({
      type: 'success',
      text,
    });
    this.resourceBookingService.triggerViewRefresh();
  }

  onError(message: string): void {
    this.notification.add({
      type: 'error',
      text: 'ERRORS.' + message || 'GENERAL',
    });
  }
}
