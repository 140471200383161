import { Pipe, PipeTransform } from '@angular/core';
import {
  IResourceBookingOrder,
  IResourceBookingQuotation,
  OrderPaymentStatus,
  QuotationStatus,
} from '@dm-workspace/types';

@Pipe({
  name: 'bookingCanMarkAsPaid',
})
export class MmsBookingCanMarkAsPaidPipe implements PipeTransform {
  transform(booking: IResourceBookingQuotation): boolean {
    if (!booking) {
      return false;
    }

    const isCorrectQuotationStatus = [QuotationStatus.SENT, QuotationStatus.DRAFT].includes(booking.quotationStatus);
    return (
      booking?.boat?.id &&
      booking?.customer?.id &&
      booking?.resource &&
      booking?.primaryOrder?.id &&
      this.#validateOrder(booking.primaryOrder) &&
      isCorrectQuotationStatus
    );
  }

  #validateOrder(order: IResourceBookingOrder) {
    if (!order) {
      return false;
    }

    const isCorrectPaymentStatus = order.paymentStatus !== OrderPaymentStatus.PAID;
    return order.id && isCorrectPaymentStatus;
  }
}
