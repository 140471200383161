<div class="row row-cols-5 gy-4">
  <dm-form-input-value>
    <ng-container header>{{"OFFER_NAME" | translate}}</ng-container>
    <ng-container body>{{offer?.name | empty}}</ng-container>
  </dm-form-input-value>
  <dm-form-input-value>
    <ng-container header>{{"BOOKING_ID" | translate}}</ng-container>
    <ng-container body>{{offer?.booking?.humanReadableId | empty}}</ng-container>
  </dm-form-input-value>
  <dm-form-input-value>
    <ng-container header>{{"STATUS" | translate}}</ng-container>
    <ng-container body>{{offer?.quoteStatus | translate | empty}}</ng-container>
  </dm-form-input-value>
  <dm-form-input-value>
    <ng-container header>{{"BERTH" | translate}}</ng-container>
    <ng-container body>{{offer?.proposedResource?.name | empty}}</ng-container>
  </dm-form-input-value>
</div>

<div class="row row-cols-5 gy-4 pt-4">
  <dm-form-input-value>
    <ng-container header>{{"CUSTOMER" | translate}}</ng-container>
    <ng-container body>
      <a
        [routerLink]="['/customers', offer?.customer.id]"
        class="link link--color-primary"
        returnUrlCurrentPath
      >{{offer?.customer.displayName | empty}}</a>
    </ng-container>
  </dm-form-input-value>
  <dm-form-input-value>
    <ng-container header>{{"BOAT" | translate}}</ng-container>
    <ng-container body>
      <a
        [routerLink]="['/boats', offer?.boat?.id]"
        [state]="{ backOnClose: true }"
        class="link link--color-primary"
        returnUrlCurrentPath
      >{{ offer?.boat?.name }}</a>
    </ng-container>
  </dm-form-input-value>
  <dm-form-input-value>
    <ng-container header>{{"START_DATE" | translate}}</ng-container>
    <ng-container body>{{offer?.startDate | dateFormat }}</ng-container>
  </dm-form-input-value>
  <dm-form-input-value>
    <ng-container header>{{"END_DATE" | translate}}</ng-container>
    <ng-container body>{{offer?.endDate | dateFormat }}</ng-container>
  </dm-form-input-value>
  <dm-form-input-value>
    <ng-container header>{{"CREATED_AT" | translate}}</ng-container>
    <ng-container body>{{offer?.createdAt | dateFormat : 'date_time' }}</ng-container>
  </dm-form-input-value>
</div>
