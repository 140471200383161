import { ChangeDetectionStrategy, Component, DestroyRef, inject, Input, signal } from '@angular/core';
import {
  IAvailabilityCheck,
  IAvailabilityCheckPayload,
  IBaseId,
  ResourceBookingBody,
  ResourceType,
} from '@dm-workspace/types';
import { ConfirmModal } from '@dm-workspace/ui';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormUtils } from '@dm-workspace/forms';
import { ResourceBookingApiService } from '@dm-workspace/data-access';
import { finalize, map } from 'rxjs/operators';
import { NotificationService } from '@dm-workspace/notification';
import { ApiValidator } from '@dm-workspace/shared';
import { formatDate } from '@dm-workspace/utils';
import { defer, Observable } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'dm-mms-boat-report-arrival-modal',
  templateUrl: './mms-transfer-booking-modal.component.html',
  styleUrls: ['./mms-transfer-booking-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsTransferBookingModalComponent extends ConfirmModal {
  readonly #resourceApi = inject(ResourceBookingApiService);
  readonly #notificationService = inject(NotificationService);
  #destroyRef = inject(DestroyRef);
  @Input({ required: true }) reservationId: string;
  @Input({ required: true }) boatId: string;
  @Input({ required: true }) fromDate: string;
  @Input({ required: true }) toDate: string;
  @Input({ required: true }) set resource(value: IBaseId) {
    this.form.controls.from.setValue(value);
  }
  protected readonly apiCalling = signal<boolean>(false);
  protected readonly ResourceType = ResourceType;
  protected readonly form = new FormGroup({
    resource: new FormControl<IBaseId>(null, { validators: [Validators.required] }),
    from: new FormControl<IBaseId>(
      {
        value: null,
        disabled: true,
      },
      { validators: [Validators.required] }
    ),
  });

  checkPayload$: Observable<IAvailabilityCheckPayload> = defer(() =>
    this.form.controls.resource.valueChanges.pipe(
      map((value) => ({
        resourceId: value.id,
        fromDate: formatDate(new Date(this.fromDate)),
        toDate: formatDate(new Date(this.toDate)),
        boatId: this.boatId,
      })),
      takeUntilDestroyed(this.#destroyRef)
    )
  );

  submitForm() {
    if (this.apiCalling() || !FormUtils.isValid(this.form)) {
      return;
    }

    this.apiCalling.set(true);

    const { resource } = this.form.getRawValue();
    const dto: Partial<ResourceBookingBody> = {
      resourceId: resource.id,
      existingBoatId: this.boatId,
      fromDate: formatDate(new Date(this.fromDate)),
      toDate: formatDate(new Date(this.toDate)),
    };
    // const { fromDate, toDate, reservationId } = this.reservation;
    this.#resourceApi
      .updateBooking(this.reservationId, dto)
      .pipe(finalize(() => this.apiCalling.set(false)))
      .subscribe({
        next: () => {
          this.#notificationService.openSuccess();
          super.onConfirm();
        },
        error: (res) => this.#notificationService.openError(ApiValidator.getApiNotificationError(res)),
      });
  }

  availabilityCheck($event: IAvailabilityCheck) {
    this.apiCalling.set($event.pending);
  }
}
