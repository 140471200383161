import { inject, Injectable } from '@angular/core';
import { first, Observable, of, switchMap } from 'rxjs';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { MarinasService } from '@dm-workspace/core';

@Injectable({ providedIn: 'root' })
export class MmsMarinaCodeGuard {
  #marinasService = inject(MarinasService);
  #router = inject(Router);

  public canActivate(aRoute: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const { marinaCode } = aRoute.params;

    return this.#marinasService.selectedMarina$.pipe(
      first(),
      switchMap((currentlySelectedMarina) => {
        const urlContainsSelectedMarinaCode = marinaCode === currentlySelectedMarina.code;

        if (!urlContainsSelectedMarinaCode) {
          return this.#checkIfRouteMarinaCodeIsValid(marinaCode);
        }

        return of(true);
      })
    );
  }

  #checkIfRouteMarinaCodeIsValid(routeMarinaCode: string): Observable<boolean | UrlTree> {
    return this.#marinasService.validatedMarinaCode(routeMarinaCode).pipe(
      switchMap((marina) => {
        if (!marina) {
          return of(this.#router.parseUrl(`404`));
        }

        return of(true);
      })
    );
  }
}
