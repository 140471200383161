import {
  ProfileDetailsFormDirective,
  ProfileDetailsFormService,
  ProfileDocumentsFormService,
} from '@dm-workspace/shared';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CorporateType, COUNTRIES_NEW, CountryCode } from '@dm-workspace/types';
import { selectSearchCountriesNewFn } from '@dm-workspace/utils';
import { MmsCustomerForm } from './mms-customer-form.service';
import { MarinasService } from '@dm-workspace/core';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'dm-mms-customer-form',
  templateUrl: './mms-customer-form.component.html',
  styleUrls: ['./mms-customer-form.component.scss'],
  hostDirectives: [
    {
      directive: ProfileDetailsFormDirective,
      inputs: ['form'],
    },
  ],
})
export class MmsCustomerFormComponent {
  @Input() public form: MmsCustomerForm;
  @Output() public countryCodeChange = new EventEmitter<CountryCode>();

  protected readonly COUNTRIES_NEW = COUNTRIES_NEW;
  protected readonly selectSearchCountriesNewFn = selectSearchCountriesNewFn;
  protected readonly CorporateType = CorporateType;

  protected get showingDocuments(): boolean {
    if (!this.form) {
      return false;
    }
    const { fiscalCode, taxExemptNumber, passport } = this.form.controls;
    return Boolean(fiscalCode || taxExemptNumber || passport);
  }

  constructor(
    private marinasService: MarinasService,
    private profileDocumentsFormService: ProfileDocumentsFormService,
    private profileDetailsFormService: ProfileDetailsFormService,
    protected profileDetailsFormDirective: ProfileDetailsFormDirective
  ) {}

  public onCountryCodeChange(countryCode: CountryCode): void {
    this.marinasService.selectedMarina$
      .pipe(
        tap((marina) => {
          const { corporateType, type } = this.form.getRawValue();
          this.profileDocumentsFormService.changeForm(this.form, {
            countryCode,
            marinaCode: marina?.countryCode,
            corporateType,
            customerType: type,
          });
          this.profileDetailsFormService.changeFormByCountryCode(this.form, countryCode);
        })
      )
      .subscribe();
  }
}
