import { Component, Input, OnInit } from '@angular/core';
import { FormView } from '@dm-workspace/core';
import { IResourceBooking } from '@dm-workspace/types';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '@dm-workspace/notification';
import { ResourceBookingApiService } from '@dm-workspace/data-access';
import { ApiValidatorService } from '@dm-workspace/forms';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { catchError, Observable, Subscription, switchMap, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ResourceBookingCancellationPreviewModalComponent } from '../resource-booking-cancellation-preview-modal/resource-booking-cancellation-preview-modal.component';
import { finalize, map } from 'rxjs/operators';

enum ResourceBookingCancellationReason {
  NO_AVAILABILITY = 'Berth is not available on the selected date',
  EXPIRED_PAYMENT = 'Payment link has expired',
  NO_PAYMENT = 'No payment has been received for booking',
  CUSTOMER_REQUEST = "At the customer's request",
  PROCESSED_IN_CRM = 'Processed in CRM',
  DUPLICATE = 'Duplicate',
  OTHER = 'Other',
}

interface FormValue {
  reason: ResourceBookingCancellationReason;
  comment?: string;
  sendEmail: boolean;
}

@Component({
  selector: 'dm-mms-shared-resource-booking-cancellation-modal',
  templateUrl: './resource-booking-cancellation-modal.component.html',
  styleUrls: ['./resource-booking-cancellation-modal.component.scss'],
})
export class ResourceBookingCancellationModalComponent extends FormView<FormValue> implements OnInit {
  @Input() booking: { id: string; marinaCode?: string };

  public readonly reasonData = Object.entries(ResourceBookingCancellationReason).map((val) => {
    const [label, value] = val;
    return {
      label,
      value,
    };
  });

  private commentControl = new UntypedFormControl(null, [Validators.required]);
  private reasonControl = new UntypedFormControl(null, [Validators.required]);
  private sendEmailControl = new UntypedFormControl(null);
  private subscription = new Subscription();

  private get customComment(): string {
    const { reason, comment } = this.form.value;
    return reason === ResourceBookingCancellationReason.OTHER ? comment : reason;
  }

  public get isReasonProcessInCrm(): boolean {
    return this.reasonControl.value === ResourceBookingCancellationReason.PROCESSED_IN_CRM;
  }

  constructor(
    apiValidator: ApiValidatorService,
    private activeModal: NgbActiveModal,
    private notification: NotificationService,
    private bookingApiService: ResourceBookingApiService,
    private fb: UntypedFormBuilder,
    private modal: NgbModal,
    private resourceBookingService: ResourceBookingApiService
  ) {
    super(apiValidator);
  }

  public ngOnInit(): void {
    this.form = this.fb.group({
      reason: this.reasonControl,
      comment: this.commentControl,
      sendEmail: this.sendEmailControl,
    });

    this.commentControl.disable();

    this.subscription.add(
      this.reasonControl.valueChanges.subscribe((value: ResourceBookingCancellationReason) =>
        this.onReasonChange(value)
      )
    );
  }

  private onReasonChange(value: ResourceBookingCancellationReason) {
    switch (value) {
      case ResourceBookingCancellationReason.OTHER: {
        this.commentControl.enable();
        return;
      }
      default: {
        this.commentControl.disable();
        return;
      }
    }
  }

  protected override apiCall(params: FormValue): Observable<IResourceBooking> {
    const { id, marinaCode } = this.booking;
    const { sendEmail } = params;

    return this.bookingApiService.cancel(id, marinaCode, {
      customComment: this.customComment,
      sendEmail,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected override onSuccess(res: any) {
    super.onSuccess(res);

    this.notification.add({
      content: 'QUOTATION_CANCELED',
    });
    this.resourceBookingService.triggerViewRefresh();
    this.activeModal.close(true);
  }

  protected override onError(res: HttpErrorResponse) {
    super.onError(res);

    this.notification.add({
      type: 'error',
      content: res?.error?.message,
    });
  }

  public onBeforeSubmit(sendEmail: boolean): void {
    this.sendEmailControl.setValue(sendEmail);

    super.onSubmit();
  }

  public onShowEmailPreview(): void {
    if (this.pending || this.formIsNotValid() || this.isReasonProcessInCrm) {
      return;
    }

    this.fetchEmailPreview()
      .pipe(switchMap((value) => this.openEmailPreviewModal(value)))
      .subscribe((doAction) => {
        if (doAction) {
          this.onBeforeSubmit(true);
        }
      });
  }

  private fetchEmailPreview(): Observable<string> {
    this.pending = true;

    const { id, marinaCode } = this.booking;

    return this.resourceBookingService
      .cancelEmailPreview(id, marinaCode, {
        customComment: this.customComment,
      })
      .pipe(
        finalize(() => (this.pending = false)),
        catchError((res: HttpErrorResponse) => {
          this.notification.add({
            type: 'error',
            content: res?.error?.message,
          });

          return throwError(() => res);
        }),
        map((res) => res.html)
      );
  }

  private openEmailPreviewModal(value: string): Observable<boolean> {
    const modal = this.modal.open(ResourceBookingCancellationPreviewModalComponent);
    const componentInstance = modal.componentInstance as ResourceBookingCancellationPreviewModalComponent;

    componentInstance.emailPreviewContent = value;

    return modal.closed;
  }

  public closeModal() {
    this.activeModal.close(false);
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
