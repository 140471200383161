import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { mmsAppRoutes } from './mms-app-routing.module';
import { MmsAppComponent } from './mms-app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { API_PREFIX, ApiPrefix, APP_TOKEN, AppToken, HTTP_REQUESTS_URL } from '@dm-workspace/types';
import { CoreModule, HttpCacheInterceptor, MarinaCodeInterceptor } from '@dm-workspace/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxMaskModule } from 'ngx-mask';
import { RouterModule } from '@angular/router';
import { SentryCoreModule } from '@dm-workspace/sentry-core';
import { ServiceWorkerCoreModule } from '@dm-workspace/service-worker-core';
import { MmsLayoutModule } from '@dm-workspace/mms-panel/mms-layout';
import { MarinasApiServiceToken, MmsMarinasApiService } from '@dm-workspace/data-access';

@NgModule({
  declarations: [MmsAppComponent],
  imports: [
    CoreModule,
    MmsLayoutModule,
    BrowserModule,
    NgbModule,
    SentryCoreModule,
    RouterModule.forRoot(mmsAppRoutes, {
      enableTracing: false,
      bindToComponentInputs: true,
      paramsInheritanceStrategy: 'always',
    }),
    NgxMaskModule.forRoot(),
    ServiceWorkerCoreModule,
  ],
  providers: [
    {
      provide: APP_TOKEN,
      useValue: AppToken.mmsPanel,
    },
    {
      provide: HTTP_REQUESTS_URL,
      useValue: [/^\/mms\/marinas\/(.+)\/berths$/],
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: HttpCacheInterceptor,
    },
    {
      provide: MarinasApiServiceToken,
      useClass: MmsMarinasApiService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: MarinaCodeInterceptor,
    },
    {
      provide: API_PREFIX,
      useValue: ApiPrefix.mmsPanel,
    },
  ],
  bootstrap: [MmsAppComponent],
})
export class MmsAppModule {}
