<div class="card px-xxl py-xxl">
  <h2 class="mb-l">{{ "OFFER_DETAILS" | translate | uppercase }}</h2>
  <dm-mms-offer-preview [offer]="offer()" />
</div>

<div class="card px-xxl py-xxl mt-2">
  <h2 class="mb-s">{{ "PRODUCT_LIST" | translate | uppercase }}</h2>

  <div [style.max-width.px]="636">
    <dm-mms-offer-products-table [offerProducts]="offer()?.data" [currency]="offer()?.currency" />
    <table class="table table--no-border mt-2" *ngIf="offer()?.data.length > 0">
      <tr>
        <td class="text-md"><strong>{{ "TOTAL_AMOUNT" | translate | uppercase }}</strong></td>
        <td class="text-right text-md">
          <strong>{{ offer()?.data | offerProductsSumPrice | currency : offer()?.currency }}</strong>
        </td>
      </tr>
    </table>
  </div>
</div>
