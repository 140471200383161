<div class="card position-relative px-4 pb-4">
  <dm-mms-dashboard-list-done *ngIf="hasBookingsAllDone">
    {{ "MMS_DASHBOARD.UNFINISHED_BOOKINGS_DONE" | translate }}
  </dm-mms-dashboard-list-done>

  <ng-container *ngIf="hasBookingsTodo">
    <table class="table pb-3">
      <thead>
        <tr>
          <th>{{ "BERTH" | translate }}</th>
          <th class="max-width max-width--id">{{ "BOOKING_ID" | translate }}</th>
          <th>{{ "START" | translate }}&nbsp;/<br />{{ "END_DATE" | translate }}</th>
          <th>{{ "BOAT" | translate }} /<br />{{ "BOAT_STATUS" | translate }}</th>
          <th class="actions">{{ "ACTIONS" | translate }}</th>
        </tr>
      </thead>
      <tbody *ngIf="bookings?.length">
        <tr *ngFor="let booking of bookings">
          <td>{{ booking.resource?.name }}</td>
          <td class="max-width max-width--id">{{ booking.humanReadableId }}</td>
          <td>{{ booking.fromDate | dateFormat }}<br />{{ booking.toDate | dateFormat }}</td>
          <td class="max-width max-width--boat">
            <dm-ui-boat-cell
              [boat]="booking.boat"
              [boatDimensions]="booking.boatDimensions"
              [additionalRow]="'statusInMarina'"
             />
          </td>
          <td class="actions actions--hide-overflow">
            @if (booking.availableActions | arrayIncludes : "DEPARTURE") {
              <button type="button" (click)="openDepartureModal(booking)" class="btn-pill-primary-light">
                {{ "DEPARTURE" | bookingActionTranslateKey | translate }}
              </button>
            }
          </td>
        </tr>
      </tbody>
    </table>

    <dm-ui-load-more-button
      [collectionSize]="pagination.totalCount"
      [offset]="pagination.offset"
      [rowsPerLoad]="pagination.limit"
      (pageChange)="onPageChange($event)"
      [isPending]="this.pending"
      >{{ "SHOW_MORE" }}</dm-ui-load-more-button
    >
  </ng-container>

  <dm-ui-overflow-spinner
    type="absolute"
    [showSpinner]="pending"
    [position]="pagination.offset > 0 ? 'bottom' : 'center'"
   />
</div>

<ng-template #empty>
  <span>-</span>
</ng-template>
