<form [formGroup]="form" *ngIf="form">
  <div class="card px-xxl py-l">
    <h3 class="mb-l">{{ "BOOKING_DETAILS" | translate | uppercase }}</h3>
    <div class="d-flex row justify-content-start align-items-start">
      <dm-form-group [class]="columnClasses">
        <label>{{ "BERTH" | translate }}</label>
        <dm-form-select-typeahead
          dmForm
          typeahead="resources"
          [notFoundText]="'NO_BERTHS_FOUND' | translate"
          formControlName="resourceId"
          bindLabel="name"
          bindValue="id"
          [placeholder]="'BERTH' | translate"
          [addonRequestParams]="resourceAddonParams"
          [defaultSearchValue]="berthSearch"
          (itemChanged)="checkBerthAvailability()"
        />
        <dm-form-error-message controlName="berthId" />
      </dm-form-group>

      <dm-form-group [class]="columnClasses">
        <label>{{ "START_DATE" | translate }}</label>
        <dm-form-input-icon icon="calendar">
          <input
            (closed)="checkBerthAvailability()"
            [minDate]="minStartDate"
            dmInputDatepicker
            [dmInputMaxDate]="(form.value.toDate ?? bookingMaxDateString) | modifyDateDays : -1"
            dmForm
            formControlName="fromDate"
            ngbDatepicker
            [placeholder]="datePlaceholderFormat"
            type="text"
            datepickerClass="modal-z-index"
            container="body"
          />
        </dm-form-input-icon>
        <dm-form-error-message controlName="fromDate" [apiError]="apiErrors?.fromDate"/>
      </dm-form-group>
      <dm-form-group [class]="columnClasses">
        <label>{{ "END_DATE" | translate }}</label>
        <dm-form-input-icon icon="calendar">
          <input
            (closed)="checkBerthAvailability()"
            dmForm
            [dmInputMinDate]="form.value.fromDate | modifyDateDays : 1"
            [dmInputMaxDate]="bookingMaxDateString"
            dmInputDatepicker
            [placeholder]="datePlaceholderFormat"
            formControlName="toDate"
            ngbDatepicker
            type="text"
            datepickerClass="modal-z-index"
            container="body"
          />
        </dm-form-input-icon>
        <dm-form-error-message [apiError]="apiErrors?.toDate" controlName="toDate" />
      </dm-form-group>
      <dm-form-group [class]="wideColumnClasses">
        <label>{{ "COMMENT" | translate }}</label>
        <input dmForm formControlName="comments" type="text" placeholder="{{ 'COMMENT' | translate }}" />
        <dm-form-error-message [apiError]="apiErrors?.comments" controlName="comments" />
      </dm-form-group>
    </div>
    <div class="row" *ngIf="!form.get('searchInGaps').disabled">
      <dm-form-group class="col-6 mb-4">
        <dm-form-checkbox
          class="checkbox-spacer"
          label="INCLUDE_BERTHS_FOR_WHICH_BOATS_ARE_ON_CRUISE"
          formControlName="searchInGaps"
        />
      </dm-form-group>
    </div>

    <dm-ui-info-box
      *dmIsLongTerm="getDateRange()"
      class="pb-xl mt-0"
      icon="info"
      text="LONG_TERM_BOOKING_INFO"
    />

    <dm-ui-availability-check
      [checkPayload]="availabilityPayload"
      [searchInGaps]="form.get('searchInGaps').value"
      (availabilityCheck)="sendAvailabilityEvent($event)"
    />
  </div>
</form>
