import { Component, inject } from '@angular/core';
import { MmsOfferViewService } from '../../services/mms-offer-view.service';
import { MARINA_URL_TOKEN } from '@dm-workspace/types';
import { QuoteViewType } from '../../../../../shared/src/lib/components/mms-quote-details-header/mms-quote-details-header.component';

@Component({
  selector: 'dm-mms-offers-offer-details-view',
  templateUrl: './mms-offer-view.component.html',
  styleUrls: ['./mms-offer-view.component.scss'],
})
export class MmsOfferViewComponent {
  private readonly offerViewService = inject(MmsOfferViewService);
  protected readonly offer = this.offerViewService.offer;
  protected readonly fetchingOffer = this.offerViewService.fetching;
  protected readonly MARINA_URL_TOKEN = MARINA_URL_TOKEN;

  detailsRoutes: { route: string; label: string }[] = [
    {
      label: 'SUMMARY',
      route: 'summary',
    },
    {
      label: 'TIMELINE',
      route: 'timeline',
    },
    {
      label: 'PRODUCTS',
      route: 'products',
    },
  ];
  protected readonly QuoteViewType = QuoteViewType;

  refreshOffer() {
    this.offerViewService.refreshOffers().subscribe();
  }
}
