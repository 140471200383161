  <div class="wrap">
    <div class="inner">
      <dm-mms-layout-marina-switch class="marina-switch" />
      <dm-mms-layout-navigation class="mx-xxl" />

      <div class="ml-auto d-flex align-items-center">
        <span class="separator"></span>
        <p class="text-uppercase mx-4 typo-body-s fw-700">{{ currentLang$ | async }}</p>
        <dm-mms-layout-user-actions />
      </div>
    </div>
  </div>
