<table class="table">
  <thead dmUiStickyElement name="orders">
    <tr>
      <th>{{ "ORDER_NAME" | translate }}</th>
      <th class="max-width">{{ "START_DATE" | translate }}</th>
      <th class="max-width">{{ "END_DATE" | translate }}</th>
      <th *ngIf="isVisible('customer')">{{ "CUSTOMER" | translate }}</th>
      <th *ngIf="isVisible('boat')">{{ "BOAT_NAME" | translate }}</th>
      <th>{{ "ORDER_STATUS" | translate }}</th>
      <th>{{ "BILLING_METHOD" | translate }}</th>
      <th>{{ "PAYMENT_STATUS" | translate }}</th>
      <th class="products-column">{{ "PRODUCTS" | translate }}</th>
      <th class="text-right">{{ "TOTAL_AMOUNT" | translate }}</th>
      <th class="text-right">{{ "ACTION" | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let order of orders">
      <td class="name">
        <a [selectedMarinaRouterLink]="['/orders', order.id]" class="link" returnUrlCurrentPath
          ><strong>{{ order.name }}</strong></a
        >
      </td>
      <td class="max-width">{{ order.startDate | dateFormat }}</td>
      <td class="max-width">{{ order.endDate | dateFormat }}</td>
      <td *ngIf="isVisible('customer')">
        <a [routerLink]="['/customers', order.customer?.id]" returnUrlCurrentPath class="link">
          <strong>{{ order.customer?.name }}</strong>
        </a>
      </td>
      <td *ngIf="isVisible('boat')">
        <a [routerLink]="['/boats', order.boat?.id]" returnUrlCurrentPath class="link">
          <strong>{{ order.boat?.name }}</strong>
        </a>
      </td>
      <td>{{ order.status | empty }}</td>
      <td>{{ "BILLING_METHODS_." + order.billingMethod | translate }}</td>
      <td>
        <dm-ui-tag [color]="order.paymentStatus | paymentStatusTagColor" class="tag-min-width">{{
          "PAYMENT_STATUS_." + order.paymentStatus | uppercase | translate
        }}</dm-ui-tag>
      </td>
      <td class="products-column">
        <ng-container *ngFor="let product of order.associatedProducts; last as isLast">
          <ng-container *ngIf="product.product.productUnit"
            >({{ product.quantity }}{{ product.product.productUnit }}) </ng-container
          >{{ product.product?.name | trim }}<ng-container *ngIf="!isLast">, </ng-container>
        </ng-container>
      </td>
      <td class="text-right">{{ order.associatedProducts | productsSum | currency : order.currency }}</td>
      <td>
        <div class="d-flex justify-content-end align-items-center">
          <div ngbDropdown>
            <button ngbDropdownToggle type="button" class="action-button"></button>

            <div ngbDropdownMenu>
              <a ngbDropdownItem
                 [selectedMarinaRouterLink]="['/orders', order.id]"
                 returnUrlCurrentPath
              >{{ "DETAILS" | translate }}</a>

              <a *ngIf="order?.id && order.paymentStatus !== OrderPaymentStatus.PAID && !order?.isDraft"
                 ngbDropdownItem
                 (click)="clickButtonMarkOrderAsPaid(order)"
              >{{ "MARK_AS_PAID" | translate }}</a>
            </div>
          </div>
        </div>
      </td>
    </tr>
    <tr *ngIf="orders.length < 1">
      <td colspan="10">
        {{ "NO_TYPEOF_RESULTS_FOR_FILTERS" | translate : { items: "ORDERS" | translate | lowercase } }}
      </td>
    </tr>
  </tbody>
</table>
