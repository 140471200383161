import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IBoat, IBoatDto, ResourceBookingBody } from '@dm-workspace/types';
import { NewBookingFormComponentClass } from '../../../../../marina/src/lib/classes/new-booking-form-component';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { NewBoatModalComponent } from '@dm-workspace/mms-panel/shared';

@Component({
  selector: 'dm-mms-bookings-boat-booking-form-card',
  templateUrl: './boat-booking-form-card.component.html',
  styleUrls: ['./boat-booking-form-card.component.scss'],
})
export class BoatBookingFormCardComponent extends NewBookingFormComponentClass<ResourceBookingBody> implements OnInit {
  @Input() columnClasses: string;

  public existingBoatSearch: string;

  public get selectedBoat(): IBoatDto {
    return this.form?.value?.existingBoat;
  }

  public get existsInDatabase(): IBoatDto {
    return this.form?.value?.existingBoat?.existsInDatabase;
  }

  private get existingBoatControl(): UntypedFormControl {
    return this.form.get('existingBoat') as UntypedFormControl;
  }

  constructor(private modal: NgbModal, private activatedRoute: ActivatedRoute) {
    super();
  }

  public ngOnInit() {
    const { existingBoat } = this.activatedRoute.snapshot.queryParams;

    if (existingBoat) {
      this.existingBoatSearch = existingBoat;
    }
  }

  public openNewBoatModal(boat?: IBoat): void {
    const modal = this.modal.open(NewBoatModalComponent);
    const modalInstance = modal.componentInstance as NewBoatModalComponent;
    if (boat) {
      modalInstance.boat = boat;
    }

    modal.closed.subscribe((boat: IBoatDto) => {
      this.existingBoatControl.patchValue(boat);
    });
  }

  public clearBoat(): void {
    this.existingBoatSearch = null;
    this.existingBoatControl.reset();
  }
}
