<div class="modal-header">
  <h2 class="title">{{ "ACCEPT_OFFER" | translate }}</h2>
</div>

<p class="modal-body" [innerHTML]="'CONFIRM_OFFER_ACCEPT' | translate : { offerName: offer.name }">
</p>

<div class="modal-footer justify-content-between d-flex">
  <button type="button" class="btn btn-secondary-negative" (click)="onCancel()">
    {{ "CANCEL" | translate }}
  </button>
  <button type="button" class="btn btn-primary" (click)="buttonConfirmClick()" [disabled]="apiCalling()">
    <dm-ui-spinner class="me-1" [showSpinner]="apiCalling()" />
    {{ "ACCEPT_OFFER" | translate }}
  </button>
</div>
