import { Validators, UntypedFormBuilder } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IDocument, ApiFile } from '@dm-workspace/types';
import { ApiValidatorService } from '@dm-workspace/forms';
import { ResourceBookingApiService } from '@dm-workspace/data-access';
import { FormView } from '@dm-workspace/core';
import { Observable } from 'rxjs';

type FormValue = {
  files: ApiFile[];
};

@Component({
  selector: 'dm-mms-layout-add-attachment-modal',
  templateUrl: './add-booking-attachment-modal.component.html',
  styleUrls: ['./add-booking-attachment-modal.component.scss'],
})
export class AddBookingAttachmentModalComponent extends FormView<FormValue, IDocument[]> implements OnInit {
  @Input() value: ApiFile[] = [];
  @Input() bookingId: string;
  @Input() minLength = 1;

  constructor(
    apiValidator: ApiValidatorService,
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private resourceApiService: ResourceBookingApiService
  ) {
    super(apiValidator);
  }

  public ngOnInit() {
    this.form = this.fb.group({
      files: [this.value, [Validators.required, Validators.minLength(this.minLength)]],
    });
  }

  protected apiCall(params: FormValue): Observable<IDocument[]> {
    return this.resourceApiService.documents(
      this.bookingId,
      params.files.map((value) => value.id)
    );
  }

  protected onSuccess(response: IDocument[]) {
    super.onSuccess(response);

    const files = this.form.value.files as ApiFile[];

    this.activeModal.close(
      response.map((document, index) => ({
        ...document,
        file: files[index],
      }))
    );
  }

  public closeModal(): void {
    this.activeModal.close();
  }
}
