@if (hasItemsToShow()) {
  <section class="mb-5">
    @if (berth()?.reservationStatus.includes(BerthReservationsStatusInMarina.available)) {
      <h3 class="header-16 fw-700 mb-3">{{ "BOAT_PRESENCE" | translate }}</h3>
    } @else {
      <h3 class="header-16 fw-700 mb-3">{{ "ONGOING_BOOKINGS" | translate }}</h3>
    }
    @for (boatBooking of bookingListBoats(); track boatBooking.boat.id) {
      @if (boatBooking.customerId) {
        <div class="d-flex py-2">
          <label for="customer" class="me-2 typo-body-m fw-500">{{ "CUSTOMER" | translate }}:</label>
          <a
            id="customer"
            class="details-link text-nowrap me-2 typo-body-m fw-500"
            [routerLink]="['/', 'customers', boatBooking.customerId, 'details']"
            [state]="{ backOnClose: true }"
            returnUrlCurrentPath
            >{{ boatBooking.customerDisplayName }}</a
          >
        </div>
      }

      <div class="d-flex align-items-baseline py-2">
        <label for="booking" class="me-2 typo-body-m fw-500">{{ "BOOKING" | translate }}:</label>
        <div id="booking" class="d-flex align-items-baseline w-100 justify-content-between">
          @if (boatBooking.id) {
            <div>
              <a
                class="details-link text-nowrap me-2 typo-body-m fw-500"
                [routerLink]="['/', marinaCode(), 'bookings', boatBooking.id, 'details']"
                [state]="{ backOnClose: true }"
                returnUrlCurrentPath
                >{{ boatBooking.humanReadableId }}</a
              >
              <span class="typo-body-m fw-600">{{ boatBooking.contractType }}</span>
            </div>
            @if (!bookingBoatIsInMarina(boatBooking.boatId)) {
              <button
                type="button"
                (click)="openTransferBookingModal(boatBooking)"
                class="btn btn-secondary btn-secondary-outline p-2 list-action-button"
              >
                {{ "TRANSFER_BOOKING" | translate | uppercase }}
              </button>
            }
          } @else {
            <span class="typo-body-m fw-600 color-danger">{{ "NO_BOOKING" | translate }}</span>
            @if (getShowQQonBoat) {
              <button
                type="button"
                (click)="goToQQ(boatBooking.boat.id, boatBooking.resourceId)"
                class="btn btn-primary btn-primary-outline-outline p-2 list-action-button"
              >
                {{ "QUICK_QUOTE" | translate | uppercase }}
              </button>
            }
          }
        </div>
      </div>

      <div class="d-flex py-2 justify-content-between align-items-center">
        <div>
          <label for="boat" class="me-2 typo-body-m fw-500">{{ "BOAT" | translate }}:</label>
          <a
            id="boat"
            class="details-link text-nowrap me-2 typo-body-m fw-500"
            [routerLink]="['/', 'boats', boatBooking.boat.id]"
            [state]="{ backOnClose: true }"
            returnUrlCurrentPath
            >{{ boatBooking.boat.name }}</a
          >
        </div>
      </div>

      <div class="d-flex py-2">
        <label for="boatStatus" class="me-2 typo-body-m fw-500">{{ "BOAT_STATUS" | translate }}:</label>
        @if (boatBooking.boat.statusInMarina) {
          <span id="boatStatus" class="typo-body-m fw-600">{{
            "BOAT_MARINA_STATUS." + (boatBooking.boat.statusInMarina | uppercase) | translate
          }}</span>
        } @else if (boatIsInOccupancy(boatBooking.boatId) && !boatBooking.boat.statusInMarina) {
          <span class="typo-body-m fw-600">{{ "BOAT_MARINA_STATUS.IN_MARINA" | translate }}</span>
        } @else {
          -
        }
      </div>

      @if (!bookingBoatIsInMarina(boatBooking.boatId) && temporaryAbsence(boatBooking.boat.id); as temporaryAbsence) {
        <div class="d-flex py-2 justify-content-between align-items-center">
          <div class="d-flex">
            <label for="overbookingRange" class="me-2 typo-body-m fw-500"
              >{{ "ALLOWED_OVERBOOKING" | translate }}:</label
            >
            <span id="overbookingRange" class="typo-body-m fw-600"
              >{{ temporaryAbsence.gapStart | slice: 0 : 10 | dateFormat }} - {{ temporaryAbsence.gapEnd | slice: 0 : 10 | dateFormat}}</span
            >
          </div>
          @if (!temporaryAbsence.gapStart && !temporaryAbsence.gapEnd) {
            <button
              type="button"
              class="btn btn-secondary btn-secondary-outline py-2 px-3 list-action-button"
              (click)="openAddEditGapModal(temporaryAbsence)"
            >
              {{ "CREATE_GAP" | translate | uppercase }}
            </button>
          }
          @if (temporaryAbsence.gapStart && temporaryAbsence.gapEnd) {
            <div class="d-flex flex-column">
              <button
                type="button"
                class="btn btn-secondary btn-secondary-outline py-2 px-3 list-action-button my-1"
                (click)="openAddEditGapModal(temporaryAbsence)"
              >
                {{ "EDIT_GAP" | translate | uppercase }}
              </button>
              @if (berth().data.bookings.length === 1) {
                <button
                  type="button"
                  (click)="goToQQ(null, boatBooking.resourceId)"
                  class="btn btn-primary btn-primary-outline-outline p-2 list-action-button my-1"
                >
                  {{ "QUICK_QUOTE" | translate | uppercase }}
                </button>
              }
            </div>
          }
        </div>
      }
      <dm-ui-horizontal-line />
    }
  </section>
}
@if (!hasItemsToShow() && berth()?.reservationStatus?.includes(BerthReservationsStatusInMarina.available)) {
  <section class="mb-5 text-center">
    <button
      type="button"
      (click)="goToQQ()"
      class="btn btn-primary btn-primary-outline-outline px-5 py-2 list-action-button my-1"
    >
      {{ "QUICK_QUOTE" | translate | uppercase }}
    </button>
  </section>
}
