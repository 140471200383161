<table *ngIf="hasItemsToShow()">
  <tr>
    <th>{{ "BOATS" | translate }}</th>
    <th colspan="2">{{ hasSomeReservation() ? ("BOOKINGS" | translate) : "" }}</th>
  </tr>
  @for (occupancy of mappedOccupancy(); track occupancy.resourceId) {
    <tr>
      <td><dm-map-map-berth-details-boat-name [name]="occupancy.boat.name" [boatId]="occupancy.boat.id" /></td>

      @if (occupancy.booking || occupancy.temporaryAbsence) {
        <td>
          @if (occupancy.booking) {
            <dm-map-popup-row [label]="occupancy.booking.contractType | contractTypeTranslate">
              <a
                class="details-link text-nowrap"
                [routerLink]="['/', marinaCode, 'bookings', occupancy.booking.reservationId, 'details']"
                [state]="{ backOnClose: true }"
                returnUrlCurrentPath
                >{{ occupancy.booking.humanReadableId }}</a
              >
            </dm-map-popup-row>
          }
          @if (occupancy.temporaryAbsence) {
            <dm-map-popup-row
              [label]="'GAP_END' | translate"
              [txt]="occupancy.temporaryAbsence.gapEnd || occupancy.temporaryAbsence.expectedReturnDate | dateFormat"
            />
          } @else {
            <dm-map-popup-row [label]="'END_DATE' | translate" [txt]="occupancy.booking.toDate | dateFormat" />
          }
        </td>
      }

      <td [colSpan]="occupancy.booking || occupancy.temporaryAbsence ? 1 : 2">
        <div class="d-flex justify-content-end align-items-center">
          <div ngbDropdown>
            <button ngbDropdownToggle type="button" class="action-button"></button>
            <div ngbDropdownMenu>
              @if (!occupancy.booking && !occupancy.temporaryAbsence) {
                <button type="button" ngbDropdownItem class="d-flex align-items-center" (click)="quickQuote(occupancy)">
                  <i [style.font-size.px]="16" class="i-addbooking ml-2 me-2 color-gray"></i>
                  <span>{{ "QUICK_QUOTE" | translate }}</span>
                </button>
              }

              @if (
                (occupancy.expectedDepartures && occupancy.boatIsInBerth) ||
                (!occupancy.booking && !occupancy.temporaryAbsence)
              ) {
                <button
                  ngbDropdownItem
                  type="button"
                  class="d-flex align-items-center"
                  (click)="departOrArrivalBoat(occupancy)"
                >
                  <i [style.font-size.px]="16" class="i-anchor-2 ml-2 me-2 color-gray"></i>
                  <a>{{ (occupancy.expectedDepartures ? "REPORT_DEPARTURE" : "DEPART_BOAT") | translate }}</a>
                </button>
              }
              @if (occupancy.expectedArrivals && !occupancy.boatIsInBerth) {
                <button
                  ngbDropdownItem
                  type="button"
                  class="d-flex align-items-center"
                  (click)="departOrArrivalBoat(occupancy, true)"
                >
                  <i [style.font-size.px]="16" class="i-anchor-2 ml-2 me-2 color-gray"></i>
                  <span>{{ "REPORT_ARRIVAL" | translate }}</span>
                </button>
              }
              @if (
                (occupancy.booking && occupancy.boatIsInBerth && !occupancy.expectedDepartures) || !occupancy.booking
              ) {
                <button ngbDropdownItem type="button" class="d-flex align-items-center" (click)="moveBoat(occupancy)">
                  <i [style.font-size.px]="16" class="i-boat ml-2 me-2 color-gray"></i>
                  <span>{{ "MOVE_BOAT" | translate }}</span>
                </button>
              }

              @if (
                !occupancy.boatIsInBerth &&
                !occupancy.expectedDepartures &&
                !occupancy.expectedArrivals &&
                occupancy.booking
              ) {
                <button
                  ngbDropdownItem
                  type="button"
                  class="d-flex align-items-center"
                  (click)="transferBooking(occupancy.booking)"
                >
                  <i [style.font-size.px]="16" class="i-refresh ml-2 me-2 color-gray"></i>
                  <span>{{ "TRANSFER_BOOKING" | translate }}</span>
                </button>
              }

              @if (occupancy.temporaryAbsence?.operation === BoatMovementOperation.CRUISING) {
                <button
                  ngbDropdownItem
                  type="button"
                  class="d-flex align-items-center"
                  (click)="moveBoat(occupancy, BoatMovementOperation.RETURN)"
                >
                  <i [style.font-size.px]="16" class="i-time ml-2 me-2 color-gray"></i>
                  <span>{{ "RETURN_FROM_CRUISE" | translate }}</span>
                </button>
              }

              @if (occupancy.temporaryAbsence?.operation === BoatMovementOperation.DRY_DOCK_LIFT) {
                <button
                  ngbDropdownItem
                  type="button"
                  class="d-flex align-items-center"
                  (click)="moveBoat(occupancy, BoatMovementOperation.DRY_DOCK_LAUNCH)"
                >
                  <i [style.font-size.px]="16" class="i-time ml-2 me-2 color-gray"></i>
                  <span>{{ "RETURN_FROM_LAND" | translate }}</span>
                </button>
              }
              @if (temporaryAbsence(occupancy.boatId); as temporaryAbsence) {
                <button
                  type="button"
                  ngbDropdownItem
                  class="d-flex align-items-center"
                  (click)="openEditGapModal(temporaryAbsence)"
                >
                  <i [style.font-size.px]="16" class="i-edit ml-2 me-2 color-gray"></i>
                  <span>{{ "EDIT_GAP" | translate }}</span>
                </button>
              }

              <!--        @if (occupancy.temporaryAbsence) {-->
              <!--          <button type="button" class="btn btn-secondary btn-tiny" (click)="transferBooking(occupancy)">-->
              <!--            {{ "EDIT_GAP" | translate | uppercase }}-->
              <!--          </button>-->
              <!--        }-->
            </div>
          </div>
        </div>
      </td>
    </tr>
  }
</table>
