import { ChangeDetectionStrategy, Component, inject, Input, signal } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  AutocompleteTypes,
  IMarinaPylonSocketsForUsageResponse,
  IMarinaPylonsResponse,
  ListMarinaPylonsShort,
  PylonMediaType,
  PylonSocket,
} from '@dm-workspace/types';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PylonUtils } from '@dm-workspace/utils';
import { MmsPylonsApiService } from '@dm-workspace/data-access';
import { finalize, take, tap } from 'rxjs/operators';
import { NotificationService } from '@dm-workspace/notification';
import { catchError, throwError } from 'rxjs';
import { ApiValidator } from '@dm-workspace/shared';
import { HttpErrorResponse } from '@angular/common/http';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'dm-mms-shared-socket-connect-modal',
  templateUrl: './mms-socket-connect-modal.component.html',
  styleUrls: ['./mms-socket-connect-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsSocketConnectModalComponent {
  @Input() humanReadableId: string;
  #activeModal = inject(NgbActiveModal);
  #pylonService = inject(MmsPylonsApiService);
  #notificationService = inject(NotificationService);
  selectedSocket: Map<string, PylonSocket> = new Map();
  apiCalling = signal<boolean>(false);
  marinaMapPylons = signal<ListMarinaPylonsShort[]>(null);
  confirmApiCalling = signal<boolean>(false);
  form = new FormGroup({
    selectedPedestal: new FormControl<ListMarinaPylonsShort>(null, Validators.required),
  });
  icons = PylonUtils.icons;
  pylonSocketsGroupedByMediaTypes = signal<Record<PylonMediaType, IMarinaPylonSocketsForUsageResponse[]>>(null);

  constructor() {
    this.#pylonService
      .fetchPylonsShort()
      .pipe(
        takeUntilDestroyed(),
        tap((pylons) => this.marinaMapPylons.set(pylons))
      )
      .subscribe();
  }

  public onSubmit() {
    this.confirmApiCalling.set(true);

    this.selectedSocket.forEach((value, key) => {
      this.#pylonService
        .switchSocketState(this.form.controls.selectedPedestal.value.code, value.channel, {
          state: true,
          referenceId: this.humanReadableId,
        })
        .pipe(
          take(1),
          catchError((res: HttpErrorResponse) => {
            this.#notificationService.openError(ApiValidator.getApiNotificationError(res));
            return throwError(() => res);
          }),
          finalize(() => {
            this.confirmApiCalling.set(false);
          }),
          tap(() => {
            this.#notificationService.openSuccess();
            this.#activeModal.close({
              pylonName: this.form.controls.selectedPedestal.value.name,
              isUsed: false,
              outputName: value.channel,
            } as IMarinaPylonsResponse);
          })
        )
        .subscribe();
    });
  }

  public dismissModal() {
    this.#activeModal.dismiss();
  }

  protected selectSocket(socket: PylonSocket, key: string) {
    this.selectedSocket.set(key, socket);
  }

  onSelectedPedestal(event: ListMarinaPylonsShort) {
    this.apiCalling.set(true);
    this.#pylonService
      .getSocketsStatusForBooking(event?.code, this.humanReadableId)
      .pipe(
        take(1),
        tap((sockets) => {
          this.pylonSocketsGroupedByMediaTypes.set(PylonUtils.groupPylonSocketsByMediaType(sockets));
        }),
        catchError((res: HttpErrorResponse) => {
          this.#notificationService.openError(ApiValidator.getApiNotificationError(res));
          return throwError(() => res);
        }),
        finalize(() => this.apiCalling.set(false))
      )
      .subscribe();
  }

  onClearPedestal(): void {
    this.pylonSocketsGroupedByMediaTypes.set(null);
  }

  resultFormatter = (pylonShort: ListMarinaPylonsShort) => `${pylonShort.code}`;
  inputFormatter = (pylonShort: ListMarinaPylonsShort) => pylonShort.code;

  protected readonly autocompleteTypes = AutocompleteTypes;
}
