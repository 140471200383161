<h2 class="mb-l">
  {{ "CUSTOMER_DETAILS" | translate | uppercase }}
</h2>
<div class="row row-cols-6 gy-4">
  <dm-form-input-value>
    <ng-container header>{{"FIRST_NAME" | translate}}</ng-container>
    <ng-container body>{{customer?.firstName | empty}}</ng-container>
  </dm-form-input-value>

  <dm-form-input-value>
    <ng-container header>{{"LAST_NAME" | translate}}</ng-container>
    <ng-container body>{{customer?.lastName | empty}}</ng-container>
  </dm-form-input-value>

  <dm-form-input-value>
    <ng-container header>{{"EMAIL" | translate}}</ng-container>
    <ng-container body>{{customer?.email | empty}}</ng-container>
  </dm-form-input-value>

  <dm-form-input-value>
    <ng-container header>{{"PHONE" | translate}}</ng-container>
    <ng-container body>{{ customer?.phone | empty }}</ng-container>
  </dm-form-input-value>

  <dm-form-input-value>
    <ng-container header>{{"CUSTOMER_TYPE" | translate}}</ng-container>
    <ng-container body>
      {{ (customer?.corporateType ?? customer?.type) | getCustomerTypeTranslateKey | translate | empty }}
    </ng-container>
  </dm-form-input-value>
</div>

<h2 class="my-l pt-l">
  {{ "ADDRESS_DETAILS" | translate | uppercase }}
</h2>
<div class="row row-cols-6 gy-4">
  <dm-form-input-value>
    <ng-container header>{{"COUNTRY" | translate}}</ng-container>
    <ng-container
      body>{{customer?.country ?? (customer?.countryCode | countryCodeTranslateKey) | empty }}</ng-container>
  </dm-form-input-value>

  <dm-form-input-value *ngIf="customer?.stateOrProvince">
    <ng-container header>{{"STATE" | translate}}</ng-container>
    <ng-container body>{{customer?.stateOrProvince?.name ?? customer?.state | empty }}</ng-container>
  </dm-form-input-value>

  <dm-form-input-value *ngIf="customer?.county">
    <ng-container header>{{"COUNTY" | translate}}</ng-container>
    <ng-container body>{{customer?.county?.name  | empty }}</ng-container>
  </dm-form-input-value>

  <dm-form-input-value>
    <ng-container header>{{"CITY" | translate}}</ng-container>
    <ng-container body>{{customer?.city | empty}}</ng-container>
  </dm-form-input-value>

  <dm-form-input-value>
    <ng-container header>{{"ADDRESS" | translate}}</ng-container>
    <ng-container body>{{customer?.address | empty}}</ng-container>
  </dm-form-input-value>

  <dm-form-input-value>
    <ng-container header>{{"ZIP_POSTAL_CODE" | translate}}</ng-container>
    <ng-container body>{{customer?.zip | empty}}</ng-container>
  </dm-form-input-value>
</div>

<h2 class="my-l pt-l">
  {{ "DOCUMENTS" | translate | uppercase }}
</h2>
<div class="row row-cols-6 gy-4">
  <dm-form-input-value>
    <ng-container header>{{"FISCAL_CODE" | translate}}</ng-container>
    <ng-container body>{{customer?.fiscalCode | empty }}</ng-container>
  </dm-form-input-value>

  <dm-form-input-value>
    <ng-container header>{{"PASSPORT_NO" | translate}}</ng-container>
    <ng-container body>{{customer?.passport ?? customer?.contact?.passport | empty}}</ng-container>
  </dm-form-input-value>

  <dm-form-input-value>
    <ng-container header>{{"TAX_EXEMPT_NUMBER" | translate}}</ng-container>
    <ng-container body>{{customer?.taxExemptNumber | empty }}</ng-container>
  </dm-form-input-value>
</div>
