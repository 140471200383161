import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IResourceBookingQuotation } from '@dm-workspace/types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  MmsBoatReportArrivalModalComponent,
  BoatCruiseModalComponent,
  MmsBoatDepartureModalComponent,
  BoatReturnModalComponent,
} from '@dm-workspace/mms-panel/shared';

@Component({
  selector: 'dm-mms-shared-booking-action-button',
  templateUrl: './booking-action-button.component.html',
  styleUrls: ['./booking-action-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingActionButtonComponent {
  @Input() booking: IResourceBookingQuotation;

  constructor(private modal: NgbModal) {}

  public get actionName(): string {
    const { availableActions } = this.booking;
    return 'BOOKING_ACTION_.' + availableActions[0];
  }

  public get hasActionAndModal(): boolean {
    const { availableActions } = this.booking;
    if (!availableActions || availableActions.length === 0) {
      return false;
    }
    return !!this.getModal(availableActions[0]);
  }

  public getModal(action: string): object {
    const modals: { [action: string]: object } = {
      ARRIVAL: MmsBoatReportArrivalModalComponent,
      CRUISE: BoatCruiseModalComponent,
      RETURN: BoatReturnModalComponent,
      DEPARTURE: MmsBoatDepartureModalComponent,
    };

    return modals[action];
  }

  public openModal(): void {
    const { availableActions } = this.booking;
    //TODO: Use multiple actions when transfer to backend logic is complete
    const [firstAction] = availableActions;
    if (!firstAction) {
      return;
    }
    const modal = this.getModal(firstAction);

    if (!modal) {
      return;
    }

    const modalRef = this.modal.open(modal, {
      modalDialogClass: `booking-boat-confirmation-modal-container modal-type-${firstAction}`,
    }).componentInstance;

    modalRef.booking = this.booking;
  }
}
