import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'dm-mms-table-cell-flag',
  templateUrl: './mms-table-cell-flag.component.html',
  styleUrls: ['./mms-table-cell-flag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsTableCellFlagComponent {
  @Input({ required: true }) flag: string;
}
