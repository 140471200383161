import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Currency, QuoteProduct } from '@dm-workspace/types';

@Component({
  selector: 'dm-mms-offer-products-table',
  templateUrl: './mms-offer-products-table.component.html',
  styleUrls: ['./mms-offer-products-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsOfferProductsTableComponent {
  @Input() public offerProducts: QuoteProduct[];
  @Input({ required: true }) public currency: Currency;
}
