import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { MmsDashboardViewService } from '../services/mms-dashboard-view.service';
import { Observable, switchMap } from 'rxjs';
import { DASHBOARD_VIEWS } from '../mms-dashboard.const';
import { SelectedMarinaRouter } from '@dm-workspace/mms-panel/shared';

@Injectable()
export class MmsDashboardEmptyTileGuard {
  constructor(
    private dashboardService: MmsDashboardViewService,
    private selectedMarinaRouter: SelectedMarinaRouter
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree | boolean> {
    return this.dashboardService.stats$.pipe(
      switchMap((stats) => {
        const selectedPath = route.routeConfig.path;
        const viewModes = DASHBOARD_VIEWS[selectedPath];
        const viewMode = (stats && viewModes.find((view) => stats[view.value]?.todoCount > 0)) || viewModes[0];
        return this.selectedMarinaRouter.parseUrl('/dashboard/' + selectedPath + '/' + viewMode.path);
      })
    );
  }
}
