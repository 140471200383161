<div class="modal-header">
  <h2 class="title">{{ "SEND_REMAINDER_OFFER" | translate }}</h2>
</div>

<div class="modal-body">
  <dm-form-input-value>
    <ng-container header>{{"CUSTOMERS_PRIMARY_CONTACT_EMAIL" | translate}}</ng-container>
    <ng-container body>{{customerPrimaryContact()?.email}}</ng-container>
  </dm-form-input-value>

  <dm-ui-horizontal-line />

  <p [innerHTML]="'CONFIRM_OFFER_SEND_REMINDER' | translate : { offerName: offer.name }"></p>
</div>

<div class="modal-footer justify-content-between d-flex">
  <button type="button" class="btn btn-secondary-negative" (click)="onCancel()">
    {{ "CANCEL" | translate }}
  </button>
  <button type="button" class="btn btn-primary" (click)="buttonConfirmClick()" [disabled]="apiCalling()">
    <dm-ui-spinner class="me-1" [showSpinner]="apiCalling()" />
    {{ "SEND" | translate }}
  </button>
</div>
