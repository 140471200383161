<section class="mb-5">
  <h2 class="header-20">{{ header() | translate }}</h2>
  <p class="color-secondary mt-2 text-md">
    {{ subHeader() | translate }}
  </p>
</section>

<div [style.max-width.px]="885">
  <dm-mms-timeline-item [last]="true">
    <ng-container date>
      <p class="typo-body-s">{{ currentTime$ | async | dateFormat: "dd MMM yy" }}</p>
      <p class="typo-body-s color-secondary">{{ currentTime$ | async | dateFormat: "EEE, HH:mm" }}</p>
    </ng-container>
    <ng-container thumbnail>
      {{ (currentUser$ | async)?.name | initials }}
    </ng-container>
    <ng-container content>
      <dm-mms-enquiry-note-form [formGroup]="noteFormGroup" (ngSubmit)="addNote()" [sending]="sendingActivity()" />
    </ng-container>
  </dm-mms-timeline-item>

  <dm-ui-horizontal-line class="mt-4 mb-0" />

  <div class="position-relative" [style.min-height.px]="150">
    @for (activity of activities(); track activity.createdAt; let last = $last) {
      <dm-mms-timeline-item [last]="last" @fadeAnimation>
        <ng-container date>
          <p class="typo-body-s">
            <dm-ui-relative-date-formatter [date]="activity.createdAt" />
          </p>
          <p class="typo-body-s color-secondary">{{ activity.createdAt | dateFormat: "EEE, HH:mm" }}</p>
        </ng-container>

        <ng-container thumbnail>
          @if (activity.data?.content) {
            {{ activity.author.name | initials }}
          } @else {
            <span class="i-time"></span>
          }
        </ng-container>

        <div class="content" content>
          @if (activity.data?.content) {
            <p class="header-14 mb-2">
              {{ activity.author?.name | empty }}
            </p>
            <p class="typo-body-s break-word">{{ activity.data?.content }}</p>
          } @else {
            <p class="header-14 mb-1">
              {{ activity.type | quoteActivityTypeTranslate }}
            </p>
            @if (activity.author) {
              <p class="typo-body-s">{{ "BY" | translate }} {{ activity.author?.name | empty }}</p>
            }
          }

          @if (activity.data?.asLastContact) {
            <p class="typo-body-s color-secondary mt-3">
              {{ "LAST_CONTACT_DATE_UPDATED_TO" | translate }}
            </p>
            <p class="typo-body-s fw-600">
              {{ activity.createdAt | dateFormat: "dd.MM.yyyy" }} • {{ activity.createdAt | dateFormat: "HH:mm" }}
            </p>
          }
        </div>
      </dm-mms-timeline-item>
    }
    <dm-ui-overflow-spinner type="absolute" [showSpinner]="fetchingActivities()" [disableBg]="true" />
  </div>
</div>
