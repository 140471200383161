import { ChangeDetectionStrategy, Component, inject, Input, signal } from '@angular/core';
import { Quote } from '@dm-workspace/types';
import { ConfirmModal } from '@dm-workspace/ui';
import { MmsQuotesApiService } from '@dm-workspace/data-access';
import { NotificationService } from '@dm-workspace/notification';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiValidatorService } from '@dm-workspace/forms';
import { finalize } from 'rxjs';
import { MmsConfirmOfferModal } from '../mms-offer-modal.interface';

@Component({
  selector: 'dm-mms-offers-action-accept-modal',
  templateUrl: './mms-offer-action-accept-modal.component.html',
  styleUrls: ['./mms-offer-action-accept-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsOfferActionAcceptModalComponent extends ConfirmModal implements MmsConfirmOfferModal {
  @Input({ required: true }) public offer: Quote;
  protected readonly apiCalling = signal<boolean>(false);
  protected readonly offerApiService = inject(MmsQuotesApiService);
  protected readonly notification = inject(NotificationService);
  protected readonly apiValidator = inject(ApiValidatorService);

  constructor() {
    super();
  }

  protected buttonConfirmClick() {
    this.apiCalling.set(true);

    this.offerApiService
      .transitionToOrder(this.offer.quoteId)
      .pipe(finalize(() => this.apiCalling.set(false)))
      .subscribe({
        next: () => {
          this.notification.openSuccess();
          this.onConfirm();
        },
        error: (res: HttpErrorResponse) => {
          const notificationContent = this.apiValidator.getApiNotificationError(res);
          this.notification.openError(notificationContent);
        },
      });
  }
}
