<div class="modal-header">
  <h2 class="title">{{ "ENABLE_UTILITIES" | translate }}</h2>
</div>
<div class="modal-body">
  @if (fetchingMediaMediaAccess$ | async) {
    <div class="d-flex justify-content-center align-items-center" [style.height.px]="200">
      <dm-ui-spinner [showSpinner]="true" size="lg" [color]="'primary'" />
    </div>
  }

  @if (mediaAccess$ | async; as mediaAccess) {
    <p class="mb-3">
      @if (mediaAccess.shouldCreateNewOrder && mediaAccess.existingMediaProducts?.length) {
        {{ "BASED_ON_PRODUCTS_BELOW_CLIENT_WILL_GET_ACCESS_TO_MEDIA_DUPLICATE_OF_THE_ORDER_WITH_UTILITIES_WILL_CREATED_CUSTOMER_WILL_RECEIVE_INVOICE_AFTER_BILLING_PERIOD" | translate }}
      } @else {
        {{ "BASED_ON_PRODUCTS_BELOW_CLIENT_WILL_GET_ACCESS_TO_MEDIA_WHICH_HE_WILL_HAVE_TO_PAY_LATER_AT_MARINA_OFFICE" | translate }}
        @if (!mediaAccess.existingMediaProducts?.length) {
          {{ "NEW_ORDER_WILL_BE_CREATED" | translate }}
        }
      }
    </p>

    @if (mediaAccess?.existingMediaProducts?.length) {
      <dm-mms-shared-booking-products-list [products]="mediaAccess?.existingMediaProducts" />
    } @if (mediaAccess?.additionalMediaProducts?.length) {
      <dm-mms-shared-booking-products-list [products]="mediaAccess?.additionalMediaProducts" />
    }

    @if (!(mediaAccess.shouldCreateNewOrder && mediaAccess.existingMediaProducts?.length)) {
      <p class="mt-3 fw-700">
        {{ "TOTAL_PAYMENT_TO_BE_COLLECTED_BY_MARINA_OFFICE" | translate }}: {{ totalPaymentForProducts$ | async | currency : (currency$ | async) }}
      </p>
    }
  }
</div>

<div class="modal-footer justify-content-between d-flex">
  <button type="button" class="btn btn-tertiary btn-narrow" (click)="dismissModal()">
    {{ "CANCEL" | translate }}
  </button>
  <button
    class="btn btn-primary btn-default"
    type="button"
    [disabled]="(fetchingMediaMediaAccess$ | async) || apiCalling()"
    (click)="onSubmit()"
  >
    <dm-ui-spinner class="me-2" [showSpinner]="apiCalling()" />
    {{ "CONFIRM" | translate }}
  </button>
</div>

