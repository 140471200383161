import { inject, Pipe, PipeTransform } from '@angular/core';
import { MarinasService } from '@dm-workspace/core';
import { MARINA_URL_TOKEN } from '@dm-workspace/types';
import { Observable, of, switchMap } from 'rxjs';

@Pipe({
  name: 'replaceMarinaTokenWithSelectedMarinaCode',
})
export class ReplaceMarinaTokenWithSelectedMarinaCodePipe implements PipeTransform {
  #marinaService = inject(MarinasService);

  transform(routerLink: string): Observable<string> {
    if (!routerLink) {
      return of('');
    }

    return this.#marinaService.selectedMarina$.pipe(
      switchMap((marina) => {
        const routerLinkWithSelectedMarinaCode = routerLink.replace(MARINA_URL_TOKEN, marina.code);
        return of(routerLinkWithSelectedMarinaCode);
      })
    );
  }
}
