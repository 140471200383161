import { Component, input } from '@angular/core';
import { Quote } from '@dm-workspace/types';
import { DATE_SHORT_YEAR_FORMAT } from '@dm-workspace/shared';

@Component({
  selector: 'dm-mms-table-cell-last-send-date',
  templateUrl: './mms-table-cell-last-send-date.component.html',
  styleUrl: './mms-table-cell-last-send-date.component.scss',
})
export class MmsTableCellLastSendDateComponent {
  quote = input.required<Quote>();
  protected readonly nowDate = new Date();

  protected readonly DATE_SHORT_YEAR_FORMAT = DATE_SHORT_YEAR_FORMAT;
}
