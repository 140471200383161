@if (boat?.type) {
  <dm-ui-boat-icon class="boat-icon me-2" [boatType]="boat.type" />
}

@if (boat?.id) {
  <a class="link d-inline" [routerLink]="['/boats', boat?.id]" returnUrlCurrentPath [state]="{ backOnClose: true }">
    {{ boat?.name | empty }}
  </a>
} @else {
  <span>{{ boat?.name | empty }}</span>
}

<p>{{ boat?.length ?? boatDimensions?.length | empty }} / {{ boat?.maxBeam ?? boatDimensions?.width | empty }} m</p>

