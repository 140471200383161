import { Pipe, PipeTransform } from '@angular/core';
import { QuoteStatus } from '@dm-workspace/types';
import { TranslatePipe } from '@ngx-translate/core';

@Pipe({
  name: 'offerQuoteStatusTranslate',
})
export class OfferQuoteStatusTranslatePipe extends TranslatePipe implements PipeTransform {
  public override transform(quoteStatus: QuoteStatus | undefined): string {
    if (!quoteStatus) {
      return;
    }
    return super.transform(quoteStatus);
  }
}
