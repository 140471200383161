<nav class="position-relative" *ngLet="stats$ | async as stats">
  <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 row-cols-xxl-6 gy-3 gx-5" [class.pending]="pending">
    <dm-mms-dashboard-navigation-item
      titleKey="NEW_INQUIRIES"
      doneIcon="😊"
      link="new-inquiries"
      [stats]="stats?.inquiries"
      tooltipText="MMS_DASHBOARD.TOOLTIPS.INQUIRIES"
     />
    <dm-mms-dashboard-navigation-item
      titleKey="CHECK_INS"
      doneIcon="🥳"
      link="check-ins"
      [stats]="stats?.checkins"
      tooltipText="MMS_DASHBOARD.TOOLTIPS.CHECKINS"
     />
    <dm-mms-dashboard-navigation-item
      [stats]="stats?.boatMovements"
      doneIcon="😃"
      link="boat-movements"
      titleKey="BOAT_MOVEMENTS"
      tooltipText="MMS_DASHBOARD.TOOLTIPS.BOAT_MOVEMENTS"
     />
    <dm-mms-dashboard-navigation-item
      [stats]="stats?.quotes"
      doneIcon="😎"
      link="quotes"
      titleKey="OFFERS"
      tooltipText="MMS_DASHBOARD.TOOLTIPS.QUOTES"
     />
    <dm-mms-dashboard-navigation-item
      titleKey="OTHER_ISSUES"
      doneIcon="😍"
      link="other-issues"
      [stats]="stats?.otherBookingIssues"
      tooltipText="MMS_DASHBOARD.TOOLTIPS.OTHER_ISSUES"
     />
  </div>

  <dm-ui-overflow-spinner [showSpinner]="pending" type="absolute" [disableBg]="true" />
</nav>
