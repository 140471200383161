import { MmsDashboardStatName, OptionLabelAndValue } from '@dm-workspace/types';

export const DASHBOARD_VIEWS: { [key: string]: OptionLabelAndValue<MmsDashboardStatName>[] } = {
  'check-ins': [
    {
      label: 'WITH_DOCUMENTS',
      value: 'checkinsWithDocuments',
      path: 'with-documents',
    },
    {
      label: 'NOT_STARTED',
      value: 'notStartedCheckins',
      path: 'not-started',
    },
    {
      label: 'PERFORMED_OFFLINE',
      value: 'checkinsPerformedOffline',
      path: 'performed-offline',
    },
  ],
  'new-inquiries': [
    {
      label: 'NEW',
      value: 'newInquiries',
      path: 'new',
    },
    {
      label: 'PAYMENT_EXPIRED',
      path: 'expired-payments',
      value: 'expiredInquiries',
    },
  ],
  'boat-movements': [
    {
      label: 'EXPECTED_ARRIVALS',
      path: 'expected-arrivals',
      value: 'expectedArrivals',
    },
    {
      label: 'EXPECTED_DEPARTURES',
      path: 'expected-departures',
      value: 'expectedDepartures',
    },
    {
      label: 'CRUISES',
      path: 'cruises',
      value: 'cruisesForVerification',
    },
  ],
};
