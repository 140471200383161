import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  inject,
  input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, first, map } from 'rxjs';
import { MapViewTypes } from '@dm-workspace/utils';
import {
  BerthOccupancyStatusInMarina,
  BerthReservationsStatusInMarina,
  IBoatAddMovementPayload,
  IBoatDepartureArrivalPayload,
  ITransferBookingPayload,
} from '@dm-workspace/types';
import { BoatMovementService } from '@dm-workspace/mms-panel/shared';
import { ACTION_TYPES, BoatActions } from './action.model';
import { MarinasService } from '@dm-workspace/core';
import { MapBerthClass } from '../../berths.class';
import { MmsEnquiryQuickQuotationViewRouterState } from '../../../../../mms-panel/mms-enquiry/src/lib/views/mms-enquiry-quick-quotation-view/mms-enquiry-quick-quotation-view.component';

@Component({
  selector: 'dm-map-berth-details-drawer',
  templateUrl: './map-berth-details-drawer.component.html',
  styleUrls: ['./map-berth-details-drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MapBerthDetailsDrawerComponent {
  #boatsMovementService = inject(BoatMovementService);
  #marinasService = inject(MarinasService);
  #router = inject(Router);
  marinaCode$ = inject(ActivatedRoute).params.pipe(map((params) => params.marinaCode));
  berth = input.required<MapBerthClass>();
  viewType = input.required<MapViewTypes>();
  showNewQQBtn = computed(() => {
    const temporaryAvailable =
      this.berth().reservationStatus.includes(BerthReservationsStatusInMarina.temporaryAvailableDryDock) ||
      this.berth().reservationStatus.includes(BerthReservationsStatusInMarina.temporaryAvailableCruise);
    if (
      this.berth().reservationStatus.includes(BerthReservationsStatusInMarina.available) ||
      temporaryAvailable ||
      (temporaryAvailable &&
        !this.berth().reservationStatus.includes(BerthReservationsStatusInMarina.allowedOverbooking))
    ) {
      return true;
    }
    return false;
  });
  public viewTypes = MapViewTypes;
  @Output() berthChange = new EventEmitter();

  boatAction($event: BoatActions) {
    switch ($event.action) {
      case ACTION_TYPES.moveBoat:
        this.openBoatMovementModal($event.payload);
        break;
      case ACTION_TYPES.reportArrival:
        this.openArrivalBoatModal($event.payload);
        break;
      case ACTION_TYPES.reportDeparture:
        this.openDepartureBoatModal($event.payload);
        break;
      case ACTION_TYPES.transferBooking:
        this.openTransferBookingModal($event.payload);
        break;
      case ACTION_TYPES.quickQuote:
        this.goToQQ($event.payload.boatId);
        break;
    }
  }
  public openBoatMovementModal(movementPayload: IBoatAddMovementPayload) {
    const boatBooking = this.berth().data?.bookings?.find((booking) => booking.boatId === movementPayload.boat.id);
    this.#boatsMovementService
      .openBoatMovementModal(movementPayload, boatBooking?.humanReadableId)
      .pipe(filter(Boolean), first())
      .subscribe(() => this.berthChange.emit());
  }
  public openDepartureBoatModal(departure: IBoatDepartureArrivalPayload) {
    this.#boatsMovementService
      .openDepartureBoatModal(departure)
      .pipe(filter(Boolean), first())
      .subscribe(() => this.berthChange.emit());
  }
  public openArrivalBoatModal(departure: IBoatDepartureArrivalPayload) {
    this.#boatsMovementService
      .openArrivalBoatModal(departure)
      .pipe(filter(Boolean), first())
      .subscribe(() => this.berthChange.emit());
  }
  public openTransferBookingModal(payload: ITransferBookingPayload) {
    this.#boatsMovementService
      .openTransferBookingModal(payload)
      .pipe(filter(Boolean), first())
      .subscribe(() => this.berthChange.emit());
  }

  goToQQ(boatId?: string) {
    const state: MmsEnquiryQuickQuotationViewRouterState = {
      berthId: this.berth().data.berthId,
    };
    if (boatId) {
      state.boatId = boatId;
    }
    if (
      this.berth().occupancyStatus.length === 1 &&
      this.berth().data.temporaryAbsence.length &&
      (this.berth().occupancyStatus.includes(BerthOccupancyStatusInMarina.temporaryAbsenceCruise) ||
        this.berth().occupancyStatus.includes(BerthOccupancyStatusInMarina.temporaryAbsenceDryDock))
    ) {
      const { gapEnd, expectedReturnDate } = this.berth().data.temporaryAbsence[0];
      state.dateRange = {
        fromDate: new Date().toISOString().split('T')[0],
        toDate: (gapEnd || expectedReturnDate).split('T')[0],
      };
    }
    this.#router.navigate(['/', this.#marinasService.selectedMarina().code, 'enquiry', 'quick-quotation'], {
      state: {
        returnUrl: this.#router.url,
        ...state,
      },
    });
  }
}
