<dm-mms-offers-filters
  [defaultFilters]="defaultFilters"
  (filtersChange)="onFiltersChange($event)"
  [storageKey]="'offers'"
/>

<div dmUiStickyElementOffset="plannerTasks" class="pt-s">
  <div class="card px-xxl py-xl">
    <h1 class="text-m mb-2">{{ "OFFERS" | translate | uppercase }}</h1>
    <ng-container *ngIf="'offers' | selectedMarinaSupportView | async; then marinaSupported; else marinaUnsupported" />

    <ng-template #marinaSupported>
      <div class="position-relative" [style.min-height.px]="100">
        <dm-mms-offers-table [offers]="offers$ | async" (offersChange)="fetchCollection()" [isRenewalsView]="true" />
        <dm-ui-pagination
          *ngIf="pagination$ | async as pagination"
          [rows]="pagination.limit"
          (pageChange)="onPageChange($event)"
          (numberOfRowsChange)="onLimitChange($event)"
          [collectionSize]="pagination.totalCount"
        />
        <dm-ui-overflow-spinner [showSpinner]="pending$ | async" type="absolute" />
      </div>
    </ng-template>

    <ng-template #marinaUnsupported>
      <p class="mt-4">
        {{ "THIS_FEATURE_IS_YET_NOT_AVAILABLE_IN_THIS_MARINA" | translate }}
      </p>
    </ng-template>
  </div>
</div>
