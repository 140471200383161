@for (pylon of pylon().sockets; track pylon.pylonCode; let i = $index) {
  <div class="d-flex py-2 justify-content-between align-items-center">
    <div>
      <label for="socket" class="me-2 typo-body-m fw-500">{{ "SOCKET_NUMBER" | translate }}:</label>
      <span id="socket" class="typo-body-m fw-700">{{ pylon.outputName }}</span>
    </div>
    <div class="d-flex align-items-center">
      <div class="d-flex me-5">
        <label for="status" class="me-2 typo-body-m fw-500">{{ "STATUS" | translate }}:</label>
        <div id="status">
          @if (pylon.isUsed && !!pylon.bookingReferenceId) {
            <dm-ui-tag color="green-light">{{ "ON" | translate }}</dm-ui-tag>
          } @else if (pylon.isUsed) {
            <dm-ui-tag color="red-light">{{ "ON" | translate }}</dm-ui-tag>
          } @else {
            <dm-ui-tag color="grey-light">{{ "OFF" | translate }}</dm-ui-tag>
          }
        </div>
      </div>
      <button
        type="button"
        class="btn btn-secondary btn-secondary-outline py-2 px-3 list-action-button"
        (click)="changeSocketConnectionStatus.emit(pylon)"
      >
        {{ (pylon.isUsed ? "TURN_OFF" : "TURN_ON") | translate | uppercase }}
      </button>
    </div>
  </div>

  <div class="d-flex align-items-baseline py-2">
    <label for="socketType" class="me-2 typo-body-m fw-500">{{ "SOCKET_TYPE" | translate }}:</label>
    <span id="socketType" class="typo-body-m fw-600">{{ pylon.mediaType | translate | titlecase }}</span>
  </div>

  <div class="d-flex align-items-baseline justify-content-between py-2">
    <div class="d-flex">
      <label for="booking" class="me-2 typo-body-m fw-500">{{ "CONNECTED_BOOKING" | translate }}:</label>
      @if (!pylon.bookingReferenceId) {
        <span id="booking" class="typo-body-m fw-600">-</span>
      } @else {
        <div>
          <a
            class="details-link text-nowrap me-2 typo-body-m fw-500"
            [routerLink]="['/', marinaCode(), 'bookings', pylon.bookingId, 'details']"
            [state]="{ backOnClose: true }"
            returnUrlCurrentPath
            >{{ pylon.bookingReferenceId }}</a
          >
          <span class="typo-body-m fw-600">{{ pylon.contractType }}</span>
        </div>
      }
    </div>
  </div>

  <div class="d-flex py-2">
    <label for="boat" class="me-2 typo-body-m fw-500">{{ "CONNECTED_BOAT" | translate }}:</label>
    @if (pylon.boat) {
      <a
        id="boat"
        class="details-link text-nowrap me-2 typo-body-m fw-500"
        [routerLink]="['/', 'boats', pylon.boat?.id]"
        [state]="{ backOnClose: true }"
        returnUrlCurrentPath
        >{{ pylon.boat?.name }}</a
      >
    } @else {
      -
    }
  </div>

  <dm-ui-horizontal-line />
}
