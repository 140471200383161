<div class="card px-xxl py-xl">
  <h3 class="text-m mb-2 dashboard-header">
    {{ "CRUISES" | translate | uppercase }}
  </h3>

  <div class="position-relative" [style.min-height.px]="300">
    <dm-mms-dashboard-list-done header="😎️" *ngIf="hasBookingsAllDone">
      {{ "MMS_DASHBOARD.CRUISES_DONE.0" | translate }}
      {{ "MMS_DASHBOARD.CRUISES_DONE.1" | translate }}
    </dm-mms-dashboard-list-done>

    <ng-container *ngIf="hasBookingsTodo">
      <dm-mms-dashboard-bookings-list *ngIf="bookings" [bookings]="bookings" [actionRef]="action" />

      <dm-ui-pagination
        [rows]="pagination.limit"
        (pageChange)="onPageChange($event)"
        (numberOfRowsChange)="onLimitChange($event)"
        [collectionSize]="pagination.totalCount"
       />
    </ng-container>

    <ng-template #action let-booking="booking">
      <button type="button" (click)="openCruiseModal(booking)" class="btn-pill-primary-light">
        {{ "CRUISE_VERIFICATION" | translate }}
      </button>
    </ng-template>

    <dm-ui-overflow-spinner [showSpinner]="pending" type="absolute" />
  </div>
</div>
