import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiModule } from '@dm-workspace/ui';
import { LibsFormsModule } from '@dm-workspace/forms';
import { LibsSharedModule } from '@dm-workspace/shared';
import { TranslateModule } from '@ngx-translate/core';
import { BookingsFindBerthFormComponent } from './components/bookings-find-berth-form/bookings-find-berth-form.component';
import { BookingsBerthSelectionComponent } from './components/bookings-berth-selection/bookings-berth-selection.component';
import { BookingsBerthsTableComponent } from './components/bookings-berths-table/bookings-berths-table.component';
import { NgbDatepickerModule, NgbNavModule, NgbTooltipModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { BookingsFormsCreatorService } from './services/bookings-forms-creator.service';
import { NgxMaskModule } from 'ngx-mask';
import { NewBookingFormComponent } from './components/new-booking-form/new-booking-form.component';
import { CustomerBookingFormCardComponent } from './components/customer-booking-form-card/customer-booking-form-card.component';
import { MmsResourceBookingFormComponent } from './components/mms-resource-booking-form/mms-resource-booking-form.component';
import { BoatBookingFormCardComponent } from './components/boat-booking-form-card/boat-booking-form-card.component';
import { MarinaFormsCreatorService } from './services/marina-forms-creator.service';
import { BookingsBerthsTableWithSelectComponent } from './components/bookings-berths-table/bookings-berths-table-with-select/bookings-berths-table-with-select.component';

const declarations = [
  BookingsFindBerthFormComponent,
  BookingsBerthSelectionComponent,
  BookingsBerthsTableComponent,
  NewBookingFormComponent,
  MmsResourceBookingFormComponent,
  CustomerBookingFormCardComponent,
  BoatBookingFormCardComponent,
  BookingsBerthsTableWithSelectComponent,
];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    UiModule,
    LibsFormsModule,
    NgbDatepickerModule,
    LibsSharedModule,
    NgbTypeaheadModule,
    NgbTooltipModule,
    NgbNavModule,
    NgxMaskModule.forChild(),
  ],
  declarations: [...declarations],
  exports: [...declarations],
  providers: [BookingsFormsCreatorService, MarinaFormsCreatorService],
})
export class MmsPanelBookingsModule {}
