import { Component, Input } from '@angular/core';

@Component({
  selector: 'dm-mms-layout-not-found-view',
  templateUrl: './mms-layout-not-found-view.component.html',
  styleUrls: ['./mms-layout-not-found-view.component.scss'],
})
export class MmsLayoutNotFoundViewComponent {
  @Input() errorCode: number = 404;
}
