<div class="card px-xxl py-xl">
  <div class="header mb-2">
    <h3 class="text-m">
      {{ "BOAT_MOVEMENTS" | translate | uppercase }}
    </h3>
    <dm-ui-toggle-switch [items]="views" [selectItemIndex]="selectedIndex" />
  </div>

  <div class="position-relative" [style.min-height.px]="300">
    <dm-mms-dashboard-list-done header="😊️" *ngIf="hasBookingsAllDone">
      {{ "MMS_DASHBOARD.BOAT_MOVEMENTS_DONE.0" | translate }}
      {{ "MMS_DASHBOARD.BOAT_MOVEMENTS_DONE.1" | translate }}
    </dm-mms-dashboard-list-done>

    <ng-container *ngIf="hasBookingsTodo">
      <dm-mms-dashboard-bookings-list *ngIf="bookings" [bookings]="bookings" [actionRef]="action" />
      <dm-ui-pagination
        [rows]="pagination.limit"
        (pageChange)="onPageChange($event)"
        (numberOfRowsChange)="onLimitChange($event)"
        [collectionSize]="pagination.totalCount"
      />
    </ng-container>

    <ng-template #action let-booking="booking">
      <dm-mms-shared-booking-actions-button [booking]="booking" />
    </ng-template>

    <dm-ui-overflow-spinner [showSpinner]="pending" type="absolute" />
  </div>
</div>
