import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input } from '@angular/core';
import { IBerthDto, Marina } from '@dm-workspace/types';
import { BerthsApiService } from '@dm-workspace/data-access';
import { MapPolygon } from '@angular/google-maps';
import { finalize } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BerthStatusModalComponent } from '../berth-status-modal/berth-status-modal.component';
import { MapComponentBase, MapService } from '@dm-workspace/map-utils';

@Component({
  selector: 'dm-map-berth-statuses-map',
  templateUrl: './berth-statuses-map.component.html',
  styleUrls: ['./berth-statuses-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BerthStatusesMapComponent extends MapComponentBase {
  public pending = true;

  @Input() set marina(marina: Marina) {
    this.selectedMarina = marina;
    if (marina) {
      this.updateMarina();
      this.fetchBerths();
    }
  }

  public berths: IBerthDto[] = [];

  constructor(
    mapService: MapService,
    cd: ChangeDetectorRef,
    private berthApiService: BerthsApiService,
    el: ElementRef,
    private modal: NgbModal
  ) {
    super(mapService, cd, el);
  }

  private fetchBerths() {
    this.pending = true;
    this.cd.markForCheck();
    this.berthApiService
      .fetchAll(true)
      .pipe(
        finalize(() => {
          this.pending = false;
          this.cd.markForCheck();
        })
      )
      .subscribe((value) => {
        this.berths = value;
      });
  }

  getMouseOverPopupContent(berthName: string, berthId?: string) {
    return berthName;
  }

  onMouseOver(berthM: MapPolygon, berth: IBerthDto) {
    this.openInfoWindowWithBerthName(berthM, `<strong>${berth.name}</strong> (<small>${berth.status}</small>)`);
  }

  onMouseOut() {
    this.closeInfoWindowWithBerthName();
  }

  onBerthClick(berthM: MapPolygon, berth: IBerthDto) {
    const modal = this.modal.open(BerthStatusModalComponent, {
      size: 'md',
    });
    modal.componentInstance.berth = berth;
    modal.closed.subscribe((value) => {
      if (value) {
        Object.assign(berth, value);
        this.cd.detectChanges();
      }
    });
  }
}
