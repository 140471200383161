import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DATE_SHORT_YEAR_FORMAT } from '@dm-workspace/shared';

@Component({
  selector: 'dm-mms-table-cell-start-end',
  templateUrl: './mms-table-cell-start-end.component.html',
  styleUrls: ['./mms-table-cell-start-end.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsTableCellStartEndComponent {
  @Input({ required: true }) start: string;
  @Input({ required: true }) end: string;
  protected readonly DATE_SHORT_YEAR_FORMAT = DATE_SHORT_YEAR_FORMAT;
}
