import { Component, Input } from '@angular/core';
import { MmsResourceBookingAvailableProducts } from '@dm-workspace/types';

@Component({
  selector: 'dm-mms-shared-booking-products-list',
  templateUrl: './mms-booking-products-list.component.html',
  styleUrls: ['./mms-booking-products-list.component.scss'],
})
export class MmsBookingProductsListComponent {
  @Input() public products: MmsResourceBookingAvailableProducts[] | null;
}
