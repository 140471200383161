<dm-ui-modal>
  <h2 header class="title">{{ "REPORT_ARRIVAL" | translate }}</h2>
  <div body>
    <p class="mb-4">
      {{ "CHECK_IF_CUSTOMER_MOORED_TO_CORRECT_SPOT" | translate }}:
      <strong>{{ "BERTH" | translate }} {{ form.value.destination?.name }}</strong>
    </p>

    <div class="row" [formGroup]="form">
      <dm-form-group class="col-6">
        <label>{{"DESTINATION_BERTH" | translate}}</label>
        <dm-form-select-typeahead
          dmForm
          [clearable]="false"
          typeahead="resources"
          formControlName="destination"
          bindLabel="name"
          [resourceType]="ResourceType.berth"
        />
        <dm-form-error-message controlName="destinationId" />
      </dm-form-group>
    </div>
  </div>

  <ng-container footer>
    <button class="btn btn-tertiary btn-narrow" type="button" (click)="onCancel()">
      {{ "CANCEL" | translate }}
    </button>

    <button type="button" class="btn btn-primary" (click)="submitForm()" [disabled]="apiCalling()">
      <dm-ui-spinner [showSpinner]="apiCalling()" class="me-2" />
      {{ "CONFIRM" | translate }}
    </button>
  </ng-container>
</dm-ui-modal>
