<google-map
  *ngIf="isLoaded$ | async as options"
  #myMap="googleMap"
  width="100%"
  [options]="options"
  [center]="center"
  [height]="height"
>
  <map-polygon *ngFor="let pier of piers" [options]="piersOptions" [paths]="pier | arrayToLatLng" />
  <map-polygon
    *ngFor="let berth of berths"
    #berthM="mapPolygon"
    [paths]="berth.polygon | arrayToLatLng"
    [options]="berth.status | berthPolygonStyleByBerthStatus"
    (polygonClick)="onBerthClick(berthM, berth)"
    (polygonMouseover)="onMouseOver(berthM, berth)"
    (polygonMouseout)="onMouseOut()"
  />
</google-map>
<dm-ui-overflow-spinner type="absolute" [showSpinner]="pending" />
