import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MarinasService, UserService } from '@dm-workspace/core';
import { fadeRouterAnimation } from '@dm-workspace/shared';
import { filter, Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { MmsDashboardViewService } from '../../services/mms-dashboard-view.service';

@Component({
  selector: 'dm-mms-dashboard-view',
  templateUrl: './mms-dashboard-view.component.html',
  styleUrls: ['./mms-dashboard-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeRouterAnimation],
})
export class MmsDashboardViewComponent implements OnInit, OnDestroy {
  public readonly user$ = this.userService.user$;
  public animationState = '';
  private subscription = new Subscription();

  constructor(
    private userService: UserService,
    private dashboardService: MmsDashboardViewService,
    private router: Router,
    private marinaService: MarinasService
  ) {}

  public ngOnInit(): void {
    this.subscription.add(
      this.router.events
        .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
        .subscribe((value) => this.setAnimationStateByUrl(value.urlAfterRedirects))
    );

    this.subscription.add(
      this.marinaService.selectedMarinaChange$.subscribe(() => {
        this.dashboardService.refreshStats();
      })
    );
  }

  private setAnimationStateByUrl(urlAfterRedirects: string) {
    const [, , , ...urlParts] = urlAfterRedirects.split('/');

    this.dashboardService.lastActiveRoute = urlParts.join('/') || '';
    this.animationState = urlParts[0] || '';
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
