import { Component, Input } from '@angular/core';
import { IBoat } from '@dm-workspace/types';

@Component({
  selector: 'dm-mms-boat-details-preview',
  templateUrl: './mms-boat-details-preview.component.html',
  styleUrls: ['./mms-boat-details-preview.component.scss'],
})
export class MmsBoatDetailsPreviewComponent {
  @Input({ required: true }) public boat: IBoat;
}
