<ng-container *ngIf="berth">
  <!--  <dm-map-berth-status [berth]="berth" [name]="berth?.data.berthName" />-->
  <div *ngIf="berth.data.bookings || (berth.data.occupancy && isUtilization)" class="reservations">
    <table>
      <thead>
        <tr>
          <th>{{ "BOATS" | translate }}</th>
          <th>
            <span *ngIf="berth.data.bookings">{{ "RESERVATIONS" | translate }}</span>
          </th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let reservation of reservations" class="reservation-row">
          <td>
            <ng-container
              [ngTemplateOutlet]="boatIcon"
              [ngTemplateOutletContext]="{ boat: boats[reservation.boatId] }"
            />
          </td>
          <td colspan="2">
            <dm-map-popup-row [label]="reservation.contractType | contractTypeTranslate">
              <a
                class="details-link text-nowrap"
                [routerLink]="['/bookings', reservation.reservationId, 'details']"
                [state]="{ backOnClose: true }"
                returnUrlCurrentPath
                >{{ reservation.humanReadableId }}</a
              >
            </dm-map-popup-row>
            <dm-map-popup-row [label]="'END_DATE' | translate" [txt]="reservation.toDate | dateFormat" />
            <dm-map-popup-row
              *ngIf="boats[reservation.boatId] && boats[reservation.boatId].statusInMarina"
              [label]="'BOAT_STATUS' | translate"
              [txt]="boats[reservation.boatId].statusInMarina"
            />
          </td>
          <td>
            <button
              *ngIf="boats[reservation.boatId] && reservation.resourceId"
              type="button"
              class="details-link"
              (click)="
                checkIfBoatIsInOccupancy(reservation.boatId)
                  ? onBoatMovementClick(boats[reservation.boatId], reservation.resourceId, reservation)
                  : openAddBoatMovementModal(boats[reservation.boatId], reservation.reservationId)
              "
            >
              {{
                (checkIfBoatIsInOccupancy(reservation.boatId) ? "TRANSFER_BOOKING" : "MOVE_BOAT")
                  | translate
                  | uppercase
              }}
            </button>
          </td>
        </tr>
        <ng-container *ngFor="let occupation of occupancys">
          <tr *ngIf="checkIfBoatIsInReservation(occupation.boatId) && boats[occupation.boatId] as boat">
            <td>
              <ng-container [ngTemplateOutletContext]="{ boat: boat }" [ngTemplateOutlet]="boatIcon" />
            </td>
            <td>
              <a
                class="btn btn-primary btn-sm"
                [routerLink]="['/marina/new-booking']"
                returnUrlCurrentPath
                [queryParams]="{ berth: berth.data.berthName, existingBoat: boat.id }"
                >{{ "CREATE_BOOKING" | translate }}</a
              >
            </td>
            <td>
              <button
                *ngIf="occupation.resourceId"
                type="button"
                class="details-link"
                (click)="onDepartureBoatClick(boat.id, occupation.resourceId)"
              >
                {{ "BOAT_OPERATIONS.DEPART" | translate | uppercase }} {{ "BOAT" | translate | uppercase }}
              </button>
            </td>
            <td>
              <button
                *ngIf="occupation.resourceId"
                type="button"
                class="details-link"
                (click)="openAddBoatMovementModal(boat, occupation.resourceId)"
              >
                {{ "MOVE_BOAT" | translate | uppercase }}
              </button>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div class="text-center">
    <a
      *ngIf="showNewBookingButton"
      class="btn btn-primary btn-narrow mt-3"
      [routerLink]="['/marina/new-booking']"
      [queryParams]="{ berth: berth.data.berthName }"
      returnUrlCurrentPath
      [state]="{ returnUrl: '/bookings/map' }"
      >{{ "NEW_BOOKING" | translate }}</a
    >
  </div>
  <dm-ui-overflow-spinner type="absolute" [showSpinner]="pending" />
</ng-container>
<ng-template #boatIcon let-boat="boat">
  <ng-container *ngIf="boat">
    <dm-ui-boat-icon [boatType]="boat.type" />
    <a *ngIf="boat.id" class="details-link" [routerLink]="['/boats', boat.id]" returnUrlCurrentPath>{{ boat.name }}</a>
  </ng-container>
</ng-template>
