import { Pipe, PipeTransform } from '@angular/core';
import { ResourceBookingLabels } from '@dm-workspace/types';

@Pipe({
  name: 'filterInvisibleBookingLabels',
})
export class MmsFilterInvisibleBookingLabelsPipe implements PipeTransform {
  transform(labels: ResourceBookingLabels[] | undefined): ResourceBookingLabels[] {
    if (!labels?.length) {
      return;
    }

    const disallowedLabels: ResourceBookingLabels[] = [
      ResourceBookingLabels.OVERSTAYED,
      ResourceBookingLabels.UNANNOUNCED_ARRIVAL,
    ];
    return labels.filter((label) => !disallowedLabels.includes(label));
  }
}
