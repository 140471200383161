<div class="card px-xxl py-xl">
  <div class="mb-4 d-flex align-items-center">
    <h2 class="text-m me-3">
      {{ "OFFERS" | translate | uppercase }}
    </h2>

    <ng-container *ngLet="routeStats$ | async as quotesStats">
      <dm-ui-state-switch>
        <a [routerLink]="['./../new']" dmUiStateSwitchItemLink>
          {{ "NEW" | translate }}
          <dm-ui-state-switch-badge *ngIf="quotesStats?.newQuotes?.todoCount as todoCount"
                                    class="ms-1">{{ todoCount || 0 }}
          </dm-ui-state-switch-badge>
        </a>
        <a [routerLink]="['./../sent']" dmUiStateSwitchItemLink>
          {{ "SENT" | translate }}
          <dm-ui-state-switch-badge *ngIf="quotesStats?.sentQuotes?.todoCount as todoCount"
                                    class="ms-1">{{ todoCount || 0 }}
          </dm-ui-state-switch-badge>
        </a>
        <a [routerLink]="['./../accepted']" dmUiStateSwitchItemLink>
          {{ "ACCEPTED" | translate }}
          <dm-ui-state-switch-badge *ngIf="quotesStats?.acceptedQuotes?.todoCount as todoCount"
                                    class="ms-1">{{ todoCount || 0 }}
          </dm-ui-state-switch-badge>
        </a>
      </dm-ui-state-switch>
    </ng-container>
  </div>

  <div [style.min-height.px]="300" class="position-relative">
    <ng-container *ngIf="'offers' | selectedMarinaSupportView | async; else unsupportedMarina">
      <dm-mms-dashboard-list-done header="😎️" *ngIf="hasAllDone$ | async">
        {{ "MMS_DASHBOARD.OFFERS_DONE.0" | translate }}
        {{ "MMS_DASHBOARD.OFFERS_DONE.1" | translate }}
      </dm-mms-dashboard-list-done>

      <ng-container *ngIf="hasUndone$ | async">
        <dm-mms-offers-table [offers]="items$ | async" (offersChange)="refreshItems()" />
        <dm-ui-pagination [pagination]="pagination" />
      </ng-container>

      <dm-ui-overflow-spinner [showSpinner]="pending$ | async" position="top" type="absolute" />
    </ng-container>

    <ng-template #unsupportedMarina>
      <p class="mt-4">
        {{ "THIS_FEATURE_IS_YET_NOT_AVAILABLE_IN_THIS_MARINA" | translate }}
      </p>
    </ng-template>
  </div>
</div>
