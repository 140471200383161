import { mmsLayoutRedirectMap } from './mms-layout-redirect-map';
import { Ability, MARINA_URL_TOKEN, Permissions } from '@dm-workspace/types';
import { Features } from '@dm-workspace/core';

export enum SearchContext {
  marina,
  boat,
  users,
}

export interface MmsNavRoutes {
  path?: string;
  label: string;
  icon?: string;
  permission?: Permissions;
  isHidden?: boolean;
  feature?: Features;
  searchContext?: SearchContext;
  children?: MmsNavRouteChildren[];
}

export type MmsNavRouteChildren = {
  path: string;
  label: string;
  permission?: Permissions;
  feature?: Features;
};

export const mmsLayoutNavigationRoutes: MmsNavRoutes[] = [
  {
    path: `${MARINA_URL_TOKEN}/bookings/map`,
    label: 'START',
    icon: 'i-home',
  },
  {
    path: `${MARINA_URL_TOKEN}/enquiry`,
    label: 'ENQUIRIES',
    icon: 'i-addbooking',
  },
  {
    icon: 'i-wallet',
    label: 'SALES',
    permission: mmsLayoutRedirectMap.orders,
    path: `${MARINA_URL_TOKEN}/renewals`,
    children: [
      {
        path: `${MARINA_URL_TOKEN}/renewals`,
        label: 'RENEWALS',
        permission: mmsLayoutRedirectMap.orders,
      },
      {
        path: `${MARINA_URL_TOKEN}/contracts/sensors`,
        label: 'SENSORS_LIST',
        permission: mmsLayoutRedirectMap.contracts,
      },
      {
        path: `${MARINA_URL_TOKEN}/orders`,
        label: 'ORDERS_LIST',
        permission: mmsLayoutRedirectMap.orders,
      },
      {
        path: `${MARINA_URL_TOKEN}/contracts`,
        label: 'CONTRACTS_LIST',
        permission: mmsLayoutRedirectMap.contracts,
      },
      {
        path: `${MARINA_URL_TOKEN}/contracts/billing-management`,
        label: 'BILLING_MANAGEMENT',
        permission: mmsLayoutRedirectMap.contracts,
        feature: Features.postPaid,
      },
    ],
  },
  {
    path: `${MARINA_URL_TOKEN}/bookings`,
    icon: 'i-calendar',
    label: 'RESERVATIONS',
    permission: mmsLayoutRedirectMap.marina,
    children: [
      {
        path: `${MARINA_URL_TOKEN}/bookings/planned`,
        label: 'PLANNED',
      },
      {
        path: `${MARINA_URL_TOKEN}/dashboard/check-ins`,
        label: 'CHECK_INS',
      },
      {
        path: `${MARINA_URL_TOKEN}/bookings/confirmed`,
        label: 'CONFIRMED',
      },
      {
        path: `${MARINA_URL_TOKEN}/bookings/calendar`,
        label: 'CALENDAR',
      },
    ],
  },
  {
    icon: 'i-plug-in',
    label: 'UTILITIES',
    permission: mmsLayoutRedirectMap.pylons,
    feature: Features.pylons,
    path: `${MARINA_URL_TOKEN}/pylons`,
  },
  {
    icon: 'i-marina-task',
    label: 'JOURNAL_TASKS',
    path: `${MARINA_URL_TOKEN}/journal-tasks`,
  },
  {
    icon: 'i-contract',
    path: `${MARINA_URL_TOKEN}/planner/tasks`,
    label: 'TASKS',
    permission: mmsLayoutRedirectMap.manageTasks,
    children: [
      {
        path: `${MARINA_URL_TOKEN}/planner/tasks/list`,
        label: 'TASKS_LIST',
      },
      {
        path: `${MARINA_URL_TOKEN}/planner/templates/list`,
        label: 'TEMPLATES_LIST',
      },
      {
        label: 'TEAMS_LIST',
        path: `${MARINA_URL_TOKEN}/planner/teams/list`,
      },
    ],
  },
  {
    icon: 'i-boat',
    label: 'BOATS',
    path: '/boats',
    permission: mmsLayoutRedirectMap.boats,
  },
  {
    icon: 'i-more-squares',
    label: 'MORE',
    path: '/users/employees',
    children: [
      {
        label: 'CUSTOMERS',
        path: '/customers',
        permission: Ability.READ_CUSTOMER,
      },
      {
        label: 'MARINA_SETUP',
        path: `${MARINA_URL_TOKEN}/admin/map`,
        permission: Ability.UPDATE_MARINA,
      },
      {
        label: 'MARINA_PROPERTIES',
        path: `${MARINA_URL_TOKEN}/admin/marina`,
        permission: Ability.UPDATE_MARINA,
      },
      {
        label: 'PRODUCTS_AND_PRICE_LISTS',
        path: `${MARINA_URL_TOKEN}/products`,
        permission: mmsLayoutRedirectMap.products,
      },
      {
        label: 'MMI_USERS',
        path: '/users/employees',
        permission: Ability.READ_USER,
      },
      // {
      //   label: 'TRANSLATIONS',
      //   path: '/translations',
      //   permission: mmsLayoutRedirectMap.translations,
      // },
    ],
  },
];
