<table class="table">
  <colgroup>
    <col>
    <col [style.width.px]="200">
  </colgroup>

  <thead>
    <tr>
      <th>{{ "SERVICE" | translate }}</th>
      <th class="text-right">{{ "AMOUNT" | translate }}</th>
    </tr>
  </thead>

  <tbody>
    <tr *ngFor="let offerProduct of offerProducts">
      <td>{{ offerProduct.product?.name || offerProduct.product?.productNumber }}</td>
      <td class="text-right">{{ offerProduct.totalPriceGross | currency : currency }}</td>
    </tr>

    <tr *ngIf="offerProducts?.length < 1">
      <td colspan="2">
        {{ "NO_ITEMS_FOUND" | translate }}
      </td>
    </tr>
  </tbody>
</table>
