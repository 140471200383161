import { ChangeDetectionStrategy, Component, effect, inject, input, signal } from '@angular/core';
import { ApiValidator, fadeAnimation } from '@dm-workspace/shared';
import { MmsQuotesApiService } from '@dm-workspace/data-access';
import { UserService } from '@dm-workspace/core';
import { NotificationService } from '@dm-workspace/notification';
import { MmsQuoteActivity } from '@dm-workspace/types';
import { DateUtils } from '@dm-workspace/utils';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { catchError, finalize, switchMap, tap, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { FormUtils } from '@dm-workspace/forms';
import { MmsEnquiryNoteFormGroup } from '../mms-enquiry-note-form/mms-enquiry-note-form.component';

@Component({
  selector: 'dm-mms-timeline',
  templateUrl: './mms-timeline.component.html',
  styleUrls: ['./mms-timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeAnimation],
})
export class MmsTimelineComponent {
  #quotesApiService = inject(MmsQuotesApiService);
  #userService = inject(UserService);
  #notificationService = inject(NotificationService);
  quoteId = input.required<string>();
  header = input('SCROLL_TROUGH_RECENT_HISTORY_TIMELINE_OF_THIS_ENQUIRY');
  subHeader = input('LOOK_FOR_IMPORTANT_INFORMATION_OR_ADD_AN_COMMENT_WITH_CURRENT_ENQUIRY_STATUS');
  protected activities = signal<MmsQuoteActivity[]>(null);
  protected fetchingActivities = signal<boolean>(true);
  protected sendingActivity = signal<boolean>(false);
  protected currentUser$ = this.#userService.user$;
  protected currentTime$ = DateUtils.currentTime$;

  protected noteFormGroup: MmsEnquiryNoteFormGroup = new FormGroup({
    content: new FormControl(null, { validators: [Validators.required], updateOn: 'blur' }),
    asLastContact: new FormControl<boolean>(false, { nonNullable: true }),
  });
  constructor() {
    effect(
      () => {
        if (this.quoteId()) {
          this.refreshActivities().subscribe();
        }
      },
      {
        allowSignalWrites: true,
      }
    );
  }

  protected refreshActivities() {
    this.fetchingActivities.set(true);

    return this.#quotesApiService.getActivities(this.quoteId()).pipe(
      catchError((res: HttpErrorResponse) => {
        this.#notificationService.openError(ApiValidator.getApiNotificationError(res));
        return throwError(() => res);
      }),
      finalize(() => this.fetchingActivities.set(false)),
      tap((activities) => this.activities.set(activities))
    );
  }

  protected addNote() {
    if (this.sendingActivity() || !FormUtils.isValid(this.noteFormGroup)) {
      return;
    }
    this.sendingActivity.set(true);

    const formValue = this.noteFormGroup.getRawValue();
    this.#quotesApiService
      .createNote(this.quoteId(), formValue)
      .pipe(
        catchError((res: HttpErrorResponse) => {
          this.#notificationService.openError(ApiValidator.getApiNotificationError(res));
          return throwError(() => res);
        }),
        finalize(() => {
          this.sendingActivity.set(false);
          this.#notificationService.openSuccess();
        }),
        tap(() => this.noteFormGroup.reset()),
        switchMap(() => this.refreshActivities())
      )
      .subscribe();
  }
}
