import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { greaterThan } from '@dm-workspace/forms';

@Injectable()
export class BookingsFormsCreatorService {
  constructor(private fb: UntypedFormBuilder) {}

  public createQuickQuotationCustomerForm(): UntypedFormGroup {
    return this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      phone: [null],
      firstName: [null],
      lastName: [null],
      customerId: [null],
    });
  }

  public createQuickQuotationFindBerthForm(): UntypedFormGroup {
    return this.fb.group({
      boat: [null],
      marinaCode: [null, Validators.required],
      dateRange: [null, Validators.required],
      boatLength: [null, Validators.required],
      boatWidth: [null, Validators.required],
      boatType: [null, Validators.required],
      boatDraft: [null, [Validators.required, greaterThan(0)]],
      searchInGaps: [null],
    });
  }
}
