<div class="modal-header">
  <h2 class="title">{{ "SETUP_POSTPAID_DETAILS_MODAL_TITLE" | translate }}</h2>
</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="modal-body">
    <p class="mb-l">{{ "SETUP_POSTPAID_DETAILS_MODAL_TEXT" | translate }}</p>

    <ng-container *ngIf="!confirmEmail; else confirm">
      <div class="row">
        <dm-form-group class="col-6 mb-m" iconName="calendar">
          <label>{{ "START_DATE" | translate }}</label>
          <input
            dmForm
            formControlName="pylonStartDate"
            placeholder="{{ datePlaceholderFormat }}"
            ngbDatepicker
            container="body"
            type="text"
            #d="ngbDatepicker"
            [maxDate]="adapter.fromModel(now)"
            [minDate]="adapter.fromModel(firstDayOfMonth)"
            navigation="none"
            (click)="d.open()"
          />
          <dm-form-error-message
            controlName="pylonStartDate"
            [apiError]="apiErrors?.pylonStartDate"
          />
        </dm-form-group>
      </div>

      <div class="row" *ngIf="!hasPylonData">
        <dm-ui-info-box
          class="py-s"
          icon="info"
          color="info"
          text="MAKE_POSTPAID_MODAL_EMPTY_FIELDS_INFO"
        />
      </div>

      <div
        class="row"
        *ngFor="
          let pylon of form.controls.pylonOutputUsages.controls;
          let i = index;
          let isFirst = first;
          let isLast = last
        "
      >
        <ng-container [formGroup]="pylon">
          <div class="visual-line" *ngIf="!isFirst"></div>
          <dm-form-group class="col-6 my-s">
            <label>{{ "SOCKET_NUMBER" | translate }}</label>
            <input dmForm formControlName="socketNumber" placeholder="{{ 'SOCKET_NUMBER' | translate }}" type="text" />
            <dm-form-error-message
              controlName="socketNumber"
              [apiError]="apiErrors?.pylonOutputUsages?.[i]?.socketNumber"
            />
          </dm-form-group>
          <dm-form-group class="col-6 my-s">
            <label>{{ "PYLON_CODE" | translate }}</label>
            <input dmForm formControlName="pedestalId" placeholder="{{ 'PYLON_CODE' | translate }}" type="text" />
            <dm-form-error-message
              controlName="pedestalId"
              [apiError]="apiErrors?.pylonOutputUsages?.[i]?.pedestalId"
            />
          </dm-form-group>
        </ng-container>
      </div>
      <div class="row" *ngIf="hasPylonData">
        <dm-ui-info-box
          class="py-xl"
          icon="info"
          alignIcon="top"
          color="info"
          text="MAKE_POSTPAID_INFO_BOX"
        />
      </div>
    </ng-container>
  </div>

  <div class="modal-footer justify-content-between d-flex">
    <button class="btn btn-tertiary btn-narrow" type="button" (click)="closeModal()">
      {{ "CANCEL" | translate }}
    </button>
    <button type="button" class="btn btn-primary" *ngIf="!confirmEmail" (click)="nextAction()">
      {{ "CONTINUE" | translate }}
    </button>
    <button class="btn btn-primary" type="submit" *ngIf="confirmEmail" [disabled]="pending">
      <dm-ui-spinner [showSpinner]="pending"/>
      {{ "MAKE_POSTPAID" | translate }}
    </button>
  </div>
</form>

<ng-template #confirm>
  <div class="visual-line"></div>
  <dm-ui-info-text [text]="customerEmail" [label]="'CUSTOMER_EMAIL' | translate"/>
  <div class="visual-line"></div>
  <dm-ui-info-box icon="exclamation-mark-circle" text="MAKE_POSTPAID_MODAL_FOOTNOTES" color="warning"/>
</ng-template>
