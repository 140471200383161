import { Component, EventEmitter, HostBinding, inject, Injector, Input, OnInit, Output } from '@angular/core';
import { FormView, MarinasService } from '@dm-workspace/core';
import { ResourceBookingBody, ResourceBookingResponse } from '@dm-workspace/types';
import { ConfirmModalService } from '@dm-workspace/ui';
import { MarinaFormsCreatorService } from '../../services/marina-forms-creator.service';
import { NotificationService } from '@dm-workspace/notification';
import { ResourceBookingApiService } from '@dm-workspace/data-access';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiValidatorService } from '@dm-workspace/forms';
import { filter, first, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

export interface INewBookingFormQueryParams {
  existingBoat?: string;
  customer?: string;
}

@Component({
  selector: 'dm-mms-bookings-new-booking-form',
  templateUrl: './new-booking-form.component.html',
  styleUrls: ['./new-booking-form.component.scss'],
})
export class NewBookingFormComponent extends FormView<ResourceBookingBody, ResourceBookingResponse> implements OnInit {
  @Output() submitSuccess = new EventEmitter<boolean>();
  @HostBinding('class.is-modal-view')
  @Input()
  isModalView = false;

  public form = this.formCreator.createNewBookingForm();

  public columnClasses = 'col-sm-6 col-md-4 col-lg-2 mb-4';
  public wideColumnClasses = 'col-md-8 col-lg-4 mb-4';

  private activeModal?: NgbActiveModal;
  #marinasService = inject(MarinasService);
  protected selectedMarina$ = this.#marinasService.selectedMarina$;

  constructor(
    private formCreator: MarinaFormsCreatorService,
    private notification: NotificationService,
    private resourceBookingService: ResourceBookingApiService,
    private confirmModal: ConfirmModalService,
    private injector: Injector,
    apiValidator: ApiValidatorService
  ) {
    super(apiValidator);
  }

  public ngOnInit(): void {
    if (this.isModalView) {
      this.activeModal = this.injector.get(NgbActiveModal);
    }
  }

  apiCall(params: ResourceBookingBody): Observable<ResourceBookingResponse> {
    this.pending = false;
    return this.confirmModal
      .open({
        title: 'CREATE_NEW_BOOKING',
        body: 'CREATE_UNRESTRICTED_BOOKING_CONFIRM_MODAL',
      })
      .pipe(
        first(),
        filter((value) => value),
        tap((value) => (this.pending = true)),
        switchMap(() => this.resourceBookingService.createUnrestrictedBooking(params))
      );
  }

  getRequestParams(): ResourceBookingBody {
    const { fromDate, toDate, comments, contractType, existingBoat, customer, resourceId } = this.form.getRawValue();

    return {
      comments,
      contractType,
      resourceId: resourceId.id || resourceId,
      customerId: customer?.id || null,
      fromDate,
      toDate,
      existingBoatId: existingBoat?.id || null,
    };
  }

  onError(res: HttpErrorResponse) {
    super.onError(res);
    this.notification.add({
      text: 'ERRORS.BOOKING_NOT_ADDED',
      type: 'error',
    });
  }

  close(): void {
    this.activeModal.close();
  }

  onSuccess(res: ResourceBookingResponse) {
    super.onSuccess(res);

    this.resourceBookingService.triggerViewRefresh();

    this.notification.add({
      text: 'BOOKING_CREATED',
    });
    this.submitSuccess.emit();
    if (this.isModalView) {
      this.activeModal.close();
    }
  }
}
