import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { fadeAnimation } from '@dm-workspace/shared';
import { IResourceBookingQuotation, MmsDashboardStatName, QuotationStatus } from '@dm-workspace/types';
import { MmsDashboardApiService, ResourceBookingApiService } from '@dm-workspace/data-access';
import { MapDashboardChildView } from '../../class/mms-dashboard-child-view.class';
import { MmsDashboardViewService } from '../../services/mms-dashboard-view.service';
import { NotificationService } from '@dm-workspace/notification';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MarinasService } from '@dm-workspace/core';
import { MmsBoatDepartureModalComponent } from '@dm-workspace/mms-panel/shared';

@Component({
  selector: 'dm-mms-dashboard-unfinished-bookings',
  templateUrl: './mms-dashboard-unfinished-bookings.component.html',
  styleUrls: ['./mms-dashboard-unfinished-bookings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeAnimation],
})
export class MmsDashboardUnfinishedBookingsComponent extends MapDashboardChildView {
  public readonly quotationStatuses = QuotationStatus;
  protected override hasLoadMoreButton = true;
  protected override statName: MmsDashboardStatName = 'unfinishedBookings';
  constructor(
    cd: ChangeDetectorRef,
    dashboardService: MmsDashboardViewService,
    dashboardApiService: MmsDashboardApiService,
    resourceBookingApiService: ResourceBookingApiService,
    notification: NotificationService,
    marinaService: MarinasService,
    modal: NgbModal
  ) {
    super(cd, dashboardService, dashboardApiService, resourceBookingApiService, notification, marinaService, modal);
  }

  public override get hasBookingsTodo(): boolean {
    return this.stats?.todoCount > 0;
  }

  protected openDepartureModal(booking: IResourceBookingQuotation) {
    const modal = this.modal.open(MmsBoatDepartureModalComponent);
    const modalComponent = modal.componentInstance as MmsBoatDepartureModalComponent;
    modalComponent.boatId = booking.boat.id;
    modalComponent.resource = booking.resource;

    modalComponent.confirmed.subscribe((confirmed) => {
      if (!confirmed) {
        return;
      }
      this.removeFromBookings(booking.id);
    });
  }
  override getParams() {
    this.pagination.offset = this.pagination.offset - this.deleted;
    this.deleted = 0;
    return super.getParams();
  }
}
