<div class="card position-relative px-4 pb-4">
  <dm-mms-dashboard-list-done *ngIf="hasBookingsAllDone">
    {{ "MMS_DASHBOARD.CUSTOMER_DID_NOT_SHOW_DONE" | translate }}
  </dm-mms-dashboard-list-done>

  <ng-container *ngIf="hasBookingsTodo">
    <table class="table pb-3">
      <thead>
        <tr>
          <th class="max-width max-width--id">{{ "BOOKING_ID" | translate }}</th>
          <th>{{ "START" | translate }}&nbsp;/<br />{{ "END_DATE" | translate }}</th>
          <th>{{ "BOAT" | translate }}</th>
          <th>{{ "CONTACT" | translate }}</th>
          <th class="actions">{{ "ACTIONS" | translate }}</th>
        </tr>
      </thead>
      <tbody *ngIf="bookings?.length">
        <tr *ngFor="let booking of bookings">
          <td class="max-width max-width--id">{{ booking.humanReadableId }}</td>
          <td>{{ booking.fromDate | dateFormat }}<br />{{ booking.toDate | dateFormat }}</td>
          <td class="max-width max-width--boat">
            <dm-ui-boat-cell [boat]="booking.boat" [hideDetails]="true" />
          </td>
          <td>
            <span *ngIf="booking.customer?.firstName">{{ booking.customer | fullName }} <br /></span>
            <span *ngIf="booking.customer?.phone"
              >{{ "PHONE" | translate }}: {{ booking.customer?.phone | empty }}<br
            /></span>
            <a href="mailto:{{ booking.customer?.email }}" class="link" *ngIf="booking.customer?.email">{{
              booking.customer?.email | empty
            }}</a>
          </td>
          <td class="actions actions--hide-overflow">
            <button
              *ngIf="booking.toDate | isNowBeforeDatesEndOfDay; else markAsCompleted"
              class="btn-pill-primary-light"
              type="button"
              (click)="openArrivalModal(booking)"
              [title]="'REPORT_ARRIVAL' | translate"
            >
              {{ "REPORT_ARRIVAL" | translate }}
            </button>
            <ng-template #markAsCompleted>
              <button
                type="button"
                class="btn-pill-primary-light"
                (click)="changeStatus(booking, quotationStatuses.COMPLETED)"
                [title]="'MARK_AS_COMPLETED' | translate"
              >
                <dm-ui-overflow-spinner
                  [showSpinner]="pendingMarkCompleted[booking.humanReadableId]"
                  type="absolute"
                  position="center"
                  size="sm"
                 />
                {{ "MARK_AS_COMPLETED" | translate }}
              </button>
            </ng-template>
          </td>
        </tr>
      </tbody>
    </table>

    <dm-ui-load-more-button
      [collectionSize]="pagination.totalCount"
      [offset]="pagination.offset"
      [rowsPerLoad]="pagination.limit"
      (pageChange)="onPageChange($event)"
      [isPending]="this.pending"
      >{{ "SHOW_MORE" }}</dm-ui-load-more-button
    >
  </ng-container>

  <dm-ui-overflow-spinner
    type="absolute"
    [showSpinner]="pending"
    [position]="pagination.offset > 0 ? 'bottom' : 'center'"
   />
</div>
