<dm-mms-ui-full-page>
  <dm-mms-ui-full-page-header class="align-items-center" [returnUrl]="MARINA_URL_TOKEN + '/renewals' | replaceMarinaTokenWithSelectedMarinaCode | async">
    <i class="i-contract i-oval i-oval--border me-2"></i>
    <h1 class="typo-header-xxl">
      {{ "OFFER" | translate }}: {{ offer()?.name }}
    </h1>
  </dm-mms-ui-full-page-header>

  <div class="mt-2">
    <router-outlet/>
  </div>

  <dm-ui-overflow-spinner [showSpinner]="fetchingOffer()" />
</dm-mms-ui-full-page>
