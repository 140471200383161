import {
  BerthAvailabilityOccupationDto,
  BerthAvailabilityReservationDto,
  BerthBoatAlertStatus,
  BerthOccupancyDetails,
  BerthOccupancyStatusInMarina,
  BoatAbsenceMapResponseDto,
  GroupedPylons,
  IBoatAddMovementPayload,
  IBoatAlertResponseDtoStatusEnum,
  IBoatDepartureArrivalPayload,
  IMarinaPylonsResponse,
  MapBoatStatusInMarina,
} from '@dm-workspace/types';
import { effect, inject, input } from '@angular/core';
import { MapBerthClass } from '../../berths.class';
import { filter, first, switchMap } from 'rxjs';
import {
  BoatMovementService,
  MmsBoatMovementChangeGapModalComponent,
  MmsBookingMediaAccessModalComponent,
} from '@dm-workspace/mms-panel/shared';
import { MmsEnquiryQuickQuotationViewRouterState } from '../../../../../mms-panel/mms-enquiry/src/lib/views/mms-enquiry-quick-quotation-view/mms-enquiry-quick-quotation-view.component';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MapMmiComponentToken } from '@dm-workspace/marina-map';
import { MarinasService } from '@dm-workspace/core';
import { MmsSocketConnectModalComponent } from '../../../../../mms-panel/shared/src/lib/modals/mms-socket-connect-modal/mms-socket-connect-modal.component';
import { ConfirmModalService, IConfirmHandOverModalOptions } from '@dm-workspace/ui';
import { ContractsApiService } from '@dm-workspace/data-access';
import { NotificationService } from '@dm-workspace/notification';
import { MmsSocketTurnOffOnModalComponent } from '../../../../../mms-panel/shared/src/lib/modals/mms-socket-turn-off-on-modal/mms-socket-turn-off-on-modal.component';
import { diffDatesInDays } from '@dm-workspace/utils';

export class BerthDrawerDetailsClass {
  berth = input.required<MapBerthClass>();
  extendedBookingsData = input.required<BerthAvailabilityReservationDto[]>();
  pylon = input<GroupedPylons>();

  #router = inject(Router);
  #mapMmiComponent = inject(MapMmiComponentToken);
  #boatsMovementService = inject(BoatMovementService);
  #marinasService = inject(MarinasService);
  #confirmModalService = inject(ConfirmModalService);
  #contractService = inject(ContractsApiService);
  #notificationService = inject(NotificationService);
  protected modalService = inject(NgbModal);

  constructor() {
    effect(
      () => {
        if (!this.berth() && !this.pylon()) {
          this.closeDrawer();
        }
      },
      { allowSignalWrites: true }
    );
  }

  getOccupancyListDetails(): BerthOccupancyDetails[] {
    const data = this.berth().data;
    const berthBoatsBookingData = this.extendedBookingsData();

    const occupancyDetailsMap: Map<string, BerthAvailabilityOccupationDto> = [
      ...data.expectedArrivals,
      ...data.temporaryAbsence,
      ...data.expectedDepartures,
      ...data.occupancy,
    ].reduce((p, c) => p.set(c.boatId, c), new Map<string, BerthAvailabilityOccupationDto>());
    const bookings = [...(berthBoatsBookingData ?? []), ...data.expectedArrivals, ...data.expectedDepartures];
    return Array.from(occupancyDetailsMap.values())
      .map((occupancy) => {
        return {
          ...occupancy,
          temporaryAbsence: data.temporaryAbsence.find((temporaryA) => temporaryA.boat?.id === occupancy.boat?.id),
          booking: bookings.find(({ boat }) => boat?.id === occupancy.boat?.id),
          eligibleContract: data.eligibleContracts.find((eli) => eli.id === occupancy.boatId) ?? null,
          sensorSubscription: data.sensorSubscriptions.find((sensor) => sensor.boat.id === occupancy.boatId) ?? null,
          alerts: data.alerts.filter((alert) => alert.boatId === occupancy.boatId) ?? null,
          boatIsInBerth: !!data.occupancy.find(({ boat }) => boat.id === occupancy.boat?.id),
          expectedDepartures: data.expectedDepartures.some(
            (eDepartures) => eDepartures.boat?.id === occupancy.boat?.id
          ),
          expectedArrivals: data.expectedArrivals.some((eArrivals) => eArrivals.boat?.id === occupancy.boat?.id),
        };
      })
      .sort((x, y) => (!!x.booking === !!y.booking ? 0 : x.booking ? -1 : 1));
  }

  temporaryAbsence(boatId: string): BoatAbsenceMapResponseDto {
    return this.berth().data.temporaryAbsence.find((value) => value.boatId === boatId);
  }
  boatIsInOccupancy(boatId: string): boolean {
    return this.berth().data.occupancy.some((value) => value.boatId === boatId);
  }
  occupancyBoatIsInMarina(boatId: string): boolean {
    const bookingBoat = this.berth().data.occupancy?.find((booking) => booking.boat.id === boatId);
    return bookingBoat ? bookingBoat.boat.statusInMarina === MapBoatStatusInMarina.IN_MARINA : false;
  }

  bookingBoatIsInMarina(boatId: string): boolean {
    const bookingBoat = this.berth().data.bookings?.find((booking) => booking.boat.id === boatId);
    return bookingBoat ? bookingBoat.boat.statusInMarina === MapBoatStatusInMarina.IN_MARINA : false;
  }

  getBoatSensor(occupancy?: BerthOccupancyDetails): BerthBoatAlertStatus {
    if (!occupancy) {
      return BerthBoatAlertStatus.empty;
    }
    if (occupancy.eligibleContract) {
      return BerthBoatAlertStatus.eligible;
    }
    if (!occupancy.sensorSubscription) {
      return BerthBoatAlertStatus.noSubscription;
    }
    if (
      occupancy.alerts.length > 0 &&
      occupancy.alerts.some(
        (value) =>
          value.status === IBoatAlertResponseDtoStatusEnum.Active ||
          value.status === IBoatAlertResponseDtoStatusEnum.InProgress
      )
    ) {
      return BerthBoatAlertStatus.activeAlert;
    }
    return BerthBoatAlertStatus.noAlert;
  }

  protected openHandOverPopup(customerEmail: string, contractId: string): void {
    const modalContent: IConfirmHandOverModalOptions = {
      title: `HAND_OVER_NOW_CONFIRMATION`,
      body: `HAND_OVER_NOW_CONFIRMATION_DESCRIPTION`,
      customerEmail,
    };

    this.#confirmModalService
      .openHandOverConfirmModal(modalContent)
      .pipe(
        first(),
        filter(Boolean),
        switchMap((modalRes) => {
          return this.#contractService.handOver([contractId, false], modalRes !== true ? modalRes.phone : null);
        })
      )
      .subscribe({
        next: () => {
          this.#notificationService.add({ text: 'HAND_OVER_SUCCESS', type: 'success' });
          this.#mapMmiComponent.refreshMapData(false);
        },
        error: (e) =>
          this.#notificationService.add({
            text: 'HAND_OVER_ERRORED',
            content: e?.error?.detailedErrorCode || e?.error?.errorCode,
            type: 'error',
          }),
      });
  }

  public openBoatMovementModal(movementPayload: IBoatAddMovementPayload) {
    const boatBooking = this.berth().data?.bookings?.find((booking) => booking.boatId === movementPayload.boat.id);
    this.#boatsMovementService
      .openBoatMovementModal(movementPayload, boatBooking?.humanReadableId)
      .pipe(filter(Boolean), first())
      .subscribe(() => this.#mapMmiComponent.refreshMapData(false));
  }
  public openDepartureBoatModal(departure: IBoatDepartureArrivalPayload) {
    this.#boatsMovementService
      .openDepartureBoatModal(departure)
      .pipe(filter(Boolean), first())
      .subscribe(() => this.#mapMmiComponent.refreshMapData(false));
  }
  public openArrivalBoatModal(departure: IBoatDepartureArrivalPayload) {
    this.#boatsMovementService
      .openArrivalBoatModal(departure)
      .pipe(filter(Boolean), first())
      .subscribe(() => this.#mapMmiComponent.refreshMapData(false));
  }
  public openTransferBookingModal(transferPayload: BerthAvailabilityReservationDto) {
    this.#boatsMovementService
      .openTransferBookingModal({
        ...transferPayload,
        resource: { id: transferPayload.resourceId, name: this.berth().data.berthName },
      })
      .pipe(filter(Boolean), first())
      .subscribe(() => this.#mapMmiComponent.refreshMapData(false));
  }

  public openPylonConnectionModal(enableUtilitiesPayload: BerthAvailabilityReservationDto): void {
    const modal = this.modalService.open(MmsSocketConnectModalComponent, {
      size: 'md',
    });

    (modal.componentInstance as MmsSocketConnectModalComponent).humanReadableId =
      enableUtilitiesPayload.humanReadableId;
    modal.closed.pipe(filter(Boolean), first()).subscribe((socket) => {
      this.#mapMmiComponent.initSocket.set(socket);
      this.#mapMmiComponent.socketStateChanged.set(true);
      this.#mapMmiComponent.refreshMapData(false);
    });
  }

  public openEnableUtilitiesBookingModal(enableUtilitiesPayload: BerthAvailabilityReservationDto): void {
    const modal = this.modalService.open(MmsBookingMediaAccessModalComponent, {
      size: 'md',
    });
    (modal.componentInstance as MmsBookingMediaAccessModalComponent).bookingId = enableUtilitiesPayload.id;
    modal.closed.pipe(filter(Boolean)).subscribe(() => {
      this.#mapMmiComponent.refreshMapData(false);
    });
  }

  onChangeSocketConnectStatus(socket: IMarinaPylonsResponse) {
    const modal = this.modalService.open(MmsSocketTurnOffOnModalComponent, {
      size: 'md',
    });
    const componentInstance = modal.componentInstance as MmsSocketTurnOffOnModalComponent;
    componentInstance.socket = socket;
    modal.closed.pipe(filter(Boolean), first()).subscribe(() => {
      this.#mapMmiComponent.initSocket.set(socket);
      this.#mapMmiComponent.socketStateChanged.set(true);
      this.#mapMmiComponent.refreshMapData(false);
    });
  }

  public openAddEditGapModal(temporaryAbsence: BoatAbsenceMapResponseDto) {
    const { expectedReturnDate, movementOperationDate, movementId, gapEnd, gapStart } = temporaryAbsence;
    const reservation = this.berth().data.bookings.find((value) => value.boatId === temporaryAbsence.boatId);
    const modal = this.modalService.open(MmsBoatMovementChangeGapModalComponent, {
      size: 'lg',
    });
    const modalInstance = modal.componentInstance as MmsBoatMovementChangeGapModalComponent;

    modalInstance.expectedReturnDate.set(expectedReturnDate);
    modalInstance.movementOperationDate.set(movementOperationDate);
    modalInstance.gapStart.set(gapStart);
    modalInstance.gapEnd.set(gapEnd);
    modalInstance.movementId.set(movementId);
    if (reservation) {
      modalInstance.reservation.set(reservation);
    }

    modal.closed.pipe(filter(Boolean)).subscribe(() => {
      this.#mapMmiComponent.refreshMapData(false);
    });
  }

  goToQQ(boatId?: string, resourceId?: string) {
    const state: MmsEnquiryQuickQuotationViewRouterState = {
      berthId: this.berth().data.berthId,
    };
    if (boatId) {
      state.boatId = boatId;
    }
    if (resourceId) {
      state.resourceId = resourceId;
    }
    if (
      this.berth().data.temporaryAbsence.length &&
      (this.berth().occupancyStatus.includes(BerthOccupancyStatusInMarina.temporaryAbsenceCruise) ||
        this.berth().occupancyStatus.includes(BerthOccupancyStatusInMarina.temporaryAbsenceDryDock))
    ) {
      const { gapStart, gapEnd, expectedReturnDate } = this.berth().data.temporaryAbsence[0];
      state.dateRange = {
        fromDate:
          diffDatesInDays(gapStart, new Date().toISOString().split('T')[0]) < 0
            ? gapStart.split('T')[0]
            : new Date().toISOString().split('T')[0],
        toDate: (gapEnd || expectedReturnDate).split('T')[0],
      };
    }
    this.#router.navigate(['/', this.#marinasService.selectedMarina().code, 'enquiry', 'quick-quotation'], {
      state: {
        returnUrl: this.#router.url,
        ...state,
      },
    });
  }

  closeDrawer(): void {
    this.#mapMmiComponent.onMapClick();
  }
}
