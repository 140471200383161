import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IResourceBookingBoat, IResourceBookingBoatDimensions } from '@dm-workspace/types';

@Component({
  selector: 'dm-mms-table-cell-boat',
  templateUrl: './mms-table-cell-boat.component.html',
  styleUrls: ['./mms-table-cell-boat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsTableCellBoatComponent {
  @Input() boat?: Partial<Pick<IResourceBookingBoat, 'id' | 'type' | 'length' | 'name' | 'maxBeam'>>;
  @Input() boatDimensions?: IResourceBookingBoatDimensions;
}
