import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { NgbActiveModal, NgbCalendar, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ContractsApiService } from '@dm-workspace/data-access';
import { NotificationService } from '@dm-workspace/notification';
import { FormBuilder } from '@angular/forms';
import { DATE_FORMAT_PLACEHOLDER } from '@dm-workspace/shared';
import { IContractConnectedPylonData, IMakePostpaidContractPayload } from '@dm-workspace/types';
import { ApiValidatorService } from '@dm-workspace/forms';
import { FormView } from '@dm-workspace/core';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'dm-mms-shared-make-postpaid-modal',
  templateUrl: './make-postpaid-modal.component.html',
  styleUrls: ['./make-postpaid-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MakePostpaidModalComponent extends FormView<IMakePostpaidContractPayload> {
  @Input() contractId: string;
  @Input() customerEmail: string;
  @Input() set pylonData(data: IContractConnectedPylonData[]) {
    const hasPylonData = data && data.length > 0;

    if (hasPylonData) {
      this.hasPylonData = hasPylonData;
      this.form.controls.pylonOutputUsages.clear();
      data.forEach((pylonData) => this.form.controls.pylonOutputUsages.push(this.createPylonOutputUsage(pylonData)));
    }
  }

  public confirmEmail = false;
  public hasPylonData = false;
  public override form = this.fb.group({
    pylonStartDate: [this.today],
    pylonOutputUsages: this.fb.array([this.createPylonOutputUsage(), this.createPylonOutputUsage()]),
  });
  public datePlaceholderFormat = DATE_FORMAT_PLACEHOLDER;

  constructor(
    protected adapter: NgbDateAdapter<string>,
    protected override apiValidator: ApiValidatorService,
    protected activeModal: NgbActiveModal,
    private calendar: NgbCalendar,
    private cd: ChangeDetectorRef,
    private contractsService: ContractsApiService,
    private notification: NotificationService,
    private fb: FormBuilder
  ) {
    super(apiValidator);
  }

  private createPylonOutputUsage(pylonData?: IContractConnectedPylonData) {
    const { pylonCode, socketNumber } = pylonData || {};
    return this.fb.group({
      pedestalId: [pylonCode],
      socketNumber: [socketNumber],
    });
  }

  protected get now(): string {
    return new Date().toISOString();
  }

  protected get firstDayOfMonth(): string {
    const date = new Date();
    date.setUTCDate(1);
    date.setUTCHours(0, 0, 0, 0);
    return date.toISOString();
  }

  private get today(): string {
    return this.adapter.toModel(this.calendar.getToday());
  }

  nextAction(): void {
    if (this.apiValidator.formIsValid(this.form)) {
      this.confirmEmail = true;
    }
  }

  protected override parseForm(formData: IMakePostpaidContractPayload): IMakePostpaidContractPayload {
    const { pylonStartDate, pylonOutputUsages } = formData;
    return {
      pylonStartDate,
      pylonOutputUsages: pylonOutputUsages.filter(
        (pylonOutputUsage) => pylonOutputUsage.pedestalId || pylonOutputUsage.socketNumber
      ),
    };
  }

  protected override apiCall(params: IMakePostpaidContractPayload): Observable<void> {
    return this.contractsService.makePostpaid(this.contractId, params);
  }

  protected override onSuccess(): void {
    this.activeModal.close(true);
    this.notification.add({ text: 'MAKE_POSTPAID_SUCCESS', type: 'success' });
  }

  protected override onError(error: HttpErrorResponse) {
    super.onError(error);

    this.confirmEmail = false;
    this.pending = false;
    this.cd.detectChanges();
    this.notification.add({
      text: 'MAKE_POSTPAID_ERROR',
      content: error?.error?.detailedErrorCode || error?.error?.errorCode,
      type: 'error',
    });
  }

  public closeModal(): void {
    this.activeModal.close(false);
  }
}
