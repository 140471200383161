import { Pipe, PipeTransform } from '@angular/core';
import { OrderProduct } from '@dm-workspace/types';

@Pipe({
  name: 'productsSum',
})
export class MmsProductsSumPipe implements PipeTransform {
  transform(products: OrderProduct[] | undefined): number {
    if (!products?.length) {
      return;
    }

    return products.reduce((previous, product) => {
      return previous + product.totalPriceGross;
    }, 0);
  }
}
