import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { MmsDashboardViewService } from '../services/mms-dashboard-view.service';
import { filter, map } from 'rxjs';
import { MmsDashboardPathStatNameArray } from '../class/mms-dashboard-stats-view.directive';

export function mmsDashboardRedirectToUnfinishedStat(pathStatNameArray: MmsDashboardPathStatNameArray): CanActivateFn {
  return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const dashboardService = inject(MmsDashboardViewService);
    const router = inject(Router);

    return dashboardService.stats$.pipe(
      filter(Boolean),
      map((stats) => {
        const firstUnfinishedStat = pathStatNameArray.find(
          (pathStatName) => stats[pathStatName.statName]?.todoCount > 0
        );
        const { path } = firstUnfinishedStat ?? pathStatNameArray[0];
        return router.parseUrl(`${state.url}/${path}`);
      })
    );
  };
}
