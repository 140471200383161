import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

import {
  BerthAvailabilityReservationDto,
  BoatMovementOperation,
  GroupedPylons,
  PylonsStatusInMarina,
  BoatPylonInfo,
  ResourceBookingProduct,
} from '@dm-workspace/types';
import { BerthDrawerDetailsClass } from '../berth-drawer-details.class';
import { MapBerthClass } from '@dm-workspace/marina-map';

@Component({
  selector: 'dm-map-berth-details-pylons-list',
  templateUrl: './map-berth-details-pylons-list.component.html',
  styleUrl: '../map-berth-details-list-styles.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapBerthDetailsPylonsListComponent extends BerthDrawerDetailsClass {
  berth = input.required<MapBerthClass>();
  pylon = input.required<GroupedPylons>();
  marinaCode = input.required<string>();
  extendedBookingsData = input.required<BerthAvailabilityReservationDto[]>();
  berthBoatsPylonsInfo = input.required<BoatPylonInfo[]>();

  hasItemsToShow = computed(() => this.berth().data.bookings.length);
  bookingsPylonsList = computed<BerthAvailabilityReservationDto[]>(() => {
    return [...(this.extendedBookingsData() ?? [])].map((item) => {
      return {
        ...item,
        pylonConnection: this.berthBoatsPylonsInfo()?.find((pylon) => pylon.id === item.boatId) ?? null,
      };
    });
  });

  public getBoatHaveUtilities(bookingPylon: BerthAvailabilityReservationDto) {
    const availableProducts: ResourceBookingProduct[] = [];
    bookingPylon.bookedProducts.map((product) => {
      if (
        (product.product === 'WATER' ||
          product.product === 'ELECTRICITY' ||
          product.product === 'ELECTRICITY_AND_WATER') &&
        product.isAlreadyPaid
      ) {
        availableProducts.push(product);
      }
    });
    return availableProducts;
  }

  protected readonly BoatMovementOperation = BoatMovementOperation;
  protected readonly pylonStatus = PylonsStatusInMarina;
}
