import { Component, HostBinding, Inject } from '@angular/core';
import { APP_TOKEN, AppToken } from '@dm-workspace/types';

@Component({
  selector: 'dm-mms-root',
  templateUrl: './mms-app.component.html',
  styleUrls: ['./mms-app.component.scss'],
})
export class MmsAppComponent {
  @HostBinding('class') get appClass(): string {
    return this.appToken.toLowerCase();
  }

  constructor(@Inject(APP_TOKEN) private appToken: AppToken) {}
}
