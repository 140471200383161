import { Pipe, PipeTransform } from '@angular/core';
import { PermissionService } from '@dm-workspace/core';
import { Observable } from 'rxjs';
import { Permissions } from '@dm-workspace/types';

@Pipe({
  name: 'hasPermission',
})
export class HasPermissionPipe implements PipeTransform {
  constructor(private permissionService: PermissionService) {}

  public transform(permissions: Permissions): Observable<boolean> {
    return this.permissionService.checkUserPermission(permissions);
  }
}
