import { Pipe, PipeTransform } from '@angular/core';
import { QuoteStatus } from '@dm-workspace/types';

@Pipe({
  name: 'offerStatusIconClass',
})
export class OfferStatusIconClassPipe implements PipeTransform {
  public transform(quoteStatus: QuoteStatus): string {
    switch (quoteStatus) {
      case QuoteStatus.ACCEPTED:
        return 'i-check-circle';
      case QuoteStatus.DECLINED:
      case QuoteStatus.CANCELLED:
        // case QuoteStatus.EXPIRED:
        return 'i-close-circle';
      // case QuoteStatus.DRAFT:
      case QuoteStatus.NEW:
      case QuoteStatus.SENT:
        return 'i-exclamation-mark-circle';
    }
  }
}
