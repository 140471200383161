import {
  BerthBoatAlertStatus,
  BerthAvailabilityOccupationDto,
  BerthDimensions,
  BerthMaintenanceStatusInMarina,
  BerthMapResponseDto,
  BerthOccupancyDetails,
  BerthOccupancyStatusInMarina,
  BerthPylonsStatusInMarina,
  BerthReservationsStatusInMarina,
  BerthStatusInMarina,
  ListMarinaPylonsResponseDto,
} from '@dm-workspace/types';
import {
  diffDatesInDays,
  getAlertMapStatus,
  getOccupancyMapStatus,
  getPylonsMapStatus,
  getReservationMapStatus,
  MapViewTypes,
} from '@dm-workspace/utils';
import { getBerthColorByStatus } from '@dm-workspace/map-utils';

export class MapBerthClass {
  alertStatus!: BerthBoatAlertStatus[];
  occupancyStatus!: BerthOccupancyStatusInMarina[];
  reservationStatus!: BerthReservationsStatusInMarina[];
  pylonsStatus!: BerthPylonsStatusInMarina[];
  constructor(
    public data: BerthMapResponseDto,
    public dimensions: BerthDimensions,
    private marinaCode: string
  ) {
    this.data.bookings.sort((a, b) => diffDatesInDays(b.fromDate, b.toDate) - diffDatesInDays(a.fromDate, a.toDate));
    this.alertStatus = getAlertMapStatus(this.data);
    this.occupancyStatus = getOccupancyMapStatus(this.data);
    this.reservationStatus = getReservationMapStatus(this.data, this.marinaCode);
    this.pylonsStatus = [];
  }
  getStatusesByViewType(viewType: MapViewTypes): BerthStatusInMarina[] {
    if (this.data.isInMaintenance) {
      return [BerthMaintenanceStatusInMarina.disabled];
    }
    switch (viewType) {
      case MapViewTypes.occupancy:
        return this.occupancyStatus;
      case MapViewTypes.sensors:
        return this.alertStatus;
      case MapViewTypes.pylons:
        return this.pylonsStatus;
      default:
        return this.reservationStatus;
    }
  }
  getColorByViewType(viewType: MapViewTypes) {
    return getBerthColorByStatus(this.getStatusesByViewType(viewType)[0]);
  }

  visibleStatus(viewType: MapViewTypes, filters: BerthStatusInMarina[]): BerthStatusInMarina | undefined {
    return this.getStatusesByViewType(viewType).find((status) => filters.includes(status));
  }
  getOccupancyListDetails(): BerthOccupancyDetails[] {
    const data = this.data;

    const occupancyDetailsMap: Map<string, BerthAvailabilityOccupationDto> = [
      ...data.expectedArrivals,
      ...data.temporaryAbsence,
      ...data.expectedDepartures,
      ...data.occupancy,
    ].reduce((p, c) => p.set(c.boatId, c), new Map<string, BerthAvailabilityOccupationDto>());
    const bookings = [...data.bookings, ...data.expectedArrivals, ...data.expectedDepartures];
    return Array.from(occupancyDetailsMap.values())
      .map((occupancy) => ({
        ...occupancy,
        temporaryAbsence: data.temporaryAbsence.find((temporaryA) => temporaryA.boat?.id === occupancy.boat?.id),
        booking: bookings.find(
          ({ resourceId, boat }) =>
            boat?.id === occupancy.boat?.id && (!occupancy.resourceId || occupancy.resourceId === resourceId)
        ),
        boatIsInBerth: !!data.occupancy.find(({ boat }) => boat.id === occupancy.boat?.id),
        expectedDepartures: data.expectedDepartures.some((eDepartures) => eDepartures.boat?.id === occupancy.boat?.id),
        expectedArrivals: data.expectedArrivals.some((eArrivals) => eArrivals.boat?.id === occupancy.boat?.id),
      }))
      .sort((x, y) => (!!x.booking === !!y.booking ? 0 : x.booking ? -1 : 1));
  }
  updatePylonStatus(pylon?: ListMarinaPylonsResponseDto) {
    this.pylonsStatus = getPylonsMapStatus(pylon);
  }
}
