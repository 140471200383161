import { Quote } from '@dm-workspace/types';
import {
  MmsOfferActionSendModalComponent,
  MmsOfferActionSendTarget,
} from '../modals/mms-offer-action-send-modal/mms-offer-action-send-modal.component';
import { MmsOfferActionSendReminderModalComponent } from '../modals/mms-offer-action-send-reminder-modal/mms-offer-action-send-reminder-modal.component';
import { MmsOfferActionAcceptModalComponent } from '../modals/mms-offer-action-accept-modal/mms-offer-action-accept-modal.component';
import { MmsOrderActionSendPaymentReminderModalComponent } from '../../../../mms-orders/src/lib/modals/mms-order-action-send-payment-reminder-modal/mms-order-action-send-payment-reminder-modal.component';
import { MmsOrderActionMarkAsPaidModalComponent } from '../../../../mms-orders/src/lib/modals/mms-order-action-mark-as-paid/mms-order-action-mark-as-paid-modal.component';
import {
  MmsQuoteActionCancelModalComponent,
  MmsQuoteActionDeclineModalComponent,
} from '@dm-workspace/mms-panel/shared';
import { inject, Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Injectable({
  providedIn: 'root',
})
export class MmsOfferActionsService {
  readonly #modal = inject(NgbModal);
  public clickButtonSendOffer(offer: Quote, target: MmsOfferActionSendTarget) {
    const modalComponent = this.#modal.open(MmsOfferActionSendModalComponent, {
      size: 'md',
    }).componentInstance as MmsOfferActionSendModalComponent;
    modalComponent.offer = offer;
    modalComponent.target = target;
    return modalComponent.confirmed;
  }

  public clickButtonSendReminderOffer(offer: Quote, target: MmsOfferActionSendTarget) {
    const modalComponent = this.#modal.open(MmsOfferActionSendReminderModalComponent, {
      size: 'md',
    }).componentInstance as MmsOfferActionSendReminderModalComponent;
    modalComponent.offer = offer;
    modalComponent.target = target;
    return modalComponent.confirmed;
  }

  public clickButtonAcceptOffer(offer: Quote) {
    const modalComponent = this.#modal.open(MmsOfferActionAcceptModalComponent, {
      size: 'md',
    }).componentInstance as MmsOfferActionAcceptModalComponent;
    modalComponent.offer = offer;
    return modalComponent.confirmed;
  }

  public clickButtonSendPaymentReminder(offer: Quote) {
    const modalComponent = this.#modal.open(MmsOrderActionSendPaymentReminderModalComponent, {
      size: 'md',
    }).componentInstance as MmsOrderActionSendPaymentReminderModalComponent;
    modalComponent.orderId = offer.order.id;
    return modalComponent.confirmed;
  }

  public clickButtonMarkOrderAsPaid(offer: Quote) {
    const modalComponent = this.#modal.open(MmsOrderActionMarkAsPaidModalComponent, {
      size: 'md',
    }).componentInstance as MmsOrderActionMarkAsPaidModalComponent;
    modalComponent.orderId = offer.order.id;
    return modalComponent.confirmed;
  }

  public clickButtonCancelOffer(offer: Quote) {
    const modalComponent = this.#modal.open(MmsQuoteActionCancelModalComponent, { size: 'lg' })
      .componentInstance as MmsQuoteActionCancelModalComponent;
    modalComponent.quote = offer;
    modalComponent.isCustomer = !!offer.customer;
    return modalComponent.confirmed;
  }

  public clickButtonDeclineOffer(offer: Quote) {
    const modalComponent = this.#modal.open(MmsQuoteActionDeclineModalComponent, {
      size: 'md',
    }).componentInstance as MmsQuoteActionDeclineModalComponent;
    modalComponent.quote = offer;
    return modalComponent.confirmed;
  }
}
