import { Pipe, PipeTransform } from '@angular/core';
import { BookingAvailableActions } from '@dm-workspace/types';

@Pipe({
  name: 'bookingActionTranslateKey',
})
export class MmsBookingActionTranslateKeyPipe implements PipeTransform {
  transform(action: BookingAvailableActions): string {
    return MmsBookingActionTranslateKeyPipe.getTranslateKey(action);
  }

  static getTranslateKey(action: BookingAvailableActions): string {
    if (!action) {
      return '';
    }
    return `BOOKING_ACTION_.${action}`;
  }
}
