<div class="date column">
  <ng-content select="[date]" />
</div>
<div class="line column">
  <div class="thumbnail">
    <ng-content select="[thumbnail]" />
  </div>
</div>
<div class="content column">
  <ng-content select="[content]" />
</div>
