import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { emailValidator } from '@dm-workspace/forms';

@Injectable()
export class MarinaFormsCreatorService {
  constructor(private fb: UntypedFormBuilder) {}

  createNewBookingForm() {
    return this.fb.group({
      fromDate: [null, Validators.required],
      toDate: [null, Validators.required],
      comments: [null],
      resourceId: [null, Validators.required],
      existingBoat: [null, Validators.required],
      customer: [null, Validators.required],
      searchInGaps: [null],
    });
  }

  createNewCustomerBookingForm() {
    return this.fb.group({
      email: [null, [Validators.required, emailValidator]],
      lastName: [null, Validators.required],
      firstName: [null, Validators.required],
    });
  }

  public createBookingDetailsForm(): UntypedFormGroup {
    return this.fb.group({
      berth: [null, Validators.required],
      fromDate: [null, Validators.required],
      toDate: [null, Validators.required],
      contractType: [null, Validators.required],
      comments: null,
    });
  }
}
