<div ngbDropdown class="d-inline-block list-dropdown">
  <button type="button" class="action-button" id="actionsDropdown" ngbDropdownToggle></button>
  <div ngbDropdownMenu aria-labelledby="actionsDropdown">
    <a ngbDropdownItem [selectedMarinaRouterLink]="['/bookings', booking.id, 'details']" returnUrlCurrentPath>
      {{ "DETAILS" | translate }}
    </a>

    @if (booking.availableActions | arrayIncludes : "EDIT") {
      <a
        ngbDropdownItem
        [selectedMarinaRouterLink]="['/bookings', booking.id, 'edit']"
        returnUrlCurrentPath
        [state]="{ backOnClose: true }"
      >{{ "EDIT" | translate }}</a>
    }

    @if (booking.availableActions | arrayIncludes : "COPY") {
      <a
        ngbDropdownItem
        [selectedMarinaRouterLink]="['/bookings', booking.id, 'copy']"
        [state]="{ backOnClose: true }"
      >{{ "COPY" | translate }}</a>
    }

    @if (booking.availableActions | arrayIncludes : "SEND") {
      <a
        ngbDropdownItem
        (click)="changeStatus(booking, QuoteStatus.SENT, 'QUOTATION_SENT')"
      >
        {{ (booking.quotationStatus === QuotationStatus.SENT ? "RESEND_QUOTATION" : "SEND_QUOTATION") | translate }}
      </a>
    }

    @if (booking.availableActions | arrayIncludes : "CHECK_IN_OFFLINE") {
      <a
        ngbDropdownItem
        (click)="changeStatus(booking, QuoteStatus.CHECKED_OFFLINE, 'QUOTATION_CHECK_IN_OFFLINE')">
        {{ "CONFIRM_OFFLINE_CHECKIN" | translate }}
      </a>
    }

    @if (booking.availableActions | arrayIncludes : "COMPLETE") {
      <a
        ngbDropdownItem
        (click)="changeStatus(booking, QuoteStatus.COMPLETED, 'QUOTATION_COMPLETED')">
        {{ "MARK_AS_COMPLETED" | translate }}
      </a>
    }

    @if (booking.availableActions | arrayIncludes : "MARK_AS_PAID") {
      <a ngbDropdownItem (click)="openMarkAsPaidModal(booking)">
        {{ "MARK_AS_PAID" | translate }}
      </a>
    }

    @if (booking.availableActions | arrayIncludes : "MOVE_BOAT") {
      <a
        ngbDropdownItem
        (click)="moveBoat(booking)">
        {{ (isStatusForTransfer(booking.boat?.statusInMarina) ? "TRANSFER_BOOKING" : "MOVE_BOAT") | translate }}
      </a>
    }

    @if (booking.availableActions | arrayIncludes : "COMPLETE_CHECK_IN") {
      <a ngbDropdownItem returnUrlCurrentPath [selectedMarinaRouterLink]="['/bookings', booking.id, 'check-in']"
         [state]="{ backOnClose: true, content: 'documents' }"
      >{{ "CHECK_IN" | translate }}</a>
    }

    @if (booking.availableActions | arrayIncludes : "ARRIVAL") {
      <a ngbDropdownItem (click)="openModal('arrival', booking)">
        {{ "REPORT_ARRIVAL" | translate }}
      </a>
    }

    @if (booking.availableActions | arrayIncludes : "CRUISE") {
      <a ngbDropdownItem (click)="openModal('cruise', booking)">
        {{ "REPORT_CRUISE" | translate }}
      </a>
    }

    @if (booking.availableActions | arrayIncludes : "RETURN") {
      <a ngbDropdownItem (click)="openModal('return', booking)">
        {{ "REPORT_RETURN" | translate }}
      </a>
    }

    @if (booking.availableActions | arrayIncludes : "DEPARTURE") {
      <a ngbDropdownItem (click)="openDepartureModal(booking)">
        {{ "REPORT_DEPARTURE" | translate }}
      </a>
    }

    @if (booking.availableActions | arrayIncludes : "VERIFY_CRUISE") {
      <a ngbDropdownItem (click)="openModal('verifyCruise', booking)">
        {{ "CRUISE_VERIFICATION" | translate }}
      </a>
    }

    @if (booking.availableActions | arrayIncludes : "CANCEL") {
      <a
        ngbDropdownItem
        (click)="openModal('cancel', booking)">
        {{ "CANCEL" | translate }}
      </a>
    }
  </div>
</div>
