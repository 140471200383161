import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { BoatMovementOperationForStatus, IResourceBookingQuotation } from '@dm-workspace/types';
import { MmsBoatsApiService, ResourceBookingApiService } from '@dm-workspace/data-access';
import { NotificationService } from '@dm-workspace/notification';
import { DATE_FORMAT_PLACEHOLDER } from '@dm-workspace/shared';
import { NgModel } from '@angular/forms';
import { ApiValidatorService } from '@dm-workspace/forms';
import { min } from 'date-fns';
import { parseDate } from '@dm-workspace/utils';
import { BoatOperationModalClass } from '../../classes/boat-operation-modal.class';

@Component({
  selector: 'dm-mms-shared-boat-cruise-modal',
  templateUrl: './boat-cruise-modal.component.html',
  styleUrls: ['./boat-cruise-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoatCruiseModalComponent
  extends BoatOperationModalClass<IResourceBookingQuotation>
  implements OnInit, AfterViewInit
{
  @ViewChild('exitDate') exitDate: NgModel;
  @ViewChild('returnDate') returnDate: NgModel;

  operationType = BoatMovementOperationForStatus.CRUISING;
  successMessage = 'CRUISE_REPORTED';
  errorMessage = 'CRUISE_REPORT_ERROR';
  public datePlaceholderFormat = DATE_FORMAT_PLACEHOLDER;
  public minExitDate: string;
  public maxExitDate: string;
  public minReturnDate: string;
  public maxReturnDate: string;

  constructor(
    cd: ChangeDetectorRef,
    activeModal: NgbActiveModal,
    resourceBookingApi: ResourceBookingApiService,
    notification: NotificationService,
    resourceBookingService: ResourceBookingApiService,
    boatApi: MmsBoatsApiService,
    private apiValidator: ApiValidatorService,
    public adapter: NgbDateAdapter<string>
  ) {
    super(cd, activeModal, resourceBookingApi, notification, resourceBookingService, boatApi);
  }

  ngOnInit() {
    this.minExitDate = this.getMinExitDate();
    this.maxExitDate = this.getMaxExitDate();
    this.minReturnDate = this.getMinReturnDate();
    this.maxReturnDate = this.getMaxReturnDate();
  }

  ngAfterViewInit() {
    this.exitDate.control.valueChanges.subscribe(() => {
      this.minReturnDate = this.getMinReturnDate();
      this.maxReturnDate = this.getMaxReturnDate();
    });
  }

  protected override valid(): boolean {
    return (
      this.apiValidator.formIsValid(this.exitDate.control) && this.apiValidator.formIsValid(this.returnDate.control)
    );
  }
  private getMinExitDate(): string {
    const bookingFromDate = parseDate(this.booking?.fromDate);
    const maxDate = min([new Date(), bookingFromDate]);
    return maxDate.toISOString();
  }
  private getMaxExitDate(): string {
    const bookingToDate = parseDate(this.booking?.toDate);
    bookingToDate.setHours(12);
    const maxDate = min([new Date(), bookingToDate]);
    return maxDate.toISOString();
  }

  private getMinReturnDate(): string {
    const exitDateControlValue = this.exitDate?.control.value;
    const dates = [new Date()];
    if (exitDateControlValue) {
      dates.push(new Date(exitDateControlValue));
    }
    return min(dates).toISOString();
  }

  private getMaxReturnDate(): string {
    const bookingToDate = new Date(this.booking?.toDate);
    bookingToDate.setHours(12);
    return bookingToDate.toISOString();
  }
}
