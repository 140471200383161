import { Pipe, PipeTransform } from '@angular/core';
import { QuoteCrmStatus } from '@dm-workspace/types';

@Pipe({
  name: 'offerCrmStatusTranslateKey',
})
export class MmsOfferCrmStatusTranslateKeyPipe implements PipeTransform {
  public transform(crmStatus: QuoteCrmStatus | undefined): string {
    if (!crmStatus) {
      return;
    }
    return `${crmStatus.toUpperCase()}`;
  }
}
