@if (boatInfos && boatInfos.length > 0) {
  @if (boatInfos.length === 1 && boatInfos[0].type === BoatAlertStatusInBerth.empty) {
    <ng-template *ngTemplateOutlet="berthStatusEmpty" />
  } @else {
    <div class="d-flex align-items-start flex-column" [style.width]="'370px'">
      <div class="d-flex flex-grow-1 mt-3 ms-3">
        <dm-map-popup-row [label]="'PIER_BERTH' | translate" [txt]="name" [size]="size" />
      </div>
      <div class="w-100">
        <table class="table">
          <thead>
            <tr>
              <th class="number">{{ "NUMBER" | translate | titlecase }}</th>
              <th class="boatName">{{ "BOAT" | translate }}</th>
              <th>{{ "TYPE" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            @for (item of boatInfos; track item.id; let i = $index) {
              <tr>
                <td>
                  <span class="fw-500" [style.font-size]="'11px'">{{ i + 1 }}</span>
                </td>
                <td>
                  <a [routerLink]="['/', 'boats', item.id, 'information']" class="color-primary fw-600 border-0 link">{{
                    item.name
                  }}</a>
                </td>
                <td>
                  <dm-ui-tag [color]="item.type | tagColorByBerthStat" size="small" *ngIf="item.type">
                    {{ "MAP_BERTH_STATUS." + item.type | translate }}
                  </dm-ui-tag>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    </div>
  }
} @else {
  <ng-template *ngTemplateOutlet="berthStatus" />
}

<ng-template #berthStatus>
  <div class="d-flex align-items-center p-3">
    <div class="d-flex flex-grow-1 me-2">
      <dm-map-popup-row [label]="'PIER_BERTH' | translate" [txt]="name" [size]="size" />
    </div>
    <div class="statuses">
      @for (status of berthStatuses; track status) {
        <dm-ui-tag
          [color]="status | tagColorByBerthStat"
          size="normal"
          *ngIf="status"
          [ngClass]="{ 'mx-1': berthStatuses.length > 1 }"
        >
          {{ "MAP_BERTH_STATUS." + status | translate }}
        </dm-ui-tag>
      }
    </div>
  </div>
</ng-template>

<ng-template #berthStatusEmpty>
  <div class="d-flex align-items-center p-3">
    <div class="d-flex flex-grow-1 me-2">
      <dm-map-popup-row [label]="'PIER_BERTH' | translate" [txt]="name" [size]="size" />
    </div>
    <div class="statuses">
      <dm-ui-tag [color]="'black-light'" size="normal">
        {{ "MAP_BERTH_STATUS.EMPTY" | translate }}
      </dm-ui-tag>
    </div>
  </div>
</ng-template>
