import { Injectable, signal } from '@angular/core';
import { NotificationService } from '@dm-workspace/notification';
import { MmsQuotesApiService } from '@dm-workspace/data-access';
import { finalize, tap } from 'rxjs/operators';
import { ApiValidatorService } from '@dm-workspace/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { QuoteDetails } from '@dm-workspace/types';

@Injectable({
  providedIn: 'root',
})
export class MmsOfferViewService {
  private offerId?: string;
  public readonly offer = signal<QuoteDetails>(null);
  public readonly fetching = signal<boolean>(false);

  constructor(
    private apiValidatorService: ApiValidatorService,
    private notificationService: NotificationService,
    private offerApiService: MmsQuotesApiService
  ) {}

  public setOfferId(offerId: string) {
    this.offerId = offerId;
    this.fetchOffer().subscribe({
      next: (offer) => this.offer.set(offer),
      error: (res: HttpErrorResponse) => {
        const notificationContent = this.apiValidatorService.getApiNotificationError(res);
        this.notificationService.openError(notificationContent);
      },
    });
  }

  private fetchOffer() {
    this.fetching.set(true);
    return this.offerApiService.getById(this.offerId).pipe(finalize(() => this.fetching.set(false)));
  }

  public refreshOffers() {
    return this.fetchOffer().pipe(tap((offer) => this.offer.set(offer)));
  }

  public onDeactivateRoute() {
    this.offer.set(null);
    this.fetching.set(false);
    this.offerId = null;
  }
}
