<dm-ui-modal>
  <h2 header class="title">{{ "REPORT_DEPARTURE" | translate }}</h2>
  <div body>
    <dm-ui-info>
      <span icon class="icon i-info"></span>
      <p body>{{ "REPORTING_DEPARTURE_MAKE_BERTH_AVAILABLE" | translate }}</p>
    </dm-ui-info>

    <dm-ui-horizontal-line class="my-4"/>

    <p class="mb-4">
      {{ "CONFIRM_DEPARTURE_FROM" | translate }}:
      <strong>{{ "BERTH" | translate }} {{ form.value.source?.name }}</strong>
    </p>

    <div class="row" [formGroup]="form">
      <dm-form-group class="col-6">
        <label>{{ "SOURCE_BERTH" | translate }}</label>
        <dm-form-select-typeahead
          dmForm
          [clearable]="false"
          typeahead="resources"
          formControlName="source"
          bindLabel="name"
          [resourceType]="ResourceType.berth"
        />
        <dm-form-error-message controlName="destinationId" />
      </dm-form-group>
    </div>
  </div>

  <ng-container footer>
    <button class="btn btn-tertiary btn-narrow" type="button" (click)="onCancel()">
      {{ "CANCEL" | translate }}
    </button>

    <button type="button" class="btn btn-primary" (click)="submitForm()" [disabled]="apiCalling()">
      <dm-ui-spinner [showSpinner]="apiCalling()" class="me-2" />
      {{ "CONFIRM" | translate }}
    </button>
  </ng-container>
</dm-ui-modal>
