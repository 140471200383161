<div class="modal-header">
  <h1 class="title">{{ "NEW_BOAT" | translate }}</h1>
</div>

<div class="modal-body">
  <dm-form-boat-details-form [boat]="boat" (formReady)="onFormReady($event)" [insideModal]="true" />

  <dm-ui-overflow-spinner type="absolute" [showSpinner]="isPending" size="sm" />
</div>

<div class="modal-footer">
  <button class="btn btn-tertiary btn-narrow mr-auto" type="button" (click)="close()">
    {{ "CANCEL" | translate }}
  </button>
  <div class="buttons">
    <button type="button" class="btn btn-primary" (click)="onSubmit()" [disabled]="isPending">
      {{ "ADD_BOAT" | translate }}
    </button>
  </div>
</div>
