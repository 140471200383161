import { Component, Input } from '@angular/core';

@Component({
  selector: 'dm-mms-card-record',
  templateUrl: './mms-card-record.component.html',
  styleUrls: ['./mms-card-record.component.scss'],
})
export class MmsCardRecordComponent {
  @Input() label?: string;
  @Input() records: Record<string, string> | undefined;
  constructor() {}

  get getEntries(): [[string, string][], [string, string][]] {
    const entries = Object.entries(this.records);
    const middleIndex = Math.ceil(entries.length / 2); // Use Math.ceil to handle odd-length arrays
    const firstHalf = entries.slice(0, middleIndex);
    const secondHalf = entries.slice(middleIndex);
    return [firstHalf, secondHalf];
  }
}
