<dm-mms-bookings-resource-booking-form
  class="d-block mt-s"
  [form]="form"
  [marina]="selectedMarina$ | async"
  [apiErrors]="apiErrors"
  [columnClasses]="columnClasses"
  [wideColumnClasses]="wideColumnClasses"
/>
<hr class="visual-line" />
<dm-mms-bookings-boat-booking-form-card
  [form]="form"
  [apiErrors]="apiErrors"
  [columnClasses]="columnClasses"
  [wideColumnClasses]="wideColumnClasses"
/>
<hr class="visual-line" />
<dm-mms-bookings-customer-booking-form-card
  class="d-block mb-s"
  [form]="form"
  [columnClasses]="columnClasses"
  [wideColumnClasses]="wideColumnClasses"
/>
<div class="modal-footer" *ngIf="isModalView">
  <button class="btn btn-tertiary btn-narrow mr-auto" type="button" (click)="close()">
    {{ "CANCEL" | translate }}
  </button>
  <button type="button" class="btn btn-primary" (click)="onSubmit()">
    {{ "SAVE" | translate }}
  </button>
</div>

<dm-ui-overflow-spinner [showSpinner]="pending" type="fixed" />
