import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MmsBoatsApiService, ResourceBookingApiService } from '@dm-workspace/data-access';
import { NotificationService } from '@dm-workspace/notification';
import { BoatMovementOperationForStatus, IResourceBookingQuotation } from '@dm-workspace/types';
import { BoatOperationModalClass } from '../../classes/boat-operation-modal.class';

@Component({
  selector: 'dm-mms-shared-boat-return-modal',
  templateUrl: './boat-return-modal.component.html',
  styleUrls: ['./boat-return-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoatReturnModalComponent extends BoatOperationModalClass<IResourceBookingQuotation> {
  operationType = BoatMovementOperationForStatus.RETURN;
  successMessage = 'RETURN_REPORTED';
  errorMessage = 'RETURN_REPORT_ERROR';

  constructor(
    cd: ChangeDetectorRef,
    activeModal: NgbActiveModal,
    resourceBookingApi: ResourceBookingApiService,
    notification: NotificationService,
    resourceBookingService: ResourceBookingApiService,
    boatApi: MmsBoatsApiService
  ) {
    super(cd, activeModal, resourceBookingApi, notification, resourceBookingService, boatApi);
  }
}
