import { Component, inject } from '@angular/core';
import { MarinasService } from '@dm-workspace/core';
import { Marina } from '@dm-workspace/types';

@Component({
  selector: 'dm-mms-layout-marina-switch',
  templateUrl: './mms-layout-marina-switch.component.html',
  styleUrls: ['./mms-layout-marina-switch.component.scss'],
})
export class MmsMarinaSwitchComponent {
  #marinaService = inject(MarinasService);

  protected selectedMarina = this.#marinaService.selectedMarina;
  protected marinas$ = this.#marinaService.marinas$;

  protected selectMarina(selectedMarina: Marina) {
    this.#marinaService.selectMarina(selectedMarina);
  }
}
