import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { IOrder, OrderPaymentStatus, TOrderListColumns } from '@dm-workspace/types';
import { MmsOrderActionMarkAsPaidModalComponent } from '../../../../../mms-orders/src/lib/modals/mms-order-action-mark-as-paid/mms-order-action-mark-as-paid-modal.component';
import { filter } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dm-mms-shared-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrdersListComponent {
  readonly #modal = inject(NgbModal);
  @Input() public orders: IOrder[] = [];
  @Input() public hideColumns: TOrderListColumns[];
  @Output() public ordersChange = new EventEmitter<void>();
  protected readonly OrderPaymentStatus = OrderPaymentStatus;

  protected isVisible(column: TOrderListColumns): boolean {
    return !this.hideColumns || (Array.isArray(this.hideColumns) && !this.hideColumns.includes(column));
  }

  protected clickButtonMarkOrderAsPaid(order: IOrder) {
    const modalComponent = this.#modal.open(MmsOrderActionMarkAsPaidModalComponent, {
      size: 'md',
    }).componentInstance as MmsOrderActionMarkAsPaidModalComponent;
    modalComponent.orderId = order.id;
    modalComponent.confirmed
      .pipe(
        filter(Boolean),
        tap(() => this.ordersChange.emit())
      )
      .subscribe();
  }
}
