<menu>
  <ng-container *ngFor="let route of routes">
    <ng-container *dmHasPermission="route.permission">
      <ng-container
        *ngTemplateOutlet="route.children ? withDropdown : withoutDropdown; context: { $implicit: route }"
      />
    </ng-container>
  </ng-container>
</menu>

<ng-template #withDropdown let-route>
  <li #dropItem="ngbDropdown" (mouseleave)="dropItem.close()" ngbDropdown>
    <a
      (mouseenter)="dropItem.open()"
      [childNavRoutes]="route.children"
      [routerLink]="route.path | replaceMarinaTokenWithSelectedMarinaCode | async"
      class="item"
      ngbDropdownAnchor
    >
      <ng-container *ngTemplateOutlet="itemContent; context: { $implicit: route }" />
    </a>

    <div class="dropdown-menu" ngbDropdownMenu>
      <ng-container *ngFor="let childRoute of route.children">
        @if (childRoute.isActionItem) {
          <ng-container *dmHasFeature="childRoute.feature">
            <a *dmHasPermission="childRoute.permission" ngbDropdownItem (click)="getActionFn(childRoute.label)">
              {{ childRoute.label | translate }}
            </a>
          </ng-container>
        } @else {
          <ng-container *dmHasFeature="childRoute.feature">
            <a
              *dmHasPermission="childRoute.permission"
              [routerLinkActiveOptions]="{ exact: !childRoute.notExact }"
              [routerLink]="childRoute.path | replaceMarinaTokenWithSelectedMarinaCode | async"
              ngbDropdownItem
              routerLinkActive="active"
            >
              {{ childRoute.label | translate }}
            </a>
          </ng-container>
        }
      </ng-container>
    </div>
  </li>
</ng-template>

<ng-template #withoutDropdown let-route>
  <li>
    <a
      [routerLink]="route.path | replaceMarinaTokenWithSelectedMarinaCode | async"
      class="item"
      routerLinkActive="active"
    >
      <ng-container *ngTemplateOutlet="itemContent; context: { $implicit: route }" />
    </a>
  </li>
</ng-template>

<ng-template #itemContent let-route>
  <i *ngIf="route.icon" [ngClass]="route.icon"></i>
  {{ route.label | translate }}
</ng-template>
