import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IResourceBookingPaymentStatusResponse } from '@dm-workspace/types';

@Component({
  selector: 'dm-mms-payments-list',
  templateUrl: './mms-payments-list.component.html',
  styleUrls: ['./mms-payments-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsPaymentsListComponent {
  @Input() public payments?: IResourceBookingPaymentStatusResponse[];
}
