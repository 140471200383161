import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CustomerType, QuoteStatus } from '@dm-workspace/types';
import { DATE_SHORT_YEAR_FORMAT, DATE_TIME_FORMAT_MINUTES } from '@dm-workspace/shared';
import { MmsOffersTableBase } from '../mms-offers-table.base';

@Component({
  selector: 'dm-mms-offers-renewals-table',
  templateUrl: './mms-renewals-offers-table.component.html',
  styleUrls: ['./mms-renewals-offers-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsRenewalsOffersTableComponent extends MmsOffersTableBase {
  @Input() public columns?: string[];
  @Input() public selectedOfferTable: QuoteStatus[];

  protected readonly DATE_TIME_FORMAT_MINUTES = DATE_TIME_FORMAT_MINUTES;
  protected readonly DATE_SHORT_YEAR_FORMAT = DATE_SHORT_YEAR_FORMAT;
  protected readonly CustomerTypes = CustomerType;
}
