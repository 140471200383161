import { Pipe, PipeTransform } from '@angular/core';
import { IBerthBase, IResource, IResourceForBoat, MarinaMapColorStatus } from '@dm-workspace/types';
import { defaultBerthOptions, mergePolygonOptionsWithColor } from '@dm-workspace/map-utils';

@Pipe({
  name: 'berthPolygonStyleMovement',
})
export class BerthPolygonStyleMovementPipe implements PipeTransform {
  transform(
    berth: IBerthBase,
    resource: IResourceForBoat,
    from: IResource,
    to?: IResource
  ): google.maps.PolygonOptions {
    if (to && to.berthIds.some((value) => value === berth.id)) {
      return mergePolygonOptionsWithColor(MarinaMapColorStatus.orange);
    } else if (from && from.berthIds.some((value) => value === berth.id)) {
      return mergePolygonOptionsWithColor(MarinaMapColorStatus.green);
    } else if (resource) {
      return {
        ...defaultBerthOptions,
        fillColor: '#FFFFFF',
        strokeColor: '#1B253A',
      };
    }
    return {
      ...defaultBerthOptions,
      fillColor: '#1B253A',
      strokeColor: '#1B253A',
    };
  }
}
