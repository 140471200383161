<form (ngSubmit)="buttonConfirmClick()" [formGroup]="form">
  <div class="modal-header">
    <h2 class="title">{{ "EDIT_EMAIL" | translate }}</h2>
  </div>

  <div class="modal-body">
    <dm-form-input-value class="mb-4">
      <ng-container header>{{ "CUSTOMERS_PRIMARY_CONTACT_EMAIL" | translate }}</ng-container>
      <ng-container body>{{ contact?.email }}</ng-container>
    </dm-form-input-value>

    <dm-form-group class="mb-4">
      <label>{{ "EDIT_EMAIL" | translate }}</label>
      <input dmFormBlockEventPaste dmForm type="email" formControlName="email" />
      <dm-form-error-message controlName="email" />
    </dm-form-group>

    <dm-form-group>
      <label>{{ "REPEAT_EMAIL" | translate }}</label>
      <input dmFormBlockEventPaste dmForm type="email" formControlName="repeatEmail" />
      <dm-form-error-message controlName="repeatEmail" />
    </dm-form-group>
  </div>

  <div class="modal-footer justify-content-between d-flex">
    <button class="btn btn-tertiary btn-narrow" type="button" (click)="onCancel()">
      {{ "CANCEL" | translate }}
    </button>
    <button type="submit" class="btn btn-primary" [disabled]="apiCalling()">
      <dm-ui-spinner class="me-2" [showSpinner]="apiCalling()" />
      {{ "SAVE" | translate }}
    </button>
  </div>
</form>
