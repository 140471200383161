import {
  catchError,
  combineLatest,
  filter,
  merge,
  of,
  ReplaySubject,
  share,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs';
import { map } from 'rxjs/operators';
import { Component, inject } from '@angular/core';
import { GetQuotesParams, QuoteType } from '@dm-workspace/types';
import { ApiValidator, CollectionListClassDirective, TimestampRangeUtils } from '@dm-workspace/shared';
import { MmsQuotesApiService } from '@dm-workspace/data-access';
import { MarinasService } from '@dm-workspace/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from '@dm-workspace/notification';
import { MmsOffersFiltersFormValue } from '../../components/mms-offers-filters/mms-offers-filters.component';
import { MmsSelectedMarinaSupportViewPipe } from '@dm-workspace/mms-panel/shared';
import { removeEmptyProperties } from '@dm-workspace/utils';

@Component({
  selector: 'dm-mms-offers-view',
  templateUrl: './mms-offers-view.component.html',
  styleUrls: ['./mms-offers-view.component.scss'],
  providers: [MmsSelectedMarinaSupportViewPipe],
})
export class MmsOffersViewComponent extends CollectionListClassDirective<MmsOffersFiltersFormValue> {
  readonly #selectedMarinaSupportViewPipe = inject(MmsSelectedMarinaSupportViewPipe);
  readonly #triggerFetch = new ReplaySubject<void>(1);
  protected readonly selectedMarina$ = this.marinaService.selectedMarina$;
  protected readonly isSupportedMarinaSelected$ = this.marinaService.selectedMarina$.pipe(
    filter((marina) => this.#selectedMarinaSupportViewPipe.check('offers', marina.code)),
    share()
  );

  readonly #offersApiResult$ = combineLatest([this.isSupportedMarinaSelected$, this.#triggerFetch]).pipe(
    switchMap(() => {
      const filters = this.getParams();
      const { sentAt, acceptedAt, ...restFiltersValue } = filters;
      const dto: GetQuotesParams = {
        ...restFiltersValue,
        sentAt: TimestampRangeUtils.createFromDate(sentAt),
        acceptedAt: TimestampRangeUtils.createFromDate(acceptedAt),
        type: QuoteType.RENEWAL,
      };

      return this.offersService.get(removeEmptyProperties(dto)).pipe(
        catchError((res: HttpErrorResponse) => {
          this.notificationService.openError(ApiValidator.getApiNotificationError(res));
          return of(null);
        })
      );
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly offers$ = this.#offersApiResult$.pipe(map((v) => v?.values));

  public readonly pagination$ = this.#offersApiResult$.pipe(
    filter(Boolean),
    map((v) => v?.metadata)
  );

  public readonly pending$ = merge(
    merge(this.isSupportedMarinaSelected$, this.#triggerFetch).pipe(map(() => true)),
    this.#offersApiResult$.pipe(map(() => false))
  ).pipe(startWith(true));

  constructor(
    private offersService: MmsQuotesApiService,
    private marinaService: MarinasService,
    private notificationService: NotificationService
  ) {
    super();
  }

  public fetchCollection(): void {
    this.#triggerFetch.next();
  }
}
