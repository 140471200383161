import { inject, Pipe, PipeTransform } from '@angular/core';
import { IResourceBookingQuotation, QuotationStatus } from '@dm-workspace/types';
import { filter, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { MarinasService } from '@dm-workspace/core';
import { BookingHelperService } from '@dm-workspace/shared';

@Pipe({
  name: 'bookingEditable',
})
export class MmsBookingEditablePipe implements PipeTransform {
  #marinasService = inject(MarinasService);
  #bookingHelperService = inject(BookingHelperService);

  transform(
    booking: Pick<IResourceBookingQuotation, 'quotationStatus' | 'marina' | 'fromDate' | 'toDate'>
  ): Observable<boolean> {
    const allowedQuotationStatuses = [
      QuotationStatus.DRAFT,
      QuotationStatus.UNANNOUNCED_ARRIVAL,
      QuotationStatus.SENT,
      QuotationStatus.INCOMPLETE,
    ];
    const hasAllowedQuotationStatus = allowedQuotationStatuses.some(
      (quotationStatus) => quotationStatus === booking.quotationStatus
    );

    if (!hasAllowedQuotationStatus) {
      return of(false);
    }

    return this.#marinasService.getMarinaByCode(booking.marina).pipe(
      filter(Boolean),
      map((marina) => !this.#bookingHelperService.isInquiry(booking, marina))
    );
  }
}
