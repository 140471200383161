<div #marinaDropdown="ngbDropdown" class="dropdown" ngbDropdown>
  <button class="marina-button" type="button"
          [class.active]="marinaDropdown.isOpen()"
          ngbDropdownToggle>
    <div class="logo">
      <span class="i-dmarin-logo-signet"></span>
    </div>
    <div>
      <p class="typo-body-s fw-600 color-black">{{ "MARINA" | translate }} {{ selectedMarina()?.name }}</p>
      <p class="typo-body-xs color-primary">{{ "CHANGE" | translate }}</p>
    </div>
  </button>

  <div aria-labelledby="dropdownMenuButton" ngbDropdownMenu>
    @for (marina of marinas$ | async; track 'id') {
      <button (click)="selectMarina(marina)"
              [attr.aria-current]="marina.code === selectedMarina().code ? true : undefined"
              class="item" ngbDropdownItem
              type="button">
        <img [src]="marina.countryCode | flag" alt="{{ marina.name }}" class="me-1 flag vertical-align-middle">
        {{ marina.name }}
        <strong>({{ marina.code }})</strong>
      </button>
    }
  </div>
</div>
