@switch (booking.customer?.type) {
  @case (CustomerType.CORPORATE) {
    <span class="icon i-business"></span>
  }
  @case (CustomerType.INDIVIDUAL) {
    <span class="icon i-user-2 fw-bold"></span>
  }
}

@if (booking?.customer && (Ability.READ_CUSTOMER | hasPermission | async)) {
  <a class="link" [routerLink]="['/customers', booking.customer.id]" returnUrlCurrentPath>
    {{ booking.customer?.name }}
  </a>
} @else {
  {{(booking.customer?.name || booking.quotationPrefilledData?.email || booking.customer?.phone || booking.quotationPrefilledData?.phone) | empty }}
}
