import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import {
  BookingResourceProduct,
  DateRange,
  IAutocompleteResourceDetails,
  IAutocompleteResourceParams,
  IOnlineBookingResourceResponseWithBoatFitment,
  IResourceBookingCalculatePricingBoatData,
  IResourceBookingCalculatePricingParams,
} from '@dm-workspace/types';
import { BookingsBerthsTableComponent } from '../bookings-berths-table.component';
import { ResourceBookingApiService } from '@dm-workspace/data-access';
import { finalize } from 'rxjs/operators';
import { NotificationService } from '@dm-workspace/notification';
import { isMultiHullBoat } from '@dm-workspace/utils';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'dm-mms-bookings-berths-table-with-select',
  templateUrl: './bookings-berths-table-with-select.component.html',
  styleUrls: ['../bookings-berths-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingsBerthsTableWithSelectComponent extends BookingsBerthsTableComponent {
  @Input() dateRange: DateRange;
  @Input() boat: IResourceBookingCalculatePricingBoatData;
  @Input() marinaCode: string;
  protected mandatoryProductsWithoutMooring: BookingResourceProduct[];
  @Input() set products(products: BookingResourceProduct[]) {
    this.mandatoryProductsWithoutMooring = products.filter(
      (product) => product.isMandatory && product.product !== 'MOORING'
    );
  }

  public pending = false;
  public resourceData: IOnlineBookingResourceResponseWithBoatFitment;
  public resourceAddonParams: Partial<IAutocompleteResourceParams> = {
    excludeInaccessible: true,
  };

  constructor(
    private cd: ChangeDetectorRef,
    private resourceBookingService: ResourceBookingApiService,
    private notification: NotificationService
  ) {
    super();
  }

  fetchPricing(resource: IAutocompleteResourceDetails): void {
    if (!resource || !this.dateRange) {
      this.resourceData = null;
      this.resourceSelected.emit([null, null]);
      this.cd.detectChanges();
      return;
    }
    this.pending = true;
    const { fromDate, toDate } = this.dateRange;
    const payload: IResourceBookingCalculatePricingParams = {
      resourceId: resource.id,
      fromDate,
      toDate,
      isMultihull: this.boat && isMultiHullBoat(this.boat.type),
      boatId: this.boat && this.boat.id,
      marinaCode: this.marinaCode,
    };
    if (!this.boat.id) {
      const { loa: length, maxBeam: width, draft } = this.boat;
      payload.boatDimensions = {
        length,
        width,
        draft,
      };
    }
    this.resourceBookingService
      .fetchPricing(payload)
      .pipe(
        finalize(() => {
          this.pending = false;
          this.resourceSelected.emit([resource.id, this.resourceData?.willBoatFit]);
          this.cd.detectChanges();
        })
      )
      .subscribe({
        next: (response) => {
          this.resourceData = response;
        },
        error: (res: HttpErrorResponse) => {
          this.onError(res?.error?.errorCode);
        },
      });
  }

  onError(message: string): void {
    this.resourceData = null;
    this.notification.add({
      type: 'error',
      text: 'ERRORS.' + message || 'ERRORS.GENERAL',
    });
  }

  protected getTotalPrice() {
    if (!this.resourceData) {
      return null;
    }
    const totalMandatoryProductsPrice = this.mandatoryProductsWithoutMooring.reduce(
      (acc, product) => acc + product.totalPriceGross,
      0
    );
    return totalMandatoryProductsPrice + this.resourceData.pricingDetails.sumPriceGross;
  }
}
