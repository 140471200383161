import { Component, HostBinding, Input } from '@angular/core';
import { QuoteStatus } from '@dm-workspace/types';

@Component({
  selector: 'dm-mms-offer-status',
  templateUrl: './mms-offer-status.component.html',
  styleUrls: ['./mms-offer-status.component.scss'],
})
export class MmsOfferStatusComponent {
  @Input({ required: true })
  public offerStatus: QuoteStatus;

  @HostBinding('class')
  public get hostOfferStatusClass() {
    return this.offerStatus?.toLowerCase();
  }
}
