import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BoatMovementOperation } from '@dm-workspace/types';

export type MmsBoatMovementForm = FormGroup<{
  operation: FormControl<BoatMovementOperation>;
  startsAt?: FormControl<string>;
  endsAt?: FormControl<string>;
  gapStart?: FormControl<string>;
  gapEnd?: FormControl<string>;
  sourceId?: FormControl<string>;
  destinationId?: FormControl<string>;
  allowsBerthReuse?: FormControl<boolean>;
  transferBooking: FormControl<boolean>;
}>;

export type MmsBoatMovementFormValue = Required<ReturnType<MmsBoatMovementForm['getRawValue']>>;
export enum MmsBoatMovementFormVersion {
  OLD = 0,
  NEW = 1,
}

export class MmsBoatMovementFormBuilder {
  readonly #form: MmsBoatMovementForm;
  readonly #version: MmsBoatMovementFormVersion;

  constructor(form: MmsBoatMovementForm, version: MmsBoatMovementFormVersion) {
    this.#form = form;
    this.#version = version;
  }

  static buildForm(initValue?: Partial<MmsBoatMovementFormValue>): MmsBoatMovementForm {
    const form = new FormGroup({
      operation: new FormControl<BoatMovementOperation>(initValue?.operation, { validators: [Validators.required] }),
      transferBooking: new FormControl<boolean>(initValue?.transferBooking),
    });
    return form;
  }

  addStartAt(initValue?: string) {
    const control = new FormControl<string>(initValue, { validators: [Validators.required] });
    this.#form.addControl('startsAt', control);
    return this;
  }

  removeStartAt() {
    this.#form.removeControl('startsAt');
    return this;
  }

  addSourceId(initValue?: string) {
    const control = new FormControl<string>(initValue, { validators: this.getSourceIdValidators() });
    this.#form.addControl('sourceId', control);
    return this;
  }

  getSourceIdValidators() {
    switch (this.#version) {
      case MmsBoatMovementFormVersion.NEW: {
        switch (this.#form.value.operation) {
          case BoatMovementOperation.DRY_DOCK_LAUNCH: {
            return [];
          }
          default: {
            return [Validators.required];
          }
        }
      }

      case MmsBoatMovementFormVersion.OLD: {
        switch (this.#form.value.operation) {
          case BoatMovementOperation.DRY_DOCK_LAUNCH:
          case BoatMovementOperation.DRY_DOCK_LIFT:
          case BoatMovementOperation.DEPARTURE:
          case BoatMovementOperation.CRUISING:
          case BoatMovementOperation.INTERNAL_MOVEMENT:
          case BoatMovementOperation.RETURN: {
            return [];
          }
          default: {
            return [Validators.required];
          }
        }
      }
    }
  }

  removeSourceId() {
    this.#form.removeControl('sourceId');
    return this;
  }

  addDestinationId(initValue?: string) {
    const control = new FormControl<string>(initValue, { validators: this.#getDestinationIdValidators() });
    this.#form.addControl('destinationId', control);
    return this;
  }

  #getDestinationIdValidators() {
    switch (this.#version) {
      case MmsBoatMovementFormVersion.NEW: {
        switch (this.#form.value.operation) {
          case BoatMovementOperation.DRY_DOCK_LIFT: {
            return [];
          }
          default: {
            return [Validators.required];
          }
        }
      }

      case MmsBoatMovementFormVersion.OLD: {
        switch (this.#form.value.operation) {
          case BoatMovementOperation.DRY_DOCK_LAUNCH:
          case BoatMovementOperation.DRY_DOCK_LIFT:
          case BoatMovementOperation.ARRIVAL:
          case BoatMovementOperation.INTERNAL_MOVEMENT:
          case BoatMovementOperation.RETURN: {
            return [];
          }
          default: {
            return [Validators.required];
          }
        }
      }
    }
  }

  removeDestinationId() {
    this.#form.removeControl('destinationId');
    return this;
  }

  addEndsAt() {
    this.#form.addControl('endsAt', new FormControl<string>(null, { validators: this.getEndsAtValidators() }));
    return this;
  }

  getEndsAtValidators() {
    switch (this.#version) {
      case MmsBoatMovementFormVersion.NEW: {
        return [Validators.required];
      }

      case MmsBoatMovementFormVersion.OLD: {
        switch (this.#form.value.operation) {
          case BoatMovementOperation.CRUISING: {
            return [];
          }
          default: {
            return [Validators.required];
          }
        }
      }
    }
  }

  removeEndsAt() {
    this.#form.removeControl('endsAt');
    return this;
  }

  addGap(initValue?: { gapStart: string; gapEnd: string }) {
    this.#form.addControl('gapStart', new FormControl<string>(initValue?.gapStart));
    this.#form.addControl('gapEnd', new FormControl<string>(initValue?.gapEnd));
    return this;
  }

  removeGap() {
    this.#form.removeControl('gapStart');
    this.#form.removeControl('gapEnd');
    return this;
  }

  addAllowsBerthReuse() {
    this.#form.addControl('allowsBerthReuse', new FormControl<boolean>(false));
    return this;
  }

  removeAllowsBerthReuse() {
    this.#form.removeControl('allowsBerthReuse');
    return this;
  }
}
