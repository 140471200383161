import { ChangeDetectionStrategy, Component, inject, Input, signal } from '@angular/core';
import { Quote } from '@dm-workspace/types';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectedMarinaRouter } from '../../services/selected-marina-router';

@Component({
  selector: 'dm-mms-quote-action-create-new-version-modal',
  templateUrl: './mms-quote-action-create-new-version-modal.component.html',
  styleUrls: ['./mms-quote-action-create-new-version-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsQuoteActionCreateNewVersionModalComponent {
  #aModal = inject(NgbActiveModal);
  #selectedMarinaRouter = inject(SelectedMarinaRouter);

  @Input({ required: true }) quote: Quote;
  @Input() backToDetails = false;
  protected readonly apiCalling = signal<boolean>(false);

  protected onButtonConfirm() {
    this.#selectedMarinaRouter.navigate(['enquiry', this.quote.quoteId, 'new-version'], {
      state: { backToDetails: this.backToDetails },
    });
    this.#aModal.close(true);
  }

  protected onButtonCancel() {
    this.#aModal.dismiss();
  }
}
