import { ChangeDetectionStrategy, Component } from '@angular/core';
import { filter } from 'rxjs';
import { MmsDashboardViewService } from '../../services/mms-dashboard-view.service';

@Component({
  selector: 'dm-mms-dashboard-other-issues-view',
  templateUrl: './mms-dashboard-other-issues-view.component.html',
  styleUrls: ['./mms-dashboard-other-issues-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsDashboardOtherIssuesViewComponent {
  public stats$ = this.dashboardService.stats$.pipe(filter(Boolean));

  constructor(private dashboardService: MmsDashboardViewService) {}
}
