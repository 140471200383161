<div class="modal-header">
  <h2 class="title">{{ "CONNECT_BOOKING_TO_SOCKETS" | translate }}</h2>
</div>
<div class="modal-body">
  <p>
    {{ "CONNECT_BOOKING_TO_SOCKETS_DESCRIPTION" | translate }} <strong>{{ humanReadableId }}</strong>
  </p>

  <div [formGroup]="form">
    <dm-form-group class="w-50 my-3">
      <label>{{ "QR_CODE" | translate }}</label>
      <dm-form-autocomplete
        dmForm
        [type]="autocompleteTypes.pylons"
        [loadedData]="marinaMapPylons()"
        [resultFormatter]="resultFormatter"
        [inputFormatter]="inputFormatter"
        (changeOutput)="onSelectedPedestal($event)"
        (clearOutput)="onClearPedestal()"
        formControlName="selectedPedestal"
      />
      <dm-form-error-message controlName="selectedPedestal" />
    </dm-form-group>

    @if (pylonSocketsGroupedByMediaTypes()) {
      <div class="mb-3">
        @for (pylonSocketsMediaType of pylonSocketsGroupedByMediaTypes() | keyvalue; track pylonSocketsMediaType.key) {
          <h5 class="color-secondary mt-4 mb-3">
            {{ "MEDIA_TYPES_VALUES." + pylonSocketsMediaType.key | uppercase | translate }}
          </h5>
          <div class="d-flex gap-2">
            <button
              *ngFor="let pylonSocket of pylonSocketsMediaType.value"
              type="button"
              class="btn btn-secondary btn-secondary-outline btn-media"
              [class.selected]="selectedSocket.get(pylonSocketsMediaType.key) === pylonSocket"
              [disabled]="pylonSocket.status !== 'AVAILABLE'"
              (click)="selectSocket(pylonSocket, pylonSocketsMediaType.key)"
            >
              {{ pylonSocket.channel }}
              <span [class]="icons[pylonSocket.mediaType]" [style.margin-left]="'2px'"></span>
            </button>
          </div>
        }
      </div>
    }
    <div class="modal-footer justify-content-between d-flex">
      <button type="button" class="btn btn-tertiary btn-narrow" (click)="dismissModal()">
        {{ "CANCEL" | translate }}
      </button>
      <button class="btn btn-primary btn-default" type="button" (click)="onSubmit()" [disabled]="confirmApiCalling() || form.invalid">
        <dm-ui-spinner class="me-2" [showSpinner]="confirmApiCalling()" />
        {{ "CONFIRM" | translate }}
      </button>
    </div>
    <dm-ui-overflow-spinner [showSpinner]="apiCalling()" type="absolute" />
  </div>
</div>
