import { Ability } from '@dm-workspace/types';

export const mmsLayoutRedirectMap: Record<string, Ability> = {
  admin: Ability.ADMIN,
  marina: Ability.READ_RESOURCE_BOOKING,
  bookings: Ability.READ_RESOURCE_BOOKING,
  boats: Ability.READ_BOAT,
  products: Ability.READ_PRODUCT,
  orders: Ability.READ_ORDER,
  sales: Ability.READ_OFFERS,
  users: Ability.READ_USER,
  customers: Ability.READ_CUSTOMER,
  contracts: Ability.READ_CONTRACT,
  translations: Ability.CREATE_LOCALIZATION,
  manageTasks: Ability.MANAGE_TASK_MANAGEMENT,
  operateTasks: Ability.OPERATE_TASK_MANAGEMENT,
};
