<div ngbDropdown class="d-inline-block">
  <div ngbDropdownToggle class="no-chevron">
    <i class="icon i-gear"></i>
  </div>
  <div ngbDropdownMenu aria-labelledby="dropdownUser">
    <ng-container *ngIf="user$ | async as user">
      <p class="user-info">{{ user.name }}</p>
      <button type="button" ngbDropdownItem (click)="logout()">Logout</button>
    </ng-container>
  </div>
</div>
