import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MmsDashboardStat } from '@dm-workspace/types';

@Component({
  selector: 'dm-mms-dashboard-navigation-item',
  templateUrl: './mms-dashboard-navigation-item.component.html',
  styleUrls: ['./mms-dashboard-navigation-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsDashboardNavigationItemComponent {
  @Input() public titleKey: string;
  @Input() public doneIcon: string;
  @Input() public link: string;
  @Input() public stats?: MmsDashboardStat;
  @Input() public tooltipText?: string;
}
