<div class="modal-header">
  <h2 class="title">{{ "NEW_CUSTOMER" | translate }}</h2>
</div>

<div class="modal-body">
  <form autocomplete="off">
    <dm-mms-customer-form [form]="form" />
  </form>
</div>

<div class="modal-footer">
  <button class="btn btn-tertiary btn-narrow mr-auto" type="button" (click)="dismissModal()">
    {{ "CANCEL" | translate }}
  </button>
  <button type="button" [disabled]="pending" class="btn btn-primary" (click)="submit()">
    <dm-ui-spinner [showSpinner]="pending" class="me-2" />
    {{ "ADD_CUSTOMER" | translate }}
  </button>
</div>
