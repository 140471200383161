import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import {
  BerthAvailabilityReservationDto,
  BerthReservationsStatusInMarina,
  BoatMovementOperationForStatus,
} from '@dm-workspace/types';

import { BerthDrawerDetailsClass } from '../berth-drawer-details.class';
import { MapBerthClass } from '../../../berths.class';

@Component({
  selector: 'dm-map-map-berth-details-reservations-list',
  templateUrl: './map-berth-details-reservations-list.component.html',
  styleUrl: '../map-berth-details-list-styles.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapBerthDetailsReservationsListComponent extends BerthDrawerDetailsClass {
  berth = input.required<MapBerthClass>();
  extendedBookingsData = input.required<BerthAvailabilityReservationDto[]>();
  hasItemsToShow = computed(() => this.berth()?.data.bookings.length || this.berth()?.data.occupancy.length);
  marinaCode = input.required<string>();

  bookingListBoats = computed(() => {
    const occupancyWithoutBooking = this.berth().data.occupancy.filter(
      (boat) => !this.berth().data.bookings.some((booking) => booking.boatId === boat.boatId)
    );

    const showBoatsWithoutBooking =
      (this.berth().data.temporaryAbsence.find((absence) => absence.gapStart && absence.gapEnd) &&
        this.extendedBookingsData()?.length === 1) ||
      (!this.extendedBookingsData()?.length &&
        this.berth().reservationStatus.includes(BerthReservationsStatusInMarina.available));

    return [
      ...(this.extendedBookingsData() ?? []),
      ...(showBoatsWithoutBooking ? (occupancyWithoutBooking as BerthAvailabilityReservationDto[]) : []),
    ];
  });

  get getShowQQonBoat(): boolean {
    if (
      this.berth().data.bookings.length === 1 &&
      !this.boatIsInOccupancy(this.berth().data.bookings[0].boatId) &&
      this.temporaryAbsence(this.berth().data.bookings[0].boatId)?.gapStart &&
      this.temporaryAbsence(this.berth().data.bookings[0].boatId)?.gapEnd
    ) {
      return true;
    } else if (this.berth().data.bookings.length === 0) {
      return true;
    }
    return false;
  }

  protected readonly BoatMovementOperationForStatus = BoatMovementOperationForStatus;
  protected readonly BerthReservationsStatusInMarina = BerthReservationsStatusInMarina;
}
