import { Component, ContentChild, ElementRef, HostBinding } from '@angular/core';
import {
  enterLeaveFromBottomAnimation,
  enterLeaveFromTopAnimation,
  enterLeaveOpacityAnimation,
  enterLeaveFromLeftAnimation,
} from '@dm-workspace/shared';
import { MmsUiFullPageSidebarComponent } from '../mms-ui-full-page-sidebar/mms-ui-full-page-sidebar.component';

@Component({
  selector: 'dm-mms-ui-full-page',
  templateUrl: './mms-ui-full-page.component.html',
  styleUrls: ['./mms-ui-full-page.component.scss'],
  animations: [
    enterLeaveFromTopAnimation,
    enterLeaveFromBottomAnimation,
    enterLeaveOpacityAnimation,
    enterLeaveFromLeftAnimation,
  ],
})
export class MmsUiFullPageComponent {
  @HostBinding('class.has-sidebar')
  @ContentChild(MmsUiFullPageSidebarComponent, { static: false, read: ElementRef })
  sidebar: ElementRef;
  @HostBinding('style.paddingLeft.px') get leftPadding() {
    return this.sidebar?.nativeElement?.offsetWidth ?? 0;
  }
}
