<form (ngSubmit)="submitForm()" [formGroup]="form">
  <dm-ui-modal>
    <h2 header>{{ "CHANGE_THE_VALID_UNTIL_DATE" | translate }}</h2>
    <div body>
      <div class="row row-cols-2">
        <dm-form-group>
          <label>{{ "VALID_UNTIL_DATE" | translate }}</label>
          <dm-form-input-icon icon="calendar">
            <input
              dmInputDatepicker
              dmForm
              formControlName="waitingListValidUntil"
              ngbDatepicker
              type="text"
              datepickerClass="modal-z-index"
              container="body"
              [dmInputMinDate]="todayDate"
            />
          </dm-form-input-icon>
          <dm-form-error-message controlName="waitingListValidUntil"/>
        </dm-form-group>
      </div>
      <p class="mt-3 typo-body-s color-secondary">{{ "CHANGE_VALID_UNTIL_DATE_INFORMATION" | translate }}</p>
    </div>

    <div footer class="d-flex justify-content-between align-items-center w-100">
      <button type="button" class="btn btn-tertiary btn-narrow" (click)="closeModal()">
        {{ "CANCEL" | translate }}
      </button>
      <button class="btn btn-primary btn-default" type="submit" [disabled]="formSubmitting()">
        <dm-ui-spinner [showSpinner]="formSubmitting()" class="me-2" />
        {{ "CHANGE" | translate }}
      </button>
    </div>
  </dm-ui-modal>
</form>
