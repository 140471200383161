import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Marina, MmsDashboardStatName } from '@dm-workspace/types';
import { fadeAnimation } from '@dm-workspace/shared';
import { MmsDashboardApiService, ResourceBookingApiService } from '@dm-workspace/data-access';
import { MmsDashboardViewService } from '../../services/mms-dashboard-view.service';
import { MapDashboardChildView } from '../../class/mms-dashboard-child-view.class';
import { NotificationService } from '@dm-workspace/notification';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MarinasService } from '@dm-workspace/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'dm-mms-dashboard-expected-arrivals-view',
  templateUrl: './mms-dashboard-expected-arrivals-view.component.html',
  styleUrls: ['./mms-dashboard-expected-arrivals-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeAnimation],
})
export class MmsDashboardExpectedArrivalsViewComponent extends MapDashboardChildView {
  protected override statName: MmsDashboardStatName = 'expectedArrivals';
  public selectedMarina$: Observable<Marina> = this.marinaService.selectedMarina$;

  constructor(
    modal: NgbModal,
    dashboardService: MmsDashboardViewService,
    dashboardApiService: MmsDashboardApiService,
    cd: ChangeDetectorRef,
    resourceBookingApiService: ResourceBookingApiService,
    notification: NotificationService,
    marinaService: MarinasService
  ) {
    super(cd, dashboardService, dashboardApiService, resourceBookingApiService, notification, marinaService, modal);
  }

  public get arrivalDates(): (string | Date)[] {
    const bookingsDate: Date[] = this.bookings?.map((booking) => new Date(booking.fromDate));
    if (!bookingsDate || bookingsDate.length === 0) {
      return [new Date()];
    }

    const [first] = bookingsDate;

    const minMaxDates = bookingsDate?.reduce(
      ({ minDate, maxDate }, date) => ({
        minDate: date < minDate ? date : minDate,
        maxDate: date > maxDate ? date : maxDate,
      }),
      { minDate: first, maxDate: first }
    );
    const { minDate, maxDate } = minMaxDates;

    if (minDate.getTime() === maxDate.getTime()) {
      return [minDate];
    } else {
      return [minDate, maxDate];
    }
  }
}
