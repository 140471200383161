<button class="btn btn-primary-negative filter-btn" (click)="toggleShowLegend()" [class.is-open]="showLegend()">
  {{ "FILTERS" | translate }}<i class="i-chevron-left"></i>
</button>

<section *ngIf="showLegend()" [@fromRightAnimation]>
  <ol>
    @for (status of filters; track status) {
      <li>
        <dm-form-group>
          <label>
            <dm-ui-tag size="normal" [color]="status | tagColorByBerthStat">{{
              "MAP_BERTH_STATUS." + (status | uppercase) | translate
            }}</dm-ui-tag>
            <input type="checkbox" dmForm [checked]="selectedFilters?.has(status)" (click)="toggleFilter(status)" />
          </label>
        </dm-form-group>
      </li>
    }
  </ol>
  <button class="btn btn-primary-negative btn-tiny" (click)="resetFilters()">
    {{ (hasSelectedAll ? "DESELECT_ALL_FILTERS" : "SELECT_ALL_FILTERS") | translate }}
  </button>
</section>
