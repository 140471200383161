import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FiltersCollectionDirective } from '@dm-workspace/shared';
import { QuotesPaymentStatus, QuoteStatus, QuoteType } from '@dm-workspace/types';
import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap/datepicker/datepicker-input';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgForUtils } from '@dm-workspace/utils';

export type MmsOffersFiltersFormValue = Partial<
  ReturnType<(typeof MmsOffersFiltersComponent.prototype)['form']['getRawValue']>
>;

@Component({
  selector: 'dm-mms-offers-filters',
  templateUrl: './mms-offers-filters.component.html',
  styleUrls: ['./mms-offers-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsOffersFiltersComponent extends FiltersCollectionDirective {
  protected readonly quoteStatuses = [
    QuoteStatus.ACCEPTED,
    QuoteStatus.DECLINED,
    QuoteStatus.READY_TO_SEND,
    QuoteStatus.CANCELLED,
    QuoteStatus.REVISED,
    QuoteStatus.SENT,
  ];
  protected readonly QuoteType = QuoteType;
  protected readonly NgForUtils = NgForUtils;
  protected readonly QuoteStatus = QuoteStatus;
  protected readonly GetQuotesParamPaymentStatus = QuotesPaymentStatus;
  protected readonly form = new FormGroup({
    nameFragment: new FormControl<string>(null),
    boatId: new FormControl<string>(null),
    customerId: new FormControl<string>(null),
    dateFrom: new FormControl<string>(null),
    dateTo: new FormControl<string>(null),
    quoteStatus: new FormControl<QuoteStatus[]>(null),
    paymentStatus: new FormControl<QuotesPaymentStatus>(null),
    lastSentAt: new FormControl<string>(null),
    acceptedAt: new FormControl<string>(null),
  });

  constructor(protected adapter: NgbDateAdapter<unknown>) {
    super();
  }

  protected toggleDatepicker($event: MouseEvent, datePicker: NgbInputDatepicker): void {
    $event.stopPropagation();
    $event.preventDefault();
    datePicker.open();
  }
}
