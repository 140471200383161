import { inject, Pipe, PipeTransform } from '@angular/core';
import { first, map, Observable, ReplaySubject, tap } from 'rxjs';
import { BoatType } from '@dm-workspace/types';
import { MAP_BOAT_ID_BOAT_TYPE } from '../const/boat-temp';
import { MmsBoatsApiService } from '@dm-workspace/data-access';

@Pipe({
  name: 'GetBoatTypeByBoatId',
})
export class GetBoatTypeByBoatIdPipe implements PipeTransform {
  private boatTypes = inject(MAP_BOAT_ID_BOAT_TYPE);
  private boatService = inject(MmsBoatsApiService);
  transform(boatId: string): Observable<BoatType> {
    if (this.boatTypes.has(boatId)) {
      return this.boatTypes.get(boatId).pipe(first());
    }
    const replaySubject = new ReplaySubject<BoatType>(1);
    this.boatTypes.set(boatId, replaySubject);
    return this.boatService.fetchById(boatId).pipe(
      map((value) => value.type),
      tap((boatType) => replaySubject.next(boatType))
    );
  }
}
