import { Pipe, PipeTransform } from '@angular/core';
import { QuotesPaymentStatus, OrderPaymentStatus } from '@dm-workspace/types';
import { GetKeyFromEnumPipe } from '@dm-workspace/shared';

@Pipe({
  name: 'orderPaymentStatusTranslateKey',
})
export class MmsOrderPaymentStatusTranslateKeyPipe implements PipeTransform {
  readonly #getKeyFromEnum = new GetKeyFromEnumPipe().transform;

  public transform(paymentStatus: OrderPaymentStatus | QuotesPaymentStatus | undefined): string {
    if (!paymentStatus) {
      return;
    }
    const paymentStatusLowerCase = paymentStatus.toLowerCase();
    const enumKey = this.#getKeyFromEnum(paymentStatusLowerCase, OrderPaymentStatus);
    return enumKey ? `PAYMENT_STATUS_.${enumKey}` : null;
  }
}
