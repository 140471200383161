import { ActivatedRoute } from '@angular/router';
import { Directive, inject } from '@angular/core';
import { MmsDashboardViewService } from '../services/mms-dashboard-view.service';
import { NotificationService } from '@dm-workspace/notification';
import { ApiValidatorService } from '@dm-workspace/forms';
import { MmsDashboardQuotesPath } from '../views/mms-dashboard-quotes-view/mms-dashboard-quotes-view.component';
import {
  MmsDashboardStatName,
  MmsDashboardStats,
  PaginatedNormal,
  PaginationMetadataNormal,
} from '@dm-workspace/types';
import { Pagination } from '@dm-workspace/ui';
import {
  catchError,
  combineLatest,
  debounceTime,
  filter,
  first,
  map,
  merge,
  Observable,
  of,
  share,
  shareReplay,
  startWith,
  Subject,
  switchMap,
  tap,
} from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

export type MmsDashboardPathStatNameArray<P extends string = string, S = Partial<MmsDashboardStatName>> = Readonly<
  Array<{ path: P; statName: S }>
>;

@Directive()
export abstract class MmsDashboardStatsViewComponent<S extends Partial<MmsDashboardStatName>, I> {
  private readonly aRoute = inject(ActivatedRoute);
  private readonly dashboardViewService = inject(MmsDashboardViewService);
  private readonly notificationService = inject(NotificationService);
  private readonly apiValidatorService = inject(ApiValidatorService);

  protected readonly pagination = new Pagination();
  private readonly triggerFetchResource = new Subject<void>();

  protected abstract getRouteStats: (stats: MmsDashboardStats) => Partial<MmsDashboardStats>;
  protected readonly routeStats$ = this.dashboardViewService.stats$.pipe(
    filter(Boolean),
    map((stats) => this.getRouteStats(stats))
  );

  protected abstract pathToStatName(currentPath: string): Partial<MmsDashboardStatName>;
  private readonly currentStatName$ = this.aRoute.params.pipe(
    map((params: { path?: MmsDashboardQuotesPath }) => this.pathToStatName(params?.path)),
    filter(Boolean),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly currentStat$ = combineLatest([this.routeStats$.pipe(filter(Boolean)), this.currentStatName$]).pipe(
    map(([stats, statName]) => {
      return stats[statName];
    }),
    filter(Boolean),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly fetchItemsTriggers$ = combineLatest([
    this.triggerFetchResource.pipe(startWith(null)),
    this.pagination.changed$.pipe(startWith(null)),
    this.currentStat$.pipe(
      filter((currentStat) => currentStat.todoCount != 0),
      startWith(null)
    ),
  ]).pipe(debounceTime(200), share());

  protected abstract apiFetchCall: (
    statName: S,
    pagination: PaginationMetadataNormal
  ) => Observable<PaginatedNormal<I>>;

  protected readonly apiResult$ = this.fetchItemsTriggers$.pipe(
    switchMap(() => {
      return this.currentStatName$.pipe(
        first(),
        switchMap((statName) => {
          return this.apiFetchCall(statName as S, this.pagination.getPagination()).pipe(
            catchError((res: HttpErrorResponse) => {
              const notificationContent = this.apiValidatorService.getApiNotificationError(res);
              this.notificationService.openError(notificationContent);
              return of({ metadata: null, values: [] });
            }),
            share()
          );
        }),
        tap((v) => this.pagination.setPagination(v?.metadata))
      );
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  protected readonly items$ = this.apiResult$.pipe(map((v) => v?.values));

  protected readonly pending$ = merge(
    this.fetchItemsTriggers$.pipe(map(() => true)),
    merge(this.items$).pipe(map(() => false))
  ).pipe(shareReplay({ bufferSize: 1, refCount: true }));

  protected readonly hasAllDone$ = this.currentStat$.pipe(
    map((currenStat) => currenStat.todoCount === 0),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  protected readonly hasUndone$ = this.currentStat$
    .pipe(filter(Boolean))
    .pipe(map((currenStat) => currenStat.todoCount > 0));

  public refreshItems() {
    this.dashboardViewService.refreshStats();
    this.triggerFetchResource.next();
  }
}
