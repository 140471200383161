import { Pipe, PipeTransform } from '@angular/core';
import { OrderPaymentStatus } from '@dm-workspace/types';
import { TagColor } from '@dm-workspace/ui';

@Pipe({
  name: 'paymentStatusTagColor',
})
export class MmsPaymentStatusTagColorPipe implements PipeTransform {
  public transform(paymentStatus: OrderPaymentStatus | undefined): TagColor {
    if (!paymentStatus) {
      return;
    }

    switch (paymentStatus) {
      case OrderPaymentStatus.UNPAID:
      case OrderPaymentStatus.REJECTED:
        return 'red-light';
      case OrderPaymentStatus.PAID:
        return 'green-light';
      case OrderPaymentStatus.WAITING:
        return 'orange-light';
      default:
        return null;
    }
  }
}
