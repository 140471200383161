import { Pipe, PipeTransform } from '@angular/core';
import { QuotationStatus } from '@dm-workspace/types';
import { TagColor } from '@dm-workspace/ui';

@Pipe({
  name: 'quotationStatusTagColor',
})
export class MmsQuotationStatusTagColorPipe implements PipeTransform {
  public transform(quotationStatus: QuotationStatus | undefined): TagColor {
    if (!quotationStatus) {
      return;
    }

    switch (quotationStatus) {
      case QuotationStatus.DRAFT:
      case QuotationStatus.CHECK_IN:
      case QuotationStatus.IN_PROGRESS:
        return 'light';
      case QuotationStatus.CHECKED_OFFLINE:
        return 'grey-light';
      case QuotationStatus.INCOMPLETE:
        return 'orange-light';
      case QuotationStatus.CONFIRMED:
      case QuotationStatus.CHECKED_IN:
      case QuotationStatus.COMPLETED:
      case QuotationStatus.SENT:
        return 'green-light';
      case QuotationStatus.BLOCKED:
      case QuotationStatus.CANCELLED:
      case QuotationStatus.CHECK_IN_REJECTED:
        return 'green-light';
      case QuotationStatus.UNANNOUNCED_ARRIVAL:
        return 'grey';
      default:
        return 'light';
    }
  }
}
