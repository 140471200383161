import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MmsCustomerContactFormModalComponent, MmsCustomerFormService } from '@dm-workspace/mms-panel/shared';
import { NewBookingFormComponentClass } from '../../../../../marina/src/lib/classes/new-booking-form-component';
import { CustomerType, Customer } from '@dm-workspace/types';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MarinasService } from '@dm-workspace/core';
import { filter, first, map, race, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'dm-mms-bookings-customer-booking-form-card',
  templateUrl: './customer-booking-form-card.component.html',
  styleUrls: ['./customer-booking-form-card.component.scss'],
})
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class CustomerBookingFormCardComponent extends NewBookingFormComponentClass<any> implements OnInit {
  @Input() customerHasPrefilledData: boolean;

  public customerSearch: string;
  private readonly selectedMarina = this.marinasService.selectedMarina$;

  private get customerControl(): UntypedFormControl {
    return this.form.get('customer') as UntypedFormControl;
  }

  constructor(
    private modal: NgbModal,
    private mmsCustomerFormService: MmsCustomerFormService,
    private activatedRoute: ActivatedRoute,
    private marinasService: MarinasService
  ) {
    super();
  }

  public ngOnInit() {
    const { customer } = this.activatedRoute.snapshot.queryParams;

    if (customer) {
      this.customerSearch = customer;
    }
  }

  public get selectedCustomer(): Customer {
    return this.form?.value?.customer;
  }

  public isSelectedCustomerCorporate(customer: Customer): boolean {
    return customer?.type === CustomerType.CORPORATE;
  }

  public clearCustomer(): void {
    this.customerSearch = null;
    this.customerControl.reset();
  }

  public openCustomerFormModal(customer?: Partial<Customer>) {
    this.selectedMarina
      .pipe(
        first(),
        switchMap((marina) => {
          const modal = this.modal.open(MmsCustomerContactFormModalComponent);
          const modalInstance = modal.componentInstance as MmsCustomerContactFormModalComponent;
          const form = this.mmsCustomerFormService.createForm(customer, {
            marinaCode: marina.countryCode,
            countryCode: customer?.countryCode,
            corporateType: customer?.corporateType,
            customerType: customer?.type,
          });
          modalInstance.form = form;

          return race(modal.closed, modal.dismissed.pipe(map(() => false)));
        }),
        first(),
        filter(Boolean),
        tap((customer: Customer) => {
          this.customerControl.patchValue(customer);
          this.customerHasPrefilledData = false;
        })
      )
      .subscribe();
  }
}
