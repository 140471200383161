<!--<div class="px-xxl py-xl d-print-none">-->
<!--  <h1 class="title">-->
<!--    {{ "MMS_DASHBOARD.TITLE" | translate: { name: (user$ | async)?.name || "..." } }}-->
<!--  </h1>-->

  <dm-mms-dashboard-navigation class="d-none" />
<!--</div>-->

<div
  [@fadeRouterAnimation]="animationState"
  class="router-wrapper"
  dmUiStickyElementOffset="dashboard"
  [stickyTop]="80"
>
  <router-outlet />
</div>
