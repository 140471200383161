import { NgModule } from '@angular/core';
import { NgbDatepickerModule, NgbNavModule, NgbTooltipModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { LibsFormsModule } from '@dm-workspace/forms';
import { LibsSharedModule } from '@dm-workspace/shared';
import { UiModule } from '@dm-workspace/ui';
import { MmsPanelSharedModule } from '@dm-workspace/mms-panel/shared';
import { mmsOffersRoutes } from './mms-offers.routes';
import { MmsOfferViewComponent } from './views/mms-offer-view/mms-offer-view.component';
import { MmsOffersViewComponent } from './views/mms-offers-view/mms-offers-view.component';
import { MmsOffersFiltersComponent } from './components/mms-offers-filters/mms-offers-filters.component';
import { provideRouter, RouterModule } from '@angular/router';
import { MmsOffersTableComponent } from './components/mms-offers-table/mms-offers-table.component';
import { MmsUiModule } from '@dm-workspace/mms-panel/mms-ui';
import { MmsOfferCrmStatusTranslateKeyPipe } from './pipes/mms-offer-crm-status-translate-key.pipe';
import { MmsOfferDetailsViewComponent } from './views/mms-offer-details-view/mms-offer-details-view.component';
import { MmsOfferPreviewComponent } from './components/mms-offer-preview/mms-offer-preview.component';
import { MmsOfferProductsTableComponent } from './components/mms-offer-products-table/mms-offer-products-table.component';
import { MmsOfferProductsSumPricePipe } from './pipes/mms-offer-products-sum-price.pipe';
import { MmsOfferCanCancelPipe } from './pipes/mms-offer-can-cancel.pipe';
import { MmsOfferCanResendPipe } from './pipes/mms-offer-can-resend.pipe';
import { MmsOfferActionSendModalComponent } from './modals/mms-offer-action-send-modal/mms-offer-action-send-modal.component';
import { MmsOfferStatusComponent } from './components/mms-offer-status/mms-offer-status.component';
import { OfferStatusIconClassPipe } from './pipes/mms-offer-status-icon.pipe';
import { MmsOfferActionAcceptModalComponent } from './modals/mms-offer-action-accept-modal/mms-offer-action-accept-modal.component';
import { MmsOfferActionSendReminderModalComponent } from './modals/mms-offer-action-send-reminder-modal/mms-offer-action-send-reminder-modal.component';

@NgModule({
  declarations: [
    MmsOffersViewComponent,
    MmsOffersFiltersComponent,
    MmsOffersTableComponent,
    MmsOfferViewComponent,
    MmsOfferDetailsViewComponent,
    MmsOfferPreviewComponent,
    MmsOfferProductsTableComponent,
    MmsOfferCrmStatusTranslateKeyPipe,
    MmsOfferProductsSumPricePipe,
    MmsOfferCanCancelPipe,
    MmsOfferCanResendPipe,
    MmsOfferActionSendModalComponent,
    MmsOfferStatusComponent,
    OfferStatusIconClassPipe,
    MmsOfferActionAcceptModalComponent,
    MmsOfferActionSendReminderModalComponent,
  ],
  imports: [
    LibsFormsModule,
    LibsSharedModule,
    NgbDatepickerModule,
    NgbNavModule,
    NgbTypeaheadModule,
    UiModule,
    MmsPanelSharedModule,
    NgbTooltipModule,
    RouterModule,
    MmsUiModule,
  ],
  providers: [provideRouter(mmsOffersRoutes)],
  exports: [MmsOffersTableComponent],
})
export class MmsOffersModule {}
