<dm-ui-modal>
  <h2 header class="title">{{ "TRANSFER_BOOKING" | translate }}</h2>
  <div body>
    <div class="row" [formGroup]="form">
      <dm-form-group class="col-6">
        <label>{{ "SOURCE_BERTH" | translate }}</label>
        <dm-form-select-typeahead
          dmForm
          [clearable]="false"
          typeahead="resources"
          formControlName="from"
          bindLabel="name"
          [resourceType]="ResourceType.berth"
        />
        <dm-form-error-message controlName="from" />
      </dm-form-group>
      <dm-form-group class="col-6">
        <label>{{ "DESTINATION_BERTH" | translate }}</label>
        <dm-form-select-typeahead
          dmForm
          [clearable]="false"
          typeahead="resources"
          formControlName="resource"
          bindLabel="name"
          [resourceType]="ResourceType.berth"
        />
        <dm-form-error-message controlName="resource" />
      </dm-form-group>
    </div>
    <dm-ui-availability-check
      class="mt-4"
      [checkPayload]="checkPayload$ | async"
      (availabilityCheck)="availabilityCheck($event)"
    />
  </div>

  <ng-container footer>
    <button class="btn btn-tertiary btn-narrow" type="button" (click)="onCancel()">
      {{ "CANCEL" | translate }}
    </button>

    <button type="button" class="btn btn-primary" (click)="submitForm()" [disabled]="apiCalling()">
      <dm-ui-spinner [showSpinner]="apiCalling()" class="me-2" />
      {{ "CONFIRM" | translate }}
    </button>
  </ng-container>
</dm-ui-modal>
