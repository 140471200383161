import { ChangeDetectionStrategy, Component, DestroyRef, inject, Input, OnInit, signal } from '@angular/core';
import { Contact } from '@dm-workspace/types';
import { MmsContactApiService } from '@dm-workspace/data-access';
import { NotificationService } from '@dm-workspace/notification';
import { ConfirmResultModal } from '@dm-workspace/ui';
import { ApiValidatorService, emailValidator, FormUtils, inputRepeatValidator } from '@dm-workspace/forms';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { filter, finalize } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'dm-mms-contact-change-email-modal',
  templateUrl: './mms-contact-change-email-modal.component.html',
  styleUrls: ['./mms-contact-change-email-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsContactChangeEmailModalComponent extends ConfirmResultModal<Contact> implements OnInit {
  @Input({ required: true }) public contact: Contact;
  protected readonly apiCalling = signal<boolean>(false);
  protected readonly contactApiService = inject(MmsContactApiService);
  protected readonly notification = inject(NotificationService);
  protected readonly apiValidator = inject(ApiValidatorService);
  protected readonly destroyRef = inject(DestroyRef);
  protected readonly form = new FormGroup({
    email: new FormControl(null, { validators: [Validators.required, emailValidator()], updateOn: 'blur' }),
    repeatEmail: new FormControl(null, {
      validators: [Validators.required, emailValidator(), inputRepeatValidator('email')],
      updateOn: 'blur',
    }),
  });

  constructor() {
    super();
  }

  public ngOnInit() {
    this.initForm();
  }

  private initForm() {
    const { email, repeatEmail } = this.form.controls;
    email.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(() => repeatEmail.touched),
        tap(() => repeatEmail.updateValueAndValidity())
      )
      .subscribe();
  }

  protected buttonConfirmClick() {
    if (this.apiCalling() || !FormUtils.isValid(this.form)) {
      return;
    }
    this.apiCalling.set(true);

    const { email } = this.form.getRawValue();

    return this.contactApiService
      .update(this.contact.id, {
        contact: {
          ...this.contact,
          email,
        },
      })
      .pipe(finalize(() => this.apiCalling.set(false)))
      .subscribe({
        next: (contact) => {
          this.notification.openSuccess();
          this.onConfirm(contact);
        },
        error: (res: HttpErrorResponse) => {
          const notificationContent = this.apiValidator.getApiNotificationError(res);
          this.notification.openError(notificationContent);
        },
      });
  }
}
