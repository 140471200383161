import { ChangeDetectorRef, Component } from '@angular/core';
import { Ability, MmsDashboardStatName, QuotationStatus } from '@dm-workspace/types';
import { MmsDashboardApiService, ResourceBookingApiService } from '@dm-workspace/data-access';
import { MmsDashboardViewService } from '../../services/mms-dashboard-view.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MapDashboardChildView } from '../../class/mms-dashboard-child-view.class';
import { NotificationService } from '@dm-workspace/notification';
import { MarinasService } from '@dm-workspace/core';

@Component({
  selector: 'dm-mms-dashboard-customer-did-not-show',
  templateUrl: './mms-dashboard-customer-did-not-show.component.html',
  styleUrls: ['./mms-dashboard-customer-did-not-show.component.scss'],
})
export class MmsDashboardCustomerDidNotShowComponent extends MapDashboardChildView {
  public readonly abilities = Ability;
  public readonly quotationStatuses = QuotationStatus;
  protected override hasLoadMoreButton = true;
  protected override statName: MmsDashboardStatName = 'customerDidNotShow';

  constructor(
    modal: NgbModal,
    cd: ChangeDetectorRef,
    dashboardService: MmsDashboardViewService,
    dashboardApiService: MmsDashboardApiService,
    resourceBookingApiService: ResourceBookingApiService,
    notification: NotificationService,
    marinaService: MarinasService
  ) {
    super(cd, dashboardService, dashboardApiService, resourceBookingApiService, notification, marinaService, modal);
  }

  public override get hasBookingsTodo(): boolean {
    return this.stats?.todoCount > 0;
  }
}
