<dm-ui-modal header="VERIFY_CRUISE">
  <ng-container body>
    <p class="mt-2" *ngIf="cruiseDetails">
      {{ "EXIT_DATE" | translate }}: <strong>{{ cruiseDetails.exitDate | dateFormat }}</strong
      ><br />
      {{ "EXPECTED_RETURN_DATE" | translate }}: <strong>{{ cruiseDetails.expectedReturnDate | dateFormat }}</strong>
    </p>
    <p>{{ "DECIDE_IF_BERTH_SHOULD_BE_AVAILABLE_FOR_BOOKING" | translate }}</p>
    <dm-form-group class="mt-l">
      <input type="checkbox" id="allowsBerthReuse" dmForm [(ngModel)]="allowsBerthReuse" />
      <label for="allowsBerthReuse">{{ "CONFIRM_THE_POSSIBILITY_OF_BOOKING" | translate }}</label>
    </dm-form-group>
    <dm-ui-overflow-spinner [showSpinner]="pending" type="absolute" />
  </ng-container>

  <ng-container footer>
    <button class="btn btn-tertiary btn-narrow" type="button" (click)="dismissModal()">
      {{ "CANCEL" | translate }}
    </button>
    <button type="button" class="btn btn-primary" (click)="onAction()" [disabled]="pending">
      <dm-ui-spinner [showSpinner]="pending" />
      {{ "CONFIRM" | translate }}
    </button>
  </ng-container>
</dm-ui-modal>
