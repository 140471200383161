import { ChangeDetectionStrategy, Component, EventEmitter, input, Output } from '@angular/core';
import { GroupedPylons, IMarinaPylonsResponse } from '@dm-workspace/types';

@Component({
  selector: 'dm-map-pylon-details-list',
  templateUrl: './map-pylon-details-pylons-list.component.html',
  styleUrl: '../../map-berth-details-list-styles.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapPylonDetailsPylonsListComponent {
  pylon = input.required<GroupedPylons>();
  marinaCode = input.required<string>();
  @Output()
  changeSocketConnectionStatus = new EventEmitter<IMarinaPylonsResponse>();
}
