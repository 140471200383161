<table class="table">
  <colgroup>
    @for (col of columns; track col) {
      <col [class]="col | lowercase" [style.width.px]="133" />
    }
  </colgroup>

  <thead dmUiStickyElement name="offerList" [secondElementOffset]="110" [style.z-index]="'2'">
    <tr>
      @for (col of columns; track col) {
        <th [class]="col | lowercase">{{ col | uppercase | translate }}</th>
      }
    </tr>
  </thead>

  <tbody>
    @for (offer of offers; track offer.quoteId) {
      <tr>
        @if (selectedOfferTable.length === 1) {
          @switch (selectedOfferTable[0]) {
            @case (QuoteStatus.READY_TO_SEND) {
              <ng-container *ngTemplateOutlet="status; context: { offer }" />
              <ng-container *ngTemplateOutlet="created_at; context: { offer }" />
              <ng-container *ngTemplateOutlet="customer; context: { offer }" />
              <ng-container *ngTemplateOutlet="resource; context: { offer }" />
              <ng-container *ngTemplateOutlet="boat; context: { offer }" />
              <ng-container *ngTemplateOutlet="period; context: { offer }" />
              <ng-container *ngTemplateOutlet="total_amount; context: { offer }" />
            }
            @case (QuoteStatus.SENT) {
              <ng-container *ngTemplateOutlet="status; context: { offer }" />
              <ng-container *ngTemplateOutlet="customer; context: { offer }" />
              <ng-container *ngTemplateOutlet="resource; context: { offer }" />
              <ng-container *ngTemplateOutlet="boat; context: { offer }" />
              <ng-container *ngTemplateOutlet="period; context: { offer }" />
              <ng-container *ngTemplateOutlet="last_send_date; context: { offer }" />
              <ng-container *ngTemplateOutlet="last_seen_date; context: { offer }" />
              <ng-container *ngTemplateOutlet="total_amount; context: { offer }" />
            }
            @case (QuoteStatus.ACCEPTED) {
              <ng-container *ngTemplateOutlet="status; context: { offer }" />
              <ng-container *ngTemplateOutlet="created_at; context: { offer }" />
              <ng-container *ngTemplateOutlet="customer; context: { offer }" />
              <ng-container *ngTemplateOutlet="resource; context: { offer }" />
              <ng-container *ngTemplateOutlet="boat; context: { offer }" />
              <ng-container *ngTemplateOutlet="period; context: { offer }" />
              <ng-container *ngTemplateOutlet="acceptedAt; context: { offer }" />
              <ng-container *ngTemplateOutlet="paymentStatus; context: { offer }" />
              <ng-container *ngTemplateOutlet="total_amount; context: { offer }" />
            }
            @case (QuoteStatus.DECLINED) {
              <ng-container *ngTemplateOutlet="status; context: { offer }" />
              <ng-container *ngTemplateOutlet="created_at; context: { offer }" />
              <ng-container *ngTemplateOutlet="customer; context: { offer }" />
              <ng-container *ngTemplateOutlet="resource; context: { offer }" />
              <ng-container *ngTemplateOutlet="boat; context: { offer }" />
              <ng-container *ngTemplateOutlet="period; context: { offer }" />
              <ng-container *ngTemplateOutlet="declinedAt; context: { offer }" />
              <ng-container *ngTemplateOutlet="total_amount; context: { offer }" />
            }
          }
        } @else {
          <!--ALL-->
          <ng-container *ngTemplateOutlet="status; context: { offer }" />
          <ng-container *ngTemplateOutlet="created_at; context: { offer }" />
          <ng-container *ngTemplateOutlet="customer; context: { offer }" />
          <ng-container *ngTemplateOutlet="resource; context: { offer }" />
          <ng-container *ngTemplateOutlet="boat; context: { offer }" />
          <ng-container *ngTemplateOutlet="period; context: { offer }" />
          <ng-container *ngTemplateOutlet="paymentStatus; context: { offer }" />
          <ng-container *ngTemplateOutlet="total_amount; context: { offer }" />
        }
        <ng-container *ngTemplateOutlet="actions; context: { offer }" />
      </tr>
    }
    <tr *ngIf="offers?.length === 0">
      <td colspan="13">
        {{ "NO_TYPEOF_RESULTS_FOR_FILTERS" | translate: { items: "OFFERS" | translate | lowercase } }}
      </td>
    </tr>
  </tbody>
</table>

<ng-template #status let-offer="offer">
  <td>
    <dm-ui-tag [color]="offer.quoteStatus | quoteStatusTagColor">
      {{ offer.quoteStatus | translate | empty }}
    </dm-ui-tag>
  </td>
</ng-template>

<ng-template #created_at let-offer="offer">
  <td>
    {{ offer.createdAt | dateFormat: DATE_SHORT_YEAR_FORMAT }}<br />
    {{ offer.createdAt | dateFormat: "time" }}
  </td>
</ng-template>

<ng-template #service_type let-offer="offer">
  <td>
    {{ offer.type | getKeyFromEnum: QuoteType | translate | empty }}
  </td>
</ng-template>

<ng-template #customer let-offer="offer">
  <td class="user-row">
    <dm-mms-table-cell-booking-customer [booking]="offer" />
  </td>
</ng-template>

<ng-template #resource let-offer="offer">
  <td>
    {{ offer.proposedResource?.name | empty }}
  </td>
</ng-template>

<ng-template #boat let-offer="offer">
  <td>
    <dm-mms-table-cell-boat [boat]="offer.boat || (offer.descriptionParsed?.boat | quoteDescriptionBoatToBoat)" />
  </td>
</ng-template>

<ng-template #period let-offer="offer">
  <td>
    <dm-mms-table-cell-start-end [start]="offer.startDate" [end]="offer.endDate" />
  </td>
</ng-template>

<ng-template #paymentStatus let-offer="offer">
  <td>
    <dm-ui-tag [color]="offer.order?.paymentStatus | paymentStatusTagColor">
      {{ offer.order?.paymentStatus | orderPaymentStatusTranslateKey | translate | empty }}
    </dm-ui-tag>
  </td>
</ng-template>

<ng-template #validUntil let-offer="offer">
  <td>
    <p class="mb-1">
      {{ offer.expirationDate | dateFormat: DATE_SHORT_YEAR_FORMAT | empty }}
    </p>
    <p class="color-secondary mb-1">
      {{ "LAST_CONTACT" | translate | lowercase }}
    </p>
    <!--            [class.color-danger]="enquiry.activitySummary?.contactAttempts?.count >= 3"-->
    <strong class="color-black">
      {{ offer.activitySummary?.lastSeenAt | dateFormat: DATE_TIME_FORMAT_MINUTES | empty }}
    </strong>
  </td>
</ng-template>

<ng-template #declinedAt let-offer="offer">
  <td>{{ offer.activitySummary?.declinedAt | dateFormat: DATE_SHORT_YEAR_FORMAT | empty }}<br /></td>
</ng-template>

<ng-template #acceptedAt let-offer="offer">
  <td>{{ offer.activitySummary?.acceptedAt | dateFormat: DATE_SHORT_YEAR_FORMAT }}<br /></td>
</ng-template>

<ng-template #total_amount let-offer="offer">
  <td><dm-mms-table-cell-total-price [quote]="offer" /></td>
</ng-template>

<ng-template #last_send_date let-offer="offer">
  <td>
    <dm-mms-table-cell-last-send-date [quote]="offer" />
  </td>
</ng-template>

<ng-template #last_seen_date let-offer="offer">
  <td>
    {{ offer.activitySummary?.lastSeenAt | dateFormat: DATE_SHORT_YEAR_FORMAT | empty }}
  </td>
</ng-template>

<ng-template #actions let-offer="offer">
  <td>
    <div class="d-flex justify-content-center align-items-center table-actions">
      <div ngbDropdown>
        <button ngbDropdownToggle type="button" class="action-button"></button>

        <div ngbDropdownMenu>
          <a ngbDropdownItem [selectedMarinaRouterLink]="['/renewals', offer.quoteId]" returnUrlCurrentPath>
            <span [style.font-size.px]="16" class="color-primary me-2 i-info-2"></span>
            {{ "DETAILS" | translate }}</a
          >

          @if (offer.actions | arrayIncludes: "SEND_OFFER") {
            <a ngbDropdownItem (click)="clickButtonSendOffer(offer, 'NATIVE_APP')">
              <span [style.font-size.px]="16" class="color-gray me-2 i-mail-2"></span>
              {{ "SEND_MOBILE_OFFER" | translate }}
            </a>

            <a ngbDropdownItem (click)="clickButtonSendOffer(offer, 'WEB')">
              <span [style.font-size.px]="16" class="color-gray me-2 i-mail-2"></span>
              {{ "SEND_WEB_OFFER" | translate }}
            </a>
          }

          @if (offer.actions | arrayIncludes: "RESEND_OFFER") {
            <a ngbDropdownItem (click)="clickButtonSendOffer(offer, 'NATIVE_APP')">
              <span [style.font-size.px]="16" class="color-warning me-2 i-refresh"></span>
              {{ "RESEND_MOBILE_OFFER" | translate }}
            </a>

            <a ngbDropdownItem (click)="clickButtonSendOffer(offer, 'WEB')">
              <span [style.font-size.px]="16" class="color-warning me-2 i-refresh"></span>
              {{ "RESEND_WEB_OFFER" | translate }}
            </a>
          }

          @if (offer.actions | arrayIncludes: "SEND_REMINDER") {
            <a ngbDropdownItem (click)="clickButtonSendReminderOffer(offer, 'NATIVE_APP')">
              <span [style.font-size.px]="16" class="color-gray me-2 i-mail-notification"></span>
              {{ "SEND_MOBILE_REMINDER" | translate }}
            </a>

            <a ngbDropdownItem (click)="clickButtonSendReminderOffer(offer, 'WEB')">
              <span [style.font-size.px]="16" class="color-gray me-2 i-mail-notification"></span>
              {{ "SEND_WEB_REMINDER" | translate }}
            </a>
          }

          @if (offer.actions | arrayIncludes: "SEND_PAYMENT_REMINDER") {
            <a ngbDropdownItem (click)="clickButtonSendPaymentReminder(offer)">
              <span [style.font-size.px]="16" class="me-2 color-gray i-money-notification"></span>
              {{ "SEND_PAYMENT_REMINDER" | translate }}
            </a>
          }

          @if (offer.actions | arrayIncludes: "MARK_AS_PAID") {
            <a ngbDropdownItem (click)="clickButtonMarkOrderAsPaid(offer)">
              <span [style.font-size.px]="16" class="me-2 color-gray i-wallet"></span>
              {{ "MARK_AS_PAID" | translate }}</a
            >
          }

          <!--            <a *ngIf="offer.quoteStatus === QuoteStatus.SENT"-->
          <!--               ngbDropdownItem-->
          <!--               (click)="clickButtonAcceptOffer(offer)"-->
          <!--            >{{ "ACCEPT_OFFER" | translate }}</a>-->

          @if (offer.actions | arrayIncludes: "DECLINE_OFFER") {
            <a ngbDropdownItem (click)="clickButtonDeclineOffer(offer)" class="color-danger">
              <span [style.font-size.px]="16" class="me-2 i-close-circle"></span>
              {{ "DECLINE_OFFER" | translate }}</a
            >
          }

          @if (offer.actions | arrayIncludes: "CANCEL") {
            <a ngbDropdownItem (click)="clickButtonCancelOffer(offer)" class="color-danger">
              <span [style.font-size.px]="16" class="me-2 i-bin"></span>
              {{ "CANCEL" | translate }}</a
            >
          }
        </div>
      </div>
    </div>
  </td>
</ng-template>
