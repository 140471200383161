import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { Ability, IResourceBookingQuotation, MmsDashboardStatName, OptionLabelAndValue } from '@dm-workspace/types';

@Component({
  selector: 'dm-mms-dashboard-bookings-list',
  templateUrl: './mms-dashboard-bookings-list.component.html',
  styleUrls: ['./mms-dashboard-bookings-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsDashboardBookingsListComponent implements OnChanges {
  @Input() public actionRef: TemplateRef<{ booking: IResourceBookingQuotation }>;
  @Input() public bookings?: IResourceBookingQuotation[];
  @Input() viewMode?: OptionLabelAndValue<MmsDashboardStatName>;
  public readonly abilities = Ability;
  public showTotalAmount!: boolean;

  public isView(view: MmsDashboardStatName[]): boolean {
    return view.includes(this.viewMode?.value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.showTotalAmount = !this.isView(['checkinsPerformedOffline']);
  }
}
