import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'dm-map-popup-row',
  templateUrl: './map-popup-row.component.html',
  styleUrls: ['./map-popup-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapPopupRowComponent {
  @Input() label!: string;
  @Input() txt!: string;
  @Input() size: 'm' | 'l' = 'm';

  @HostBinding('class') get sizeClass(): string {
    return `size-${this.size}`;
  }
}
