import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { ListMarinaPylonsResponseDto } from '@dm-workspace/types';

@Component({
  selector: 'dm-map-map-pylon-details',
  templateUrl: './map-pylon-details.component.html',
  styleUrls: ['./map-pylon-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapPylonDetailsComponent {
  pylons = input.required<ListMarinaPylonsResponseDto[]>();
}
