import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { fadeAnimation } from '@dm-workspace/shared';
import { ActivatedRoute } from '@angular/router';
import { MapDashboardChildView } from '../../class/mms-dashboard-child-view.class';
import { MmsDashboardStatName, OptionLabelAndValue } from '@dm-workspace/types';
import { DASHBOARD_VIEWS } from '../../mms-dashboard.const';
import { MmsDashboardViewService } from '../../services/mms-dashboard-view.service';
import { MmsDashboardApiService, ResourceBookingApiService } from '@dm-workspace/data-access';
import { NotificationService } from '@dm-workspace/notification';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MarinasService } from '@dm-workspace/core';

@Component({
  selector: 'dm-mms-dashboard-boat-movements-view',
  templateUrl: './mms-dashboard-boat-movements-view.component.html',
  styleUrls: ['./mms-dashboard-boat-movements-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeAnimation],
})
export class MmsDashboardBoatMovementsViewComponent extends MapDashboardChildView {
  public override views: OptionLabelAndValue<MmsDashboardStatName>[] = DASHBOARD_VIEWS['boat-movements'];

  constructor(
    cd: ChangeDetectorRef,
    dashboardService: MmsDashboardViewService,
    dashboardApiService: MmsDashboardApiService,
    resourceBookingApiService: ResourceBookingApiService,
    notification: NotificationService,
    modal: NgbModal,
    activatedRoute: ActivatedRoute,
    marinaService: MarinasService
  ) {
    super(
      cd,
      dashboardService,
      dashboardApiService,
      resourceBookingApiService,
      notification,
      marinaService,
      modal,
      activatedRoute
    );
  }
}
