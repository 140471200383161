import { inject, Pipe, PipeTransform } from '@angular/core';
import {
  MMS_MARINAS_SUPPORT_VIEWS_CONFIG_TOKEN,
  MmsMarinasSupportView,
} from '../tokens/mms-marinas-support-views.config';
import { MarinasService } from '@dm-workspace/core';
import { map, Observable } from 'rxjs';

@Pipe({ name: 'selectedMarinaSupportView' })
export class MmsSelectedMarinaSupportViewPipe implements PipeTransform {
  #marinasSupportViewsConfig = inject(MMS_MARINAS_SUPPORT_VIEWS_CONFIG_TOKEN);
  #marinasService = inject(MarinasService);

  check(view: MmsMarinasSupportView, marinaCode = this.#marinasService.selectedMarina().code): boolean {
    const config = this.#marinasSupportViewsConfig[view];
    if (!config) {
      return true;
    }

    const { supportedMarinas, unsupportedMarinas } = config;
    if (supportedMarinas?.includes(marinaCode)) {
      return true;
    }
    if (unsupportedMarinas?.includes(marinaCode)) {
      return false;
    }
    if (supportedMarinas === '*') {
      return true;
    }
    if (unsupportedMarinas === '*') {
      return false;
    }

    return true;
  }

  transform(view: MmsMarinasSupportView): Observable<boolean> {
    return this.#marinasService.selectedMarina$.pipe(map((marina) => this.check(view, marina.code)));
  }
}
