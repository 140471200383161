import { AfterViewInit, Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { MmsDashboardBoatAlertNotificationService } from '@dm-workspace/mms-panel/mms-dashboard';

@Component({
  selector: 'dm-mms-layout-main-view',
  templateUrl: './mms-layout-main-view.component.html',
  styleUrls: ['./mms-layout-main-view.component.scss'],
})
export class MmsLayoutMainViewComponent implements AfterViewInit, OnDestroy {
  @ViewChild('boatAlertErrorTpl')
  private readonly boatAlertErrorTpl: TemplateRef<HTMLElement>;

  constructor(private boatAlertNotificationService: MmsDashboardBoatAlertNotificationService) {}

  public ngAfterViewInit() {
    this.boatAlertNotificationService.showBoatAlerts(this.boatAlertErrorTpl).subscribe();
  }

  public ngOnDestroy() {
    this.boatAlertNotificationService.closeUserSse();
  }
}
