import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthService, UserService } from '@dm-workspace/core';
import { Observable } from 'rxjs';
import { IUserSingle } from '@dm-workspace/types';

@Component({
  selector: 'dm-mms-layout-user-actions',
  templateUrl: './mms-layout-user-actions.component.html',
  styleUrls: ['./mms-layout-user-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsLayoutUserActionsComponent {
  public user$: Observable<IUserSingle | null> = this.userService.user$;
  constructor(
    private authService: AuthService,
    private userService: UserService
  ) {}

  public logout(): void {
    this.authService.logout();
  }
}
