import { Pipe, PipeTransform } from '@angular/core';
import { ShortResourceBookingBody } from '@dm-workspace/types';

@Pipe({
  name: 'bookingsSourceName',
})
export class MmsBookingsSourceNamePipe implements PipeTransform {
  transform(bookings: ShortResourceBookingBody[]): string {
    if (!bookings?.length) {
      return '';
    }
    return bookings.map((booking) => booking.source).join(', ');
  }
}
