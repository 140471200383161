<div class="sidebar" @enterLeaveFromLeft>
  <ng-content select="dm-mms-ui-full-page-sidebar" />
</div>

<div class="header" @enterLeaveFromTop>
  <ng-content select="dm-mms-ui-full-page-header" />
</div>

<div class="body" @enterLeaveOpacity>
  <ng-content />
</div>

<div class="footer" @enterLeaveFromBottom>
  <ng-content select="dm-mms-ui-full-page-footer" />
</div>
