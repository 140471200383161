import { ChangeDetectorRef, Directive, inject, OnDestroy, OnInit } from '@angular/core';
import { ApiValidator, CollectionListClassDirective } from '@dm-workspace/shared';
import {
  IResourceBookingQuotation,
  MmsDashboardStat,
  MmsDashboardStatName,
  OptionLabelAndValue,
  PaginatedNormal,
  PaginationMetadataNormal,
  QuotationStatus,
} from '@dm-workspace/types';
import { combineLatest, filter, finalize, map, of, skip, Subscription, switchMap } from 'rxjs';
import { MmsDashboardViewService } from '../services/mms-dashboard-view.service';
import { MmsDashboardApiService, ResourceBookingApiService } from '@dm-workspace/data-access';
import { NotificationService } from '@dm-workspace/notification';
import { MmsBoatReportArrivalModalComponent } from '@dm-workspace/mms-panel/shared';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { MarinasService } from '@dm-workspace/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiValidatorService } from '@dm-workspace/forms';

@Directive()
export abstract class MapDashboardChildView extends CollectionListClassDirective<void> implements OnInit, OnDestroy {
  public override pagination: Omit<PaginationMetadataNormal, 'type'> = {
    limit: 20,
    offset: 0,
    totalCount: 0,
  };
  public bookings?: IResourceBookingQuotation[];
  public stats?: MmsDashboardStat;
  public pending = true;
  protected hasLoadMoreButton = false;
  protected deleted = 0;
  public pendingMarkCompleted: { [key: string]: boolean } = {};
  public views?: OptionLabelAndValue<MmsDashboardStatName>[] = [];
  public viewMode?: OptionLabelAndValue<MmsDashboardStatName> = this.views[0];
  public selectedIndex?: number;
  protected statName: MmsDashboardStatName;
  protected apiValidator = inject(ApiValidatorService);

  private subscriptions = new Subscription();

  public get hasBookingsAllDone(): boolean {
    return !this.pending && this.stats?.todoCount === 0;
  }
  public get hasBookingsTodo(): boolean {
    return this.stats?.todoCount > 0;
  }

  protected constructor(
    protected cd: ChangeDetectorRef,
    protected dashboardService: MmsDashboardViewService,
    protected dashboardApiService: MmsDashboardApiService,
    protected resourceBookingApiService: ResourceBookingApiService,
    protected notification: NotificationService,
    protected marinaService: MarinasService,
    protected modal?: NgbModal,
    protected activatedRoute?: ActivatedRoute
  ) {
    super();
  }

  public ngOnInit() {
    this.subscriptions.add(
      this.marinaService.selectedMarinaChange$.subscribe(() => {
        this.resetPageAndFilters(false);
        this.bookings = null;
      })
    );

    this.subscriptions.add(
      this.dashboardService.stats$
        .pipe(
          filter(Boolean),
          switchMap((stats) => {
            return (this.activatedRoute?.paramMap || of(null)).pipe(
              map((params) => {
                const viewMode = params?.get('viewMode');
                if (viewMode) {
                  this.findActiveByParam(viewMode);
                }
                return stats;
              })
            );
          })
        )
        .subscribe((stats) => {
          this.stats = stats[this.viewMode?.value || this.statName];

          if (this.views) {
            this.views = this.views.map((view) => {
              return {
                ...view,
                count: stats[view.value].todoCount,
              };
            });
          }

          if (this.stats?.todoCount === 0) {
            this.pending = false;
            this.cd.detectChanges();
            return;
          }

          this.fetchCollection();
        })
    );

    this.subscriptions.add(
      combineLatest([this.dashboardService.refreshStatsTrigger$, this.resourceBookingApiService.refreshView$])
        .pipe(skip(1))
        .subscribe(() => {
          this.pending = true;
          this.bookings = null;
          this.resetPageAndFilters(false);
          this.cd.detectChanges();
        })
    );
  }
  override onPageChange(page: number): void {
    if (this.hasLoadMoreButton) {
      this.pagination.offset += this.pagination.limit;
    } else {
      this.pagination.offset = this.pagination.limit * (page - 1);
    }

    this.fetchCollection();
  }
  findActiveByParam(paramName: string) {
    if (!this.views.length) {
      return;
    }

    const findIndex = this.views.findIndex((value) => value.path === paramName);
    if (findIndex >= 0) {
      this.selectedIndex = findIndex;
      this.viewMode = this.views[findIndex];
    }
    this.resetPageAndFilters(false);
    this.cd.detectChanges();
  }

  protected onApiFetchSuccess(res: PaginatedNormal<IResourceBookingQuotation>) {
    if (this.hasLoadMoreButton) {
      this.bookings = [...(this.bookings || []), ...res.values];
    } else {
      this.bookings = res.values;
    }
  }

  public fetchCollection(): void {
    this.pending = true;
    this.cd.detectChanges();

    this.dashboardApiService
      .fetchResourceBookingsByStats(this.viewMode?.value || this.statName, this.getParams())
      .pipe(
        finalize(() => {
          this.pending = false;
          this.cd.detectChanges();
        })
      )
      .subscribe({
        next: (res) => {
          this.pagination = res.metadata;
          this.onApiFetchSuccess(res);
        },
        error: (res: HttpErrorResponse) => {
          const notificationContent = this.apiValidator.getApiNotificationError(res);
          this.notification.openError(notificationContent);
        },
      });
  }
  override getParams() {
    if (this.hasLoadMoreButton && this.deleted) {
      this.pagination.offset = this.pagination.offset - this.deleted;
      this.deleted = 0;
    }
    return super.getParams();
  }
  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public openArrivalModal(booking: IResourceBookingQuotation) {
    const modal = this.modal.open(MmsBoatReportArrivalModalComponent, {
      size: 'md',
    });
    const modalComponent = modal.componentInstance as MmsBoatReportArrivalModalComponent;
    modalComponent.boatId = booking.boat.id;
    modalComponent.resource = booking.resource;
    modalComponent.confirmed.subscribe((success) => {
      if (!success) {
        return;
      }
      if (this.hasLoadMoreButton) {
        this.removeFromBookings(booking.id);
      } else {
        this.fetchCollection();
      }
    });
  }

  public changeStatus(booking: IResourceBookingQuotation, status: QuotationStatus, setPendingForPage?: boolean): void {
    if (this.pendingMarkCompleted[booking.humanReadableId]) {
      return;
    }

    if (setPendingForPage) {
      this.pending = true;
    } else {
      this.pendingMarkCompleted[booking.humanReadableId] = true;
    }

    const { marina: marinaCode, id } = booking;

    this.resourceBookingApiService.changeStatus(id, status, marinaCode).subscribe({
      next: () => {
        this.dashboardService.refreshStats();

        this.notification.add({
          type: 'success',
          text: status === QuotationStatus.CHECKED_OFFLINE ? 'BOOKING_CHECKED_OFFLINE' : 'QUOTATION_COMPLETED',
        });
      },
      error: (res: HttpErrorResponse) => {
        if (setPendingForPage) {
          this.pending = false;
        } else {
          this.pendingMarkCompleted[booking.humanReadableId] = false;
        }
        this.cd.detectChanges();
        this.notification.openError(ApiValidator.getApiNotificationError(res));
      },
    });
  }
  removeFromBookings(bookingId: string) {
    this.bookings = this.bookings?.filter((bookingResource) => bookingResource.id !== bookingId);
    this.deleted++;
    this.cd.markForCheck();
  }
}
