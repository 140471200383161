import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { MmsDashboardStatName } from '@dm-workspace/types';
import { fadeAnimation } from '@dm-workspace/shared';
import { MmsDashboardApiService, ResourceBookingApiService } from '@dm-workspace/data-access';
import { MmsDashboardViewService } from '../../services/mms-dashboard-view.service';
import { MapDashboardChildView } from '../../class/mms-dashboard-child-view.class';
import { NotificationService } from '@dm-workspace/notification';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MarinasService } from '@dm-workspace/core';

@Component({
  selector: 'dm-mms-dashboard-departures-view',
  templateUrl: './mms-dashboard-departures-view.component.html',
  styleUrls: ['./mms-dashboard-departures-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeAnimation],
})
export class MmsDashboardDeparturesViewComponent extends MapDashboardChildView {
  protected override statName: MmsDashboardStatName = 'expectedDepartures';

  constructor(
    modal: NgbModal,
    cd: ChangeDetectorRef,
    dashboardService: MmsDashboardViewService,
    dashboardApiService: MmsDashboardApiService,
    resourceBookingApiService: ResourceBookingApiService,
    notification: NotificationService,
    marinaService: MarinasService
  ) {
    super(cd, dashboardService, dashboardApiService, resourceBookingApiService, notification, marinaService, modal);
  }
}
