import { Component, EventEmitter, inject, input, Output } from '@angular/core';
import { Quote, QuoteDetails } from '@dm-workspace/types';
import { MmsOfferActionsService } from '../../services/mms-offer-actions';
import { MmsOfferActionSendTarget } from '../../modals/mms-offer-action-send-modal/mms-offer-action-send-modal.component';
import { filter } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'dm-mms-offer-header-actions',
  templateUrl: './mms-offer-header-actions.component.html',
  styleUrls: ['./mms-offer-header-actions.component.scss'],
})
export class MmsOfferHeaderActionsComponent {
  readonly #offerActionsService = inject(MmsOfferActionsService);
  offer = input.required<QuoteDetails>();
  @Output() offerChange: EventEmitter<QuoteDetails> = new EventEmitter<QuoteDetails>();
  protected clickButtonSendOffer(offer: Quote, target: MmsOfferActionSendTarget) {
    this.#offerActionsService
      .clickButtonSendOffer(offer, target)
      .pipe(
        filter(Boolean),
        tap(() => this.offerChange.emit())
      )
      .subscribe();
  }

  protected clickButtonSendReminderOffer(offer: Quote, target: MmsOfferActionSendTarget) {
    this.#offerActionsService
      .clickButtonSendReminderOffer(offer, target)
      .pipe(
        filter(Boolean),
        tap(() => this.offerChange.emit())
      )
      .subscribe();
  }

  protected clickButtonAcceptOffer(offer: Quote) {
    this.#offerActionsService
      .clickButtonAcceptOffer(offer)
      .pipe(
        filter(Boolean),
        tap(() => this.offerChange.emit())
      )
      .subscribe();
  }

  protected clickButtonSendPaymentReminder(offer: Quote) {
    this.#offerActionsService
      .clickButtonSendPaymentReminder(offer)
      .pipe(
        filter(Boolean),
        tap(() => this.offerChange.emit())
      )
      .subscribe();
  }

  protected clickButtonMarkOrderAsPaid(offer: Quote) {
    this.#offerActionsService
      .clickButtonMarkOrderAsPaid(offer)
      .pipe(
        filter(Boolean),
        tap(() => this.offerChange.emit())
      )
      .subscribe();
  }

  protected clickButtonCancelOffer(offer: Quote) {
    this.#offerActionsService
      .clickButtonCancelOffer(offer)
      .pipe(
        filter(Boolean),
        tap(() => this.offerChange.emit())
      )
      .subscribe();
  }

  protected clickButtonDeclineOffer(offer: Quote) {
    this.#offerActionsService
      .clickButtonDeclineOffer(offer)
      .pipe(
        filter(Boolean),
        tap(() => this.offerChange.emit())
      )
      .subscribe();
  }
}
