import { ChangeDetectionStrategy, Component, inject, Input, signal } from '@angular/core';
import { Quote } from '@dm-workspace/types';
import { MmsQuotesApiService } from '@dm-workspace/data-access';
import { NotificationService } from '@dm-workspace/notification';
import { HttpErrorResponse } from '@angular/common/http';
import { finalize } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiValidator } from '@dm-workspace/shared';

@Component({
  selector: 'dm-mms-quote-action-mark-as-paid-modal',
  templateUrl: './mms-quote-action-mark-as-paid-modal.component.html',
  styleUrls: ['./mms-quote-action-mark-as-paid-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsQuoteActionMarkAsPaidModalComponent {
  @Input() public quote?: Quote;
  @Input({ required: true }) public quoteId: string;
  protected readonly apiCalling = signal<boolean>(false);
  readonly #quotesApiService = inject(MmsQuotesApiService);
  readonly #notificationService = inject(NotificationService);
  readonly #aModal = inject(NgbActiveModal);

  protected dismissModal() {
    this.#aModal.close(false);
  }

  protected buttonConfirmClick() {
    if (this.apiCalling()) {
      return;
    }
    this.apiCalling.set(true);

    this.#quotesApiService
      .markAsPaid(this.quoteId)
      .pipe(finalize(() => this.apiCalling.set(false)))
      .pipe(
        tap(() => {
          this.#notificationService.openSuccess();
          this.#aModal.close(true);
        })
      )
      .subscribe({
        error: (res: HttpErrorResponse) => {
          this.#notificationService.openError(ApiValidator.getApiNotificationError(res));
        },
      });
  }
}
