<dm-ui-animated-fixed-header>
  <div hidden-content>
    <form [formGroup]="form" class="pt-xl" (reset)="resetForm()">
      <div class="row row-cols-6 gy-4">
        <dm-form-group>
          <label>{{ "OFFER_NAME" | translate }}</label>
          <input [placeholder]="'ALL' | translate" formControlName="nameFragment" />
          <dm-form-error-message controlName="nameFragment" />
        </dm-form-group>

        <dm-form-group>
          <label>{{ "BOAT" | translate }}</label>
          <dm-form-select-typeahead
            typeahead="boats"
            [notFoundText]="'NO_BOATS_FOUND' | translate"
            bindLabel="name"
            formControlName="boatId"
            bindValue="id"
            [placeholder]="'ALL' | translate"
          />
          <dm-form-error-message controlName="boatId" />
        </dm-form-group>

        <dm-form-group>
          <label>{{ "CUSTOMER" | translate }}</label>
          <dm-form-select-typeahead
            typeahead="customers"
            [notFoundText]="'NO_CUSTOMERS_FOUND' | translate"
            formControlName="customerId"
            bindValue="id"
            bindLabel="displayName"
            [placeholder]="'ALL' | translate"
          />
          <dm-form-error-message controlName="customerId" />
        </dm-form-group>

        <dm-form-group>
          <label>{{ "START_DATE" | translate }}</label>
          <dm-form-input-icon icon="calendar">
            <input
              dmForm
              formControlName="dateFrom"
              [placeholder]="'ALL' | translate"
              container="body"
              ngbDatepicker
              #d1="ngbDatepicker"
              [maxDate]="adapter.fromModel(form.get('dateTo').value)"
              (click)="toggleDatepicker($event, d1)"
            />
          </dm-form-input-icon>
          <dm-form-error-message controlName="dateFrom" />
        </dm-form-group>

        <dm-form-group [clear]="true">
          <label>{{ "END_DATE" | translate }}</label>
          <dm-form-input-icon icon="calendar">
            <input
              dmForm
              formControlName="dateTo"
              container="body"
              ngbDatepicker
              [placeholder]="'ALL' | translate"
              #d2="ngbDatepicker"
              [minDate]="adapter.fromModel(form.get('dateFrom').value)"
              (click)="toggleDatepicker($event, d2)"
            />
          </dm-form-input-icon>
          <dm-form-error-message controlName="dateTo" />
        </dm-form-group>

        <dm-form-group>
          <label>{{ "OFFER_STATUS" | translate }}</label>
          <ng-select [multiple]="true" [placeholder]="'ALL' | translate" dmForm formControlName="quoteStatus">
            <ng-option *ngFor="let quoteStatus of quoteStatuses" [value]="quoteStatus">
              {{ quoteStatus | getKeyFromEnum : QuoteStatus | translate }}
            </ng-option>
          </ng-select>
          <dm-form-error-message controlName="quoteStatus" />
        </dm-form-group>

        <dm-form-group>
          <label>{{ "OFFER_SEND_ON" | translate }}</label>
          <dm-form-input-icon icon="calendar">
            <input
              dmForm
              formControlName="sentAt"
              [placeholder]="'ALL' | translate"
              container="body"
              ngbDatepicker
              #dSentAt="ngbDatepicker"
              (click)="toggleDatepicker($event, dSentAt)"
            />
          </dm-form-input-icon>
          <dm-form-error-message controlName="sentAt" />
        </dm-form-group>

        <dm-form-group>
          <label>{{ "OFFER_ACCEPTED_ON" | translate }}</label>
          <dm-form-input-icon icon="calendar">
            <input
              dmForm
              formControlName="acceptedAt"
              [placeholder]="'ALL' | translate"
              container="body"
              ngbDatepicker
              #dAcceptedAt="ngbDatepicker"
              (click)="toggleDatepicker($event, dAcceptedAt)"
            />
          </dm-form-input-icon>
          <dm-form-error-message controlName="acceptedAt" />
        </dm-form-group>

        <dm-form-group>
          <label>{{ "PAYMENT_STATUS" | translate }}</label>
          <ng-select [placeholder]="'ALL' | translate" dmForm formControlName="paymentStatus">
            <ng-option
              *ngFor="let option of GetQuotesParamPaymentStatus | keyvalue: NgForUtils.originalOrder"
              [value]="option.key"
            >
              {{ option.value | orderPaymentStatusTranslateKey | translate }}
            </ng-option>
          </ng-select>
          <dm-form-error-message controlName="paymentStatus" />
        </dm-form-group>

        <div class="col-auto ms-auto d-flex align-items-end">
          <button class="btn btn-tertiary" type="reset">
            {{ "RESET_ALL" | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</dm-ui-animated-fixed-header>
