import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { QuoteDetails } from '@dm-workspace/types';

@Component({
  selector: 'dm-mms-offer-preview',
  templateUrl: './mms-offer-preview.component.html',
  styleUrls: ['./mms-offer-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsOfferPreviewComponent {
  @Input({ required: true }) public offer: QuoteDetails;
}
