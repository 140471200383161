import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { MmsBoatsApiService } from '@dm-workspace/data-access';
import {
  BerthAvailabilityReservationDto,
  BerthReservationsStatusInMarina,
  IBoatAddMovementPayload,
  IBoatDepartureArrivalPayload,
  IBoatDto,
  IBoatMovementPayload,
} from '@dm-workspace/types';
import { finalize, forkJoin, map } from 'rxjs';
import { MapBerthClass } from '../../berths.class';

@Component({
  selector: 'dm-map-popup',
  templateUrl: './map-popup.component.html',
  styleUrls: ['./map-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapPopupComponent implements OnChanges {
  @Input() berth?: MapBerthClass;
  @Input() isUtilization: boolean;

  @Output() moveBoat: EventEmitter<IBoatMovementPayload> = new EventEmitter();
  @Output() departureBoat: EventEmitter<IBoatDepartureArrivalPayload> = new EventEmitter();
  @Output() addBoatMovement: EventEmitter<IBoatAddMovementPayload> = new EventEmitter();

  public boats?: Record<string, IBoatDto> = {};
  public pending = false;

  constructor(
    protected boatService: MmsBoatsApiService,
    protected cd: ChangeDetectorRef
  ) {}

  get showNewBookingButton() {
    return this.berth.reservationStatus.includes(BerthReservationsStatusInMarina.available);
  }
  ngOnChanges(): void {
    if (this.berth) {
      const resources = this.isUtilization ? this.berth.data.occupancy : this.berth.data.bookings;
      const boatIds = new Set([...(resources || []).map((value) => value.boatId)]);
      if (boatIds.size) {
        this.pending = true;
        this.cd.markForCheck();
        forkJoin([...boatIds].filter((value) => value !== null).map((boatId) => this.boatService.fetchById(boatId)))
          .pipe(
            finalize(() => {
              this.pending = false;
              this.cd.markForCheck();
            }),
            map((value) => value.reduce((p, c) => ({ ...p, [c.id]: c }), {}))
          )
          .subscribe((res) => {
            this.boats = res;
            this.cd.markForCheck();
          });
      } else {
        this.boats = {};
      }
    }
  }

  get occupancys() {
    return [...this.berth.data.occupancy, ...this.berth.data.expectedArrivals] || [];
  }

  get reservations() {
    const reservations = this.berth?.data.bookings || [];
    return this.isUtilization
      ? reservations.filter((value) => this.checkIfBoatIsInOccupancy(value.boatId))
      : reservations;
  }

  protected onBoatMovementClick(boat: IBoatDto, fromResourceId: string, reservation?: BerthAvailabilityReservationDto) {
    if (this.berth && boat) {
      // const { berthId, berthName } = this.berth;
      const payload: IBoatMovementPayload = {
        fromResourceId,
        boat,
      };
      if (reservation && !this.occupancys.some((value) => value.boatId === boat.id)) {
        payload.reservation = reservation;
      }
      this.moveBoat.emit(payload);
    }
  }

  protected openAddBoatMovementModal(boat: IBoatDto, resourceId: string) {
    this.addBoatMovement.emit({
      boat,
      resourceId,
    });
  }

  checkIfBoatIsInReservation(boatId: string) {
    return this.reservations.some((value) => value.boatId === boatId);
  }

  checkIfBoatIsInOccupancy(boatId: string): boolean {
    return this.occupancys.some((value) => value.boatId === boatId);
  }

  onDepartureBoatClick(id: string, resourceId: string) {
    this.departureBoat.emit({
      boatId: id,
      resourceId: resourceId,
      berthName: this.berth?.data.berthName,
    });
  }
}
