import { Component, inject } from '@angular/core';
import { MmsOfferViewService } from '../../services/mms-offer-view.service';

@Component({
  selector: 'dm-mms-offer-details-view',
  templateUrl: './mms-offer-details-view.component.html',
  styleUrls: ['./mms-offer-details-view.component.scss'],
})
export class MmsOfferDetailsViewComponent {
  private readonly offerViewService = inject(MmsOfferViewService);
  protected readonly offer = this.offerViewService.offer;
}
