import { ChangeDetectionStrategy, Component, Host, Input } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { DateUtils } from '@dm-workspace/utils';

export type MmsEnquiryNoteFormGroup = FormGroup<{
  content: FormControl<string>;
  asLastContact: FormControl<boolean>;
}>;

@Component({
  selector: 'dm-mms-enquiry-note-form',
  templateUrl: './mms-enquiry-note-form.component.html',
  styleUrls: ['./mms-enquiry-note-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsEnquiryNoteFormComponent {
  @Input() sending: boolean;
  protected currentTime$ = DateUtils.currentTime$;

  constructor(@Host() protected formGroupDirective: FormGroupDirective) {}
}
