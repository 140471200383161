<table class="table selection-table">
  <colgroup>
    <col [style.width.px]="60" />
    <col />
    <col [style.width.px]="100" />
    <col [style.width.px]="100" />
    <col [style.width.px]="200" />
  </colgroup>
  <thead>
    <th></th>
    <th>{{ "BERTH" | translate }}</th>
    <th>{{ "LENGTH_M" | translate }}</th>
    <th>{{ "WIDTH_M" | translate }}</th>
    <th>{{ "TOTAL_PRICE" | translate }}</th>
  </thead>
  <tbody>
    <tr *ngFor="let berth of berths" (click)="selectBerth(berth.resource.resourceId)" [ngClass]="rowClassNames(berth)">
      <td><dm-form-radio-button-visual [isActive]="isActive(berth)" /></td>
      <td>{{ berth.resource.name }}</td>
      <td>{{ boat?.loa }}</td>
      <td>{{ boat?.maxBeam }}</td>
      <td>
        {{ getTotalPrice(berth) | currency: berth.pricingDetails.currency }}
        <i class="ms-1 icon i-info" [ngbTooltip]="totalPriceTooltip" container="body"></i>
        <ng-template #totalPriceTooltip>
          <p class="mb-1">
            <strong>{{ "TOTAL_PRICE_INCLUDE" | translate }}:</strong>
          </p>
          <p class="d-flex justify-content-between">
            <span class="pe-3">{{ "MARINA_PRODUCT_MANDATORY_.MOORING" | translate }}</span>
            <span class="text-right">{{
              berth.pricingDetails.sumPriceGross | currency: berth.pricingDetails.currency
            }}</span>
          </p>
          <p *ngFor="let product of mandatoryProductsWithoutMooring" class="d-flex justify-content-between mt-1">
            <span class="pe-3">{{ "MARINA_PRODUCT_MANDATORY_." + product.product | translate }}</span>
            <span class="text-right">{{ product.totalPriceGross | currency: product.currency }}</span>
          </p>
        </ng-template>
      </td>
    </tr>
  </tbody>
</table>
