import { Component, inject } from '@angular/core';
import { MmsOfferViewService } from '../../services/mms-offer-view.service';
import { MARINA_URL_TOKEN } from '@dm-workspace/types';

@Component({
  selector: 'dm-mms-offers-offer-details-view',
  templateUrl: './mms-offer-view.component.html',
  styleUrls: ['./mms-offer-view.component.scss'],
})
export class MmsOfferViewComponent {
  private readonly offerViewService = inject(MmsOfferViewService);
  protected readonly offer = this.offerViewService.offer;
  protected readonly fetchingOffer = this.offerViewService.fetching;
  protected readonly MARINA_URL_TOKEN = MARINA_URL_TOKEN;
}
