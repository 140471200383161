import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  BoatMovementOperationForStatus,
  CustomerCruiseDetailsDto,
  IResourceBookingQuotation,
} from '@dm-workspace/types';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MmsBoatsApiService, ResourceBookingApiService } from '@dm-workspace/data-access';
import { NotificationService } from '@dm-workspace/notification';
import { finalize } from 'rxjs';
import { BoatOperationModalClass } from '../../classes/boat-operation-modal.class';

@Component({
  selector: 'dm-mms-shared-boat-verify-cruise-modal',
  templateUrl: './boat-verify-cruise-modal.component.html',
  styleUrls: ['./boat-verify-cruise-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoatVerifyCruiseModalComponent
  extends BoatOperationModalClass<IResourceBookingQuotation>
  implements OnInit
{
  operationType = BoatMovementOperationForStatus.VERIFY_CRUISE;
  successMessage = 'CRUISE_VERIFIED';
  errorMessage = 'CRUISE_VERIFIED_ERROR';
  override pending = true;
  public cruiseDetails?: CustomerCruiseDetailsDto;

  constructor(
    cd: ChangeDetectorRef,
    activeModal: NgbActiveModal,
    resourceBookingApi: ResourceBookingApiService,
    notification: NotificationService,
    resourceBookingService: ResourceBookingApiService,
    boatApi: MmsBoatsApiService
  ) {
    super(cd, activeModal, resourceBookingApi, notification, resourceBookingService, boatApi);
  }

  public override onAction(): void {
    if (this.pending || !this.valid()) {
      return;
    }
    this.pending = true;

    this.resourceBookingService
      .verifyCruise(this.booking.id, !!this.allowsBerthReuse)
      .pipe(
        finalize(() => {
          this.pending = false;
          this.cd.detectChanges();
        })
      )
      .subscribe({
        next: () => {
          this.onSuccess(this.successMessage);
        },
        error: () => {
          this.onError(this.errorMessage);
        },
      });
  }

  ngOnInit(): void {
    this.resourceBookingService
      .cruiseDetails(this.booking.id)
      .pipe(
        finalize(() => {
          this.pending = false;
          this.cd.markForCheck();
        })
      )
      .subscribe((value) => {
        this.cruiseDetails = value;
      });
  }
}
