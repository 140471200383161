import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {
  BoatFitmentWithReasons,
  BookingResource,
  BookingResourceProduct,
  DateRange,
  IAvailabilityCheckPayload,
  IResourceBookingCalculatePricingBoatData,
  ISelectedResourceWithType,
  SelectionTypes,
} from '@dm-workspace/types';

@Component({
  selector: 'dm-mms-bookings-berth-selection',
  templateUrl: './bookings-berth-selection.component.html',
  styleUrls: ['./bookings-berth-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingsBerthSelectionComponent {
  @Input() set berths(value: BookingResource[]) {
    this.matchingBerths = [];

    value.forEach((v) => {
      const conditionCase = v.pricingDetails.calculationBase;
      if (conditionCase === 'boat') {
        this.matchingBerths.push(v);
      }
    });
  }
  @Input() dateRange: DateRange;
  @Input() products: BookingResourceProduct[];
  @Input() marinaCode: string;
  @Input() boat?: IResourceBookingCalculatePricingBoatData;
  @Input() searchInGaps: boolean;

  @Output() resourceSelected = new EventEmitter<ISelectedResourceWithType>();

  public matchingBerths: BookingResource[] = [];
  public selectionTypes = SelectionTypes;
  public selectedResource: { [key in SelectionTypes]?: string } = {};
  public activeContent: string;
  public willBoatFit: BoatFitmentWithReasons;

  onResourceSelect(
    selectionType: SelectionTypes,
    [selectedBerth, willBoatFit]: [string, BoatFitmentWithReasons] = [undefined, undefined]
  ): void {
    if ((selectedBerth && selectedBerth !== this.selectedResource[selectionType]) || selectedBerth === null) {
      this.selectedResource[selectionType] = selectedBerth;
      this.willBoatFit = willBoatFit;
    }
    this.resourceSelected.emit({
      id: this.selectedResource[selectionType],
      type: selectionType,
    });
  }

  public availabilityPayload(): IAvailabilityCheckPayload {
    if (!this.selectedResource || !this.selectedResource.manual) {
      return null;
    }

    return {
      ...this.dateRange,
      resourceId: this.selectedResource.manual,
      boatId: this.boat?.id,
      marinaCode: this.marinaCode,
    };
  }
}
