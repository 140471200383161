import { inject, Injectable } from '@angular/core';
import { Router, UrlSegment, UrlSegmentGroup, UrlTree } from '@angular/router';
import { MarinasService } from '@dm-workspace/core';
import { first, map } from 'rxjs/operators';
import { lastValueFrom, Observable, switchMap } from 'rxjs';

type NavigateCommandValue = Array<string | UrlTree>;
type SelectedMarinaRouteValue = string | UrlTree | NavigateCommandValue;

@Injectable({
  providedIn: 'root',
})
export class SelectedMarinaRouter {
  #router = inject(Router);
  #marinasService = inject(MarinasService);

  async navigateByUrl(...params: Parameters<Router['navigateByUrl']>): ReturnType<Router['navigateByUrl']> {
    const newParams: Parameters<Router['navigateByUrl']> = [...params];
    const obs$ = this.#addMarinaCodeToUrlParams(newParams[0]).pipe(
      switchMap((updatedUrl) => {
        newParams[0] = updatedUrl as string | UrlTree;
        return this.#router.navigateByUrl(...newParams);
      })
    );
    return await lastValueFrom(obs$);
  }

  async navigate(...params: Parameters<Router['navigate']>): ReturnType<Router['navigate']> {
    const newParams: Parameters<Router['navigate']> = [...params];
    const obs$ = this.#addMarinaCodeToUrlParams(newParams[0]).pipe(
      switchMap((updatedUrl) => {
        newParams[0] = updatedUrl as NavigateCommandValue;
        return this.#router.navigate(...newParams);
      })
    );
    return await lastValueFrom(obs$);
  }

  parseUrl(...params: Parameters<Router['parseUrl']>): Observable<ReturnType<Router['parseUrl']>> {
    const newParams: Parameters<Router['parseUrl']> = [...params];
    return this.#addMarinaCodeToUrlParams(newParams[0]).pipe(
      map((updatedUrl) => {
        newParams[0] = updatedUrl as string;
        return this.#router.parseUrl(...newParams);
      })
    );
  }

  #addMarinaCodeToUrlParams(url: SelectedMarinaRouteValue): Observable<SelectedMarinaRouteValue> {
    return this.#marinasService.selectedMarina$.pipe(
      map((marina) => marina?.code),
      first((value) => !!value),
      map((selectedMarinaCode) => {
        if (typeof url === 'string') {
          return `/${selectedMarinaCode}${url}`;
        } else if (url instanceof UrlTree) {
          return this.#prependMarinaCodeToUrlTree(url, selectedMarinaCode);
        } else if (Array.isArray(url)) {
          return [selectedMarinaCode, ...url];
        } else {
          return undefined as never;
        }
      })
    );
  }

  #prependMarinaCodeToUrlTree(urlTree: UrlTree, marinaCode: string): UrlTree {
    const updatedRoot = this.#prependMarinaCodeToSegmentGroup(urlTree.root, marinaCode);
    return new UrlTree(updatedRoot, urlTree.queryParams, urlTree.fragment);
  }

  #prependMarinaCodeToSegmentGroup(segmentGroup: UrlSegmentGroup, marinaCode: string): UrlSegmentGroup {
    const updatedSegments: UrlSegment[] = [new UrlSegment(marinaCode, {}), ...segmentGroup.segments];

    return new UrlSegmentGroup(updatedSegments, segmentGroup.children);
  }
}
