<div class="modal-header">
  <h2 class="title">{{ "CREATE_NEW_OFFER_VERSION" | translate }}</h2>
</div>
<div class="modal-body" [innerHTML]="'QUOTE_CREATE_NEW_VERSION_INFORMATION' | translate"></div>

<div class="modal-footer justify-content-between d-flex">
  <button type="button" class="btn btn-secondary-negative" (click)="onButtonCancel()">
    {{ "CANCEL" | translate }}
  </button>
  <button type="button" class="btn btn-primary" (click)="onButtonConfirm()">
    <dm-ui-spinner class="me-1" />
    {{ "CONFIRM" | translate }}
  </button>
</div>
