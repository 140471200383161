import { Pipe, PipeTransform } from '@angular/core';
import { QuoteCancelReason } from '@dm-workspace/types';
import { KeyValue } from '@angular/common';

@Pipe({
  name: 'quoteCancelReasonTranslateKey',
})
export class MmsQuoteCancelReasonTranslateKeyPipe implements PipeTransform {
  public transform(reason: KeyValue<string, QuoteCancelReason>): string {
    if (!reason) {
      return;
    }
    return `OFFER_CANCEL_REASON_.${reason.key}`;
  }
}
