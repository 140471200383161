import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { AlertMapResponseDto } from '@dm-workspace/types';
import { take } from 'rxjs';
import { MapBerthClass } from './../../berths.class';

export type AlertMapResponseDtoWithBerth = AlertMapResponseDto & {
  berth: MapBerthClass;
};
@Component({
  selector: 'dm-map-alerts-drawer',
  templateUrl: './map-alerts-drawer.component.html',
  styleUrls: ['./map-alerts-drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapAlertsDrawerComponent implements OnChanges {
  @Input() public alerts: AlertMapResponseDtoWithBerth[] = [];
  @Input() public selectedBerthId?: string;
  @Input() public selectedAlertId?: string;

  @Output() public berthClick = new EventEmitter<AlertMapResponseDtoWithBerth>();

  @ViewChildren('alertRow') private alertRows: QueryList<ElementRef<HTMLElement>>;

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.alerts?.currentValue?.length || changes.selectedAlertId?.currentValue) {
      const selectedAlertId = this.selectedAlertId || changes.selectedAlertId?.currentValue;
      if (selectedAlertId) {
        this.alertRows?.changes.pipe(take(1)).subscribe(() => {
          const selectedAlertIndex = this.getAlertIndexByAlertId(selectedAlertId);
          this.scrollAlertIntoView(selectedAlertIndex);
        });
      }
    }
    if (changes.selectedBerthId?.currentValue && !this.selectedAlertId) {
      const selectedBerthIndex = this.getAlertIndexByBerthId(changes.selectedBerthId.currentValue);
      this.scrollAlertIntoView(selectedBerthIndex);
    }
  }

  private scrollAlertIntoView(alertIndex: undefined | number) {
    if (alertIndex === undefined || alertIndex < 0) {
      return;
    }
    this.alertRows
      .get(alertIndex)
      ?.nativeElement.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' });
  }

  private getAlertIndexByBerthId(value: string): undefined | number {
    return this.alerts?.findIndex((alert) => alert.berth.data.berthId === value);
  }

  private getAlertIndexByAlertId(value: string): undefined | number {
    return this.alerts?.findIndex((alert) => alert.alertId === value);
  }

  public onBerthClick(alert: AlertMapResponseDtoWithBerth): void {
    this.selectedAlertId = null;
    this.berthClick.emit(alert);
  }

  public selectedClassName(alert: AlertMapResponseDtoWithBerth): string {
    return (!this.selectedAlertId && this.selectedBerthId && this.selectedBerthId === alert.berth.data.berthId) ||
      (this.selectedAlertId && this.selectedAlertId === alert.alertId)
      ? 'selected'
      : '';
  }
}
