import { Component, computed, input, OnInit } from '@angular/core';
import { BoatMovementOperation, ResourceType } from '@dm-workspace/types';
import { DATE_FORMAT_PLACEHOLDER } from '@dm-workspace/shared';
import { NgForUtils } from '@dm-workspace/utils';
import { map, startWith, switchMap } from 'rxjs/operators';
import { defer, filter } from 'rxjs';
import { min } from 'date-fns';
import { toObservable } from '@angular/core/rxjs-interop';
import { MmsBoatMovementForm, MmsBoatMovementFormVersion } from './mms-boat-movement-form.builder';
import { MmsBoatMovementFormDecorator } from './mms-boat-movement-form.decorator';

@Component({
  selector: 'dm-mms-boat-movement-form',
  templateUrl: './mms-boat-movement-form.component.html',
  styleUrls: ['./mms-boat-movement-form.component.scss'],
})
export class MmsBoatMovementFormComponent implements OnInit {
  form = input.required<MmsBoatMovementForm>();
  resourceId = input<string>();
  boatBookingId = input<string>();
  operations = input<BoatMovementOperation[]>(Object.values(BoatMovementOperation));
  boatQuickReturnType = input<BoatMovementOperation>();
  version = input.required<MmsBoatMovementFormVersion>();
  #formDecorator = computed(() => {
    return new MmsBoatMovementFormDecorator(this.form(), this.version());
  });

  #form$ = toObservable(this.form).pipe(filter(Boolean));
  #operationControlValue$ = this.#form$.pipe(
    switchMap((form) => {
      const { operation } = form.controls;
      return operation.valueChanges.pipe(startWith(operation.value));
    })
  );

  ngOnInit() {
    if (this.boatQuickReturnType()) {
      this.form().controls.operation.setValue(this.boatQuickReturnType());
      this.onChangeOperation(this.boatQuickReturnType());
    }
  }

  protected sourceIdResourceType$ = this.#operationControlValue$.pipe(
    map((operation) => {
      switch (operation) {
        case BoatMovementOperation.CRUISING:
        case BoatMovementOperation.DEPARTURE:
        case BoatMovementOperation.INTERNAL_MOVEMENT:
        case BoatMovementOperation.DRY_DOCK_LIFT: {
          return [ResourceType.berth, ResourceType.multiberth];
        }
        default: {
          return [ResourceType.land, ResourceType.multiland];
        }
      }
    })
  );

  protected destinationIdResourceType$ = this.#operationControlValue$.pipe(
    map((operation) => {
      switch (operation) {
        case BoatMovementOperation.DRY_DOCK_LIFT: {
          return [ResourceType.land];
        }
        default: {
          return [ResourceType.berth, ResourceType.multiberth];
        }
      }
    })
  );

  protected readonly DATE_FORMAT_PLACEHOLDER = DATE_FORMAT_PLACEHOLDER;
  protected readonly NgForUtils = NgForUtils;
  protected readonly ResourceType = ResourceType;
  protected readonly BoatMovementOperation = BoatMovementOperation;

  protected readonly todayDayString = (() => {
    const today = new Date();
    today.setHours(23, 59, 59);
    return today.toISOString();
  })();

  protected readonly minReturnDate$ = defer(() => {
    const { startsAt } = this.form().controls;

    return startsAt?.valueChanges.pipe(startWith(startsAt?.value)).pipe(
      map((startsAt) => {
        const startsAtDate = new Date(startsAt);
        return min([new Date(this.todayDayString), startsAtDate]).toISOString();
      })
    );
  });

  protected readonly sourceIdLabelTranslateKey$ = this.#operationControlValue$.pipe(
    map((operation) => {
      switch (operation) {
        case BoatMovementOperation.DEPARTURE:
        case BoatMovementOperation.DRY_DOCK_LIFT:
        case BoatMovementOperation.CRUISING:
        case BoatMovementOperation.INTERNAL_MOVEMENT: {
          return 'SOURCE_BERTH';
        }
        case BoatMovementOperation.DRY_DOCK_LAUNCH: {
          return 'SOURCE_LAND';
        }
      }
    })
  );

  protected readonly destinationIdLabelTranslateKey$ = this.#operationControlValue$.pipe(
    map((operation) => {
      switch (operation) {
        case BoatMovementOperation.RETURN:
        case BoatMovementOperation.DRY_DOCK_LAUNCH:
        case BoatMovementOperation.CRUISING:
        case BoatMovementOperation.ARRIVAL:
        case BoatMovementOperation.INTERNAL_MOVEMENT: {
          return 'DESTINATION_BERTH';
        }
        case BoatMovementOperation.DEPARTURE: {
          return 'SOURCE_BERTH';
        }
        case BoatMovementOperation.DRY_DOCK_LIFT: {
          return 'DESTINATION_LAND';
        }
      }
    })
  );

  protected readonly startsAtLabelTranslateKey$ = this.#operationControlValue$.pipe(
    map((operation) => {
      switch (operation) {
        case BoatMovementOperation.RETURN:
        case BoatMovementOperation.DRY_DOCK_LAUNCH: {
          return 'RETURN_DATE';
        }
        case BoatMovementOperation.INTERNAL_MOVEMENT:
        case BoatMovementOperation.ARRIVAL: {
          return 'OPERATION_DATE';
        }
        case BoatMovementOperation.DEPARTURE:
        case BoatMovementOperation.DRY_DOCK_LIFT: {
          return 'EXIT_DATE';
        }
        case BoatMovementOperation.CRUISING: {
          return 'MOVEMENT_DATE';
        }
      }
    })
  );

  protected onChangeOperation(operation: BoatMovementOperation) {
    this.#formDecorator().changeByOperation(operation, this.resourceId());
  }

  protected onChangeAllowsBerthReuse(event: Event) {
    const value = (event.target as HTMLInputElement).checked;
    this.#formDecorator().changeByAllowsBerthReuse(value);
  }
}
