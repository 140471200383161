<table *ngIf="hasItemsToShow()">
  <tr>
    <th>{{ "BOATS" | translate }}</th>
    <th colspan="2">{{ "BOOKINGS" | translate }}</th>
  </tr>
  @for (reservation of berth().data.bookings; track reservation.reservationId) {
    <tr>
      <td>
        @if (reservation.boat) {
          <dm-map-map-berth-details-boat-name [name]="reservation.boat?.name" [boatId]="reservation.boat?.id" />
        } @else {
          —
        }
      </td>
      <td>
        <dm-map-popup-row [label]="reservation.contractType | contractTypeTranslate">
          <a
            class="details-link text-nowrap"
            [routerLink]="['/', marinaCode, 'bookings', reservation.reservationId, 'details']"
            [state]="{ backOnClose: true }"
            returnUrlCurrentPath
            >{{ reservation.humanReadableId }}</a
          >
        </dm-map-popup-row>
        @if (temporaryAbsence(reservation.boatId); as temporaryAbsence) {
          <dm-map-popup-row
            [label]="'GAP_END' | translate"
            [txt]="temporaryAbsence.gapEnd || temporaryAbsence.expectedReturnDate | dateFormat"
          />
        } @else {
          <dm-map-popup-row [label]="'END_DATE' | translate" [txt]="reservation.toDate | dateFormat" />
        }
      </td>
      <td>
        <div class="d-flex justify-content-end align-items-center">
          <div ngbDropdown>
            <button ngbDropdownToggle type="button" class="action-button"></button>
            <div ngbDropdownMenu>
              @if (boatIsInOccupancy(reservation.boatId)) {
                <button type="button" ngbDropdownItem class="d-flex align-items-center" (click)="moveBoat(reservation)">
                  <i [style.font-size.px]="16" class="i-boat ml-2 me-2 color-gray"></i>
                  <span>{{ "MOVE_BOAT" | translate }}</span>
                </button>
              } @else {
                <button
                  type="button"
                  ngbDropdownItem
                  class="d-flex align-items-center"
                  (click)="transferBooking(reservation)"
                >
                  <i [style.font-size.px]="16" class="i-refresh ml-2 me-2 color-gray"></i>
                  <span>{{ "TRANSFER_BOOKING" | translate }}</span>
                </button>
              }
              @if (temporaryAbsence(reservation.boatId); as temporaryAbsence) {
                <button
                  type="button"
                  ngbDropdownItem
                  class="d-flex align-items-center"
                  (click)="openEditGapModal(temporaryAbsence)"
                >
                  <i [style.font-size.px]="16" class="i-edit ml-2 me-2 color-gray"></i>
                  <span>{{ "EDIT_GAP" | translate }}</span>
                </button>
              }
            </div>
          </div>
        </div>
      </td>
    </tr>
  }
</table>
