<form [formGroup]="form">
  <div class="modal-header">
    <h1 class="title">{{ "BOOKING_CANCELLATION" | translate }}</h1>
  </div>

  <div class="modal-body">
    <p class="mb-3">
      {{ "CHOOSE_THE_REASON_FOR_CANCELLATION" | translate }}
    </p>

    <div class="mb-3">
      <dm-form-group [class.mb-3]="!last" *ngFor="let data of reasonData; let i = index; let last = last">
        <input type="radio" dmForm formControlName="reason" [value]="data.value" [id]="'reasonData' + i" />
        <label [for]="'reasonData' + i">
          {{ "BOOKING_CANCELLATION_ENUM." + data.label | translate }}
        </label>
      </dm-form-group>
      <dm-form-error-message controlName="reason" />
    </div>

    <dm-form-group class="mb-4">
      <input dmForm type="text" formControlName="comment" />
      <dm-form-error-message controlName="comment" />
    </dm-form-group>
  </div>

  <div class="modal-footer justify-content-between d-flex">
    <button type="button" class="btn btn-tertiary btn-narrow" [disabled]="pending" (click)="closeModal()">
      {{ "CANCEL" | translate }}
    </button>

    <div class="d-flex gap-4">
      <button type="submit" class="btn btn-secondary" (click)="onBeforeSubmit(false)" [disabled]="pending">
        {{ "SUBMIT_WITHOUT_EMAIL" | translate }}
      </button>

      <button type="button" class="btn btn-primary" (click)="onShowEmailPreview()" [disabled]="pending || isReasonProcessInCrm">
        {{ "SUBMIT_WITH_EMAIL" | translate }}
      </button>
    </div>
  </div>
</form>

<dm-ui-overflow-spinner type="absolute" [showSpinner]="pending" />
