import { ChangeDetectionStrategy, Component, signal, WritableSignal } from '@angular/core';
import { ConfirmModal } from '@dm-workspace/ui';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiValidator } from '@dm-workspace/shared';

@Component({
  selector: 'dm-mms-mark-contract-as-eligible-modal',
  templateUrl: './mms-mark-contract-as-eligible-modal.component.html',
  styleUrls: ['./mms-mark-contract-as-eligible-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsMarkContractAsEligibleModalComponent extends ConfirmModal {
  protected pending = signal(false);
  protected apiErrorMsg: WritableSignal<string | null> = signal(null);
  override onConfirm() {
    if (this.pending()) {
      return;
    }
    this.apiErrorMsg.set(null);
    this.pending.set(true);
    of(true).subscribe({
      next: () => () => super.onConfirm(),
      error: (err: HttpErrorResponse) => () => {
        this.pending.set(false);
        this.apiErrorMsg.set(ApiValidator.getApiNotificationError(err));
      },
    });
  }
}
