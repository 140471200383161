import { inject, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MmsDashboardEmptyTileGuard } from './guards/mms-dashboard-empty-tile-guard.service';
import { MmsDashboardCheckInsViewComponent } from './views/mms-dashboard-check-ins-view/mms-dashboard-check-ins-view.component';
import { MmsDashboardViewComponent } from './views/mms-dashboard-view/mms-dashboard-view.component';
import { MmsDashboardNewInquiriesViewComponent } from './views/mms-dashboard-new-inquiries-view/mms-dashboard-new-inquiries-view.component';
import { MmsDashboardExpectedArrivalsViewComponent } from './views/mms-dashboard-expected-arrivals-view/mms-dashboard-expected-arrivals-view.component';
import { MmsDashboardDeparturesViewComponent } from './views/mms-dashboard-departures-view/mms-dashboard-departures-view.component';
import { MmsDashboardCruisesViewComponent } from './views/mms-dashboard-cruises-view/mms-dashboard-cruises-view.component';
import { MmsDashboardOtherIssuesViewComponent } from './views/mms-dashboard-other-issues-view/mms-dashboard-other-issues-view.component';
import { MmsDashboardBoatMovementsViewComponent } from './views/mms-dashboard-boat-movements-view/mms-dashboard-boat-movements-view.component';
import { MmsDashboardQuotesViewComponent } from './views/mms-dashboard-quotes-view/mms-dashboard-quotes-view.component';
import { mmsDashboardRedirectToUnfinishedStat } from './guards/mms-dashboard-redirect-to-unfinished-stat';
import { MmsDashboardViewService } from './services/mms-dashboard-view.service';
import { SelectedMarinaRouter } from '@dm-workspace/mms-panel/shared';

const routes: Routes = [
  {
    path: '',
    component: MmsDashboardViewComponent,
    children: [
      {
        path: 'new-inquiries',
        redirectTo: '/',
      },
      {
        path: 'new-inquiries/:viewMode',
        component: MmsDashboardNewInquiriesViewComponent,
      },
      {
        path: 'boat-movements',
        pathMatch: 'full',
        canActivate: [MmsDashboardEmptyTileGuard],
        component: MmsDashboardBoatMovementsViewComponent,
      },
      {
        path: 'boat-movements/:viewMode',
        component: MmsDashboardBoatMovementsViewComponent,
      },
      {
        path: 'quotes',
        pathMatch: 'full',
        canActivate: [mmsDashboardRedirectToUnfinishedStat(MmsDashboardQuotesViewComponent.pathStatNameArray)],
        component: MmsDashboardQuotesViewComponent,
      },
      {
        path: 'quotes/:path',
        component: MmsDashboardQuotesViewComponent,
      },
      {
        path: 'check-ins',
        pathMatch: 'full',
        canActivate: [MmsDashboardEmptyTileGuard],
        children: [],
      },
      {
        path: 'check-ins/:viewMode',
        component: MmsDashboardCheckInsViewComponent,
      },
      {
        path: 'expected-arrivals',
        component: MmsDashboardExpectedArrivalsViewComponent,
      },
      {
        path: 'departures',
        component: MmsDashboardDeparturesViewComponent,
      },
      {
        path: 'cruises',
        component: MmsDashboardCruisesViewComponent,
      },
      {
        path: 'other-issues',
        component: MmsDashboardOtherIssuesViewComponent,
      },
      {
        path: '**',
        canActivate: [
          () => {
            const dashboardService = inject(MmsDashboardViewService);
            const selectedMarinaRouter = inject(SelectedMarinaRouter);
            return selectedMarinaRouter.parseUrl('/dashboard/' + dashboardService.lastActiveRoute);
          },
        ],
        children: [],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MmsDashboardRoutingModule {}
