<table class="table">
  <thead>
    <tr>
      <th>{{ "DATE" | translate }}</th>
      <th>{{ "PAYMENT_METHOD" | translate }}</th>
      <th>{{ "PAYMENT_STATUS" | translate }}</th>
      <th class="text-right">{{ "TOTAL_AMOUNT" | translate }}</th>
    </tr>
  </thead>
  <tbody>
   <tr *ngFor="let payment of payments">
     <td>{{ payment.createdAt | dateFormat: "date_time" }}</td>
     <td>{{ payment.type }}</td>
     <td><dm-ui-payment-status-badge [status]="payment.status" /></td>
     <td class="text-right">{{ payment.amount?.value | currency: payment.amount?.currency }}</td>
   </tr>

    <tr *ngIf="!payments?.length">
      <td colspan="4">
        {{ "NO_ONLINE_PAYMENTS_RECORDED" | translate }}
      </td>
    </tr>
  </tbody>
</table>
