import { AfterViewInit, Component, DestroyRef, inject, signal, ViewChild } from '@angular/core';
import { ApiValidator, TimestampRangeUtils } from '@dm-workspace/shared';
import { MmsSelectedMarinaSupportViewPipe } from '@dm-workspace/mms-panel/shared';
import { MarinasService } from '@dm-workspace/core';
import { MmsDashboardApiService, MmsQuotesApiService } from '@dm-workspace/data-access';
import { catchError, filter, finalize, merge, retry, skip, startWith, tap, throwError } from 'rxjs';
import { GetQuotesParams, MmsQuotesStats, PaginatedNormal, Quote, QuoteStatus, QuoteType } from '@dm-workspace/types';
import { HttpErrorResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { NotificationService } from '@dm-workspace/notification';
import {
  MmsOffersFiltersComponent,
  MmsOffersFiltersValue,
} from '../../components/mms-offers-filters/mms-offers-filters.component';
import { MmsEnquiryFiltersValue } from '../../../../../mms-enquiry/src/lib/components/mms-enquiry-filters/mms-enquiry-filters.component';
import { NavigationEnd, Router } from '@angular/router';
import { removeEmptyProperties } from '@dm-workspace/utils';
import { Pagination } from '@dm-workspace/ui';
import { offersTablesViewConfig } from './mms-offers-list.config';

@Component({
  selector: 'dm-mms-offers-mms-offers-list-view',
  templateUrl: './mms-offers-list-view.component.html',
  providers: [MmsSelectedMarinaSupportViewPipe],
  styleUrl: './mms-offers-list-view.component.scss',
})
export class MmsOffersListViewComponent implements AfterViewInit {
  @ViewChild(MmsOffersFiltersComponent, { static: true }) mmsOffersFiltersComponent: MmsOffersFiltersComponent;
  #destroyRef = inject(DestroyRef);
  #notificationService = inject(NotificationService);
  #marinaService = inject(MarinasService);
  #quotesAPI = inject(MmsQuotesApiService);
  #dashboardService = inject(MmsDashboardApiService);
  #router = inject(Router);

  readonly #filtersValue = signal<MmsOffersFiltersValue>({});
  readonly filtersChange$ = toObservable(this.#filtersValue).pipe(skip(1));
  readonly filtersValue = this.#filtersValue.asReadonly();
  readonly stats = signal<MmsQuotesStats>(null);
  readonly fetchingStats = signal<boolean>(false);
  readonly fetchingOffers = signal<boolean>(false);
  readonly pagination = new Pagination();
  quoteStatuses = signal<QuoteStatus[]>(null);
  offersTableColumns = signal<string[]>(null);
  listData = signal<PaginatedNormal<Quote>>(null);
  filtersRowColumnCount = signal<number>(5);

  ngAfterViewInit() {
    this.refreshStats();
    this.watchMarinaPageFiltersRouteChanges();
  }

  watchMarinaPageFiltersRouteChanges() {
    merge(this.#marinaService.selectedMarinaChange$, this.pagination.changed$, this.filtersChange$, this.#router.events)
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        filter((event) => event instanceof NavigationEnd),
        startWith(null),
        map(() => {
          let route = this.#router.routerState.root;
          while (route.firstChild) {
            route = route.firstChild;
          }
          switch (route.snapshot.params['viewType']) {
            case 'readyToSend':
              this.filtersRowColumnCount.set(4);
              break;
            case 'declined':
              this.filtersRowColumnCount.set(4);
              break;
            default:
              this.filtersRowColumnCount.set(5);
              break;
          }
          return offersTablesViewConfig[route.snapshot.params['viewType']];
        }),
        tap((routeData) => {
          this.quoteStatuses.set(routeData.quoteStatusValues);
          this.offersTableColumns.set(routeData.tableColumns);
          const availableFilters = routeData?.availableFilters;
          this.getTabOffers();

          if (availableFilters?.includes('paymentStatus')) {
            this.mmsOffersFiltersComponent.addPaymentStatus();
          } else {
            this.mmsOffersFiltersComponent.removePaymentStatus();
          }

          if (availableFilters?.includes('createdAt')) {
            this.mmsOffersFiltersComponent.addCreatedAt();
          } else {
            this.mmsOffersFiltersComponent.removeCreatedAt();
          }

          if (availableFilters?.includes('acceptedAt')) {
            this.mmsOffersFiltersComponent.addAcceptedAt();
          } else {
            this.mmsOffersFiltersComponent.removeAcceptedAt();
          }

          if (availableFilters?.includes('lastSendDate')) {
            this.mmsOffersFiltersComponent.addLastSentAt();
          } else {
            this.mmsOffersFiltersComponent.removeLastSentAt();
          }

          if (availableFilters?.includes('lastSeenDate')) {
            this.mmsOffersFiltersComponent.addLastSeenAt();
          } else {
            this.mmsOffersFiltersComponent.removeLastSeenAt();
          }

          if (availableFilters?.includes('declinedAt')) {
            this.mmsOffersFiltersComponent.addDeclinedAt();
          } else {
            this.mmsOffersFiltersComponent.removeDeclinedAt();
          }

          if (availableFilters?.includes('quoteStatus')) {
            this.mmsOffersFiltersComponent.addQuoteStatus(routeData?.quoteStatusValues);
          } else {
            this.mmsOffersFiltersComponent.removeQuoteStatus();
          }
        })
      )
      .subscribe();
  }

  constructor() {}

  protected changeFilters(value: MmsEnquiryFiltersValue) {
    this.#filtersValue.set(value);
  }

  protected refreshStats() {
    if (this.fetchingStats()) {
      return;
    }

    this.fetchingStats.set(true);
    this.getStats()
      .pipe(
        finalize(() => this.fetchingStats.set(false)),
        tap((res) => {
          this.stats.set(res);
        })
      )
      .subscribe();
  }

  refreshOffersStatsSilent() {
    if (this.fetchingStats()) {
      return;
    }
    this.getStats()
      .pipe(
        tap((res) => {
          this.stats.set(res);
        })
      )
      .subscribe();
  }

  getStats() {
    return this.#dashboardService.fetchOfferRenewalsStats().pipe(
      retry({
        count: 3,
        delay: 2000,
      }),
      takeUntilDestroyed(this.#destroyRef),
      catchError((res: HttpErrorResponse) => {
        const notificationMessage = ApiValidator.getApiNotificationError(res);
        this.#notificationService.openError(notificationMessage);
        return throwError(() => res);
      }),
      tap((res) => {
        this.stats.set(res);
      })
    );
  }

  protected getTabOffers() {
    this.fetchingOffers.set(true);
    this.refreshOffersStatsSilent();

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    const {
      declinedAtTo,
      declinedAtFrom,
      createdAtFrom,
      createdAtTo,
      acceptedAtFrom,
      acceptedAtTo,
      lastSentAtFrom,
      lastSentAtTo,
      quoteStatus,
      lastSeenAtTo,
      lastSeenAtFrom,
      ...restFiltersValue
    } = this.filtersValue();

    const dto: GetQuotesParams = {
      ...this.pagination.getParams(),
      createdAt: TimestampRangeUtils.createFromDate(createdAtFrom, createdAtTo),
      acceptedAt: TimestampRangeUtils.createFromDate(acceptedAtFrom, acceptedAtTo),
      declinedAt: TimestampRangeUtils.createFromDate(declinedAtFrom, declinedAtTo),
      lastSentAt: TimestampRangeUtils.createFromDate(lastSentAtFrom, lastSentAtTo),
      lastSeenAt: TimestampRangeUtils.createFromDate(lastSeenAtFrom, lastSeenAtTo),
      type: [QuoteType.RENEWAL],
      ...restFiltersValue,
      quoteStatus: quoteStatus ? quoteStatus : this.quoteStatuses(),
      showOnlyWithResource: false,
    };

    return this.#quotesAPI
      .get(removeEmptyProperties(dto))
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        catchError((res: HttpErrorResponse) => {
          const notificationMessage = ApiValidator.getApiNotificationError(res);
          this.#notificationService.openError(notificationMessage);
          return throwError(() => res);
        }),
        finalize(() => this.fetchingOffers.set(false)),
        tap((quotes) => {
          this.pagination.setPagination(quotes.metadata);
          this.listData.set(quotes);
        })
      )
      .subscribe();
  }
}
