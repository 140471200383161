import { InjectionToken, Provider } from '@angular/core';
import { IEnvironment } from '@dm-workspace/environments';
import { ENV } from '@dm-workspace/types';

export type MmsBoatMovementConfig = {
  marinasForNewFormVersion: string[] | '*';
};

export const MMS_BOAT_MOVEMENT_CONFIG_TOKEN = new InjectionToken<MmsBoatMovementConfig>(
  'MMS_BOAT_MOVEMENT_CONFIG_TOKEN'
);

export const MmsBoatMovementConfigProvider: Provider = {
  provide: MMS_BOAT_MOVEMENT_CONFIG_TOKEN,
  useFactory: (env: IEnvironment): MmsBoatMovementConfig => {
    return {
      marinasForNewFormVersion: '*',
    };
  },
  deps: [ENV],
};
