import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { OrderPaymentStatus, Quote, QuoteStatus, QuoteType } from '@dm-workspace/types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MmsQuoteActionCancelModalComponent } from '../../../../../shared/src/lib/modals/mms-quote-action-cancel-modal/mms-quote-action-cancel-modal.component';
import { filter } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  MmsOfferActionSendModalComponent,
  MmsOfferActionSendTarget,
} from '../../modals/mms-offer-action-send-modal/mms-offer-action-send-modal.component';
import { MmsOfferActionAcceptModalComponent } from '../../modals/mms-offer-action-accept-modal/mms-offer-action-accept-modal.component';
import { MmsOfferActionSendReminderModalComponent } from '../../modals/mms-offer-action-send-reminder-modal/mms-offer-action-send-reminder-modal.component';
import { MmsOrderActionSendPaymentReminderModalComponent } from '../../../../../mms-orders/src/lib/modals/mms-order-action-send-payment-reminder-modal/mms-order-action-send-payment-reminder-modal.component';
import { MmsOrderActionMarkAsPaidModalComponent } from '../../../../../mms-orders/src/lib/modals/mms-order-action-mark-as-paid/mms-order-action-mark-as-paid-modal.component';
import { MmsQuoteActionDeclineModalComponent } from '@dm-workspace/mms-panel/shared';

@Component({
  selector: 'dm-mms-offers-table',
  templateUrl: './mms-offers-table.component.html',
  styleUrls: ['./mms-offers-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsOffersTableComponent {
  @Input({ required: true }) public offers: Quote[];
  @Input() public isRenewalsView: boolean = false;
  @Output() public offersChange = new EventEmitter<void>();
  readonly #modal = inject(NgbModal);
  protected readonly QuoteStatus = QuoteStatus;
  protected readonly OrderPaymentStatus = OrderPaymentStatus;
  protected readonly QuoteType = QuoteType;

  protected clickButtonSendOffer(offer: Quote, target: MmsOfferActionSendTarget) {
    const modalComponent = this.#modal.open(MmsOfferActionSendModalComponent, {
      size: 'md',
    }).componentInstance as MmsOfferActionSendModalComponent;
    modalComponent.offer = offer;
    modalComponent.target = target;
    modalComponent.confirmed
      .pipe(
        filter(Boolean),
        tap(() => this.offersChange.emit())
      )
      .subscribe();
  }

  protected clickButtonSendReminderOffer(offer: Quote, target: MmsOfferActionSendTarget) {
    const modalComponent = this.#modal.open(MmsOfferActionSendReminderModalComponent, {
      size: 'md',
    }).componentInstance as MmsOfferActionSendReminderModalComponent;
    modalComponent.offer = offer;
    modalComponent.target = target;
    modalComponent.confirmed
      .pipe(
        filter(Boolean),
        tap(() => this.offersChange.emit())
      )
      .subscribe();
  }

  protected clickButtonAcceptOffer(offer: Quote) {
    const modalComponent = this.#modal.open(MmsOfferActionAcceptModalComponent, {
      size: 'md',
    }).componentInstance as MmsOfferActionAcceptModalComponent;
    modalComponent.offer = offer;
    modalComponent.confirmed
      .pipe(
        filter(Boolean),
        tap(() => this.offersChange.emit())
      )
      .subscribe();
  }

  protected clickButtonSendPaymentReminder(offer: Quote) {
    const modalComponent = this.#modal.open(MmsOrderActionSendPaymentReminderModalComponent, {
      size: 'md',
    }).componentInstance as MmsOrderActionSendPaymentReminderModalComponent;
    modalComponent.orderId = offer.order.id;
    modalComponent.confirmed
      .pipe(
        filter(Boolean),
        tap(() => this.offersChange.emit())
      )
      .subscribe();
  }

  protected clickButtonMarkOrderAsPaid(offer: Quote) {
    const modalComponent = this.#modal.open(MmsOrderActionMarkAsPaidModalComponent, {
      size: 'md',
    }).componentInstance as MmsOrderActionMarkAsPaidModalComponent;
    modalComponent.orderId = offer.order.id;
    modalComponent.confirmed
      .pipe(
        filter(Boolean),
        tap(() => this.offersChange.emit())
      )
      .subscribe();
  }

  protected clickButtonCancelOffer(offer: Quote) {
    const modalComponent = this.#modal.open(MmsQuoteActionCancelModalComponent, { size: 'lg' })
      .componentInstance as MmsQuoteActionCancelModalComponent;
    modalComponent.quote = offer;
    modalComponent.confirmed
      .pipe(
        filter(Boolean),
        tap(() => this.offersChange.emit())
      )
      .subscribe();
  }

  protected clickButtonDeclineOffer(offer: Quote) {
    const modalComponent = this.#modal.open(MmsQuoteActionDeclineModalComponent, {
      size: 'md',
    }).componentInstance as MmsQuoteActionDeclineModalComponent;
    modalComponent.quote = offer;
    modalComponent.confirmed
      .pipe(
        filter(Boolean),
        tap(() => this.offersChange.emit())
      )
      .subscribe();
  }
}
