<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <h1 class="title">{{ "ADD_ATTACHMENT" | translate }}</h1>
  </div>

  <div class="modal-body">
    <dm-form-group>
      <dm-form-files-uploader
        formControlName="files"
        [allowedFormats]="['png', 'jpg', 'pdf', 'txt']"
        [sizeLimitBytes]="5242880"
      />
      <dm-form-error-message controlName="files" />
    </dm-form-group>
  </div>

  <div class="modal-footer justify-content-between d-flex">
    <button type="button" class="btn btn-primary-negative" (click)="closeModal()">
      {{ "CANCEL" | translate }}
    </button>

    <button class="btn btn-primary" type="submit" [disabled]="pending">
      <dm-ui-spinner [showSpinner]="pending" />
      {{ "UPLOAD" | translate }}
    </button>
  </div>
</form>
