import { Pipe, PipeTransform } from '@angular/core';
import { IBoatMovement } from '@dm-workspace/types';

@Pipe({
  name: 'boatMovementSourceMap',
})
export class MmsBoatMovementSourceMapPipe implements PipeTransform {
  transform(boatMovement: IBoatMovement): string {
    switch (boatMovement.entitySource) {
      case 'Starflow':
        if (boatMovement.modifiedById) {
          return 'Starflow (mobile app)';
        } else {
          return 'Starflow (post-processing)';
        }
      case 'Starflow App':
        return 'Starflow (mobile app)';
      case 'Service Bus':
        return 'CRM';
      default:
        return boatMovement.entitySource;
    }
  }
}
