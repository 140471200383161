import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { BerthDrawerDetailsClass } from '../berth-drawer-details.class';
import { BerthAvailabilityReservationDto } from '@dm-workspace/types';
import { MapBerthClass } from '@dm-workspace/marina-map';

@Component({
  selector: 'dm-map-berth-details-sensor-list',
  templateUrl: './map-berth-details-sensor-list.component.html',
  styleUrls: ['../map-berth-details-list-styles.scss', './map-berth-details-sensor-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapBerthDetailsSensorListComponent extends BerthDrawerDetailsClass {
  berth = input.required<MapBerthClass>();
  extendedBookingsData = input.required<BerthAvailabilityReservationDto[]>();
  marinaCode = input.required<string>();
  hasItemsToShow = computed(() => this.berth()?.data.occupancy.length);
  boatSensorList = computed(() => {
    return this.getOccupancyListDetails().filter((occ) => occ.boatIsInBerth);
  });
}
