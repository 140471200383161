<div class="card px-xxl py-l mt-2">
  <form [formGroup]="form">
    <div class="d-flex justify-content-between align-items-start">
      <h3 class="mb-l">{{ "CUSTOMER" | translate | uppercase }}</h3>
      <div>
        <button type="button" *ngIf="customerHasPrefilledData && selectedCustomer" class="ms-2 btn btn-primary-negative btn-tiny" (click)="openCustomerFormModal(form.controls.customer.value)">
          <i class="i-add"></i>
          {{ "CREATE_CUSTOMER" | translate }}
        </button>
        <button type="button" *ngIf="!selectedCustomer" class="btn btn-primary-negative btn-tiny" (click)="openCustomerFormModal()">
          <i class="i-add"></i>{{ "CREATE_NEW_CUSTOMER" | translate }}
        </button>
        <button class="ms-2 btn btn-primary-negative btn-tiny" type="button" (click)="clearCustomer()"
                *ngIf="selectedCustomer && !disabled">
          <i class="icon i-bin"></i>
          {{ "CLEAR" | translate }}
        </button>
      </div>
    </div>

    <div *ngIf="!selectedCustomer" class="d-flex row justify-content-start align-items-end">
      <dm-form-group [class]="columnClasses">
        <label>{{ "E_MAIL" | translate }}</label>
        <dm-form-select-typeahead
          dmForm
          typeahead="customers"
          [notFoundText]="'NO_CUSTOMERS_FOUND' | translate"
          formControlName="customer"
          [placeholder]="'E_MAIL' | translate"
          [defaultSearchValue]="customerSearch"
        />
        <dm-form-error-message controlName="customer" />
      </dm-form-group>
    </div>
  </form>

  <div *ngIf="selectedCustomer as customer" class="row my-2">
    <dm-ui-info-text [label]="'E_MAIL' | translate" [text]="customer.email" class="col-4"/>
    <dm-ui-info-text
      [label]="'COMPANY_NAME' | translate"
      [text]="customer.companyName"
      *ngIf="isSelectedCustomerCorporate(customer)"
      class="col-2"
    />
    <dm-ui-info-text [label]="'FIRST_NAME' | translate" [text]="customer.firstName" class="col-2"/>
    <dm-ui-info-text [label]="'LAST_NAME' | translate" [text]="customer.lastName" class="col-2"/>
    <dm-ui-info-text [label]="'PHONE' | translate" [text]="customer.phone" class="col-2"/>
  </div>
</div>
