import { Component, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, startWith } from 'rxjs';

@Component({
  selector: 'dm-mms-layout-header',
  templateUrl: './mms-layout-header.component.html',
  styleUrls: ['./mms-layout-header.component.scss'],
})
export class MmsLayoutHeaderComponent {
  #translateCoreService = inject(TranslateService);
  protected currentLang$ = this.#translateCoreService.onLangChange.pipe(
    map((event) => event.lang),
    startWith(this.#translateCoreService.currentLang),
    filter(Boolean),
    map((lang) => {
      const trimmed = lang.slice(0, lang.indexOf('-'));
      return trimmed;
    })
  );
}
