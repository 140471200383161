@if (offer().isInCrm) {
  <a
    [href]="offer().quoteId | quoteCrmLink"
    target="_blank"
    class="btn btn-primary-negative btn-primary-negative--transparent btn-tiny ml-s"
  >
    <i class="i-outside_link"></i>{{ "GO_TO_CRM" | translate }}
  </a>
}
@if (offer().actions | arrayIncludes: "CANCEL") {
  <a
    class="btn btn-danger-negative btn-danger-negative--transparent btn-tiny"
    type="button"
    (click)="clickButtonCancelOffer(offer())"
  >
    <span class="i-bin"></span>
    {{ "CANCEL" | translate }}</a
  >
}
