import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ConfirmModal } from '@dm-workspace/ui';

@Component({
  selector: 'dm-mms-boat-movement-warning-confirm-modal',
  templateUrl: './mms-boat-movement-warning-confirm-modal.component.html',
  styleUrls: ['./mms-boat-movement-warning-confirm-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsBoatMovementWarningConfirmModalComponent extends ConfirmModal {
  @Input() warningsKeys: string[];
}
