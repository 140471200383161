import { Pipe, PipeTransform } from '@angular/core';
import { OrderPaymentStatus, PaymentStatusEnum } from '@dm-workspace/types';
import { GetKeyFromEnumPipe } from '@dm-workspace/shared';

@Pipe({
  name: 'paymentStatusTranslateKey',
})
export class MmsPaymentStatusTranslateKeyPipe implements PipeTransform {
  private readonly getKeyFromEnum = new GetKeyFromEnumPipe().transform;

  public transform(paymentStatus: PaymentStatusEnum | OrderPaymentStatus | undefined): string {
    if (!paymentStatus) {
      return;
    }
    const enumKey = this.getKeyFromEnum(paymentStatus, PaymentStatusEnum);
    return enumKey ? `PAYMENT_STATUS_.${enumKey}` : null;
  }
}
