<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <h1 class="title">{{ "MARINA_MANAGEMENT" | translate }}</h1>
  </div>
  <div class="modal-body">
    <p class="mb-4">
      {{ "CHOOSE_ACTION_FOR_A_BERTH" | translate }}: <strong>{{ berth.name }}</strong>
    </p>

    <div *ngFor="let status of statuses | keyvalue" class="mb-3">
      <dm-form-group>
        <input dmForm type="radio" name="status" formControlName="status" [id]="status.value" [value]="status.value" />
        <label class="berth-select-type-label" [for]="status.value">{{
          "BERTH_STATUSES." + status.value | uppercase | translate
        }}</label>
        <dm-form-error-message controlName="title" [apiError]="apiErrors?.status" />
      </dm-form-group>
    </div>

    <dm-form-group class="mt-4">
      <label>{{ "NOTE" | translate }}</label>
      <input dmForm formControlName="note" id="note" />
      <dm-form-error-message controlName="note" />
    </dm-form-group>
  </div>

  <div class="modal-footer justify-content-between d-flex">
    <button class="btn btn-tertiary btn-narrow" type="button" (click)="close()">
      {{ "CANCEL" | translate }}
    </button>
    <button class="btn btn-primary" type="submit" [disabled]="pending">
      <dm-ui-spinner [showSpinner]="pending" />
      {{ "SUBMIT" | translate }}
    </button>
  </div>
</form>
