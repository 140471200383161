<dm-mms-ui-full-page>
  <dm-mms-quote-details-header
    [data]="offer()"
    [header]="offer().name"
    [routes]="detailsRoutes"
    [viewType]="QuoteViewType.OFFER"
  >
    @if (offer()) {
      <dm-mms-offer-header-actions [offer]="offer()" (offerChange)="refreshOffer()" />
    }
  </dm-mms-quote-details-header>
  <div class="container">
    <router-outlet />
  </div>
  <dm-ui-overflow-spinner [showSpinner]="fetchingOffer()" type="fixed" />
</dm-mms-ui-full-page>
