import { ChangeDetectorRef, Directive } from '@angular/core';
import { CollectionListClassDirective } from '@dm-workspace/shared';
import { IOrder, OrderSearchParams } from '@dm-workspace/types';
import { catchError, Observable, of } from 'rxjs';
import { OrdersApiService } from '@dm-workspace/data-access';
import { finalize, map } from 'rxjs/operators';

@Directive()
export abstract class OrdersListClassDirective extends CollectionListClassDirective<OrderSearchParams> {
  public orders: IOrder[] = [];

  protected constructor(
    protected ordersService: OrdersApiService,
    protected cd: ChangeDetectorRef
  ) {
    super();
  }

  protected fetchContracts(): Observable<IOrder[]> {
    this.showSpinner = true;

    return this.ordersService.fetchPaginated(this.getParams()).pipe(
      map((x) => {
        this.pagination = x.metadata;
        this.orders = x.values;
        return x.values;
      }),
      catchError(() => {
        this.pagination = {};
        this.orders = [];
        return of(null);
      }),
      finalize(() => {
        this.showSpinner = false;
        this.cd.detectChanges();
      })
    );
  }
}
