import { DestroyRef, Directive, ElementRef, inject, Input, OnInit } from '@angular/core';
import { MmsNavRoutes } from '@dm-workspace/mms-panel/mms-layout';
import { NavigationEnd, Router } from '@angular/router';
import { combineLatest, filter, map, shareReplay, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MarinasService } from '@dm-workspace/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[routerLink][childNavRoutes]',
})
export class MmsLayoutNavRoutesActiveDirective implements OnInit {
  #destroyRef = inject(DestroyRef);
  #marinasService = inject(MarinasService);
  #elementRef = inject(ElementRef<HTMLElement>);
  #router = inject(Router);

  @Input() childNavRoutes: MmsNavRoutes['children'];

  #selectedMarinaCode$ = this.#marinasService.selectedMarina$.pipe(
    map((marina) => marina?.code),
    tap((selectedMarinaCode) => this.#checkLinkActivation(selectedMarinaCode))
  );
  #routerChange$ = this.#router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    shareReplay(1)
  );
  #isActive = false;

  public ngOnInit() {
    combineLatest([this.#routerChange$, this.#selectedMarinaCode$])
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(([routerEvent, selectedMarinaCode]) => {
        this.#checkLinkActivation(selectedMarinaCode);
      });
  }

  #checkLinkActivation(selectedMarinaCode: string) {
    const isActive = !!this.childNavRoutes?.find((childRoute) => {
      const urlWithMarinaCode = childRoute.path.replace('$marinaCode', selectedMarinaCode);
      return this.#router.isActive(urlWithMarinaCode, false);
    });

    if (this.#isActive === isActive) {
      return;
    }

    this.#toggleActiveClass(isActive);
  }

  #toggleActiveClass(isActive: boolean) {
    this.#isActive = isActive;
    if (isActive) {
      this.#elementRef.nativeElement.classList.add('active');
    } else {
      this.#elementRef.nativeElement.classList.remove('active');
    }
  }
}
