import { Pipe, PipeTransform } from '@angular/core';
import { MmsQuoteActivityType } from '@dm-workspace/types';
import { TranslatePipe } from '@ngx-translate/core';

@Pipe({
  name: 'quoteActivityTypeTranslate',
})
export class MmsEnquiryActivityTypeTranslatePipe extends TranslatePipe implements PipeTransform {
  public override transform(quoteActivityType: MmsQuoteActivityType): string {
    if (!quoteActivityType) {
      return super.transform(null);
    }
    return super.transform('QUOTE_ACTIVITY_TYPE_TITLE_.' + quoteActivityType);
  }
}
