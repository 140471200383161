<dm-ui-modal header="REPORT_CRUISE">
  <ng-container body>
    <p>{{ "CRUISE_MODAL.MMI.0" | translate }}</p>

    <div class="d-flex row justify-content-start align-items-start">
      <dm-form-group class="col-2">
        <label>{{ "BERTH" | translate }}</label>
        <p class="small">{{ booking.resource.name }}</p>
      </dm-form-group>
      <div class="col">
        <dm-form-group>
          <label>{{ "EXIT_DATE" | translate }}</label>
          <dm-form-date-time-picker
            dmForm
            #exitDate="ngModel"
            [(ngModel)]="operationTimestamp"
            [required]="true"
            placeholder="{{ datePlaceholderFormat }}"
            [minDate]="minExitDate"
            [maxDate]="maxExitDate"
           />
          <dm-form-error-message [control]="exitDate.control" />
        </dm-form-group>

        <dm-form-group class="mt-5 mb-5">
          <label>{{ "RETURN_DATE" | translate }}</label>
          <dm-form-date-time-picker
            dmForm
            #returnDate="ngModel"
            [(ngModel)]="declaredReturnDate"
            [required]="true"
            placeholder="{{ datePlaceholderFormat }}"
            [maxDate]="maxReturnDate"
            [minDate]="minReturnDate"
           />
          <dm-form-error-message [control]="returnDate.control" />
        </dm-form-group>
      </div>
    </div>
    <ng-container *dmHasFeature="('' | features).berthReuse">
      <p>{{ "DECIDE_IF_BERTH_SHOULD_BE_AVAILABLE_FOR_BOOKING" | translate }}</p>
      <dm-form-group>
        <dm-form-checkbox [(ngModel)]="allowsBerthReuse">
          <label class="small" label>{{ "CONFIRM_THE_POSSIBILITY_OF_BOOKING" | translate }}</label>
        </dm-form-checkbox>
      </dm-form-group>
    </ng-container>
  </ng-container>

  <ng-container footer>
    <button class="btn btn-tertiary btn-narrow" type="button" (click)="closeModal(false)">
      {{ "CANCEL" | translate }}
    </button>
    <button type="button" class="btn btn-primary" (click)="onAction()" [disabled]="pending">
      <dm-ui-spinner [showSpinner]="pending" />
      {{ "CONFIRM" | translate }}
    </button>
  </ng-container>
</dm-ui-modal>
