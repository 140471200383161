import { Pipe, PipeTransform } from '@angular/core';
import { BerthStatus, BerthStatusInMarina, MarinaMapColorStatus } from '@dm-workspace/types';

import { MapViewTypes } from '@dm-workspace/utils';
import { MapBerthClass } from '../berths.class';
import { defaultBerthOptions, getBerthColorByStatus, mergePolygonOptionsWithColor } from '@dm-workspace/map-utils';

@Pipe({
  name: 'berthPolygonStyleByStatus',
})
export class BerthPolygonStyleByStatusPipe implements PipeTransform {
  transform(berth: MapBerthClass, filters: BerthStatusInMarina[], viewType: MapViewTypes): google.maps.PolygonOptions {
    if (berth.data.isInMaintenance) {
      return mergePolygonOptionsWithColor(MarinaMapColorStatus.black);
    }
    const status = berth.visibleStatus(viewType, filters);
    return (
      (status && mergePolygonOptionsWithColor(getBerthColorByStatus(status))) || {
        ...defaultBerthOptions,
        fillOpacity: 0.4,
        strokeOpacity: 1,
      }
    );
  }
}

@Pipe({
  name: 'berthPolygonStyleByBerthStatus',
})
export class BerthPolygonStyleByBerthStatus implements PipeTransform {
  transform(berthStatus: BerthStatus): google.maps.PolygonOptions {
    switch (berthStatus) {
      case BerthStatus.unavailableOnline:
        return mergePolygonOptionsWithColor(MarinaMapColorStatus.gray);
      case BerthStatus.inaccessible:
        return mergePolygonOptionsWithColor(MarinaMapColorStatus.darkGray);
      case BerthStatus.disabled:
        return mergePolygonOptionsWithColor(MarinaMapColorStatus.black);
      default:
        return defaultBerthOptions;
    }
  }
}
