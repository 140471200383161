import { Component, computed, effect, inject, input, signal, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, map } from 'rxjs';
import { getBoatsAlertStatuses, MapViewTypes } from '@dm-workspace/utils';
import { BerthAvailabilityReservationDto, GroupedPylons, BoatPylonInfo } from '@dm-workspace/types';
import { MapBerthClass } from '../../berths.class';
import { ResourceBookingApiService } from '@dm-workspace/data-access';
import { NotificationService } from '@dm-workspace/notification';
import { ApiValidator } from '@dm-workspace/shared';

@Component({
  selector: 'dm-map-berth-details-drawer',
  templateUrl: './map-berth-details-drawer.component.html',
  styleUrls: ['./map-berth-details-drawer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MapBerthDetailsDrawerComponent {
  #resourceBookingService = inject(ResourceBookingApiService);
  #notificationService = inject(NotificationService);
  marinaCode$ = inject(ActivatedRoute).params.pipe(map((params) => params.marinaCode));

  viewTypes = MapViewTypes;
  berth = input.required<MapBerthClass>();
  pylon = input.required<GroupedPylons>();

  viewType = input.required<MapViewTypes>();
  berthBoatsPylonConnectionInfo = input<BoatPylonInfo[] | undefined>();
  extendedBookingsData = signal<BerthAvailabilityReservationDto[]>(null);
  isLoading = signal<boolean>(false);

  berthBoatsUtilitiesConnection = computed(() => [
    ...new Set(this.berthBoatsPylonConnectionInfo()?.map((item) => item.type)),
  ]);

  constructor() {
    effect(
      () => {
        if (this.berth() && this.berth().data.bookings.length > 0) {
          this.isLoading.set(true);
          forkJoin(
            this.berth().data.bookings.map((booking) => {
              return this.#resourceBookingService.getResourceById(booking.reservationId).pipe(
                map((bookingInfo) => {
                  return {
                    ...booking,
                    customerDisplayName: bookingInfo.customer?.displayName,
                    customerId: bookingInfo.customer?.id,
                    id: bookingInfo.id,
                    bookedProducts: bookingInfo.bookedProducts,
                    humanReadableId: bookingInfo.humanReadableId,
                  } as BerthAvailabilityReservationDto;
                })
              );
            })
          ).subscribe({
            next: (responses) => {
              this.isLoading.set(false);
              this.extendedBookingsData.set(responses);
            },
            error: (err) => {
              this.#notificationService.openError(ApiValidator.getApiNotificationError(err));
              throw err;
            },
          });
        } else {
          this.isLoading.set(false);
          this.extendedBookingsData.set([]);
        }
      },
      { allowSignalWrites: true }
    );
  }

  protected readonly getBoatsAlertStatuses = getBoatsAlertStatuses;
}
