import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BoatType } from '@dm-workspace/types';

@Component({
  selector: 'dm-map-map-berth-details-boat-name',
  templateUrl: './map-berth-details-boat-name.component.html',
  styleUrl: './map-berth-details-boat-name.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapBerthDetailsBoatNameComponent {
  @Input() name!: string;
  @Input() boatId!: string;
  boatType: BoatType;
}
