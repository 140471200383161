import { ChangeDetectionStrategy, Component, inject, Input, OnInit, signal } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { MmsPylonsApiService } from '@dm-workspace/data-access';
import { finalize, tap } from 'rxjs/operators';
import { ApiValidator } from '@dm-workspace/shared';
import { NotificationService } from '@dm-workspace/notification';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { IMarinaPylonsResponse } from '@dm-workspace/types';

@Component({
  selector: 'dm-mms-shared-socket-connect-modal',
  templateUrl: './mms-socket-turn-off-on-modal.component.html',
  styleUrls: ['./mms-socket-turn-off-on-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsSocketTurnOffOnModalComponent implements OnInit {
  @Input() socket: IMarinaPylonsResponse;
  #activeModal = inject(NgbActiveModal);
  #pylonService = inject(MmsPylonsApiService);
  #notificationService = inject(NotificationService);
  protected apiCalling = signal<boolean>(false);
  public socketForm = inject(FormBuilder).group({
    pylonName: [],
    socketId: [],
    bookingId: [null, Validators.required],
  });

  public onSubmit() {
    this.apiCalling.set(true);
    return this.#pylonService
      .switchSocketState(this.socket.pylonCode, this.socket.outputName, {
        state: !this.socket.isUsed,
        referenceId: this.socketForm.controls.bookingId?.value || this.socket.bookingReferenceId,
      })
      .pipe(
        catchError((res: HttpErrorResponse) => {
          this.#notificationService.openError(ApiValidator.getApiNotificationError(res));
          return throwError(() => res);
        }),
        finalize(() => {
          this.apiCalling.set(false);
        }),
        tap(() => {
          this.#notificationService.openSuccess();
          this.#activeModal.close(true);
        })
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.socketForm.patchValue({
      socketId: this.socket.outputName,
      pylonName: this.socket.pylonName,
    });
    if (this.socket.isUsed || this.socket.bookingReferenceId) {
      this.socketForm.removeControl('bookingId');
    }
    this.socketForm.controls.pylonName.disable();
    this.socketForm.controls.socketId.disable();
  }
  public dismissModal() {
    this.#activeModal.dismiss();
  }
}
