import { Component, HostBinding, inject, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationExtrasState } from '../../../../../../../types/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'dm-mms-ui-full-page-header',
  templateUrl: './mms-ui-full-page-header.component.html',
  styleUrls: ['./mms-ui-full-page-header.component.scss'],
})
export class MmsUiFullPageHeaderComponent implements OnInit {
  #router = inject(Router);
  #location = inject(Location);

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('returnUrl') public backLinkInput: string;
  @HostBinding('class.showing-close')
  public returnUrl: string;

  public ngOnInit() {
    const backLinkState = (this.#location.getState() as NavigationExtrasState)?.returnUrl;
    this.returnUrl = backLinkState || this.backLinkInput;
  }

  public onReturnClick() {
    this.#router.navigateByUrl(this.returnUrl);
  }
}
