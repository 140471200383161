import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { fadeAnimation } from '@dm-workspace/shared';
import { MmsDashboardApiService } from '@dm-workspace/data-access';
import { MmsDashboardQuotesStatName, MmsDashboardStats, PaginationMetadataNormal, Quote } from '@dm-workspace/types';
import {
  MmsDashboardPathStatNameArray,
  MmsDashboardStatsViewComponent,
} from '../../class/mms-dashboard-stats-view.directive';
import { MarinasService } from '@dm-workspace/core';

export type MmsDashboardQuotesPath = 'new' | 'sent' | 'accepted';

@Component({
  selector: 'dm-mms-dashboard-quotes-view',
  templateUrl: './mms-dashboard-quotes-view.component.html',
  styleUrls: ['./mms-dashboard-quotes-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeAnimation],
})
export class MmsDashboardQuotesViewComponent extends MmsDashboardStatsViewComponent<MmsDashboardQuotesStatName, Quote> {
  private readonly dashboardApiService = inject(MmsDashboardApiService);
  readonly #marinasService = inject(MarinasService);
  protected readonly selectedMarina$ = this.#marinasService.selectedMarina$;
  public static pathStatNameArray: MmsDashboardPathStatNameArray<MmsDashboardQuotesPath, MmsDashboardQuotesStatName> = [
    { path: 'new', statName: 'newQuotes' },
    { path: 'sent', statName: 'sentQuotes' },
    { path: 'accepted', statName: 'acceptedQuotes' },
  ] as const;

  protected getRouteStats = ({ acceptedQuotes, newQuotes, sentQuotes }: MmsDashboardStats) => {
    return {
      acceptedQuotes,
      newQuotes,
      sentQuotes,
    };
  };

  protected pathToStatName(path: string) {
    const item = MmsDashboardQuotesViewComponent.pathStatNameArray.find((v) => v.path === path);
    return item?.statName as MmsDashboardQuotesStatName;
  }

  protected apiFetchCall = (statName: MmsDashboardQuotesStatName, pagination: PaginationMetadataNormal) =>
    this.dashboardApiService.fetchQuotesByStatName(statName, pagination);
}
