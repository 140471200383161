import { IBoatAddMovementPayload, IBoatDepartureArrivalPayload, ITransferBookingPayload } from '@dm-workspace/types';

export enum ACTION_TYPES {
  editGap = 'EDIT_GAP',
  transferBooking = 'TRANSFER_BOOKING',
  moveBoat = 'MOVE_BOAT',
  departBoat = 'DEPART_BOAT',
  reportDeparture = 'REPORT_DEPARTURE',
  reportArrival = 'REPORT_ARRIVAL',
  quickQuote = 'QUICK_QUOTE',
}

export type BoatAction<A, P> = {
  action: A;
  payload: P;
};
export type BoatActions =
  | BoatAction<
      Exclude<
        ACTION_TYPES,
        | ACTION_TYPES.reportDeparture
        | ACTION_TYPES.reportArrival
        | ACTION_TYPES.transferBooking
        | ACTION_TYPES.quickQuote
      >,
      IBoatAddMovementPayload
    >
  | BoatAction<ACTION_TYPES.transferBooking, ITransferBookingPayload>
  | BoatAction<ACTION_TYPES.reportDeparture | ACTION_TYPES.reportArrival, IBoatDepartureArrivalPayload>
  | BoatAction<ACTION_TYPES.quickQuote, { boatId: string }>;
