import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BerthStatus, IBerthDto } from '@dm-workspace/types';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { BerthsApiService } from '@dm-workspace/data-access';
import { Observable, Subscription } from 'rxjs';
import { ApiValidatorService } from '@dm-workspace/forms';
import { NotificationService } from '@dm-workspace/notification';
import { FormView } from '@dm-workspace/core';
import { HttpErrorResponse } from '@angular/common/http';

type FormValue = {
  status: BerthStatus;
  note?: string;
};

@Component({
  selector: 'dm-map-berth-status-modal',
  templateUrl: './berth-status-modal.component.html',
  styleUrls: ['./berth-status-modal.component.scss'],
})
export class BerthStatusModalComponent extends FormView<FormValue, IBerthDto> implements OnInit, OnDestroy {
  @Input() berth!: IBerthDto;

  public readonly statuses = BerthStatus;

  private statusFormControl = this.fb.control(null, [Validators.required]);
  private noteFormControl = this.fb.control(null, [Validators.required]);

  private subscription = new Subscription();

  constructor(
    apiValidator: ApiValidatorService,
    private modalRef: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private berthsApiService: BerthsApiService
  ) {
    super(apiValidator);
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      status: this.statusFormControl,
      note: this.noteFormControl,
    });

    this.subscription.add(
      this.statusFormControl.valueChanges.subscribe((v: BerthStatus) => this.onBerthStatusChange(v))
    );

    this.form.patchValue(this.berth);
  }

  private onBerthStatusChange(berthStatus: BerthStatus) {
    if (berthStatus === BerthStatus.inaccessible) {
      this.noteFormControl.enable();
    } else {
      this.noteFormControl.disable();
    }
  }

  protected parseForm(formData: FormValue): FormValue {
    const { status, note } = formData;

    return {
      status,
      note: status === BerthStatus.inaccessible ? note : null,
    };
  }

  protected apiCall(params: FormValue): Observable<IBerthDto> {
    return this.berthsApiService.update(this.berth.id, params);
  }

  protected onSuccess(res: IBerthDto) {
    super.onSuccess(res);

    this.modalRef.close(res);
  }

  protected onError(res: HttpErrorResponse) {
    super.onError(res);

    this.apiErrors = this.apiValidator.parseApiValidationErrorsToObject(res.error);
    this.notification.add({
      type: 'error',
      content: 'ERRORS.GENERAL',
    });
  }

  close() {
    this.modalRef.dismiss();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
