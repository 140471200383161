<form class="row" [formGroup]="formGroupDirective.form">
  <div class="col-8">
    <dm-form-group>
      <textarea [rows]="3" class="textarea" [placeholder]="('WRITE_AN_COMMENT' | translate) + '...'" dmForm
                formControlName="content"></textarea>
    </dm-form-group>
    <div class="textarea-footer">
      <div class="row">
        <div class="col">
          <dm-form-group>
            <label class="mt-1">
              <input type="checkbox" dmForm formControlName="asLastContact" />
              {{ "SET_LAST_CONTACT_DATE" | translate }}
            </label>
          </dm-form-group>
        </div>
        <div class="col ms-auto text-right typo-body-s color-gray d-flex align-items-center justify-content-end">
          <span>{{ currentTime$ | async | dateFormat : 'dd MMM YYYY, HH:mm' }}</span>
          <span class="i-calendar ms-2" [style.font-size.px]="24"></span>
        </div>
      </div>
    </div>
    <dm-form-error-message controlName="content" />
  </div>

  <div class="col-4">
    <button class="btn btn-primary" type="submit" [disabled]="sending">
      <dm-ui-spinner [showSpinner]="sending" class="me-2" />
      {{ "SAVE" | translate }}
    </button>
  </div>
</form>
