<div class="container">
  <div class="modal-header">
    <h1 class="title">{{ "PREVIEW_EMAIL_TO_THE_CUSTOMER" | translate }}</h1>
  </div>

  <div class="modal-body" #body></div>

  <div class="modal-footer justify-content-between d-flex">
    <div class="d-flex gap-4">
      <button class="btn btn-tertiary btn-narrow" type="button" (click)="onBack()">
        <i class="i-arrow-left"></i>
        {{ "BACK" | translate }}
      </button>

      <button type="button" class="btn btn-tertiary btn-narrow" (click)="onCancel()">
        {{ "CANCEL" | translate }}
      </button>
    </div>

    <button class="btn btn-primary" type="button" (click)="onSubmit()">
      {{ "SEND_EMAIL" | translate }}
    </button>
  </div>
</div>
