import { Pipe, PipeTransform } from '@angular/core';
import { BoatMovementOperation } from '@dm-workspace/types';

@Pipe({
  name: 'boatMovementOperationTranslateKey',
})
export class MmsBoatMovementOperationTranslateKeyPipe implements PipeTransform {
  transform(boatOperation: keyof typeof BoatMovementOperation | string): string {
    return 'BOAT_OPERATIONS.' + boatOperation.toUpperCase();
  }
}
