import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'dm-mms-timeline-item',
  templateUrl: './mms-timeline-item.component.html',
  styleUrls: ['./mms-timeline-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsTimelineItemComponent {
  @HostBinding('class.last-item')
  @Input()
  last: boolean;
}
