import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'dm-mms-dashboard-list-done',
  templateUrl: './mms-dashboard-list-done.component.html',
  styleUrls: ['./mms-dashboard-list-done.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsDashboardListDoneComponent {
  @Input() public header: string;
}
